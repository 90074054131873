import { ApplicationModel } from '../types';
import { useStore } from '../context';
import { ApplicationApiClient } from './apiClient';

export const tryGetApplication = async (storage: ApplicationApiClient) => {
  try {
    const res = await storage.get<ApplicationModel>('application');
    return res;
  } catch (err) {
    console.warn('storage get error ', err);
    return Promise.resolve(undefined);
  }
};

export const trySaveApplication = async (storage: ApplicationApiClient, data: ApplicationModel) => {
  try {
    await storage.save<ApplicationModel>('application', data);
    return true;
  } catch (err) {
    console.warn('storage save error ', err, data);
    return Promise.resolve(false);
  }
};
