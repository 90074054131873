import React, { useEffect } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import _ from 'lodash';
import { useLocalize, GetTradingBlockSiteName, setPageTitle } from '@tradingblock/components';
import { useData } from '../state/selectors';

interface PageTitleProps extends LocalizeContextProps {
  page: string;
  titleKey?: string;
}

const PageTitleComponent: React.FC<PageTitleProps> = ({ page, titleKey, translate }) => {
  const { getTranslationId } = useLocalize();
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;

  useEffect(() => {
    const siteName = GetTradingBlockSiteName(undefined, siteGroup);
    const title = translate(getTranslationId({ id: titleKey || 'title', page }), { siteGroup });
    if (_.isString(title) && !_.startsWith(title, 'Missing translationId')) {
      setPageTitle(title, siteName);
    }
  }, [page, titleKey, siteGroup, translate, getTranslationId]);

  return null;
};

export const PageTitle = withLocalize(PageTitleComponent);
