import { GetQuoteUrl, GetQuoteDividendUrl, GetQuoteEarningsUrl } from '../constants';
import { GetQuoteExtendedUrl } from '../constants';
import _ from 'lodash';
import Axios from 'axios';
export function GetQuotes(api) {
  return function (symbol) {
    var symbs = _.isArray(symbol) ? symbol : [symbol];
    var cancelToken = Axios.CancelToken.source();
    return [api.get(GetQuoteUrl, {
      symbols: symbs.join(','),
      cancelToken: cancelToken.token
    }), cancelToken];
  };
}
export function GetQuotesExtended(api) {
  return function (symbol) {
    return api.get(GetQuoteExtendedUrl, {
      symbol: symbol
    });
  };
}

function GetQuoteDividends(api) {
  return function (range, symbol) {
    return api.get(GetQuoteDividendUrl, {
      symbol: symbol,
      range: range
    });
  };
}

function GetEarnings(api) {
  return function (symbol, period) {
    return api.get(GetQuoteEarningsUrl, {
      symbol: symbol,
      period: period
    });
  };
}

export function GetQuotesCharting(api) {
  return function (symbol) {
    var symbs = _.isArray(symbol) ? symbol : [symbol];
    return api.get(GetQuoteUrl, {
      symbols: symbs.join(',')
    });
  };
}
export var quotes = function quotes(api) {
  return {
    get: GetQuotes(api),
    extended: GetQuotesExtended(api),
    dividends: GetQuoteDividends(api),
    earnings: GetEarnings(api),
    getChartingFeed: GetQuotesCharting(api)
  };
};