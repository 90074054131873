import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import {
  AllRiskToleranceTypes,
  AllTaxbracketTypes,
  AllTimeHorizonTypes,
  AllLiquidityNeedTypes,
  AllExperienceYearTypes,
  AllTradeExperienceYearTypes,
  AllMonetaryRangeTypes,
  AllInvestmentObjectiveTypes,
  ApplicationStep,
  InvestmentObjectiveType,
  ExperienceKey,
  ApplicationStepProps,
  TradeGoalType,
  ExperienceYearType,
  AllIncomeRangeTypes,
  IncomeRangeType,
  MonetaryRangeType,
  RiskToleranceType,
} from '../../../types';
import { Section, FormGroup, HelpText, CheckListField, SelectDropdownField } from '@tradingblock/components';
import {
  getRiskLevelForTradeGoalType,
  getRiskLevelForInvestmentObjectiveType,
  isNonZeroYear,
  updateFieldsDependentOnExperienceYears,
  getAllExperienceKeysForApplication,
  getAllTradeGoalTypesForApplication,
  updateFieldsDependentOnAnnualIncome,
  updateFieldsDependentOnTotalNetWorth,
  updateFieldsDependentOnLiquidNetWorth,
  updateFieldsDependentOnTradeGoalType,
  updateFieldsDependentOnInvestmentObjectiveType,
} from '../../../services';
import { RiskLevelIcon, TradeGoalText, MismatchedDataModal, TradeGoalsVerification } from '..';
import { useData } from '../../../state/selectors';

export const InvestingProfileForm: React.FC<ApplicationStepProps> = ({
  getText,
  values,
  setValues,
  mismatchedDataModal,
  closeMismatchedDataModal,
}) => {
  const visibleSections = useData(d => d.ui.visibleSections);

  const getRiskToleranceTypes = useCallback(() => {
    if (values.investmentObjectiveType === InvestmentObjectiveType.Speculation) {
      return [RiskToleranceType.High];
    }
    return AllRiskToleranceTypes;
  }, [values.investmentObjectiveType]);

  return (
    <>
      {mismatchedDataModal && mismatchedDataModal.type && (
        <MismatchedDataModal
          type={mismatchedDataModal.type}
          onEdit={() => closeMismatchedDataModal({ scrollToTop: true })}
          onContinue={() => closeMismatchedDataModal({ confirmMismatchedData: true, formValues: values })}
          onClose={closeMismatchedDataModal}
        >
          <TradeGoalsVerification application={values} />
        </MismatchedDataModal>
      )}

      <Section>
        <FormGroup title={getText('tradeGoalType')}>
          <HelpText iconSize="lg" help={getText('tradeGoalType.help')}>
            {getText('tradeGoalType', 'description')}
          </HelpText>
          <Field
            component={CheckListField}
            id="tradeGoalType"
            options={_.map(getAllTradeGoalTypesForApplication(values), t => ({
              value: t,
              icon: <RiskLevelIcon level={getRiskLevelForTradeGoalType(t)} />,
            }))}
            type="radio"
            renderOption={(opt: { value: TradeGoalType }) => <TradeGoalText type={opt.value} application={values} />}
            onchange={(_e: any, tradeGoalType: TradeGoalType) =>
              setValues(updateFieldsDependentOnTradeGoalType(values, tradeGoalType))
            }
          />
        </FormGroup>

        <FormGroup title={getText('investmentObjectiveType')}>
          <p>{getText('investmentObjectiveType', 'description')}</p>
          <Field
            component={CheckListField}
            id="investmentObjectiveType"
            options={_.map(AllInvestmentObjectiveTypes, t => ({
              value: t,
              icon: <RiskLevelIcon level={getRiskLevelForInvestmentObjectiveType(t)} />,
            }))}
            type="radio"
            className="checklist-descriptions"
            renderOption={(opt: { value: InvestmentObjectiveType }) => (
              <>
                <strong>{getText(`investmentObjectiveType.${opt.value}`, undefined, { type: 'field' })}</strong>
                <span className="mute">
                  {getText(`investmentObjectiveType.${opt.value}`, 'description', { type: 'field' })}
                </span>
              </>
            )}
            onchange={(_e: any, investmentObjectiveType: InvestmentObjectiveType) =>
              setValues(updateFieldsDependentOnInvestmentObjectiveType(values, investmentObjectiveType))
            }
          />
        </FormGroup>
      </Section>

      {visibleSections && visibleSections > 1 && (
        <Section id={`${ApplicationStep.InvestingProfile}-section-2`}>
          {values.tradeGoalType !== TradeGoalType.StocksBonds && (
            <>
              <h2 className="fields-title">{getText('experience')}</h2>
              <p>{getText('experience', 'description')}</p>

              {_.map(getAllExperienceKeysForApplication(values), (exp: ExperienceKey, i) => (
                <FormGroup key={`experience${i}`}>
                  <div className="row">
                    <div className="col">
                      <Field
                        component={SelectDropdownField}
                        isSearchable={false}
                        id={`experience.${exp}.years`}
                        options={AllExperienceYearTypes}
                        optionsDefaultLabelId="experience.years"
                        onChanged={(years: ExperienceYearType) =>
                          setValues(updateFieldsDependentOnExperienceYears(values, exp, years))
                        }
                      />
                    </div>
                    <div className="col">
                      <Field
                        component={SelectDropdownField}
                        isSearchable={false}
                        id={`experience.${exp}.tradesPerYear`}
                        options={AllTradeExperienceYearTypes}
                        optionsDefaultLabelId="experience.tradesPerYear"
                        disabled={
                          !values.experience || !values.experience[exp] || !isNonZeroYear(values.experience[exp].years)
                        }
                      />
                    </div>
                  </div>
                </FormGroup>
              ))}
            </>
          )}

          <h2 className={`fields-title ${values.tradeGoalType !== TradeGoalType.StocksBonds ? 'mt-5' : ''}`}>
            {getText('income-net-worth')}
          </h2>
          <p>{getText('income-net-worth', 'description')}</p>

          <FormGroup help={getText('annualIncome.help')} toggleableHelp={true}>
            <Field
              component={SelectDropdownField}
              id="annualIncome"
              isSearchable={false}
              options={AllIncomeRangeTypes}
              optionsDefaultLabelId="incomeRangeType"
              onChanged={(annualIncome: IncomeRangeType) =>
                setValues(updateFieldsDependentOnAnnualIncome(values, annualIncome))
              }
            />
          </FormGroup>
          <FormGroup help={getText('totalNetWorth.help')} toggleableHelp={true}>
            <Field
              component={SelectDropdownField}
              id="totalNetWorth"
              isSearchable={false}
              options={AllMonetaryRangeTypes}
              optionsDefaultLabelId="monetaryRangeType"
              onChanged={(totalNetWorth: MonetaryRangeType) =>
                setValues(updateFieldsDependentOnTotalNetWorth(values, totalNetWorth))
              }
            />
          </FormGroup>
          <FormGroup help={getText('liquidNetWorth.help')} toggleableHelp={true}>
            <Field
              component={SelectDropdownField}
              id="liquidNetWorth"
              isSearchable={false}
              options={AllMonetaryRangeTypes}
              optionsDefaultLabelId="monetaryRangeType"
              onChanged={(liquidNetWorth: MonetaryRangeType) =>
                setValues(updateFieldsDependentOnLiquidNetWorth(values, liquidNetWorth))
              }
            />
          </FormGroup>

          <h2 className="fields-title mt-5">{getText('characteristics')}</h2>
          <p>{getText('characteristics', 'description')}</p>

          <FormGroup>
            {values.investmentObjectiveType === InvestmentObjectiveType.Speculation && (
              <p className="mute">{getText('characteristics.risk')}</p>
            )}
            <Field component={SelectDropdownField} isSearchable={false} id="characteristics.risk" options={getRiskToleranceTypes()} />
          </FormGroup>
          <FormGroup>
            <Field component={SelectDropdownField} isSearchable={false} id="characteristics.bracket" options={AllTaxbracketTypes} />
          </FormGroup>
          <FormGroup>
            <Field component={SelectDropdownField} isSearchable={false} id="characteristics.horizon" options={AllTimeHorizonTypes} />
          </FormGroup>
          <FormGroup>
            <Field component={SelectDropdownField} isSearchable={false} id="characteristics.liquidity" options={AllLiquidityNeedTypes} />
          </FormGroup>
        </Section>
      )}
    </>
  );
};
