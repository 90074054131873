import React, { useCallback } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { Section, FormGroup, TextboxField, StepperField } from '@tradingblock/components';
import {
  AllAccountDisclosureKeys,
  AccountDisclosureKey,
  BooleanToggleType,
  AccountDisclosureContingentFields,
  AllBooleanToggleTypes,
  ApplicationFormSection,
  ApplicationStep,
  AllEntityAccountDisclosureKeys,
  AllEntityNonTrustAccountDisclosureKeys,
  ApplicationType,
  EntityAccountType,
  AllServiceProfileKeys,
  ServiceProfileKey,
  AllIssuerDirectCommunicationTypes,
  AllMarketDataRegulatoryKeys,
  MarketDataQuestionnaireKey,
  AllMarketDataInvestmentProKeys,
  AllMarketDataCapitalTradingKeys,
  AllMarketDataBenefitsKeys,
  ApplicationModel,
  AllProSubscriptionTypes,
} from '../../../../types';
import { updateFieldsDependentOnAccountDisclosure, getNumberOfAgreementDisclosureSections } from '../../../../services';
import { PublicOfficialsArrayField } from '../../../fields/PublicOfficialsArray';

export const AccountDisclosuresSection: React.FC<ApplicationFormSection> = ({ values, setValues, getText }) => {
  const disclosureSections = getNumberOfAgreementDisclosureSections(values);
  const isPro = (values: ApplicationModel) => {
    const { primaryAccountHolder } = values;
    const { disclosures } = primaryAccountHolder;
    const { marketData } = values;

    if (
      disclosures.industryEmployed === BooleanToggleType.Yes ||
      disclosures.stakeholder === BooleanToggleType.Yes ||
      disclosures.govOfficial === BooleanToggleType.Yes ||
      marketData.personalUse === BooleanToggleType.No ||
      marketData.associationRegistration === BooleanToggleType.Yes ||
      marketData.benefitsReceived === BooleanToggleType.Yes ||
      marketData.capitalTrading === BooleanToggleType.Yes ||
      marketData.corporationTrading === BooleanToggleType.Yes ||
      marketData.investmentProfessional === BooleanToggleType.Yes ||
      marketData.secCftcRegistration === BooleanToggleType.Yes ||
      marketData.jobFunctions === BooleanToggleType.Yes
    ) {
      return true;
    } else {
      return false;
    }
  };

  // disclosure contingent fields (shown if answered yes)
  const getDisclosureContingentFields = useCallback(
    (key: AccountDisclosureKey) => {
      if (
        values.accountDisclosures &&
        values.accountDisclosures[key] === BooleanToggleType.Yes &&
        AccountDisclosureContingentFields[key]
      ) {
        const id = _.first(AccountDisclosureContingentFields[key]);
        // special case for public officials
        if (id === 'disclosuresEntityPublicOfficials') {
          return (
            <FormGroup>
              <Field component={PublicOfficialsArrayField} id={id} value={values[id]} />
            </FormGroup>
          );
        }

        return (
          <FormGroup>
            {_.map(AccountDisclosureContingentFields[key], id => (
              <div key={id}>
                <Field component={TextboxField} id={id} />
                <br />
              </div>
            ))}
          </FormGroup>
        );
      }
      return null;
    },
    [values]
  );

  return (
    <>
      <Section id={`${ApplicationStep.Agreements}-section-${1 + disclosureSections}`}>
        <h2 className="fields-title">{getText('accountDisclosures')}</h2>
        <p>
          <strong>{getText('accountDisclosures', 'description')}</strong>
        </p>

        {_.map(AllAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
          <div key={`accountDisclosures${i}`}>
            <p>{getText(`accountDisclosures.${d}`)}</p>
            <FormGroup>
              <Field
                component={StepperField}
                id={`accountDisclosures.${d}`}
                options={AllBooleanToggleTypes}
                defaultLabelId="boolean"
                onChanged={(value: BooleanToggleType) =>
                  setValues(updateFieldsDependentOnAccountDisclosure(values, d, value))
                }
              />
            </FormGroup>
            {getDisclosureContingentFields(d)}
          </div>
        ))}

        {values.type === ApplicationType.Entity && (
          <>
            <hr />
            <p>
              <strong>{getText('accountDisclosures.entityDisclosures')}</strong>
            </p>
            {_.map(AllEntityAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
              <div key={`entityAccountDisclosures${i}`}>
                <p>{getText(`accountDisclosures.${d}`)}</p>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`accountDisclosures.${d}`}
                    options={AllBooleanToggleTypes}
                    defaultLabelId="boolean"
                    onChanged={(value: BooleanToggleType) =>
                      setValues(updateFieldsDependentOnAccountDisclosure(values, d, value))
                    }
                  />
                </FormGroup>
                {getDisclosureContingentFields(d)}
              </div>
            ))}

            {values.entityAccountType !== EntityAccountType.PersonalTrust && (
              <>
                <hr />
                <p>
                  <strong>{getText('accountDisclosures.entityNonTrustDisclosures')}</strong>
                </p>
                {_.map(AllEntityNonTrustAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
                  <div key={`entityNonTrustAccountDisclosures${i}`}>
                    <p>{getText(`accountDisclosures.${d}`)}</p>
                    <FormGroup>
                      <Field
                        component={StepperField}
                        id={`accountDisclosures.${d}`}
                        options={AllBooleanToggleTypes}
                        defaultLabelId="boolean"
                        onChanged={(value: BooleanToggleType) =>
                          setValues(updateFieldsDependentOnAccountDisclosure(values, d, value))
                        }
                      />
                    </FormGroup>
                    {getDisclosureContingentFields(d)}
                  </div>
                ))}
              </>
            )}
          </>
        )}

        <h2 className="fields-title">{getText('serviceProfile')}</h2>
        {_.map(AllServiceProfileKeys, (d: ServiceProfileKey, i) => (
          <div key={`serviceProfile${i}`}>
            <p>{getText(`serviceProfile.${d}`)}</p>
            <FormGroup>
              <Field
                component={StepperField}
                id={`serviceProfile.${d}`}
                options={AllIssuerDirectCommunicationTypes}
                defaultLabelId="issuerDirectCommunication"
              />
            </FormGroup>
          </div>
        ))}

        <h2 className="fields-title">{getText('marketData')}</h2>
        <div>
          <p>{getText('marketData.personalUse')}</p>
          <FormGroup>
            <Field
              component={StepperField}
              id={`marketData.personalUse`}
              options={AllBooleanToggleTypes}
              defaultLabelId="boolean"
            />
          </FormGroup>
        </div>
        {values.marketData.personalUse === BooleanToggleType.Yes && (
          <>
            {_.map(AllMarketDataRegulatoryKeys, (d: MarketDataQuestionnaireKey, i) => (
              <div key={`marketData${i}`}>
                <p>{getText(`marketData.${d}`)}</p>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`marketData.${d}`}
                    options={AllBooleanToggleTypes}
                    defaultLabelId="boolean"
                  />
                </FormGroup>
              </div>
            ))}
          </>
        )}
        {(values.marketData.secCftcRegistration === 'yes' ||
          values.marketData.associationRegistration === 'yes' ||
          values.marketData.jobFunctions === 'yes') && (
          <>
            {_.map(AllMarketDataInvestmentProKeys, (d: MarketDataQuestionnaireKey, i) => (
              <div key={`marketData${i}`}>
                <p>{getText(`marketData.${d}`)}</p>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`marketData.${d}`}
                    options={AllBooleanToggleTypes}
                    defaultLabelId="boolean"
                  />
                </FormGroup>
              </div>
            ))}
          </>
        )}
        {values.marketData.investmentProfessional === 'yes' && (
          <>
            {_.map(AllMarketDataCapitalTradingKeys, (d: MarketDataQuestionnaireKey, i) => (
              <div key={`marketData${i}`}>
                <p>{getText(`marketData.${d}`)}</p>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`marketData.${d}`}
                    options={AllBooleanToggleTypes}
                    defaultLabelId="boolean"
                  />
                </FormGroup>
              </div>
            ))}
          </>
        )}
        {values.marketData.capitalTrading === 'yes' && (
          <>
            {_.map(AllMarketDataBenefitsKeys, (d: MarketDataQuestionnaireKey, i) => (
              <div key={`marketData${i}`}>
                <p>{getText(`marketData.${d}`)}</p>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`marketData.${d}`}
                    options={AllBooleanToggleTypes}
                    defaultLabelId="boolean"
                  />
                </FormGroup>
              </div>
            ))}
          </>
        )}
        {isPro(values) && (
          <>
            <h2 className="fields-title">{getText('proSubscriber.header')}</h2>
            <div>
              <p>{getText('proSubscriber')}</p>
              <p>{getText('proSubscriber.next')}</p>
              <ul>
                <li>{getText('proSubscriber.NYSE')}</li>
                <li>{getText('proSubscriber.AMEX')}</li>
                <li>{getText('proSubscriber.NASDAQ')}</li>
                <li>{getText('proSubscriber.OPRA')}</li>
              </ul>
              <p>{getText('proSubscriber.final')}</p>
              <p>{getText('proSubscriber.label')}</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id={`marketDataType`}
                  options={AllProSubscriptionTypes}
                  defaultLabelId="proSubscriber"
                />
              </FormGroup>
            </div>
          </>
        )}
      </Section>
    </>
  );
};
