import _ from 'lodash';
import { TradingBlockLegacyUrlsLookup, TradingBlockUrlsLookup } from '@tradingblock/types';
import { GetSiteGrp, GetSiteName } from './SiteUtilities';
export var GetTradingBlockSiteName = function GetTradingBlockSiteName(accountNumber, siteGroup, origin) {
  return GetSiteName(siteGroup || 'tb', origin, accountNumber);
}; // GetTradingBlockDomain returns domain in start case (i.e. "TradingBlock.com")

export var GetTradingBlockDomain = function GetTradingBlockDomain(accountNumber, siteGroup, origin) {
  var siteName = GetTradingBlockSiteName(accountNumber, siteGroup, origin);
  return "".concat(siteName, ".com");
};
export var GetTradingBlockFullUrl = function GetTradingBlockFullUrl(urlKey, domain, config, origin) {
  var defaultRedirect = _.get(config, 'defaultRedirect');

  var isDev = defaultRedirect && defaultRedirect.includes('devdashboard');
  var isBeta = defaultRedirect && defaultRedirect.includes('betadashboard');

  if (urlKey === 'LegacySiteHomeUrl') {
    return domain === 'MoneyBlock.com' ? 'https://legacy.moneyblock.com' : 'https://legacy.tradingblock.com';
  }

  var fulldomain = domain.toLowerCase();
  var url = TradingBlockUrlsLookup[urlKey];

  if (urlKey === 'OpenNewAccountUrl') {
    var appDomain = isDev ? 'devapplication' : isBeta ? 'betaapplication' : 'application';
    return "https://".concat(appDomain, ".").concat(fulldomain).concat(url);
  }

  var isLegacyURL = urlKey in TradingBlockLegacyUrlsLookup;
  var host = isLegacyURL ? 'legacy' : 'www';
  return "https://".concat(host, ".").concat(fulldomain).concat(url);
};
export var GetLogoutUrl = function GetLogoutUrl(accountNumber, siteGroup) {
  return "/logout?grp=".concat(GetSiteGrp(siteGroup, accountNumber));
};
export var setPageTitle = function setPageTitle(title, siteName) {
  document.title = "".concat(title).concat(title && siteName ? ' - ' : '').concat(siteName || '');
};