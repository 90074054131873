import { useEffect } from 'react';
import { GetUrlParams } from '@tradingblock/api';
import { useDispatcher } from '../components/hooks';
import { UiActions } from '../state/actions';
import { useData } from '../state/selectors';

export const useSiteGroup = () => {
  const dispatch = useDispatcher();
  const params = GetUrlParams(window.location.search);
  const siteGrp = useData(d => d.ui.siteGrp);
  useEffect(() => {
    const grp = process.env.REACT_APP_TB_SITE_GROUP;
    if (grp && siteGrp !== grp) {
      // if arriving with grp param, store in state
      dispatch(UiActions.setSiteGrp(grp));
    }
  }, [params, siteGrp, dispatch]);
};
