import * as tslib_1 from "tslib";
import React from 'react';
import { calculateColumnWidth } from '..';
var InlineHeaderClass = 'inlineheader';
/**
 * Responsive table cells
 */

export var ResponsiveTd = function ResponsiveTd(_a) {
  var header = _a.header,
      children = _a.children,
      numColumns = _a.numColumns,
      columnWidth = _a.columnWidth,
      props = tslib_1.__rest(_a, ["header", "children", "numColumns", "columnWidth"]);

  return React.createElement("td", Object.assign({}, props, {
    style: Object.assign({
      width: columnWidth ? columnWidth : calculateColumnWidth(numColumns)
    }, props.style)
  }), React.createElement("div", {
    className: InlineHeaderClass
  }, header), children || React.createElement("div", null, "\xA0"));
};
export var ResponsiveTdMemo = React.memo(ResponsiveTd);