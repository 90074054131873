import { Country } from './Country';
export var BaseApiVersion = 'v1';
export var BaseApiUrl = "/api/".concat(BaseApiVersion);
export var LoginApiUrl = function LoginApiUrl(subroot) {
  return "".concat(BaseApiUrl, "/").concat(subroot ? "".concat(subroot, "/login") : 'login');
};
export var StorageGridPath = "grid";
export var objects = {
  block: 'block',
  blocks: 'blocks',
  dashboard: 'dashboard',
  dashboards: 'dashboards',
  settings: 'settings',
  debugSettings: 'debugsettings'
};
export var StorageBlocksPath = function StorageBlocksPath(dashboardId) {
  return "".concat(objects.blocks, "/").concat(dashboardId);
};
export var StorageBlockPath = function StorageBlockPath(blockId) {
  return "".concat(objects.block, "/").concat(blockId);
};
export var StorageBlockSettingsPath = function StorageBlockSettingsPath(blockId) {
  return "".concat(objects.block, "/").concat(blockId, "/").concat(objects.settings);
};
export var StorageSettingsBlockPath = "".concat(objects.settings, "/block");
export var StorageSettingsFieldPath = function StorageSettingsFieldPath(field) {
  return "".concat(objects.settings, "/").concat(field);
};
export var StorageSettingsBlockTypeFieldPath = function StorageSettingsBlockTypeFieldPath(type) {
  return StorageSettingsFieldPath("".concat(objects.block, "-").concat(type));
};
export var StorageDashboardsPath = "".concat(objects.dashboards);
export var StorageDashboardPath = "".concat(objects.dashboard);
export var CreateDashboardPath = "".concat(objects.dashboard, "/new");
export var DefaultDashboardPath = "".concat(objects.dashboard, "/defaults");
export var DeleteDashboardPath = function DeleteDashboardPath(dashboardId) {
  return "".concat(objects.dashboard, "/").concat(dashboardId);
};
export var StorageFavoritesPath = "favorites";
export var RefreshTokenPath = "/refresh_token";
export var DashboardSettingsPath = "".concat(objects.settings, "/dashboard");
export var PendingApplicationsPath = '/db/applications';
export var StorageStrategyPath = "resource/strategy";
export var MAX_TRANSFER_AMOUNT = 50000; // accepted countries of residence for accts

export var AcceptedCountries = [Country.Australia, Country.UnitedKingdomOfGreatBritainAndNorthernIreland, Country.UnitedStatesOfAmerica, Country.NewZealand];
export var ClientVersionHeader = 'x-client-version';
export var PERSIST_BLOCKS_DEBOUNCE_MS = 1000;