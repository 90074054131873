import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { components } from 'react-select/lib/components';
import { useDebounce } from 'use-hooks';
import { SelectDropdownIndicator, AutocompleteControlComponent } from './Autocomplete';
import { SelectMenuPortal, defaultStyles } from './BasicSelect';
export var ReactSelectComponents = components;

var DefaultOptionComponent = function DefaultOptionComponent(props) {
  return React.createElement(ReactSelectComponents.Option, Object.assign({}, props));
};

var LoadingResults = function LoadingResults(_ref) {
  var noResultsFound = _ref.noResultsFound;
  return useMemo(function () {
    if (noResultsFound) {
      return React.createElement("div", {
        className: "option select__option"
      }, "Symbol not found");
    }

    return React.createElement("div", {
      className: "option select__option"
    }, "Loading...") //TODO: add in loading icon when component memos correctly <i className="fas fa-circle-notch fa-spin mx-2" />
    ;
  }, [noResultsFound]);
};

export var AsyncAutocomplete = function AsyncAutocomplete(props) {
  var OptionComponent = props.OptionComponent,
      defaultValue = props.defaultValue,
      dropdownIcon = props.dropdownIcon,
      placeholder = props.placeholder,
      search = props.search,
      onSelect = props.onSelect,
      debounceTimeout = props.debounceTimeout,
      minimumLength = props.minimumLength,
      initialValues = props.initialValues,
      isClearable = props.isClearable,
      clearOptionsOnSelect = props.clearOptionsOnSelect,
      value = props.value,
      name = props.name,
      isSearchable = props.isSearchable,
      onClear = props.onClear,
      symbol = props.symbol,
      onBlur = props.onBlur,
      charLimit = props.charLimit,
      rest = props.rest,
      rootPortalId = props.rootPortalId;
  var Option = useMemo(function () {
    return OptionComponent || DefaultOptionComponent;
  }, [OptionComponent]);
  var selectRef = useRef();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      noResultsFound = _useState2[0],
      setNoResultsFound = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      queryValue = _useState4[0],
      setQueryValue = _useState4[1];

  var _useState5 = useState(''),
      _useState6 = _slicedToArray(_useState5, 2),
      inputValue = _useState6[0],
      setInputValue = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      setIsOpen = _useState8[1];

  var _useState9 = useState(_.isNumber(minimumLength) ? minimumLength : 2),
      _useState10 = _slicedToArray(_useState9, 1),
      minLength = _useState10[0];

  var initialSelectOpts = useMemo(function () {
    return _(initialValues || []).union([value]).filter(function (v) {
      return v !== undefined;
    }).uniqBy(function (v) {
      return v.value;
    }).value();
  }, [value, initialValues]);

  var _useState11 = useState(initialSelectOpts),
      _useState12 = _slicedToArray(_useState11, 2),
      selectOptions = _useState12[0],
      setOptions = _useState12[1];

  var setSelectOptions = useCallback(function (values) {
    return setOptions(values);
  }, []);
  var debouncedQueryValue = useDebounce(queryValue, debounceTimeout || 175);
  useEffect(function () {
    setSelectOptions(initialValues);
  }, [initialValues]);
  useEffect(function () {
    if (debouncedQueryValue && debouncedQueryValue.length >= minLength) {
      search(debouncedQueryValue).then(function (res) {
        setSelectOptions(res);

        if (res.length <= 0) {
          setNoResultsFound(true);
        }
      });
    }
  }, [debouncedQueryValue]);
  var onChange = useCallback(function (val, _ref2) {
    var action = _ref2.action;
    onSelect(val);
    setInputValue('');
    var clearOptions = clearOptionsOnSelect && action === 'select-option';

    if (clearOptions || action === 'clear' || action === 'deselect-option' || action === 'remove-value' || action === 'pop-value') {
      setSelectOptions(initialValues || []);
    }

    if (action === 'clear' && onClear) {
      onClear();
    }
  }, [onSelect]);
  var onInputChange = useCallback(function (val, _ref3) {
    var action = _ref3.action;

    switch (action) {
      case 'input-change':
        {
          setInputValue(val);
          setSelectOptions(initialValues || []);
          setQueryValue(val);
          setNoResultsFound(false);
          return;
        }

      case 'menu-close':
        if (selectOptions && selectOptions.length === 0 && !_.isNil(inputValue) && !_.isEmpty(inputValue)) {
          setInputValue(symbol);
          setInputValue('');
          setSelectOptions(initialValues || []);
        }

        return;

      default:
        return;
    }
  }, [setIsOpen, inputValue, setInputValue, search]);
  var getOptionValue = useCallback(function (option) {
    var optionVal = _.join([_.get(option, 'label'), _.get(option, 'searchValue'), _.get(option, 'value')], ' ');

    return optionVal;
  }, []);
  var currentValue = useMemo(function () {
    if (_.isNil(value) && !_.isNil(defaultValue)) {
      var initValue = _.find(selectOptions, function (o) {
        return o.data === defaultValue.data;
      });

      return initValue;
    }

    return value || null;
  }, [defaultValue, value, selectOptions]);
  var formatOptionLabel = useCallback(function (option, labelMeta) {
    if (!_.isNil(defaultValue) && _.isNil(value) && currentValue && currentValue.value === option.value && labelMeta.context === 'value') {
      return React.createElement("div", {
        className: "badge select__placeholder"
      }, placeholder);
    }

    return option.label;
  }, [currentValue, defaultValue, placeholder, value]);
  var styles = useMemo(function () {
    return {
      menuPortal: defaultStyles.menuPortal
    };
  }, [window.scrollY, defaultStyles.menuPortal]);
  useEffect(function () {
    if (symbol && inputValue !== '' && symbol !== inputValue.toUpperCase()) {
      setInputValue(symbol);
      setSelectOptions(initialValues || []);
    }
  }, [symbol]);

  var inputFormat = function inputFormat(inputValue) {
    if (charLimit) {
      return inputValue.slice(0, charLimit);
    }

    return inputValue;
  };

  return React.createElement(Select, Object.assign({}, rest || {}, {
    ref: selectRef,
    placeholder: placeholder,
    value: currentValue,
    inputValue: inputFormat(inputValue),
    onInputChange: onInputChange,
    menuPlacement: 'auto',
    formatOptionLabel: formatOptionLabel,
    onChange: onChange,
    name: name,
    isClearable: _.isBoolean(isClearable) ? isClearable : true,
    isSearchable: _.isBoolean(isSearchable) ? isSearchable : true,
    options: selectOptions,
    className: "basic-multi-select",
    getOptionValue: getOptionValue,
    classNamePrefix: "select",
    menuShouldBlockScroll: 'true',
    styles: styles,
    menuPortalTarget: document.getElementById(rootPortalId ? rootPortalId : 'portal-root'),
    noOptionsMessage: function noOptionsMessage(e) {
      return e.inputValue ? '' : null;
    },
    onKeyDown: function onKeyDown(e) {
      if (e.key === 'Enter') {
        search(inputValue, 1).then(function (res) {
          if (res.length > 0 && res[0].value && res[0].value.toUpperCase() === inputValue.toUpperCase()) {
            onChange(res[0], {
              action: 'set-value'
            });

            if (selectRef && selectRef.current) {
              selectRef.current.blur();
            }
          }
        });
      }
    },
    components: {
      DropdownIndicator: rootPortalId ? undefined : SelectDropdownIndicator(dropdownIcon || 'fa-arrow-right'),
      Control: AutocompleteControlComponent,
      MenuPortal: SelectMenuPortal,
      Option: Option,
      NoOptionsMessage: function NoOptionsMessage() {
        return React.createElement(LoadingResults, {
          noResultsFound: noResultsFound
        });
      }
    },
    onBlur: onBlur
  }));
};