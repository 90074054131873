import React, { useCallback } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
export var Modal = function Modal(_ref) {
  var children = _ref.children,
      show = _ref.show,
      size = _ref.size,
      backdrop = _ref.backdrop,
      isError = _ref.isError,
      keyboard = _ref.keyboard,
      setShow = _ref.setShow,
      className = _ref.className;
  var handleClose = useCallback(function () {
    setShow(false);
  }, [setShow]);
  return React.createElement(BootstrapModal, {
    size: size,
    show: show,
    onHide: handleClose,
    backdrop: 'static',
    keyboard: keyboard,
    dialogClassName: isError ? 'modal-error' : undefined,
    className: className
  }, children);
};