import React, { useEffect } from 'react';
import { Section, Text, ContactPhone, ContactEmail } from '@tradingblock/components';
import { ApplicationLoader } from '../components/application';
import { PageTitle } from '../components';
import { useData } from '../state/selectors';
import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { config } from '../config';

const ExpiredTokenPage: React.FC = () => {
  const isExpired = useData(d => d.ui.storageTokenExpired);
  const onLogout = useLogout();
  useEffect(() => {
    if (isExpired) {
      onLogout();
    }
  }, [isExpired, onLogout]);
  return (
    <ApplicationLoader allowAnonymous>
      <Section>
        <h1>
          <Text id="title" page="expired-token" />
        </h1>
        <p>
          <a className="btn btn-block btn-primary btn-icon" href={`${config.loginUrl}/?type=application&redirect=${config.authRedirect}/auth`}>
            <Text id="relogin" page="expired-token" />
          </a>
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default ExpiredTokenPage;
