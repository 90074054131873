import React, { useMemo } from 'react';
import _ from 'lodash';
import { SummaryStepProps, EntityAccountType, ApplicationType } from '../../../types';
import { EntitySummary } from './account-information/EntitySummary';
import {
  PrimaryAccountHolderContactSummary,
  JointAccountHolderContactSummary,
  UgmaAccountHolderContactSummary,
} from './account-information/AccountHolderContactSummary';
import {
  PrimaryAccountHolderPersonalInfoSummary,
  JointAccountHolderPersonalInfoSummary,
  UgmaAccountHolderPersonalInfoSummary,
} from './account-information/AccountHolderPersonalInfoSummary';
import { EntityFinancialActivitySummary } from './account-information/EntityFinancialActivitySummary';

export const AccountInformationSummary: React.FC<SummaryStepProps> = props => {
  const { application, visibleSections } = props;

  const sections = useMemo(() => {
    if (application === undefined) {
      return [];
    }

    let allSections: React.FC<SummaryStepProps>[] = [];
    if (application.entityAccountType) {
      // entity details
      allSections = [...allSections, EntitySummary];
      if (application.entityAccountType !== EntityAccountType.PersonalTrust) {
        // non-trust entity financial activity
        allSections = [...allSections, EntityFinancialActivitySummary];
      }
    }
    // primary account holder contact / personal info
    allSections = [...allSections, PrimaryAccountHolderContactSummary, PrimaryAccountHolderPersonalInfoSummary];
    if (application.type === ApplicationType.Joint) {
      // joint account holder contact / personal info
      allSections = [...allSections, JointAccountHolderContactSummary, JointAccountHolderPersonalInfoSummary];
    }
    if (application.type === ApplicationType.UGMA) {
      allSections = [...allSections, UgmaAccountHolderContactSummary, UgmaAccountHolderPersonalInfoSummary];
    }

    return _.take(allSections, visibleSections || 1);
  }, [application, visibleSections]);

  return (
    <>
      {_.map(sections, (ChildSummary, index) => (
        <ChildSummary key={`summary-${index}`} {...props} />
      ))}
    </>
  );
};
