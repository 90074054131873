import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { SummaryStepProps } from '../../../../types';
import { FieldValue, DateValue, MaskedValue, AddressValue } from '../../../values';

interface OwnersOfficersListProps extends Pick<SummaryStepProps, 'application' | 'getText' | 'activeLanguage'> {}

export const OwnersOfficersList: React.FC<OwnersOfficersListProps> = ({ application, getText, activeLanguage }) => {
  return (
    <>
      {_.isEmpty(application && application.ownersOfficers) && (
        <p>
          <Text id="skipped" />
        </p>
      )}
      {_.map(application && application.ownersOfficers, (o, i) => (
        <div key={`ownerOfficer${i}`}>
          <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
            {getText('ownersOfficers.name', undefined, { data: { number: i + 1 } })}
          </h3>
          <p>
            <strong>
              <FieldValue value={(o.firstName || o.lastName) && `${o.firstName || ''} ${o.lastName || ''}`} />
            </strong>
            <br />
            {getText('dateOfBirth', undefined, { defaultPage: true })}: <DateValue value={o.dateOfBirth} activeLanguage={activeLanguage} />
            <br />
            {getText('ssn', undefined, { defaultPage: true, data: { itin: <Text id="itin.label" /> } })}: <MaskedValue value={o.ssn} />
            <br />
            <AddressValue value={o.address} />
            {o.isOwner && (
              <>
                <br />
                - <Text id="ownersOfficers.isOwner" type="field" />
              </>
            )}
            {o.isOfficer && (
              <>
                <br />
                - <Text id="ownersOfficers.isOfficer" type="field" />
              </>
            )}
          </p>
        </div>
      ))}
    </>
  );
};
