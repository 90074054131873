import React, { useMemo } from 'react';
import { GetTradingBlockDomain, GetTradingBlockFullUrl } from '../../utilities/site';
import { ExternalLink } from './ExternalLink';
export var TradingBlockLink = function TradingBlockLink(_ref) {
  var to = _ref.to,
      accountNumber = _ref.accountNumber,
      className = _ref.className,
      children = _ref.children,
      origin = _ref.origin,
      siteGroup = _ref.siteGroup,
      config = _ref.config;
  var domain = useMemo(function () {
    return GetTradingBlockDomain(accountNumber, siteGroup, origin);
  }, [accountNumber, siteGroup, origin]);
  var url = useMemo(function () {
    return GetTradingBlockFullUrl(to, domain, config, origin);
  }, [to, domain]);
  return React.createElement(ExternalLink, {
    href: to.includes('LegacySiteHomeUrl') && origin === 'mb' ? 'https://legacy.moneyblock.com/default.aspx' : url,
    className: className
  }, children);
};