import React from 'react';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
import { FormGroup, TextboxField } from '@tradingblock/components';
import { ApplicationStepProps } from '../../../../types';

interface InCommonInterestPercentFieldsProps
  extends Pick<ApplicationStepProps, 'values' | 'setValues' | 'getText' | 'errors'> {}

export const InCommonInterestPercentFields: React.FC<InCommonInterestPercentFieldsProps> = ({ values }) => {
  const accountHolders = [values.primaryAccountHolder, values.secondaryAccountHolder];

  const getPrefix = (index: number) => {
    return index === 0 ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  };

  return (
    <FieldArray
      name="inCommonInterestPercent"
      render={arrHelpers => (
        <>
          {_.map(accountHolders, (h, i) => (
            <>
              <div key={`accountHolder${i}`}>
                <h2 className="fields-title">
                  {h ? h.firstName : null} {h ? h.lastName : null}
                </h2>
                <FormGroup>
                  <Field
                    component={TextboxField}
                    id={`${getPrefix(i)}.jointTenantsInCommonInterestPercent`}
                    type="number"
                    min="1"
                    max="100"
                    step="1"
                  />
                </FormGroup>
              </div>
            </>
          ))}
        </>
      )}
    />
  );
};
