import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { useData } from '../../state/selectors';
import { ApplicationModel, FieldRequirementType } from '../../types';
import { getFieldRequirements, isRequiredLengthValid, isRequiredDigitsValid, isRequiredLowercaseValid, isRequiredUppercaseValid, isRequiredSpecialCharsValid, getSpecialCharsAsHtml, isUserNameContainedInPassword, isUserNameEqualToEmail } from '../../services';
import { FieldRequirement } from './FieldRequirement';

interface FieldRequirementsHelpProps {
  values: ApplicationModel;
  field: keyof Pick<ApplicationModel, 'userName' | 'password'>;
  formSubmitted?: boolean;
}

export const FieldRequirementsHelp: React.FC<FieldRequirementsHelpProps> = ({ values, field, formSubmitted }) => {
  const value = values[field] || '';
  const userRequirements = useData(d => d.ui.userRequirements);
  const requirements = getFieldRequirements(field, userRequirements);

  const charData = useMemo(() => {
    return {
      chars: getSpecialCharsAsHtml(requirements.acceptableSpecialChars),
    };
  }, [requirements]);

  const hintValidation = (validationFunction: boolean) => {
    return validationFunction ? 'var(--teal)' : 'red'
  }

  const getFieldRequirementProps = useCallback(
    (type: FieldRequirementType) => {
      switch (type) {
        case 'length-min':
        case 'length-range':
          return {
            type,
            min: requirements.minLength,
            max: requirements.maxLength,
            valid: formSubmitted ? isRequiredLengthValid(value, requirements.minLength, requirements.maxLength) : undefined,
            color: hintValidation(isRequiredLengthValid(value, requirements.minLength, requirements.maxLength))
          };
        case 'lower-min':
          return {
            type,
            min: requirements.minLowerCount,
            valid: formSubmitted ? isRequiredLowercaseValid(value, requirements.minLowerCount) : undefined,
            color: hintValidation(isRequiredLowercaseValid(value, requirements.minLowerCount))
          };
        case 'upper-min':
          return {
            type,
            min: requirements.minUpperCount,
            valid: formSubmitted ? isRequiredUppercaseValid(value, requirements.minUpperCount) : undefined,
            color: hintValidation(isRequiredUppercaseValid(value, requirements.minUpperCount))
          };
        case 'digit-min':
          return {
            type,
            min: requirements.minDigitCount,
            valid: formSubmitted ? isRequiredDigitsValid(value, requirements.minDigitCount) : undefined,
            color: hintValidation(isRequiredDigitsValid(value, requirements.minDigitCount))
          };
        case 'special-min':
          return {
            type,
            min: requirements.minSpecialCount,
            valid: formSubmitted ? isRequiredSpecialCharsValid(value, requirements.minSpecialCount, requirements.acceptableSpecialChars) : undefined,
            color: hintValidation(isRequiredSpecialCharsValid(value, requirements.minSpecialCount, requirements.acceptableSpecialChars)),
            data: charData,
          };
        case 'special-acceptable':
          return {
            type,
            min: -1,
            valid: formSubmitted ? isRequiredSpecialCharsValid(value, 0, requirements.acceptableSpecialChars) : undefined,
            color: hintValidation(isRequiredSpecialCharsValid(value, 0, requirements.acceptableSpecialChars)),
            data: charData,
          };
        case 'contains-username':
          return {
            type,
            min: -1,
            valid: formSubmitted && field === 'password' ? !isUserNameContainedInPassword(values) : undefined,
            color: hintValidation(field === 'password' && !isUserNameContainedInPassword(values)),
          };
        case 'equals-email':
          return {
            type,
            min: -1,
            valid: formSubmitted && field === 'userName' ? !isUserNameEqualToEmail(values) : undefined,
            color: hintValidation(field === 'userName' && !isUserNameEqualToEmail(values)),
          };
      }
    },
    [requirements, field, value, values, formSubmitted, charData]
  );

  return (
    <>
      <Text id={`${field}.help`} page="secure" />
      <ul>
        <FieldRequirement {...getFieldRequirementProps(requirements.maxLength ? 'length-range' : 'length-min')} />
        <FieldRequirement {...getFieldRequirementProps('lower-min')} />
        <FieldRequirement {...getFieldRequirementProps('upper-min')} />
        <FieldRequirement {...getFieldRequirementProps('digit-min')} />
        <FieldRequirement {...getFieldRequirementProps('special-min')} />
        {!requirements.minSpecialCount && requirements.acceptableSpecialChars && <FieldRequirement {...getFieldRequirementProps('special-acceptable')} />}
        {field === 'userName' && <FieldRequirement {...getFieldRequirementProps('equals-email')} />}
        {field === 'password' && <FieldRequirement {...getFieldRequirementProps('contains-username')} />}
      </ul>
    </>
  );
};
