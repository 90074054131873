import React, { useCallback, useMemo, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import moment from 'moment';
import _ from 'lodash';
import { ApplicationStep, ApplicationType, DefaultApplicationModel, MismatchedDataModalOptions } from '../types';
import { useApplication, useStore } from '../context';
import {
  getApplicationStepUrl,
  isPreviouslyTouchedApplicationStep,
  validateApplication,
  checkForMismatchedDataType,
  getVisibleSectionsForApplicationStep,
  getNextApplicationStep,
  isFinalApplicationSteps,
  isApplicationStepInvalid,
} from '../services';
import { Section, Text, TextOptions, Footer, Svg } from '@tradingblock/components';
import { PageTitle, Errors } from '../components';
import { ApplicationLoader, ApplicationSummaryComponents } from '../components/application';
import { useData } from '../state/selectors';
import { useDispatcher } from '../components/hooks';
import { ApplicationActions, UiActions } from '../state/actions';
import { shouldRemoveClearerOption } from '../hooks/useApplicationSteps';

interface SummaryPageProps extends LocalizeContextProps {}

const SummaryPage: React.FC<SummaryPageProps> = ({ activeLanguage }) => {
  const storage = useStore();
  const dispatch = useDispatcher();
  const [{ mismatchedDataModal }, { setMismatchedDataModal }] = useApplication();

  const application = useData(d => d.data.application);

  const app = application || DefaultApplicationModel;
  const { latestStepSaved, latestStepCompleted, accountId } = app;
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;
  const clearer = useData(d => d.ui.clearer);

  const accountCreated = useMemo(() => !!accountId, [accountId]);
  const onFinalSteps = useMemo(() => latestStepSaved && isFinalApplicationSteps(latestStepSaved), [latestStepSaved]);
  const canBeginFinalSteps = useMemo(
    () => latestStepCompleted && isFinalApplicationSteps(getNextApplicationStep(latestStepCompleted)),
    [latestStepCompleted]
  );
  const nextStep = useMemo(() => {
    // if account has been created, cannot continue to next step
    if (accountCreated) {
      return undefined;
    }
    if (canBeginFinalSteps && latestStepCompleted === ApplicationStep.Beneficiaries) {
      return ApplicationStep.Agreements;
    }
    return latestStepSaved;
  }, [canBeginFinalSteps, latestStepSaved, latestStepCompleted, accountCreated]);

  // page used for text translation ids
  const page = useMemo(() => (canBeginFinalSteps ? 'review' : 'summary'), [canBeginFinalSteps]);

  // validate entire application (including agreements if on final steps)
  const errors = useMemo(() => {
    return validateApplication(app, latestStepSaved === ApplicationStep.Sign ? [ApplicationStep.Agreements] : []);
  }, [app, latestStepSaved]);

  // prevent clicking continue btn if errors present on final steps
  const continueDisabled = useMemo(() => {
    const isInvalid = latestStepSaved && onFinalSteps && isApplicationStepInvalid(latestStepSaved, app, false);
    const isPartialSubmission = app.isPartialEntitySubmission;
    // don't return a null value here
    return isInvalid || isPartialSubmission ? true : false;
  }, [latestStepSaved, onFinalSteps, app]);

  const primaryBtnProps = useMemo(() => {
    if (canBeginFinalSteps) {
      if (nextStep === ApplicationStep.Sign && !isApplicationStepInvalid(nextStep, app)) {
        return {
          id: 'sign-and-submit',
          page,
        };
      }
      // default continue btn
      return {
        id: 'btns.continue',
      };
    }
    return {
      id: 'resume',
      page,
    };
  }, [canBeginFinalSteps, nextStep, app, page]);

  const checkForMismatchedData = useCallback(() => {
    const mismatchedDataType = checkForMismatchedDataType(application, ApplicationStep.InvestingProfile);
    if (mismatchedDataType) {
      setMismatchedDataModal({ type: mismatchedDataType });
    }
  }, [application, setMismatchedDataModal]);

  const onPrimaryBtnClick = useCallback(() => {
    if (nextStep) {
      if (isApplicationStepInvalid(nextStep, application)) {
        checkForMismatchedData();
      } else {
        dispatch(UiActions.setNextPage(getApplicationStepUrl(nextStep)));
      }
    }
  }, [nextStep, application, checkForMismatchedData, dispatch]);

  useEffect(() => {
    // check for mismatched data once past first 2 steps
    if (
      latestStepCompleted &&
      !_.includes([ApplicationStep.SecureAccount, ApplicationStep.AccountType], latestStepCompleted)
    ) {
      checkForMismatchedData();
    }
  }, [latestStepCompleted, checkForMismatchedData]);

  const heardAbout = useData(d => d.ui.heardAbout);
  const shouldSkipClearer = shouldRemoveClearerOption(
    application ? application.hearAboutUsId : undefined,
    application ? application.repId : undefined,
    heardAbout
  );

  const isStepVisible = useCallback(
    (step: ApplicationStep) => {
      return (
        isPreviouslyTouchedApplicationStep(step, latestStepSaved) &&
        !isApplicationStepInvalid(step, application) &&
        ((step === ApplicationStep.ClearerType && !shouldSkipClearer) || step !== ApplicationStep.ClearerType)
      );
    },
    [latestStepSaved, application]
  );

  const closeMismatchedDataModal = useCallback(
    async (options?: MismatchedDataModalOptions) => {
      setMismatchedDataModal(false);
      if (application && mismatchedDataModal && options && options.confirmMismatchedData) {
        // save application to confirm data mismatch
        const applicationWithConfirmation = {
          ...application,
          confirmedMismatchedDataTypes: [mismatchedDataModal.type, ...(application.confirmedMismatchedDataTypes || [])],
        };
        dispatch(
          ApplicationActions.requestSaveApplication({
            application: applicationWithConfirmation,
            saveType: 'confirm-data-mismatch',
            storage,
          })
        );
      } else if (options && options.nextStep) {
        dispatch(UiActions.setNextPage(getApplicationStepUrl(options.nextStep)));
      }
    },
    [application, mismatchedDataModal, setMismatchedDataModal, dispatch, storage]
  );

  const getStepProps = (step: ApplicationStep) => ({
    application,
    visibleSections: getVisibleSectionsForApplicationStep(step, app),
    getText: (id: string, textKey?: string, options?: TextOptions) => (
      <Text id={id} page={step} textKey={textKey} {...options} />
    ),
    activeLanguage,
    mismatchedDataModal: mismatchedDataModal || undefined,
    closeMismatchedDataModal,
  });

  // if nothing saved yet, send to first step
  if (application && !latestStepSaved) {
    return <Redirect to={getApplicationStepUrl(ApplicationStep.SecureAccount, siteGrp)} />;
  }

  return (
    <ApplicationLoader>
      <PageTitle page={page} />
      <Section>
        <div className="step-title">
          <h1>
            <Text id="step-title" page={page} />
          </h1>
        </div>
      </Section>

      {application && application.accountId && (
        <Section>
          <p>
            <strong>
              <Text
                id="status"
                page="summary"
                data={{ submittedDate: moment(application.applicationSentOn).format('M/D/YYYY h:mm a') }}
              />
            </strong>
          </p>
          <Link className="btn btn-block btn-primary btn-icon" to="/status">
            <Text id="view-status" page="summary" />
          </Link>
        </Section>
      )}
      {application && !application.accountId && application.isPartialEntitySubmission && (
        <Section>
          <p>
            <strong>
              <Text
                id="status"
                page="summary"
                data={{ submittedDate: moment(application.applicationSentOn).format('M/D/YYYY h:mm a') }}
              />
            </strong>
          </p>
          <Link className="btn btn-block btn-primary btn-icon" to="/status">
            <Text id="view-status" page="summary" />
          </Link>
        </Section>
      )}

      {continueDisabled && !_.isEmpty(errors) && (
        <Section>
          <p className="error">
            <Text id="errors" page="summary" />
          </p>
        </Section>
      )}

      {_.map(
        ApplicationSummaryComponents,
        (Step, key: ApplicationStep) =>
          (key === ApplicationStep.SecureAccount || isStepVisible(key)) && (
            <Section key={`step${key}`}>
              <h2>
                <Text id={`sections.${key}`} page="summary" />
              </h2>
              <Step {...getStepProps(key)} />
              {!accountCreated && application && application.type !== ApplicationType.Entity && (
                <p>
                  <Link
                    to={getApplicationStepUrl(key)}
                    hidden={
                      (siteGrp === 'mb' && key === ApplicationStep.ClearerType) ||
                      (clearer && key === ApplicationStep.ClearerType)
                        ? true
                        : false
                    }
                  >
                    <Text id="make-changes" page="summary" />
                  </Link>
                </p>
              )}
            </Section>
          )
      )}

      {onFinalSteps && !_.isEmpty(errors) && <Errors values={application} errors={errors} />}

      <Footer
        primaryPanel={
          nextStep && (
            <button
              className="btn btn-block btn-primary btn-icon"
              type="button"
              disabled={continueDisabled}
              onClick={onPrimaryBtnClick}
            >
              <Text {...primaryBtnProps} />{' '}
              <span className="tb-icon">
                <Svg path="icon-arrow" />
              </span>
            </button>
          )
        }
        secondaryPanel={
          <Link className="btn btn-block btn-outline-dark" to="/logout" tabIndex={-1}>
            <Text id="btns.sign-out" />
          </Link>
        }
      />
    </ApplicationLoader>
  );
};

export default withLocalize(SummaryPage);
