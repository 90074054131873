import { ExpirationType, OptionExpirationType } from '@tradingblock/types';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { populateTimeZones, findTimeZone, convertTimeToDate } from 'timezone-support/dist/lookup-convert';
import * as tzdata from 'timezone-support/dist/data-2012-2022';
import { convertDateToTime } from 'timezone-support';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
populateTimeZones(tzdata);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

function isDayjs(d) {
  if (d === undefined) {
    return false;
  }

  return dayjs.isDayjs(d);
}

export var getLocalDateFromTimezone = function getLocalDateFromTimezone(date) {
  var tz = findTimeZone(timeZone);
  var dateVal = isDayjs(date) ? date.toDate() : date || new Date();
  var localDate = tz ? convertTimeToDate(convertDateToTime(dateVal)) : dateVal;
  return dayjs(localDate);
};
export var getWeekdayOfMonth = function getWeekdayOfMonth(date, dayOfWeek, weekOfMonth) {
  var firstDayOfMonth = dayjs(date).date(1); // start on 1st of month, then set day of week (starts at 'Sunday' === 0)

  var firstDayOfWeek = dayjs(firstDayOfMonth).day(dayOfWeek); // add extra week if setting day of week above shifted to previous month

  var weeksToAdd = firstDayOfWeek.isBefore(firstDayOfMonth) ? weekOfMonth + 1 : weekOfMonth; // shift date to week of the month (one less since starting in first week)

  return firstDayOfWeek.add(weeksToAdd - 1, 'week');
};
export var getExpirationType = function getExpirationType(date) {
  // monthly expirations are on 3rd friday of the month ('Friday' === 5)
  var dateValue = dayjs.isDayjs(date) ? date.toDate() : date;
  var monthlyExpiration = getWeekdayOfMonth(dateValue, 5, 3);
  return dayjs(dateValue).isSame(monthlyExpiration, 'date') ? ExpirationType.Monthly : ExpirationType.Weekly;
};
export var formatExpiration = function formatExpiration(dto) {
  var date = dayjs(dto.Date).toDate();
  var isWeekly = dto.ExpirationFreqCode === 'W' || dto.ExpirationFreqCode === 'MW' || dto.ExpirationFreqCode === 'WW' || dto.ExpirationFreqCode.includes('Week') || dto.ExpirationFreqCode.includes('W') || dto.ExpirationFreqCode === 'ME';
  var isQuarterly = dto.ExpirationFreqCode === 'QuarterlyEOM';
  return Object.assign({}, dto, {
    date: date,
    type: isWeekly ? ExpirationType.Weekly : isQuarterly ? ExpirationType.Quarterly : ExpirationType.Monthly,
    optionExpirationType: dto.Time,
    rootSymbol: dto.RootSymbol
  });
};
export var toExpiration = function toExpiration(date, rootSymbol, optionExpirationType) {
  return {
    date: date,
    type: getExpirationType(date),
    optionExpirationType: optionExpirationType || OptionExpirationType.PM,
    rootSymbol: rootSymbol
  };
};
export var toShort = function toShort(date) {
  return (isDayjs(date) ? date : dayjs(date)).format('MMM D, YYYY');
};
export var ToUtcMidnight = function ToUtcMidnight(date) {
  return dayjs(date || new Date()).set('hour', 0).set('minute', 0).set('second', 0);
};
export var isBetweenInclusive = function isBetweenInclusive(date, startDate, endDate) {
  if (date) {
    var d = dayjs(date);
    return d.isAfter(startDate, 'date') && d.isBefore(endDate, 'date') || d.isSame(startDate, 'date') || d.isSame(endDate, 'date');
  }

  return false;
};
export var isDateBetweenExclusive = function isDateBetweenExclusive(date, startDate, endDate) {
  if (date) {
    var d = dayjs(date);
    return d.isAfter(startDate, 'date') && d.isBefore(endDate, 'date');
  }

  return false;
};
export var isBefore = function isBefore(curr, date) {
  var compDate = dayjs.isDayjs(date) ? date.toDate() : date;
  return curr ? dayjs(curr).isBefore(compDate, 'date') : false;
};
export var TodayDate = function TodayDate() {
  return dayjs(new Date()).hour(0).toDate();
};
export var isToday = function isToday(curr) {
  return curr ? dayjs(curr).isSame(TodayDate(), 'date') : false;
}; // TODO: type fix

export var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;