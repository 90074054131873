import React from 'react';
import { ContactEmail, ContactPhone, SiteName, TradingBlockLink } from '.';
import { Text } from '../localize/Text';
import { ExternalLink } from './ExternalLink';
export var FooterTeaser = function FooterTeaser(_ref) {
  var origin = _ref.origin,
      siteGroup = _ref.siteGroup;
  return React.createElement("div", {
    className: "footer-teaser"
  }, React.createElement("div", {
    className: "footer-teaser-primary"
  }, React.createElement("h2", {
    className: "footer-heading"
  }, React.createElement(Text, {
    id: "contact"
  })), React.createElement("p", {
    className: "contact-phone"
  }, React.createElement(ContactPhone, {
    siteGroup: siteGroup
  })), React.createElement("p", null, React.createElement(Text, {
    id: "fax"
  }), ": ", React.createElement(Text, {
    id: "contact.fax"
  })), React.createElement("strong", null, React.createElement(ContactEmail, {
    className: "contact-email",
    siteGroup: siteGroup
  })), React.createElement("p", null, React.createElement(TradingBlockLink, {
    siteGroup: siteGroup,
    to: siteGroup === 'tb' ? 'TB_CRS' : 'MB_CRS',
    origin: origin,
    className: 'external'
  }, React.createElement("strong", null, React.createElement(Text, {
    id: "footer.disclaimer.clientRelationshipSummaryUrl"
  }))))), React.createElement("div", {
    className: "footer-teaser-secondary"
  }, React.createElement("div", {
    className: "disclaimer"
  }, React.createElement("p", null, React.createElement(Text, {
    id: "footer.disclaimer",
    siteGroup: siteGroup,
    data: {
      risksOfOptions: React.createElement(TradingBlockLink, {
        siteGroup: siteGroup,
        to: "RisksOfOptionsUrl",
        origin: origin
      }, React.createElement(Text, {
        id: "footer.disclaimer.risksOfOptions"
      })),
      risksOfFutures: React.createElement(ExternalLink, {
        href: "https://www.nfa.futures.org/members/member-resources/files/security-futures-disclosure.pdf"
      }, React.createElement(Text, {
        id: "footer.disclaimer.risksOfFutures"
      })),
      risksOfDayTrading: React.createElement(TradingBlockLink, {
        siteGroup: siteGroup,
        to: "RisksOfDayTradingUrl",
        origin: origin
      }, React.createElement(Text, {
        id: "footer.disclaimer.risksOfDayTrading"
      })),
      servicesAgreement: React.createElement(TradingBlockLink, {
        siteGroup: siteGroup,
        to: "ServicesAgreementUrl",
        origin: origin
      }, React.createElement(Text, {
        id: "footer.disclaimer.servicesAgreement"
      })),
      contactUs: React.createElement(TradingBlockLink, {
        siteGroup: siteGroup,
        to: "CustomerServiceUrl",
        origin: origin
      }, React.createElement(Text, {
        id: "contact"
      }))
    }
  })), React.createElement("p", null, React.createElement(Text, {
    id: "footer.subaccounts",
    siteGroup: siteGroup
  })), React.createElement("p", null, "\xA9 ", new Date().getFullYear(), " ", React.createElement(SiteName, {
    siteGroup: siteGroup,
    origin: origin
  }), "\xA0\xA0\xA0\xA0\xA0", React.createElement(Text, {
    id: "footer.member-orgs",
    data: {
      finra: React.createElement(ExternalLink, {
        href: "https://www.finra.org"
      }, React.createElement(Text, {
        id: "footer.member-orgs.finra"
      })),
      nfa: React.createElement(ExternalLink, {
        href: "https://www.nfa.futures.org"
      }, React.createElement(Text, {
        id: "footer.member-orgs.nfa"
      })),
      sipc: React.createElement(ExternalLink, {
        href: "https://www.sipc.org"
      }, React.createElement(Text, {
        id: "footer.member-orgs.sipc"
      }))
    }
  }), "\xA0\xA0\xA0\xA0\xA0", React.createElement(Text, {
    id: "footer.find-us",
    data: {
      brokerCheck: React.createElement(ExternalLink, {
        href: "https://brokercheck.finra.org/firm/summary/128605"
      }, React.createElement(Text, {
        id: "footer.find-us.brokerCheck"
      }))
    }
  })))));
};