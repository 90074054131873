import React, { useContext } from 'react';
import { Field } from 'formik';
import { AllStates, Country, getGroupedCountryOptions } from '@tradingblock/types';
import { Section, FormGroup, SelectDropdownField, StepperField, TextboxField } from '@tradingblock/components';

import {
  AllApplicationTypes,
  AllFlexibilityTypes,
  AllRetirementAccountTypes,
  AllJointAccountTypes,
  BooleanToggleType,
  ApplicationType,
  FlexibilityType,
  ApplicationStepProps,
  AllBooleanToggleTypes,
  RetirementAccountType,
  JointAccountType,
  AllEntityTypes,
  EntityAccountType,
  ClearerType,
  RQDRetirementAccountTypes,
} from '../../../types';
import {
  updateFieldsDependentOnOwnerCountry,
  updateFieldsDependentOnRetirementAccount,
  updateFieldsDependentOnApplicationType,
  updateFieldsDependentOnRetirementAccountType,
  updateFieldsDependentOnJointAccountType,
  updateFieldsDependentOnFlexibilityType,
  updateFieldsDependentOnEntityType,
} from '../../../services';
import { useStateSelector } from '../../../state/selectors';
import { useLinkClicked } from './LinkClickedContext';

export const AccountTypeForm: React.FC<ApplicationStepProps> = ({ getText, values, setValues }) => {
  const siteGrp = useStateSelector(s => s.ui.siteGrp);
  const entityListLinks = {
    tb: {
      llc: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/TB_AppPack_LLC.pdf',
      corp: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/TB_AppPack_Corporate.pdf',
      partnership: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/TB_AppPack_Partnership.pdf',
      personalTrust: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/TB_AppPack_Trust_ind.pdf',
      businessTrust: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/TB_AppPack_Trust.pdf',
    },
    mb: {
      llc: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/MB_AppPack_LLC.pdf',
      corp: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/MB_AppPack_Corporate.pdf',
      partnership: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/MB_AppPack_Partnership.pdf',
      personalTrust: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/MB_AppPack_Trust_ind.pdf',
      businessTrust: 'https://legacy.tradingblock.com/docs/EntityAccountAppPDFs/MB_AppPack_Trust.pdf',
    },
    rqd: {
      entityApplicationPacket: 'https://legacy.tradingblock.com/Admin/Forms/RQD/AppPacks/TB_RQD_Entity_AppPack.pdf',
      entityDisclosurePacket:
        'https://legacy.tradingblock.com/Admin/Forms/RQD/Disclosures/TB_RQD_Disclosure_Packet_Review_and_Retain.pdf',
    },
  };

  const disabledFlexibilityTypeOptions =
    values.type === ApplicationType.UGMA ||
    (values.retirementAccount === BooleanToggleType.Yes &&
      values.retirementAccountType === RetirementAccountType.CoverDell)
      ? [FlexibilityType.Margin]
      : undefined;

  const { setLinkClicked } = useLinkClicked();
  return (
    <>
      <Section>
        <FormGroup title={getText('ownerCountry')}>
          <Field
            component={SelectDropdownField}
            id="ownerCountry"
            options={
              values.clearer === ClearerType.RQD
                ? [Country.Argentina, Country.Chile, Country.Peru, Country.UnitedStatesOfAmerica, Country.Uruguay]
                : getGroupedCountryOptions()
            }
            defaultLabelId="address.country"
            optionsDefaultLabelId="country"
            onChanged={(ownerCountry: Country) => setValues(updateFieldsDependentOnOwnerCountry(values, ownerCountry))}
          />
        </FormGroup>

        <FormGroup
          className="mb-5"
          title={getText('accountType')}
          help={values.type && getText(`applicationType.help.${values.type}`)}
        >
          <Field
            component={StepperField}
            id="type"
            options={
              values.clearer === ClearerType.RQD
                ? [ApplicationType.Individual, ApplicationType.Entity]
                : AllApplicationTypes
            }
            defaultLabelId="applicationType"
            onChanged={(type: ApplicationType) => setValues(updateFieldsDependentOnApplicationType(values, type))}
          />
        </FormGroup>

        {values.type === ApplicationType.Individual && (
          <>
            <FormGroup
              title={getText('retirementAccount')}
              className={values.retirementAccount !== BooleanToggleType.Yes ? 'mb-5' : ''}
            >
              <Field
                component={StepperField}
                id="retirementAccount"
                options={AllBooleanToggleTypes}
                defaultLabelId="boolean"
                onChanged={(retirementAccount: BooleanToggleType) =>
                  setValues(updateFieldsDependentOnRetirementAccount(values, retirementAccount))
                }
                disabledOptions={
                  values.ownerCountry !== Country.UnitedStatesOfAmerica ? [BooleanToggleType.Yes] : undefined
                }
              />
            </FormGroup>
            {values.retirementAccount === BooleanToggleType.Yes && (
              <>
                <FormGroup
                  title={getText('retirementAccountType')}
                  help={
                    values.retirementAccountType &&
                    getText(`retirementAccountType.help.${values.retirementAccountType}`)
                  }
                  className="mb-5"
                >
                  <Field
                    component={SelectDropdownField}
                    isSearchable={false}
                    id="retirementAccountType"
                    options={values.clearer === ClearerType.RQD ? RQDRetirementAccountTypes : AllRetirementAccountTypes}
                    onChanged={(retirementAccountType: RetirementAccountType) =>
                      setValues(updateFieldsDependentOnRetirementAccountType(values, retirementAccountType))
                    }
                  />
                </FormGroup>
                {values.retirementAccountType === RetirementAccountType.Simple && (
                  <FormGroup title={getText('simpleIraPlanName')}>
                    <Field component={TextboxField} id="simpleIraPlanName" />
                  </FormGroup>
                )}
                {(values.retirementAccountType === RetirementAccountType.Beneficiary ||
                  values.retirementAccountType === RetirementAccountType.RothBeneficiary) && (
                  <FormGroup title={getText('beneficiaryIraDecedentName')}>
                    <Field component={TextboxField} id="beneficiaryIraDecedentName" />
                  </FormGroup>
                )}
              </>
            )}
          </>
        )}

        {values.type === ApplicationType.Joint && (
          <FormGroup
            title={getText('jointAccountType')}
            help={values.jointAccountType && getText(`jointAccountType.help.${values.jointAccountType}`)}
            className="mb-5"
          >
            <Field
              component={SelectDropdownField}
              isSearchable={false}
              id="jointAccountType"
              options={AllJointAccountTypes}
              onChanged={(jointAccountType: JointAccountType) =>
                setValues(updateFieldsDependentOnJointAccountType(values, jointAccountType))
              }
            />
            {values.jointAccountType === 'Community' && values.ownerCountry === Country.UnitedStatesOfAmerica && (
              <div className="mt-2">
                <Field
                  component={SelectDropdownField}
                  id={`jointCommunityPropertyState`}
                  options={AllStates}
                  defaultLabelId="jointCommunityPropertyState"
                  optionsDefaultLabelId="state"
                  labelPrefix={getText('jointCommunityPropertyState ')}
                />
              </div>
            )}
            {values.jointAccountType === 'Community' && values.ownerCountry !== Country.UnitedStatesOfAmerica && (
              <div className="mt-2">
                <Field
                  component={TextboxField}
                  id={`jointCommunityPropertyState`}
                  defaultLabelId="jointCommunityPropertyState"
                  optionsdefaultlabelid="state"
                  labelPrefix={getText('jointCommunityPropertyState ')}
                />
              </div>
            )}
          </FormGroup>
        )}

        {values.type === ApplicationType.Entity && (
          <>
            {getText('entityDescription')}
            <FormGroup
              title={getText('entityAccountType')}
              className="mb-5"
              help={values.entityAccountType && getText(`entityAccountType.help.${values.entityAccountType}`)}
            >
              <Field
                component={SelectDropdownField}
                isSearchable={false}
                id="entityAccountType"
                options={
                  values.clearer === ClearerType.RQD
                    ? AllEntityTypes.filter(entityType => entityType !== EntityAccountType.PersonalTrust)
                    : AllEntityTypes
                }
                onChanged={(entityAccountType: EntityAccountType) =>
                  setValues(updateFieldsDependentOnEntityType(values, entityAccountType))
                }
              />
            </FormGroup>

            {values.entityAccountType && (
              <>
                <h2 className="fields-title">{getText('entityList.header')}</h2>
                <ul>
                  {(values.entityAccountType === EntityAccountType.LlcCCorporation ||
                    values.entityAccountType === EntityAccountType.LlcSCorporation) &&
                    values.clearer !== ClearerType.RQD && (
                      <li className="highlighted-list-item">
                        <a
                          href={siteGrp === 'mb' ? entityListLinks.mb.llc : entityListLinks.tb.llc}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setLinkClicked(true)}
                        >
                          {getText('entityList.llc')}
                        </a>
                      </li>
                    )}
                  {(values.entityAccountType === EntityAccountType.CCorporation ||
                    values.entityAccountType === EntityAccountType.SCorporation) &&
                    values.clearer !== ClearerType.RQD && (
                      <li className="highlighted-list-item">
                        <a
                          href={siteGrp === 'mb' ? entityListLinks.mb.corp : entityListLinks.tb.corp}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setLinkClicked(true)}
                        >
                          {getText('entityList.corp')}
                        </a>
                      </li>
                    )}
                  {values.entityAccountType === EntityAccountType.Partnership && values.clearer !== ClearerType.RQD && (
                    <li className="highlighted-list-item">
                      <a
                        href={siteGrp === 'mb' ? entityListLinks.mb.partnership : entityListLinks.tb.partnership}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setLinkClicked(true)}
                      >
                        {getText('entityList.partnership')}
                      </a>
                    </li>
                  )}
                  {values.entityAccountType === EntityAccountType.LlcPartnership && values.clearer !== ClearerType.RQD && (
                    <li className="highlighted-list-item">
                      <a
                        href={siteGrp === 'mb' ? entityListLinks.mb.partnership : entityListLinks.tb.partnership}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setLinkClicked(true)}
                      >
                        {getText('entityList.llcPartnership')}
                      </a>
                    </li>
                  )}
                  {values.entityAccountType === EntityAccountType.PersonalTrust && values.clearer !== ClearerType.RQD && (
                    <li className="highlighted-list-item">
                      <a
                        href={siteGrp === 'mb' ? entityListLinks.mb.personalTrust : entityListLinks.tb.personalTrust}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setLinkClicked(true)}
                      >
                        {getText('entityList.personalTrust')}
                      </a>
                    </li>
                  )}
                  {values.entityAccountType === EntityAccountType.BusinessTrust && values.clearer !== ClearerType.RQD && (
                    <li className="highlighted-list-item">
                      <a
                        href={siteGrp === 'mb' ? entityListLinks.mb.businessTrust : entityListLinks.tb.businessTrust}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setLinkClicked(true)}
                      >
                        {getText('entityList.businessTrust')}
                      </a>
                    </li>
                  )}
                  {values.clearer === ClearerType.RQD && (
                    <>
                      <li className="highlighted-list-item">
                        <a
                          href={entityListLinks.rqd.entityApplicationPacket}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setLinkClicked(true)}
                        >
                          {getText('entityList.rqd.entityPacket')}
                        </a>
                      </li>
                      <li className="highlighted-list-item">
                        <a
                          href={entityListLinks.rqd.entityDisclosurePacket}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setLinkClicked(true)}
                        >
                          {getText('entityList.rqd.disclosurePacket')}
                        </a>
                      </li>
                    </>
                  )}
                </ul>
                <p>
                  <strong>Important:</strong> Please make sure to click the link provided above to complete your
                  registration process. Your account setup is not finished until you've followed the link and submitted
                  the PDF. If you have any questions or need further assistance, don't hesitate to reach out to us.
                </p>
              </>
            )}

            {/* TODO: Once entity applications can be handled through web app, uncomment code for additional documents required for opening entity accounts
            <FormGroup title={getText('review-documents')}>
              <p className="mb-0 pb-0">{getText('review-documents', 'description')}</p>
              <span className="mute">{getText('review-documents.files')}</span>

              {(values.entityAccountType === EntityAccountType.LlcCCorporation ||
                values.entityAccountType === EntityAccountType.LlcSCorporation) && (
                <>
                  <ul className="mt-4">
                    <li>{getText('review-documents.llc.articles-of-incorp')}</li>
                    <li>{getText('review-documents.llc.operating-agreement')}</li>
                    <li>{getText('review-documents.llc.photo-id')}</li>
                    <li>{getText('review-documents.llc.3210')}</li>
                  </ul>
                </>
              )}

              {(values.entityAccountType === EntityAccountType.CCorporation ||
                values.entityAccountType === EntityAccountType.SCorporation) && (
                <>
                  <ul className="mt-4">
                    <li>{getText('review-documents.corporate.articles-of-incorp')}</li>
                    <li>{getText('review-documents.corporate.photo-id')}</li>
                    <li>{getText('review-documents.corporate.3210')}</li>
                  </ul>
                </>
              )}

              {values.entityAccountType === EntityAccountType.Partnership && (
                <>
                  <ul className="mt-4">
                    <li>{getText('review-documents.partnerships.cert-limited-partnership')}</li>
                    <li>{getText('review-documents.partnerships.photo-id')}</li>
                    <li>{getText('review-documents.partnerships.ppm-blank-sub')}</li>
                    <li>{getText('review-documents.partnerships.3210')}</li>
                  </ul>
                </>
              )}

              {(values.entityAccountType === EntityAccountType.BusinessTrust ||
                values.entityAccountType === EntityAccountType.PersonalTrust) && (
                <>
                  <ul className="mt-4">
                    <li>{getText('review-documents.trusts.trust-agreement')}</li>
                    <li>{getText('review-documents.trusts.photo-id')}</li>
                    <li>{getText('review-documents.trusts.w9')}</li>
                    <li>{getText('review-documents.trusts.3210')}</li>
                  </ul>
                </>
              )}

              {values.ownerCountry && values.ownerCountry !== Country.UnitedStatesOfAmerica && (
                <>
                  <p className="mute pb-0 mb-0">{getText('review-documents.foreign')}</p>
                  <ul className="mt-0">
                    <li>{getText('review-documents.foreign.drivers-license')}</li>
                    <li>{getText('review-documents.foreign.passport')}</li>
                    <li>{getText('review-documents.foreign.signature-image')}</li>
                  </ul>
                </>
              )}
            </FormGroup> */}
          </>
        )}
        {values.type !== ApplicationType.Entity && (
          <>
            <FormGroup
              className="mb-5"
              title={getText('flexibilityType')}
              help={
                values.flexibilityType &&
                getText(
                  `flexibilityType.help.${values.flexibilityType}${
                    values.retirementAccount === BooleanToggleType.Yes ? 'Ira' : ''
                  }`
                )
              }
            >
              <Field
                component={StepperField}
                id="flexibilityType"
                options={AllFlexibilityTypes}
                disabledOptions={disabledFlexibilityTypeOptions}
                onChanged={(flexibilityType: FlexibilityType) =>
                  setValues(updateFieldsDependentOnFlexibilityType(values, flexibilityType))
                }
              />
            </FormGroup>
            <FormGroup title={getText('review-documents')}>
              <p className="mb-0 pb-0">{getText('review-documents', 'description')}</p>
              <span className="mute">{getText('review-documents.files')}</span>
              {values.type === ApplicationType.Individual &&
                (values.retirementAccount === BooleanToggleType.No || !values.retirementAccount) && (
                  <>
                    <ul className="mt-4">
                      <li>{getText('review-documents.individual.photo-id')}</li>
                      <li>{getText('review-documents.individual.ss-card')}</li>
                      <li>{getText('review-documents.individual.3210')}</li>
                    </ul>
                  </>
                )}
              {values.type === ApplicationType.Individual &&
                (values.retirementAccount === BooleanToggleType.Yes &&
                  values.retirementAccountType !== RetirementAccountType.CoverDell) && (
                  <>
                    <ul className="mt-4">
                      <li>{getText('review-documents.iras.photo-id')}</li>
                      <li>{getText('review-documents.iras.ss-card')}</li>
                      <li>{getText('review-documents.iras.3210')}</li>
                    </ul>
                  </>
                )}
              {values.type === ApplicationType.Individual &&
                (values.retirementAccount === BooleanToggleType.Yes &&
                  values.retirementAccountType === RetirementAccountType.CoverDell) && (
                  <>
                    <ul className="mt-4">
                      <li>{getText('review-documents.coverdell.photo-id')}</li>
                      <li>{getText('review-documents.coverdell.ss-card')}</li>
                      <li>{getText('review-documents.coverdell.3210')}</li>
                    </ul>
                  </>
                )}
              {values.type === ApplicationType.Joint && (
                <>
                  <ul className="mt-4">
                    <span className="mute">{getText('review-documents.joint')}</span>
                    <li>{getText('review-documents.joint.photo-id')}</li>
                    <li>{getText('review-documents.joint.ss-card')}</li>
                    <li>{getText('review-documents.joint.3210')}</li>
                  </ul>
                </>
              )}
              {values.type === ApplicationType.UGMA && (
                <>
                  <ul className="mt-4">
                    <li>{getText('review-documents.ugma.photo-id')}</li>
                    <li>{getText('review-documents.ugma.ss-card')}</li>
                    <li>{getText('review-documents.ugma.3210')}</li>
                  </ul>
                </>
              )}
              {values.ownerCountry && values.ownerCountry !== Country.UnitedStatesOfAmerica && (
                <>
                  <p className="mute pb-0 mb-0">{getText('review-documents.foreign')}</p>
                  <ul className="mt-0">
                    <li>{getText('review-documents.foreign.drivers-license')}</li>
                    <li>{getText('review-documents.foreign.passport')}</li>
                    <li>{getText('review-documents.foreign.signature-image')}</li>
                  </ul>
                </>
              )}
            </FormGroup>
          </>
        )}
      </Section>
    </>
  );
};
