import React from 'react';
import _ from 'lodash';
import { UserRequirementsInfo, FieldRequirements } from '@tradingblock/types';
import { ApplicationModel } from '../types';

const DefaultFieldRequirements: FieldRequirements = {
  maxLength: 0,
  minDigitCount: 0,
  minLength: 0,
  minLowerCount: 0,
  minSpecialCount: 0,
  minUpperCount: 0,
};

export const getFieldRequirements = (
  field: keyof Pick<ApplicationModel, 'userName' | 'password'>,
  userRequirements: UserRequirementsInfo | undefined
) => {
  if (userRequirements) {
    if (field === 'userName') {
      return userRequirements.nameRequirements;
    }
    if (field === 'password') {
      const requirements = userRequirements.passwordRequirements;
      // password requirements has min/max char that we need to use to generate unicode range
      const acceptableSpecialChars = generateSpecialCharUnicodeRange(
        requirements.minCharAllowed,
        requirements.maxCharAllowed
      );
      return { ...requirements, acceptableSpecialChars };
    }
  }
  return DefaultFieldRequirements;
};

export const getSpecialCharsAsHtml = (acceptableSpecialChars: string | undefined) => {
  // set special chars string as html, to interpret unicode html entities
  if (acceptableSpecialChars) {
    return <span dangerouslySetInnerHTML={{ __html: acceptableSpecialChars.replace(/\\u00/g, '&#x') }} />;
  }
  return undefined;
};

export const isUserNameEqualToEmail = ({ userName, primaryAccountHolder: { email } }: ApplicationModel) => {
  return _.isEqual(_.toLower(_.trim(userName)), _.toLower(_.trim(email)));
};

export const isUserNameContainedInPassword = ({ password, userName }: ApplicationModel) => {
  return _.includes(_.toLower(_.trim(password)), _.toLower(_.trim(userName)));
};

export const isUserNameValid = (
  userName: string,
  requirements: UserRequirementsInfo | undefined,
  values: ApplicationModel
) => {
  // separate from dynamic requirements, don't allow userName to be same as login email
  if (isUserNameEqualToEmail(values)) {
    return false;
  }
  if (!requirements) return false;
  const {
    minLength,
    maxLength,
    minDigitCount,
    minSpecialCount,
    acceptableSpecialChars,
  } = requirements.nameRequirements;
  if (!isRequiredLengthValid(userName, minLength, maxLength)) {
    return false;
  }
  if (!isRequiredDigitsValid(userName, minDigitCount)) {
    return false;
  }
  if (!isRequiredSpecialCharsValid(userName, minSpecialCount, acceptableSpecialChars)) {
    return false;
  }
  return true;
};

export const isPasswordValid = (
  password: string,
  requirements: UserRequirementsInfo | undefined,
  values: ApplicationModel
) => {
  // separate from dynamic requirements, don't allow password to contain userName
  if (isUserNameContainedInPassword(values)) {
    return false;
  }
  if (!requirements) return false;
  const {
    minLength,
    maxLength,
    minLowerCount,
    minUpperCount,
    minDigitCount,
    minSpecialCount,
    minCharAllowed,
    maxCharAllowed,
  } = requirements.passwordRequirements;
  const acceptableSpecialChars = generateSpecialCharUnicodeRange(minCharAllowed, maxCharAllowed);
  if (!isRequiredLengthValid(password, minLength, maxLength)) {
    return false;
  }
  if (!isRequiredDigitsValid(password, minDigitCount)) {
    return false;
  }
  if (!isRequiredLowercaseValid(password, minLowerCount)) {
    return false;
  }
  if (!isRequiredUppercaseValid(password, minUpperCount)) {
    return false;
  }
  if (!isRequiredSpecialCharsValid(password, minSpecialCount, acceptableSpecialChars)) {
    return false;
  }
  return true;
};

const getRequirementsRegex = (minLength: number, maxLength: number, acceptableSpecialChars: string | undefined) => {
  // Escape hyphen in acceptableSpecialChars to ensure it's treated as a literal character
  const escapedSpecialChars = acceptableSpecialChars ? acceptableSpecialChars.replace(/-/g, '\\-') : '';
  return new RegExp(`^[a-z0-9${escapedSpecialChars}-]{${minLength},${maxLength || ''}}$`, 'i');
};

export const isRequiredLengthValid = (value: string, minLength: number, maxLength: number) => {
  // pass in current value as acceptable special chars, to only validate length
  const re = getRequirementsRegex(minLength, maxLength, _.escapeRegExp(value));
  return re.test(value);
};

export const isRequiredSpecialCharsValid = (
  value: string,
  minSpecialCount: number,
  acceptableSpecialChars: string | undefined
) => {
  const re = getRequirementsRegex(minSpecialCount, 0, acceptableSpecialChars);
  if (acceptableSpecialChars) {
    return (
      re.test(value) &&
      (!minSpecialCount || countNumberOfChars(value, new RegExp(`[${acceptableSpecialChars}]`)) >= minSpecialCount)
    );
  }
  return true;
};

export const isRequiredDigitsValid = (value: string, minDigitCount: number) => {
  if (minDigitCount) {
    return countNumberOfChars(value, /\d/) >= minDigitCount;
  }
  return true;
};

export const isRequiredLowercaseValid = (value: string, minLowerCount: number) => {
  if (minLowerCount) {
    return countNumberOfChars(value, /[a-z]/) >= minLowerCount;
  }
  return true;
};

export const isRequiredUppercaseValid = (value: string, minUpperCount: number) => {
  if (minUpperCount) {
    return countNumberOfChars(value, /[A-Z]/) >= minUpperCount;
  }
  return true;
};

const countNumberOfChars = (value: string, charMatch: RegExp) => {
  return _.sumBy(value, c => (charMatch.test(c) ? 1 : 0));
};

const generateSpecialCharUnicodeRange = (minChar: string | undefined, maxChar: string | undefined) => {
  let range = '';
  if (minChar && maxChar) {
    // generate ranges for non-special chars that need to be excluded
    const numbersAndLettersRange = [
      ...generateUnicodeDecimalRange('0', '9'),
      ...generateUnicodeDecimalRange('A', 'Z'),
      ...generateUnicodeDecimalRange('a', 'z'),
    ];
    const charRange = generateUnicodeDecimalRange(minChar, maxChar);
    for (let i = 0; i < charRange.length; i++) {
      if (!_.includes(numbersAndLettersRange, charRange[i])) {
        var hex = charRange[i].toString(16);
        range += `\\u${'0000'.substring(0, 4 - hex.length)}${hex}`;
      }
    }
  }
  return range;
};

const generateUnicodeDecimalRange = (minChar: string, maxChar: string) => {
  const range: number[] = [];
  const minCodePoint = minChar.codePointAt(0) || 0;
  const maxCodePoint = maxChar.codePointAt(0) || -1;
  for (let i = minCodePoint; i <= maxCodePoint; i++) {
    range.push(i);
  }
  return range;
};
