import { UserRequirementsInfo, ResponseCode, HeardAboutInfo, ApplicationStatus } from '@tradingblock/types';
import { ApplicationStep, SaveType, ApplicationModel, UploadKey } from '../types';

export interface ApplicationUIState {
  siteGrp?: string;
  clearer?: string;
  apiToken?: string; // used for TB api
  storageToken?: string; // used for mongo storage
  storageTokenExpired?: boolean;
  apiTokenExpired?: boolean;
  step?: ApplicationStep;
  numberOfSections: number;
  visibleSections: number;
  scrollToSection: boolean;
  nextPage?: string;
  stepSaved?: boolean;
  isFetching?: boolean;
  userRequirements?: UserRequirementsInfo;
  heardAbout?: HeardAboutInfo[];
  affiliate?: string;
}

export interface ApplicationDataState {
  status?: ApplicationStatus | null;
  isFetchingStatus?: boolean;
  application?: ApplicationModel;
  isSaving?: boolean;
  saveType?: SaveType;
  saveStep?: ApplicationStep;
  uploadState: {
    [key in UploadKey]?: { uploadsInProgress?: number };
  };
  createdUser?: boolean;
  createdAccount?: boolean;
  errorCode?: ResponseCode;
  errorMessages?: string[];
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  };
}

export interface ApplicationState {
  ui: ApplicationUIState;
  data: ApplicationDataState;
}

export const InitialState: ApplicationState = {
  ui: {
    numberOfSections: 1,
    visibleSections: 1,
    scrollToSection: false,
  },
  data: {
    uploadState: {},
  },
};
