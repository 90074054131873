import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { TextboxField } from '../';
import { formatNumber, parseNumber } from '../../../utilities';
export var getCurrencyType = function getCurrencyType(locale) {
  // for now only supporting USD
  switch (locale) {
    default:
      return 'USD';
  }
};
export var CurrencyField = function CurrencyField(_a) {
  var type = _a.type,
      step = _a.step,
      value = _a.value,
      locale = _a.locale,
      props = tslib_1.__rest(_a, ["type", "step", "value", "locale"]);

  var id = props.id,
      form = props.form;
  var values = form.values,
      handleBlur = form.handleBlur,
      setValues = form.setValues;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hasFocus = _useState2[0],
      setHasFocus = _useState2[1];

  var currency = useMemo(function () {
    return getCurrencyType(locale);
  }, [locale]);
  var formattedValue = useMemo(function () {
    var currencyValue = value || _.get(values, id); // format value as currency


    return _.isNumber(currencyValue) || currencyValue ? formatNumber(currencyValue, {
      locale: locale,
      currency: currency
    }) : formatNumber(0, {
      locale: locale,
      currency: currency
    });
  }, [id, value, values, locale, currency]);
  var overrideProps = useMemo(function () {
    // use inputMode="decimal" instead of type="number" (so user can still enter delimiters like comma)
    if (hasFocus) {
      return {
        inputMode: 'decimal'
      };
    }

    return {
      value: formattedValue
    };
  }, [hasFocus, formattedValue]);

  var onFocus = function onFocus() {
    // when focusing, set form value to formatted currency value
    setValues(_.set(Object.assign({}, values), id, formattedValue));
    setHasFocus(true);
  };

  var onBlur = function onBlur(e) {
    // when blurring, set form value to number parsed from input value
    setValues(_.set(Object.assign({}, values), id, e.target.value && parseNumber(e.target.value, locale)));
    setHasFocus(false);
    handleBlur(e);
  };

  return React.createElement(TextboxField, Object.assign({}, props, overrideProps, {
    onfocus: onFocus,
    onblur: onBlur
  }));
};