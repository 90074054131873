import React from 'react';
export var NavigationContext = React.createContext({});
export function useNavigationContext() {
  var context = React.useContext(NavigationContext);

  if (!context) {
    throw new Error("Navigation compound components cannot be rendered outside the Navigation component");
  }

  return context;
}