import { OrderActionDirection } from '.'; // export enum TradingStrategyRisk {
//   Price,
//   ValueOfStrike,
//   DifferencesBetweenStrikes,
//   ValueOfBiggestStrike,
//   ValueOfSmallestStrike,
//   Infinite,
// }

export var ExpirationRules;

(function (ExpirationRules) {
  ExpirationRules[ExpirationRules["SameExpiry"] = 1] = "SameExpiry";
  ExpirationRules[ExpirationRules["DifferentExpiries"] = 2] = "DifferentExpiries";
  ExpirationRules[ExpirationRules["BuyWithLaterSellWithSooner"] = 3] = "BuyWithLaterSellWithSooner";
})(ExpirationRules || (ExpirationRules = {}));

export var DirectionRules;

(function (DirectionRules) {
  DirectionRules[DirectionRules["DifferentDirections"] = 0] = "DifferentDirections";
  DirectionRules[DirectionRules["SameDirections"] = 1] = "SameDirections";
  DirectionRules[DirectionRules["Butterfly"] = 2] = "Butterfly";
  DirectionRules[DirectionRules["Condor"] = 3] = "Condor";
  DirectionRules[DirectionRules["Iron"] = 4] = "Iron";
})(DirectionRules || (DirectionRules = {}));

export var StrikeRules;

(function (StrikeRules) {
  StrikeRules[StrikeRules["DifferentStrikes"] = 1] = "DifferentStrikes";
  StrikeRules[StrikeRules["DifferentStrikesDescending"] = 2] = "DifferentStrikesDescending";
  StrikeRules[StrikeRules["EquidistantStrikes"] = 3] = "EquidistantStrikes";
  StrikeRules[StrikeRules["SameStrikes"] = 4] = "SameStrikes";
  StrikeRules[StrikeRules["CallHigherThanPut"] = 5] = "CallHigherThanPut";
  StrikeRules[StrikeRules["IncreasingOrder"] = 6] = "IncreasingOrder";
  StrikeRules[StrikeRules["DecreasingOrder"] = 7] = "DecreasingOrder";
  StrikeRules[StrikeRules["CallFirstPutsSecond"] = 8] = "CallFirstPutsSecond";
  StrikeRules[StrikeRules["LowestCallEqualsHighestPut"] = 9] = "LowestCallEqualsHighestPut";
  StrikeRules[StrikeRules["LowestCallGreaterThanHighestPut"] = 10] = "LowestCallGreaterThanHighestPut";
  StrikeRules[StrikeRules["EquidistantStrikesByTwos"] = 11] = "EquidistantStrikesByTwos";
  StrikeRules[StrikeRules["IronCondor"] = 12] = "IronCondor";
  StrikeRules[StrikeRules["IronButterfly"] = 13] = "IronButterfly";
  StrikeRules[StrikeRules["Any"] = 14] = "Any";
})(StrikeRules || (StrikeRules = {}));