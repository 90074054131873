import { useCallback } from 'react';
import { useLocalStorage, ExpireTokenInStorage } from '@tradingblock/storage';
import { useDispatch } from 'react-redux';
import { UiActions, ApplicationActions } from '../state/actions';

export const useLogout = () => {
  const localStorage = useLocalStorage();
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    ExpireTokenInStorage(localStorage);
    dispatch(UiActions.setAuthToken(undefined));
    dispatch(ApplicationActions.receiveSaveApplication({ application: undefined, saveType: 'unload' }));
  }, [localStorage]);
  return logout;
};
