import React, { useMemo } from 'react';
import { GetTradingBlockSiteName } from '../../utilities/site';
import { Text } from '../localize/Text';
export var ContactPhone = function ContactPhone(_ref) {
  var accountNumber = _ref.accountNumber,
      siteGroup = _ref.siteGroup;
  var contactPhoneTextId = useMemo(function () {
    return "contact.phone.".concat(GetTradingBlockSiteName(accountNumber, siteGroup));
  }, [accountNumber, siteGroup]);
  return React.createElement(Text, {
    id: contactPhoneTextId
  });
};