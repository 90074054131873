import { GetBarsUrl } from '../constants';
import { dateStringOrUndefined } from '../utilities';
export function GetBars(api) {
  return function (options) {
    var opts = Object.assign({}, options, {
      starttime: dateStringOrUndefined(options.starttime),
      endtime: dateStringOrUndefined(options.endtime)
    });
    return api.get(GetBarsUrl, opts);
  };
}