export var OrderStrategyTypeId;

(function (OrderStrategyTypeId) {
  OrderStrategyTypeId[OrderStrategyTypeId["Undefined"] = 0] = "Undefined";
  OrderStrategyTypeId[OrderStrategyTypeId["Single"] = 1] = "Single";
  OrderStrategyTypeId[OrderStrategyTypeId["Vertical"] = 2] = "Vertical";
  OrderStrategyTypeId[OrderStrategyTypeId["Calendar"] = 3] = "Calendar";
  OrderStrategyTypeId[OrderStrategyTypeId["Straddle"] = 5] = "Straddle";
  OrderStrategyTypeId[OrderStrategyTypeId["Strangle"] = 6] = "Strangle";
  OrderStrategyTypeId[OrderStrategyTypeId["Butterfly"] = 7] = "Butterfly";
  OrderStrategyTypeId[OrderStrategyTypeId["Condor"] = 8] = "Condor";
  OrderStrategyTypeId[OrderStrategyTypeId["IronButterfly"] = 9] = "IronButterfly";
  OrderStrategyTypeId[OrderStrategyTypeId["IronConder"] = 10] = "IronConder";
  OrderStrategyTypeId[OrderStrategyTypeId["Box"] = 11] = "Box";
  OrderStrategyTypeId[OrderStrategyTypeId["CoveredCall"] = 13] = "CoveredCall";
  OrderStrategyTypeId[OrderStrategyTypeId["MarriedPut"] = 14] = "MarriedPut";
  OrderStrategyTypeId[OrderStrategyTypeId["Conversion"] = 15] = "Conversion";
  OrderStrategyTypeId[OrderStrategyTypeId["Collar"] = 16] = "Collar";
  OrderStrategyTypeId[OrderStrategyTypeId["SyntheticCombo"] = 17] = "SyntheticCombo";
  OrderStrategyTypeId[OrderStrategyTypeId["CreateMyOwn"] = 18] = "CreateMyOwn";
  OrderStrategyTypeId[OrderStrategyTypeId["HedgeCollar"] = 19] = "HedgeCollar";
  OrderStrategyTypeId[OrderStrategyTypeId["Contingent"] = 20] = "Contingent";
  OrderStrategyTypeId[OrderStrategyTypeId["Trailing"] = 21] = "Trailing";
  OrderStrategyTypeId[OrderStrategyTypeId["OneCancelsOther"] = 22] = "OneCancelsOther";
  OrderStrategyTypeId[OrderStrategyTypeId["Stock"] = 23] = "Stock";
  OrderStrategyTypeId[OrderStrategyTypeId["Bond"] = 24] = "Bond";
  OrderStrategyTypeId[OrderStrategyTypeId["MutualFund"] = 25] = "MutualFund";
  OrderStrategyTypeId[OrderStrategyTypeId["CoveredPut"] = 26] = "CoveredPut";
  OrderStrategyTypeId[OrderStrategyTypeId["MarriedCall"] = 27] = "MarriedCall";
  OrderStrategyTypeId[OrderStrategyTypeId["CallCalendar"] = 28] = "CallCalendar";
  OrderStrategyTypeId[OrderStrategyTypeId["PutCalendar"] = 29] = "PutCalendar";
  OrderStrategyTypeId[OrderStrategyTypeId["Call"] = 30] = "Call";
  OrderStrategyTypeId[OrderStrategyTypeId["Put"] = 31] = "Put";
})(OrderStrategyTypeId || (OrderStrategyTypeId = {}));

export var OrderStrategyType;

(function (OrderStrategyType) {
  OrderStrategyType["undefined"] = "Undefined";
  OrderStrategyType["Undefined"] = "Undefined";
  OrderStrategyType["Single"] = "Single";
  OrderStrategyType["Vertical"] = "Vertical";
  OrderStrategyType["CallVertical"] = "Call Vertical";
  OrderStrategyType["PutVertical"] = "Put Vertical";
  OrderStrategyType["Calendar"] = "Calendar";
  OrderStrategyType["CallCalendar"] = "Call Calendar";
  OrderStrategyType["PutCalendar"] = "Put Calendar";
  OrderStrategyType["Straddle"] = "Straddle";
  OrderStrategyType["Strangle"] = "Strangle";
  OrderStrategyType["Butterfly"] = "Butterfly";
  OrderStrategyType["Condor"] = "Condor";
  OrderStrategyType["IronButterfly"] = "IronButterfly";
  OrderStrategyType["IronConder"] = "IronConder";
  OrderStrategyType["Box"] = "Box";
  OrderStrategyType["CoveredCall"] = "CoveredCall";
  OrderStrategyType["MarriedPut"] = "MarriedPut";
  OrderStrategyType["Conversion"] = "Conversion";
  OrderStrategyType["Collar"] = "Collar";
  OrderStrategyType["SyntheticCombo"] = "SyntheticCombo";
  OrderStrategyType["CreateMyOwn"] = "CreateMyOwn";
  OrderStrategyType["HedgeCollar"] = "HedgeCollar";
  OrderStrategyType["Contingent"] = "Contingent";
  OrderStrategyType["Trailing"] = "Trailing";
  OrderStrategyType["OneCancelsOther"] = "OneCancelsOther";
  OrderStrategyType["Stock"] = "Stock";
  OrderStrategyType["Bond"] = "Bond";
  OrderStrategyType["MutualFund"] = "MutualFund";
  OrderStrategyType["CoveredPut"] = "CoveredPut";
  OrderStrategyType["MarriedCall"] = "MarriedCall";
  OrderStrategyType["Call"] = "Call";
  OrderStrategyType["Put"] = "Put";
  OrderStrategyType["CallButterfly"] = "CallButterfly";
  OrderStrategyType["PutButterfly"] = "PutButterfly";
  OrderStrategyType["CallCondor"] = "CallCondor";
  OrderStrategyType["PutCondor"] = "PutCondor";
})(OrderStrategyType || (OrderStrategyType = {}));