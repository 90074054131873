import { OrderStatuses } from '@tradingblock/types';
export var CancellableStatuses = [OrderStatuses.New, OrderStatuses.PartiallyFilled, OrderStatuses.Replaced, OrderStatuses.PendingCancel, OrderStatuses.PendingNew, OrderStatuses.PendingReplace, OrderStatuses.Saved, OrderStatuses.Initiated, OrderStatuses.ReplaceInitiated, OrderStatuses.CancelInitiated, OrderStatuses.CancelInitiated, OrderStatuses.CancelRejected, OrderStatuses.ReplaceRejected];
export var ReplaceableStatuses = [OrderStatuses.New, OrderStatuses.Replaced, OrderStatuses.PendingReplace, OrderStatuses.Saved, OrderStatuses.Initiated, OrderStatuses.ReplaceInitiated, OrderStatuses.ReplaceRejected];
export var OrderFilledStatuses = [OrderStatuses.Filled, OrderStatuses.PartiallyFilled, OrderStatuses.PartiallyCancelled];
export var ExecutedStatuses = [OrderStatuses.Filled, OrderStatuses.PartiallyFilled];
export var OpenStatuses = [OrderStatuses.New, OrderStatuses.PendingNew, OrderStatuses.Replaced, OrderStatuses.PendingReplace, OrderStatuses.Saved, OrderStatuses.Initiated, OrderStatuses.ReplaceInitiated, OrderStatuses.CancelInitiated, OrderStatuses.CancelRejected, OrderStatuses.ReplaceRejected];
export var OrderStatusCategories = {
  Cancellable: CancellableStatuses,
  Replaceable: ReplaceableStatuses,
  Filled: OrderFilledStatuses,
  Executed: ExecutedStatuses,
  Open: OpenStatuses
};