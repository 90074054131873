import React, { useState } from 'react';
import {
  ClearerType,
  InvestigationDocuments,
  RequestedInvestigationDocument,
  ResponseCodes,
} from '@tradingblock/types';
import { Button, FormGroup, Text } from '@tradingblock/components';
import { FileError, useDropzone } from 'react-dropzone';
import { useApi } from '../../context';
import { useCallback } from 'react';
import { ApiResponse } from '@tradingblock/api';
import { useDispatcher } from '../hooks';
import { ApplicationActions } from '../../state/actions';

/**
 * InvestigationUpload component properties
 *
 * @property {RequestedInvestigationDocument} requestedInvestigationDocument Requested document infomation.
 * @property {string} requestedInvestigationDocument Type of requsted document. Application is internal, investigation is apex related documents.
 * @property {number} accountId The account ID for the upload.
 * @property {number} [accountHolderId] The account holder id, needed only for application uploads.
 * @property {number} [investigationId] The investigation id, needed only for investigation uploads.
 */
export interface InvestigationUploadProps {
  requestedInvestigationDocument: RequestedInvestigationDocument;
  type: 'application' | 'investigation';
  accountId: number;
  accountHolderId?: number;
  investigationId?: number;
  clearer?: ClearerType | null;
}

/**
 * An independent upload widget for uploading investigations and application specific documents
 *
 * @param {InvestigationUploadProps} props
 * @returns {JSX.Element}
 */
export default function InvestigationUpload({
  requestedInvestigationDocument,
  type,
  accountId,
  accountHolderId,
  investigationId,
  clearer,
}: InvestigationUploadProps) {
  // Checks that accountHolderId is set for application uploads
  // and investigationId for investigation uploads
  if (type === 'application' && accountHolderId === undefined) {
    throw new Error('accountHolderId is required for an application upload');
  } else if (type === 'investigation' && investigationId === undefined) {
    throw new Error('investigationId is required for an investigation upload');
  }
  if (requestedInvestigationDocument.requestedDocuments.length <= 0) {
    throw new Error('Need one requested document to process');
  }

  // Used hooks
  const api = useApi();
  const dispatch = useDispatcher();

  // Used states
  const [uploadingDocument, setUploadingDocument] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<FileError[]>([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState<InvestigationDocuments>(
    requestedInvestigationDocument.requestedDocuments[0]
  );
  const [needToSelectDocumentName, setNeedToSelectDocumentName] = useState<boolean>(
    requestedInvestigationDocument.requestedDocuments.length > 1
  );

  const fetchStatus = (accountId: number) => {
    dispatch(ApplicationActions.requestApplicationStatus({ accountId }));
  };
  //Processes the upload response from the api
  const processUploadResponse = useCallback(
    (response: ApiResponse<number>) => {
      // check if the upload was not successful and set the errors/initial states.
      if (response.responseCode !== 0) {
        setUploadingDocument(false);
        setSelectedDocumentName(requestedInvestigationDocument.requestedDocuments[0]);
        setNeedToSelectDocumentName(requestedInvestigationDocument.requestedDocuments.length > 0);
        setErrors([
          {
            message: ResponseCodes[response.responseCode].description as string,
            code: ResponseCodes[response.responseCode].name,
          },
        ]);
      } else {
        // There were no errors, reset the states and set success to true
        setUploadSuccess(true);
        setErrors([]);
        const fetchStatusTimer = setTimeout(fetchStatus, 1500, accountId);
        return () => clearTimeout(fetchStatusTimer);
      }
    },
    [requestedInvestigationDocument]
  );
  const isRqdIdCard =
    (selectedDocumentName === InvestigationDocuments.DriversLicense ||
      selectedDocumentName === InvestigationDocuments.StateIdCard) &&
    clearer === ClearerType.RQD;

  const maxFiles = isRqdIdCard ? 2 : 1;

  // Dropzone properties for the upload input field
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: () => {
      setUploadingDocument(true);
    },
    onDropAccepted: acceptedFiles => {
      // If the files where accepted from frontend validation
      // The base on the type, upload the document to the backend
      if (type === 'application' && accountHolderId !== undefined) {
        api.application
          .uploadApplicationDocument(acceptedFiles[0], accountId, accountHolderId, selectedDocumentName)
          .then(response => {
            processUploadResponse(response);
          });
      } else if (type === 'investigation' && investigationId !== undefined) {
        if (isRqdIdCard) {
          if (acceptedFiles.length !== 2) {
            setErrors([
              {
                message: 'Error: Please ensure you upload both the front and back images of your ID.',
                code: 'INVALID_DOCUMENT',
              },
            ]);
            setUploadingDocument(false);
            return;
          }
        }
        // need to upload both accepted files if the document is a drivers license or state id card
        // upload the first file, then the second file
        if (isRqdIdCard) {
          api.application
            .uploadInvestigationDocument(acceptedFiles[0], accountId, investigationId, selectedDocumentName)
            .then(response => {
              processUploadResponse(response);
              api.application
                .uploadInvestigationDocument(acceptedFiles[1], accountId, investigationId, selectedDocumentName)
                .then(response => {
                  processUploadResponse(response);
                });
            });
        } else {
          api.application
            .uploadInvestigationDocument(acceptedFiles[0], accountId, investigationId, selectedDocumentName)
            .then(response => {
              processUploadResponse(response);
            });
        }
      }
    },
    onDropRejected: fileRejections => {
      //Set the upload failed and the file errors to display
      setUploadingDocument(false);
      setErrors(fileRejections[0].errors);
    },
    maxSize: 15728640,
    maxFiles: maxFiles,
    accept: 'image/png, image/jpeg, image/jpg, image/gif, application/pdf, capture=camera',
  });

  return (
    <FormGroup
      title={
        <Text
          id={'investigationUpload.title'}
          type={'field'}
          data={{
            documentName: (
              <Text id={`investigationUpload.documents.${requestedInvestigationDocument.name}.title`} type={'field'} />
            ),
          }}
        />
      }
    >
      <p>
        <span className="mute">
          <Text
            id={`investigationUpload.documents.${requestedInvestigationDocument.name}`}
            textKey={'description'}
            type={'field'}
          />
        </span>
      </p>
      {!uploadSuccess ? (
        <>
          {needToSelectDocumentName && (
            <>
              <strong>
                <Text id={'investigationUpload.selectDocumentEyebrow'} type={'field'} />
              </strong>
              <ul className={'checklist'}>
                {/** TODO: This should be in a checklist/radio list component, however, current one is too tied to formik
                 * We will refactor when components are in audit phase.
                 */}
                {requestedInvestigationDocument.requestedDocuments.map(requestedDocumentOption => (
                  <li key={`${requestedDocumentOption}_${investigationId ? investigationId : accountHolderId}`}>
                    <label>
                      <span className={'checkcontainer'}>
                        <input
                          type={'checkbox'}
                          name={`${requestedDocumentOption}_${investigationId ? investigationId : accountHolderId}`}
                          id={`${requestedDocumentOption}_${investigationId ? investigationId : accountHolderId}`}
                          value={requestedDocumentOption}
                          checked={requestedDocumentOption === selectedDocumentName}
                          onChange={() => setSelectedDocumentName(requestedDocumentOption)}
                        />
                        <span className={'radiobtn'}></span>
                      </span>
                      <Text id={`investigationUpload.documentOptions.${requestedDocumentOption}`} type={'field'} />
                    </label>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!uploadingDocument ? (
              <>
                <Button className={'btn-btn btn-primary'}>
                  {needToSelectDocumentName ? <Text id={'investigationUpload.selectUpload'} type={'field'} /> : ''}
                  <Text id={'investigationUpload.upload'} type={'field'} />
                </Button>
                {isRqdIdCard && (
                  <>
                    <br />
                    <br />
                    <span className="small-warning">
                      <Text id={'review-documents.idWarning'} page="account" />
                    </span>
                  </>
                )}
                {isRqdIdCard && <br />}
                <br />
                <span className="mute" style={{ marginTop: '1rem' }}>
                  <Text id={'review-documents.files'} page="account" />
                </span>
              </>
            ) : (
              <span>
                <i className="fas fa-circle-notch fa-spin valid" />{' '}
                <Text id={'investigationUpload.uploading'} type={'field'} />
              </span>
            )}
          </div>
        </>
      ) : (
        <span>
          <i className="fas fa-check-circle text-success" />{' '}
          <Text id={'investigationUpload.uploadSuccessful'} type={'field'} />
        </span>
      )}
      {errors.length > 0 && (
        <div className="errors">
          <ul>
            {errors.map((error, errorIndex) => (
              // if the error code is INVALID_DOCUMENT, apply some CSS to make the error more visible
              <li
                className="error"
                key={`error_${errorIndex}`}
                style={error.code === 'INVALID_DOCUMENT' ? { fontWeight: 'bold' } : {}}
              >
                {error.message}
              </li>
            ))}
          </ul>
        </div>
      )}
    </FormGroup>
  );
}
