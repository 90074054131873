import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { isFieldValueFilled } from '../../hooks';
import { FloatingLabel } from '../basic';
import { LocalizeButton as Button } from '../';
export var TextboxInput = function TextboxInput(_a) {
  var type = _a.type,
      value = _a.value,
      label = _a.label,
      placeholder = _a.placeholder,
      error = _a.error,
      onfocus = _a.onfocus,
      onblur = _a.onblur,
      onchange = _a.onchange,
      autoFocus = _a.autoFocus,
      allowUnmask = _a.allowUnmask,
      preventPaste = _a.preventPaste,
      prefix = _a.prefix,
      controlledLabel = _a.controlledLabel,
      onLabelStateChange = _a.onLabelStateChange,
      disabled = _a.disabled,
      defaultLabelId = _a.defaultLabelId,
      labelPrefix = _a.labelPrefix,
      children = _a.children,
      rest = tslib_1.__rest(_a, ["type", "value", "label", "placeholder", "error", "onfocus", "onblur", "onchange", "autoFocus", "allowUnmask", "preventPaste", "prefix", "controlledLabel", "onLabelStateChange", "disabled", "defaultLabelId", "labelPrefix", "children"]);

  var name = rest.name,
      errorImmediately = rest.errorImmediately,
      extra = tslib_1.__rest(rest, ["name", "errorImmediately"]);

  var inputRef = useRef();

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      inputValue = _useState2[0],
      setInputValue = _useState2[1];

  var val = inputValue || value;

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      maskPassword = _useState4[0],
      setMaskPassword = _useState4[1];

  var _useState5 = useState({
    isFilled: isFieldValueFilled(val)
  }),
      _useState6 = _slicedToArray(_useState5, 2),
      floatingLabelState = _useState6[0],
      setFloatingLabelState = _useState6[1];

  var updateFloatingLabelState = useCallback(function (state) {
    setFloatingLabelState(state);

    if (onLabelStateChange) {
      onLabelStateChange(state);
    }
  }, [onLabelStateChange]);
  var onFocus = useCallback(function (e) {
    if (onfocus) {
      onfocus(e);
    }

    updateFloatingLabelState({
      isFilled: true,
      hasFocus: true
    });
  }, [onfocus, updateFloatingLabelState]);
  var onBlur = useCallback(function (e) {
    if (onblur) {
      onblur(e);
    }

    updateFloatingLabelState({
      isFilled: isFieldValueFilled(e.target.value),
      hasFocus: false
    });
  }, [onblur, updateFloatingLabelState]);
  var onChange = useCallback(function (e) {
    var rawValue = e.target.value;
    var val = type === 'number' ? parseFloat(rawValue) : rawValue;

    if (onchange) {
      onchange(e, val);
    } else {
      setInputValue(rawValue);
    }

    updateFloatingLabelState({
      isFilled: isFieldValueFilled(val),
      hasFocus: floatingLabelState.hasFocus
    });
  }, [type, onchange, updateFloatingLabelState, floatingLabelState.hasFocus]);
  var onPaste = useCallback(function (e) {
    if (preventPaste) {
      e.preventDefault();
    }
  }, [preventPaste]);
  var onDragDrop = useCallback(function (e) {
    if (preventPaste) {
      e.preventDefault();
    }
  }, [preventPaste]);
  useEffect(function () {
    if (autoFocus) {
      setTimeout(function () {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
          window.setTimeout(onFocus, 10);
        }
      }, 100);
    }
  }, [autoFocus, onFocus]);
  useEffect(function () {
    if (!controlledLabel) {
      updateFloatingLabelState({
        isFilled: isFieldValueFilled(val),
        hasFocus: floatingLabelState.hasFocus
      });
    }
  }, [val, controlledLabel, floatingLabelState.hasFocus, updateFloatingLabelState]);
  var icon = useMemo(function () {
    if (type === 'password' && allowUnmask) {
      return React.createElement(Button, {
        type: maskPassword ? 'show' : 'hide',
        onClick: function onClick() {
          return setMaskPassword(!maskPassword);
        },
        tabIndex: -1
      }, React.createElement("i", {
        className: "fal fa-lg ".concat(maskPassword ? 'fa-lock-alt' : 'fa-lock-open-alt')
      }));
    }

    return undefined;
  }, [type, maskPassword, allowUnmask]);
  var fieldType = useMemo(function () {
    if (type === 'number') {
      return type;
    }

    if (!maskPassword) {
      return 'text';
    }

    return type;
  }, [maskPassword, type]);
  return React.createElement(React.Fragment, null, React.createElement(FloatingLabel, {
    id: name,
    className: error ? 'invalid' : '',
    label: placeholder || label || name,
    prefix: prefix,
    icon: icon,
    state: floatingLabelState,
    disabled: disabled
  }, React.createElement("input", Object.assign({
    ref: inputRef,
    className: "flabel-input",
    id: name,
    type: fieldType,
    value: isFieldValueFilled(val) ? val : '',
    disabled: disabled,
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    onPaste: onPaste,
    onDrag: onDragDrop,
    onDrop: onDragDrop,
    autoComplete: name
  }, extra))));
};