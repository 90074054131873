export var State;

(function (State) {
  State["Alabama"] = "AL";
  State["Alaska"] = "AK";
  State["Arizona"] = "AZ";
  State["Arkansas"] = "AR";
  State["California"] = "CA";
  State["Colorado"] = "CO";
  State["Connecticut"] = "CT";
  State["Delaware"] = "DE";
  State["DistrictOfColumbia"] = "DC";
  State["Florida"] = "FL";
  State["Georgia"] = "GA";
  State["Hawaii"] = "HI";
  State["Idaho"] = "ID";
  State["Illinois"] = "IL";
  State["Indiana"] = "IN";
  State["Iowa"] = "IA";
  State["Kansas"] = "KS";
  State["Kentucky"] = "KY";
  State["Louisiana"] = "LA";
  State["Maine"] = "ME";
  State["Maryland"] = "MD";
  State["Massachusetts"] = "MA";
  State["Michigan"] = "MI";
  State["Minnesota"] = "MN";
  State["Mississippi"] = "MS";
  State["Missouri"] = "MO";
  State["Montana"] = "MT";
  State["Nebraska"] = "NE";
  State["Nevada"] = "NV";
  State["NewHampshire"] = "NH";
  State["NewJersey"] = "NJ";
  State["NewMexico"] = "NM";
  State["NewYork"] = "NY";
  State["NorthCarolina"] = "NC";
  State["NorthDakota"] = "ND";
  State["Ohio"] = "OH";
  State["Oklahoma"] = "OK";
  State["Oregon"] = "OR";
  State["Pennsylvania"] = "PA";
  State["RhodeIsland"] = "RI";
  State["SouthCarolina"] = "SC";
  State["SouthDakota"] = "SD";
  State["Tennessee"] = "TN";
  State["Texas"] = "TX";
  State["Utah"] = "UT";
  State["Vermont"] = "VT";
  State["Virginia"] = "VA";
  State["Washington"] = "WA";
  State["WestVirginia"] = "WV";
  State["Wisconsin"] = "WI";
  State["Wyoming"] = "WY";
})(State || (State = {}));

export var AllStates = [State.Alabama, State.Alaska, State.Arizona, State.Arkansas, State.California, State.Colorado, State.Connecticut, State.Delaware, State.DistrictOfColumbia, State.Florida, State.Georgia, State.Hawaii, State.Idaho, State.Illinois, State.Indiana, State.Iowa, State.Kansas, State.Kentucky, State.Louisiana, State.Maine, State.Maryland, State.Massachusetts, State.Michigan, State.Minnesota, State.Mississippi, State.Missouri, State.Montana, State.Nebraska, State.Nevada, State.NewHampshire, State.NewJersey, State.NewMexico, State.NewYork, State.NorthCarolina, State.NorthDakota, State.Ohio, State.Oklahoma, State.Oregon, State.Pennsylvania, State.RhodeIsland, State.SouthCarolina, State.SouthDakota, State.Tennessee, State.Texas, State.Utah, State.Vermont, State.Virginia, State.Washington, State.WestVirginia, State.Wisconsin, State.Wyoming];