import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useMemo, useState } from 'react';
export var CheckboxInput = function CheckboxInput(_ref) {
  var id = _ref.id,
      name = _ref.name,
      type = _ref.type,
      checked = _ref.checked,
      icon = _ref.icon,
      label = _ref.label,
      disabled = _ref.disabled,
      onblur = _ref.onblur,
      onchange = _ref.onchange;
  var inputType = type || 'radio';
  var inputClassName = useMemo(function () {
    return inputType === 'radio' ? "".concat(inputType, "btn") : inputType;
  }, [inputType]);

  var _useState = useState(checked),
      _useState2 = _slicedToArray(_useState, 2),
      checkboxValue = _useState2[0],
      setCheckboxValue = _useState2[1];

  useEffect(function () {
    return setCheckboxValue(checked);
  }, [checked]);

  var onChange = function onChange() {
    if (onchange) {
      onchange(undefined, !checkboxValue);
    } else {
      setCheckboxValue(!checkboxValue);
    }
  };

  return React.createElement("label", {
    className: disabled ? 'disabled' : ''
  }, React.createElement("span", {
    className: "checkcontainer ".concat(disabled ? 'disabled' : '')
  }, React.createElement("input", {
    id: id || name,
    type: inputType,
    checked: checkboxValue,
    disabled: disabled,
    onChange: onChange,
    onBlur: onblur
  }), React.createElement("span", {
    className: inputClassName
  })), ' ', icon && React.createElement("span", {
    className: "checklist-icon"
  }, icon), label || checkboxValue);
};
export var BasicCheckboxInput = function BasicCheckboxInput(_ref2) {
  var id = _ref2.id,
      name = _ref2.name,
      checked = _ref2.checked,
      icon = _ref2.icon,
      disabled = _ref2.disabled,
      onblur = _ref2.onblur,
      onchange = _ref2.onchange,
      children = _ref2.children,
      className = _ref2.className;

  var _useState3 = useState(checked),
      _useState4 = _slicedToArray(_useState3, 2),
      checkboxValue = _useState4[0],
      setCheckboxValue = _useState4[1];

  useEffect(function () {
    return setCheckboxValue(checked);
  }, [checked]);

  var onChange = function onChange() {
    if (onchange) {
      onchange(undefined, !checkboxValue);
    } else {
      setCheckboxValue(!checkboxValue);
    }
  };

  return React.createElement("label", {
    className: "".concat(disabled ? 'disabled' : '', " ").concat(className)
  }, React.createElement("span", {
    className: "checkcontainer ".concat(disabled ? 'disabled' : '', " mr-1")
  }, React.createElement("input", {
    id: id || name,
    type: "checkbox",
    checked: checkboxValue,
    disabled: disabled,
    onChange: onChange,
    onBlur: onblur
  }), React.createElement("span", {
    className: 'checkbox'
  })), ' ', icon && React.createElement("span", {
    className: "checklist-icon"
  }, icon), children);
};