import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { isFieldValueFilled } from '../../hooks';
import { FloatingLabel } from '../basic/FloatingLabel';
import { useWindowSizeContext } from '../../context/page/WindowSizeContext';
export var SelectDropdownInput = function SelectDropdownInput(_ref) {
  var name = _ref.name,
      options = _ref.options,
      value = _ref.value,
      label = _ref.label,
      placeholder = _ref.placeholder,
      error = _ref.error,
      onblur = _ref.onblur,
      onchange = _ref.onchange,
      prefix = _ref.prefix,
      disabled = _ref.disabled,
      controlledLabel = _ref.controlledLabel,
      menuPortalTargetId = _ref.menuPortalTargetId,
      onLabelStateChange = _ref.onLabelStateChange,
      isSearchable = _ref.isSearchable,
      defaultValue = _ref.defaultValue,
      isMulti = _ref.isMulti;

  var _useState = useState(value),
      _useState2 = _slicedToArray(_useState, 2),
      selectValue = _useState2[0],
      setSelectValue = _useState2[1];

  useEffect(function () {
    return setSelectValue(value);
  }, [value]);

  var isFilledValue = function isFilledValue(value) {
    return isMulti ? isFieldValueFilled(value && value.length > 0 ? value[0] : null) : isFieldValueFilled(value);
  };

  var _useState3 = useState({
    isFilled: isFilledValue(selectValue)
  }),
      _useState4 = _slicedToArray(_useState3, 2),
      floatingLabelState = _useState4[0],
      setFloatingLabelState = _useState4[1];

  var updateFloatingLabelState = useCallback(function (state) {
    setFloatingLabelState(state);

    if (onLabelStateChange) {
      onLabelStateChange(state);
    }
  }, [onLabelStateChange]);
  useEffect(function () {
    if (!controlledLabel) {
      updateFloatingLabelState({
        isFilled: isFilledValue(selectValue),
        hasFocus: floatingLabelState.hasFocus
      });
    }
  }, [selectValue, controlledLabel, floatingLabelState.hasFocus, updateFloatingLabelState]);
  useEffect(function () {
    if (defaultValue) {
      setSelectValue(defaultValue);
    }
  }, []);
  var selectOptions = useMemo(function () {
    return _.map(options, function (o) {
      if (typeof o === 'string') {
        return {
          label: o,
          raw_value: o,
          value: o
        };
      } else {
        return Object.assign({}, o, {
          label: o.label || o.value,
          raw_value: o.raw_value || o.value
        });
      }
    });
  }, [options]);
  var selectValues = useMemo(function () {
    return _.map(_.isArray(selectValue) ? selectValue : !_.isNil(selectValue) ? [selectValue] : [], function (v) {
      // find selected option by matching raw value
      var option = _.find(selectOptions, function (o) {
        return o.raw_value === v;
      });

      return Object.assign({}, option, {
        value: option ? option.value : v,
        label: option ? option.label : v
      });
    });
  }, [selectValue, selectOptions]);
  var menuPortalTarget = useMemo(function () {
    return menuPortalTargetId && document.getElementById(menuPortalTargetId);
  }, [menuPortalTargetId]);
  var selectStyles = useMemo(function () {
    if (menuPortalTargetId) {
      return {
        menuPortal: function menuPortal(provided) {
          return Object.assign({}, provided, {
            zIndex: 9999
          });
        }
      };
    }

    return undefined;
  }, [menuPortalTargetId]);
  var width = useWindowSizeContext().width;
  var narrowPage = useMemo(function () {
    return width < 500;
  }, [width]);

  var onFocus = function onFocus(e) {
    updateFloatingLabelState({
      isFilled: true,
      hasFocus: true
    });
  };

  var onBlur = function onBlur(e) {
    if (onblur) {
      onblur(e);
    }

    updateFloatingLabelState({
      isFilled: isFilledValue(selectValue),
      hasFocus: false
    });
  };

  var onChange = function onChange(o) {
    var values = _.map(_.isArray(o) ? o : [o], function (v) {
      return v.raw_value;
    });

    if (!isMulti) values = _.first(values);

    if (onchange) {
      onchange(undefined, values);
    } else {
      setSelectValue(values);
    }

    updateFloatingLabelState({
      isFilled: isFilledValue(values),
      hasFocus: floatingLabelState.hasFocus
    });
  };

  return React.createElement(FloatingLabel, {
    id: name,
    className: error ? 'invalid' : '',
    label: placeholder || label || name,
    prefix: prefix,
    state: floatingLabelState,
    disabled: disabled
  }, React.createElement(Select, Object.assign({}, isMulti && {
    isMulti: isMulti
  }, {
    inputId: name,
    name: name,
    className: "basic-multi-select",
    classNamePrefix: "select",
    value: selectValues,
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    options: selectOptions,
    placeholder: "",
    isSearchable: !narrowPage && isSearchable,
    isDisabled: disabled,
    menuPortalTarget: menuPortalTarget,
    styles: selectStyles,
    selectProps: {
      autoComplete: 'nope'
    }
  })));
};