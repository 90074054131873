import _ from 'lodash';
import { AllCountries } from './Country';
import { AcceptedCountries } from './constants';
export function strEnum(o) {
  return o.reduce(function (res, key) {
    res[key] = key;
    return res;
  }, Object.create(null));
}
export var isCustomStrategy = function isCustomStrategy(value) {
  return typeof value === 'string' ? value === 'Custom' : value.info.Name === 'Custom';
};
export var isKnownStrategyType = function isKnownStrategyType(value) {
  return isCustomStrategy(value) === false;
};
export var isKnownStrategy = function isKnownStrategy(value) {
  return isCustomStrategy(value) === false;
};
export function isStrategy(strategy, stratName) {
  if (_.isNil(stratName)) {
    return false;
  }

  if (typeof stratName === 'string') {
    return _.includes(strategy.map(function (s) {
      return _.toLower(s.info.Name);
    }), _.toLower(stratName));
  }

  return false;
}
export var getGroupedCountryOptions = function getGroupedCountryOptions() {
  return _.concat(_.sortBy(_.filter(AllCountries, function (c) {
    return _.includes(AcceptedCountries, c);
  }), function (c) {
    return _.indexOf(AcceptedCountries, c);
  }), [null], // separator
  _.filter(AllCountries, function (c) {
    return !_.includes(AcceptedCountries, c);
  }));
};
export function isResponseCodeDescription(value) {
  if (_.isNil(value)) {
    return false;
  }

  var name = _.get(value, 'name', undefined);

  var description = _.get(value, 'description', undefined);

  var descriptionisValid = typeof description === 'string' || typeof description === 'function';
  return typeof name === 'string' && descriptionisValid;
}