import { Section } from '@tradingblock/components';
import React, { useMemo } from 'react';
import { useApplication, useStore } from '../../../../context';
import { getForcedOffRampUrl, isApplicationStepInvalid, updateApplication } from '../../../../services';
import { ApplicationActions } from '../../../../state/actions';
import { useData } from '../../../../state/selectors';
import {
  ApplicationFormSection,
  ClearerType,
  ApplicationStep,
  ApplicationModel,
  AllApplicationSteps,
  BooleanToggleType,
} from '../../../../types';
import _ from 'lodash';
import { Country } from '@tradingblock/types';
import { useDispatch } from 'react-redux';

export const ClearerTypeSection: React.FC<ApplicationFormSection> = ({ getText, values }) => {
  const step = useData(d => d.ui.step);
  const authToken = useData(d => d.ui.apiToken);
  const dispatch = useDispatch();
  const [{ mismatchedDataModal, formSubmitted }, { setMismatchedDataModal, setFormSubmitted }] = useApplication();
  const { numberOfSections, visibleSections, scrollToSection } = useData(d => d.ui.sectionInfo);
  const isOnLastSectionOfStep = useMemo(
    () => !!numberOfSections && !!visibleSections && visibleSections >= numberOfSections,
    [numberOfSections, visibleSections]
  );
  const stepIndex = useMemo(() => _.indexOf(AllApplicationSteps, step), [step]);
  const storage = useStore();

  const handleClick = async (e: any, clearerType: ClearerType) => {
    e.preventDefault();
    saveStepAndContinue({
      ...values,
      clearer: clearerType,
      ownerCountry: clearerType === ClearerType.RQD ? Country.UnitedStatesOfAmerica : undefined,
      retirementAccount: clearerType === ClearerType.RQD ? BooleanToggleType.No : undefined,
      primaryAccountHolder: {
        ...values.primaryAccountHolder,
        address: {
          ...values.primaryAccountHolder.address,
          country: clearerType === ClearerType.RQD ? Country.UnitedStatesOfAmerica : '',
        },
        citizenshipCountry: clearerType === ClearerType.RQD ? Country.UnitedStatesOfAmerica : null,
      },
    });
  };

  const saveStepAndContinue = async (values: ApplicationModel) => {
    // check for any off ramp conditions
    const forcedNextUrl = getForcedOffRampUrl(values);
    // check if current step is valid
    const isStepValid = step && !isApplicationStepInvalid(step, values);
    // can only move past current step if on last section and no offramp url
    const isStepCompleted = isOnLastSectionOfStep && isStepValid && !forcedNextUrl;

    // if no authToken, need to create user first
    if (!authToken && isStepCompleted && step === ApplicationStep.SecureAccount) {
      dispatch(ApplicationActions.requestCreateUser({ application: values }));
      return;
    }

    // reset mismatched data modal state
    if (mismatchedDataModal === false) {
      setMismatchedDataModal(undefined);
    }

    // update status fields on application
    const data = updateApplication(
      step || ApplicationStep.SecureAccount,
      !isStepCompleted,
      isStepCompleted,
      values,
      visibleSections
    );
    // if completing last step, use saveType = 'complete'
    const saveType = isStepCompleted && stepIndex + 1 === AllApplicationSteps.length ? 'complete' : 'continue';
    // save application
    dispatch(ApplicationActions.requestSaveApplication({ application: data, saveType, saveStep: step, storage }));
  };

  return (
    <Section id={ApplicationStep.ClearerType} className="serviceOptions">
      <div className="container service-options-container">
        <div className="row mb-5">
          <div className="col-12">
            <span className="service-level-title">{getText('defaults.clearerType.title')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4"></div>
          <div className="col-8 col-md-4">
            <span className="service-level-title">{getText('defaults.clearerType.rqd')}</span>
          </div>
          <div className="col-4 d-none d-md-block">
            <span className="service-level-title">{getText('defaults.clearerType.apex')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.best.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.best.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.best.apex')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.assetTypes.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.assetTypes.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.assetTypes.apex')}</span>
            <br />
            <span className="txt-sm">({getText('defaults.clearerType.assetTypes.apexSubText')})</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.stockETFCommission.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.stockETFCommission.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.stockETFCommission.apex.1')}</span>
            <br />
            <span>{getText('defaults.clearerType.stockETFCommission.apex.2')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.optionCommission.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.optionCommission.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.optionCommission.apex.1')}</span>
            <br />
            <span>{getText('defaults.clearerType.optionCommission.apex.2')}</span>
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.fractionalShares.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.fractionalShares.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.fractionalShares.apex')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.fractionalOptions.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.fractionalOptions.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.fractionalOptions.apex')}</span>
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.brokerAssisted.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.brokerAssisted.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.brokerAssisted.apex')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.cashieringMethods.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.cashieringMethods.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.cashieringMethods.apex')}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.accountTypes.title')}</span>
          </div>
          <div className="col-8 col-md-4">
            <span>{getText('defaults.clearerType.accountTypes.rqd')}</span>
          </div>
          <div className="col-4 col-sm-0 d-none d-md-block">
            <span>{getText('defaults.clearerType.accountTypes.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4"></div>
          <div className="col-8">
            <span className="service-level-title">{getText('defaults.clearerType.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.best.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.best.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.assetTypes.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.assetTypes.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.stockETFCommission.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.stockETFCommission.apex.1')}</span>
            <br />
            <span>{getText('defaults.clearerType.stockETFCommission.apex.2')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.optionCommission.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.optionCommission.apex.1')}</span>
            <br />
            <span>{getText('defaults.clearerType.optionCommission.apex.2')}</span>
          </div>
        </div>
        {/* <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.fractionalShares.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.fractionalShares.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.fractionalOptions.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.fractionalOptions.apex')}</span>
          </div>
        </div> */}
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.brokerAssisted.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.brokerAssisted.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.cashieringMethods.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.cashieringMethods.apex')}</span>
          </div>
        </div>
        <div className="row d-md-none mb-3">
          <div className="col-4">
            <span>{getText('defaults.clearerType.accountTypes.title')}</span>
          </div>
          <div className="col-8">
            <span>{getText('defaults.clearerType.accountTypes.apex')}</span>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4 d-none d-md-block"></div>
          <div className="col-6 col-md-4">
            <button className="btn-service-level" onClick={e => handleClick(e, ClearerType.RQD)}>
              {getText('defaults.clearerType.button.rqd')}
            </button>
          </div>
          <div className="col-6 col-md-4">
            <button className="btn-service-level" onClick={e => handleClick(e, ClearerType.Apex)}>
              {getText('defaults.clearerType.button.apex')}
            </button>
          </div>
        </div>
        <span className="txt-sm" style={{ fontStyle: 'italic', color: `var(--blue-dark)`, fontWeight: 'bold' }}>
          {getText('defaults.clearerType.disclaimer')}
        </span>
      </div>
    </Section>
  );
};
