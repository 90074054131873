import _defineProperty from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _MarginRequirements, _profile, _MarginRequirements2, _profile2, _MarginRequirements3, _profile3, _MarginRequirements4, _profile4, _MarginRequirements5, _profile5, _MarginRequirements6, _profile6, _MarginRequirements7, _profile7, _MarginRequirements8, _profile8, _MarginRequirements9, _profile9, _MarginRequirements10, _profile10, _MarginRequirements11, _profile11, _MarginRequirements12, _profile12, _MarginRequirements13, _profile13, _MarginRequirements14, _profile14, _MarginRequirements15, _profile15, _MarginRequirements16, _profile16, _MarginRequirements17, _profile17;

import { OrderClass, AssetType, SizeRatio, StrategyType, OrderActionDirection, OptionType, StrikeRules, ExpirationRules, DirectionRules } from '@tradingblock/types';
import { isIndexSymbol } from '..';
export { validateOrder } from './validation';
export { SchemaValidator } from './strategyValidator';
export var Shares = {
  info: {
    Name: 'Shares',
    Symbol: 'S',
    LegsAmount: 1,
    Strikes: 0,
    PermissionLevel: 0,
    SizeRatio: SizeRatio.NA,
    SvgPath: 'shares',
    IconKind: 'svg'
  },
  profile: (_profile = {
    Rules: {
      SpreadRatio: SizeRatio.NA
    },
    OrderClass: OrderClass.Single,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Equity,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile, OrderActionDirection.Long, {
    Strength: 9,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile, OrderActionDirection.Short, {
    Strength: 8,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile, "MarginRequirements", (_MarginRequirements = {}, _defineProperty(_MarginRequirements, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // if isLimitOrder
    // then 	if MktAsk(S) > 0   // if there is available market offer in S greater than 0
    //   then X = Min( MktAsk(S), Px(S))  // Px(S) is the client’s specified price for the strategy
    //   else X = Px(S)
    // else 	if MktAsk(S) > 0
    // 	then X = MktAsk(S)  // Market best offer will be a good estimate for execution price
    // 	else X = Last(S)  // Last price will be a good estimate for execution price X
    // fi
    // margin = ( X < 5 ? X : 0.5 * X ) * quantity;
    var ask = quotes[0].AskPrice;
    var last = quotes[0].LastTradePrice;
    var x;

    if (isLimitOrder) {
      if (ask > 0) {
        x = Math.min(ask, offerPrice);
      } else {
        x = offerPrice;
      }
    } else {
      if (ask > 0) {
        x = ask;
      } else {
        x = last;
      }
    }

    return x < 5 ? x : 0.5 * x;
  }), _defineProperty(_MarginRequirements, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // margin value is based on estimated execution price X of selling shares
    // if isLimitOrder
    // 	then	X = Max( MktBid(S), Px(S))) // Px(S) is the client’s specified price for the strategy
    // 	else	X = Max( MktBid(S), Last(S)) // conservative estimate of the execution price X
    // fi
    // margin = ( X < 2.5 ? 2.5 : ( X < 5 ? X : (X < 50 /3.0 ? 5 : 0.3 * X ) ) ) * quantity
    var bid = quotes[0].BidPrice;
    var last = quotes[0].LastTradePrice;
    var x;

    if (isLimitOrder) {
      x = Math.max(bid, offerPrice);
    } else {
      x = Math.max(bid, last);
    }

    return x < 2.5 ? 2.5 : x < 5 ? x : x < 50 / 3 ? 5 : 0.3 * x;
  }), _MarginRequirements)), _profile)
};
export var Call = {
  // validation: {
  //   orderSchema: orderSchemaBuilder(OrderClass.Single, [
  //     {
  //       assetType: AssetType.Option,
  //       action: [OrderAction.Buy, OrderAction.Sell],
  //       spreadRatio: 1,
  //     },
  //   ]),
  // },
  info: {
    Name: 'Call',
    Symbol: 'C',
    LegsAmount: 1,
    Strikes: 1,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.NA,
    SvgPath: 'call',
    IconKind: 'svg'
  },
  profile: (_profile2 = {
    Rules: {
      SpreadRatio: SizeRatio.NA
    },
    Bidirectional: true,
    OrderClass: OrderClass.Single,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile2, OrderActionDirection.Long, {
    Strength: 10,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile2, OrderActionDirection.Short, {
    Strength: 9,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile2, "MarginRequirements", (_MarginRequirements2 = {}, _defineProperty(_MarginRequirements2, OrderActionDirection.Long, function () {
    return 0;
  }), _defineProperty(_MarginRequirements2, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // X = Last(S)
    // if S is Index
    // then	margin = Max( 0.1 * X, 0.15 * (X – Max( 0, K – X ) ) ) * quantity * Multiplier
    // else	// Missing MktAsk shall be treated as zero
    // 	if MktAsk > 0 then X = Max( Last(S), MktAsk(S) )  // MktAsk(S) cannot be infinity either
    //    margin = Max( 0.1 * X, 0.20 * (X – Max( 0, K – X ) ) ) * quantity * Multiplier
    // fi
    var symbol = quotes[0].Symbol;
    var ask = quotes[0].AskPrice;

    if (Object.values(legs).length <= 0) {
      return 0;
    }

    var strike = Object.values(legs)[0].Strike;
    var x = quotes[0].LastTradePrice;

    if (isIndexSymbol(symbol)) {
      return Math.max(0.1 * x, 0.15 * (x - Math.max(0, strike - x)));
    } else {
      if (ask > 0) {
        x = Math.max(x, ask);
      }

      return Math.max(0.1 * x, 0.2 * (x - Math.max(0, strike - x)));
    }
  }), _MarginRequirements2)), _profile2)
};
export var Put = {
  // validation: {
  //   orderSchema: orderSchemaBuilder(OrderClass.Single, [
  //     {
  //       assetType: AssetType.Option,
  //       action: [OrderAction.Buy, OrderAction.Sell],
  //       spreadRatio: 1,
  //     },
  //   ]),
  // },
  info: {
    Name: 'Put',
    Symbol: 'P',
    LegsAmount: 1,
    Strikes: 1,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.NA,
    SvgPath: 'put',
    IconKind: 'svg'
  },
  profile: (_profile3 = {
    Rules: {
      SpreadRatio: SizeRatio.NA
    },
    Bidirectional: true,
    OrderClass: OrderClass.Single,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile3, OrderActionDirection.Long, {
    Strength: 10,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile3, OrderActionDirection.Short, {
    Strength: 8,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile3, "MarginRequirements", (_MarginRequirements3 = {}, _defineProperty(_MarginRequirements3, OrderActionDirection.Long, function () {
    // 0
    return 0;
  }), _defineProperty(_MarginRequirements3, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // margin is based on estimate of current value X of the underlying symbol S
    // X = Last(S)
    // if S is Index
    //   then margin = Max( 0.1 * K, 0.15 * (X – Max( 0, X - K ) ) )
    //   else margin = Max( 0.1 * K, 0.20 * (X – Max( 0, X - K ) ) )
    // fi
    //TODO: Ask Milan about how the user set price plays a role in the the margin calculation
    var symbol = quotes[0].Symbol;

    if (Object.values(legs).length <= 0) {
      return 0;
    }

    var strike = Object.values(legs)[0].Strike;
    var x = quotes[0].LastTradePrice;

    if (isIndexSymbol(symbol)) {
      return Math.max(0.1 * strike, 0.15 * (x - Math.max(0, x - strike)));
    } else {
      return Math.max(0.1 * strike, 0.2 * (x - Math.max(0, x - strike)));
    }
  }), _MarginRequirements3)), _profile3)
};
export var CoveredCall = {
  info: {
    Name: 'Covered Call',
    Symbol: 'CC',
    LegsAmount: 2,
    Strikes: 1,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.HundredToOne,
    SvgPath: 'covered-call',
    IconKind: 'svg'
  },
  profile: (_profile4 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 100,
      AssetType: AssetType.Equity,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }]
  }, _defineProperty(_profile4, OrderActionDirection.Long, {
    Strength: 6,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile4, OrderActionDirection.Short, {
    Strength: 7,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile4, "Rules", {
    SpreadRatio: SizeRatio.HundredToOne,
    Direction: DirectionRules.DifferentDirections
  }), _defineProperty(_profile4, "MarginRequirements", (_MarginRequirements4 = {}, _defineProperty(_MarginRequirements4, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // buying stock and selling call
    // // margin value is based on estimated price X of shares
    // // Px(S) is the client’s specified price for the strategy
    // if isLimitOrder
    //  then
    //    if MktAsk(S) > 0   // if there is available market offer in S greater than 0
    //    then X = Min( MktAsk(S), Px(S) + Max(0, MktAsk(S) - K)
    //    // Px(S) is the client’s specified price for the strategy
    //    else X = Min( Last(S), Px(S) + Max(0, Last(S) - K)
    // else
    //  if MktAsk(S) > 0
    // 	  then X = MktAsk(S)  // Market best offer will be a good estimate for execution price
    // 	  else X = Last(S)  // Last price will be a good estimate for execution price X
    // fi
    // margin = ( X < 5 ? X : 0.5 * X ) * quantity * Multiplier;
    // TODO: ask what should be the default value of X, inferred last price
    var ask = quotes[0].AskPrice;

    if (Object.values(legs).length <= 1) {
      return 0;
    }

    var strike = Object.values(legs)[1].Strike;
    var x;

    if (isLimitOrder) {
      if (ask > 0) {
        x = Math.min(ask, offerPrice + Math.max(0, ask - strike));
      } else {
        x = Math.min(quotes[0].LastTradePrice, offerPrice + Math.max(0, quotes[0].LastTradePrice - strike));
      }
    } else {
      if (ask > 0) {
        x = ask;
      } else {
        x = quotes[0].LastTradePrice;
      }
    }

    return x < 5 ? x : 0.5 * x;
  }), _defineProperty(_MarginRequirements4, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling stock and buying call
    // // margin value is based on estimated price X of shares
    // if MktAsk(S) > 0
    // then 	X = MktAsk(S)
    // else 	X = Last(S)
    // fi
    // margin = MAX( 0.5 * X, Min(0.1 * K + Max(0, K-X), X < 2.5 ? 2.5 : ( X < 5 ? X : (X < 50 /3.0 ? 5 : 0.3 * X ) ) ) * quantity * Multiplier
    var symbol = quotes[0].Symbol;
    var ask = quotes[0].AskPrice;
    var bid = quotes[0].BidPrice;

    if (Object.values(legs).length <= 1) {
      return 0;
    }

    var strike = Object.values(legs)[1].Strike;
    var qty = 1;
    var x = null;

    if (ask > 0) {
      x = ask;
    } else {
      x = quotes[0].LastTradePrice;
    }

    return Math.max(0.5 * x, Math.min(0.1 * strike + Math.max(0, strike - x), x < 2.5 ? 2.5 : x < 5 ? x : x < 50 / 3.0 ? 5 : 0.3 * x));
  }), _MarginRequirements4)), _profile4)
};
export var MarriedPut = {
  info: {
    Name: 'Married Put',
    Symbol: 'MP',
    LegsAmount: 2,
    Strikes: 1,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.HundredToOne,
    SvgPath: 'married-put',
    IconKind: 'svg'
  },
  profile: (_profile5 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 100,
      AssetType: AssetType.Equity,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile5, OrderActionDirection.Long, {
    Strength: 7,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile5, OrderActionDirection.Short, {
    Strength: 6,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile5, "Rules", {
    SpreadRatio: SizeRatio.HundredToOne,
    Direction: DirectionRules.SameDirections
  }), _defineProperty(_profile5, "MarginRequirements", (_MarginRequirements5 = {}, _defineProperty(_MarginRequirements5, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // buying stock and buying put
    // // margin value is based on estimated price X of shares
    // if isLimitOrder
    // then 	if MktAsk(S) > 0   // if there is available market offer in S greater than 0
    // then X = Min( MktAsk(S), Px(S))  // Px(S) is the client’s specified price for the strategy
    // else X = Min( Last(S), Px(S) )
    // else 	if MktAsk(S) > 0
    // 	then X = MktAsk(S)  // Market best offer will be a good estimate for execution price
    // 	else X = Last(S)  // Last price will be a good estimate for execution price X
    // 	fi
    // fi
    // margin = ( X < 5 ? X : 0.5 * X ) * quantity * Multiplier;
    var ask = quotes[0].AskPrice;
    var last = quotes[0].LastTradePrice;
    var x;

    if (isLimitOrder) {
      if (ask > 0) {
        x = Math.min(ask, offerPrice);
      } else {
        x = Math.min(last, offerPrice);
      }
    } else {
      if (ask > 0) {
        x = ask;
      } else {
        x = last;
      }
    }

    return x < 5 ? x : 0.5 * x;
  }), _defineProperty(_MarginRequirements5, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling stock and selling put
    // // margin value is based on estimated price X of shares
    // if MktAsk(S) > 0
    // then 	X = MktAsk(S)
    // else 	X = Last(S)
    // fi
    // margin = MAX( 0.5 * X + MAX( 0, K – X ), X < 2.5 ? 2.5 : ( X < 5 ? X : (X < 50 /3.0 ? 5 : 0.3 * X ) ) ) * quantity * Multiplier
    var ask = quotes[0].AskPrice;
    var strike = Object.values(legs)[0].Strike;
    var x;

    if (ask > 0) {
      x = ask;
    } else {
      x = quotes[0].LastTradePrice;
    }

    return Math.max(0.5 * x + Math.max(0, strike - x), x < 2.5 ? 2.5 : x < 5 ? x : x < 50 / 3.0 ? 5 : 0.3 * x);
  }), _MarginRequirements5)), _profile5)
};
export var CallCalendar = {
  info: {
    Name: 'Call Calendar',
    Symbol: 'CT',
    LegsAmount: 2,
    Strikes: 2,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.OneToOne,
    BidirectionalDebitCredit: true,
    Icon: 'fal fa-calendar-alt',
    IconKind: 'icon'
  },
  profile: (_profile6 = {
    Rules: {
      Expiration: ExpirationRules.DifferentExpiries,
      SpreadRatio: SizeRatio.OneToOne,
      Direction: DirectionRules.DifferentDirections,
      Strike: StrikeRules.Any
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call
    }]
  }, _defineProperty(_profile6, OrderActionDirection.Long, {
    Strength: 4,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile6, OrderActionDirection.Short, {
    Strength: 4,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile6, "MarginRequirements", (_MarginRequirements6 = {}, _defineProperty(_MarginRequirements6, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // buying later expiring call with strike K1 and selling earlier expiring call with strike K2
    // if IRA Aaccount
    // 	then 	// margin is the maximum of zero and strike difference
    // 	margin = Max( 0, K2 – K1 ) * quantity * Multiplier
    // else
    // 	X = Last(S)
    // 	if S is Index then
    // 		margin = Min( Max( 0, K2 – K1 ), Max( 0.1 * X, 0.15 * (X – Max( 0, K2 – X ) ) ) ) * quantity * Multiplier
    // 	else	// Missing MktAsk shall be treated as zero
    // 		if MktAsk > 0 then
    // 			X = Max( Last(S), MktAsk(S) )
    // 		fi
    // 		margin = Min( Max( 0, K2 – K1 ), Max( 0.1 * X, 0.20 * (X – Max( 0, K2 – X ) ) ) ) * quantity * Multiplier
    // 	fi
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;

    if (isIRA) {
      return Math.max(0, K2 - K1);
    }

    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;
    var ask = quotes[0].AskPrice;

    if (isIndexSymbol(symbol)) {
      return Math.min(Math.max(0, K2 - K1), Math.max(0.1 * x, 0.15 * (x - Math.max(0, K2 - x))));
    } else {
      if (ask > 0) {
        x = Math.max(x, ask);
      }

      return Math.min(Math.max(0, K2 - K1), Math.max(0.1 * x, 0.2 * (x - Math.max(0, K2 - x))));
    }
  }), _defineProperty(_MarginRequirements6, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling later expiring call with strike K1 and buying earlier expiring call with strike K2
    // // margin is the margin of the short call with strike K1 based on estimate of current value X of the
    // // underlying symbol S
    // X = Last(S)
    // if S is Index then
    // 	margin = Max( 0.1 * X, 0.15 * (X – Max( 0, K1 – X ) ) ) * quantity * Multiplier
    // else	// Missing MktAsk shall be treated as zero
    // 	if MktAsk > 0 then
    // 		X = Max( Last(S), MktAsk(S) )
    // 	fi
    // 	margin = Max( 0.1 * X, 0.20 * (X – Max( 0, K1 – X ) ) ) * quantity * Multiplier
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;

    if (isIndexSymbol(symbol)) {
      return Math.max(0.1 * x, 0.15 * (x - Math.max(0, K1 - x)));
    } else {
      var ask = quotes[0].AskPrice;

      if (ask > 0) {
        x = Math.max(x, ask);
      }

      return Math.max(0.1 * x, 0.2 * (x - Math.max(0, K1 - x)));
    }
  }), _MarginRequirements6)), _profile6)
};
export var PutCalendar = {
  info: {
    Name: 'Put Calendar',
    Symbol: 'PT',
    LegsAmount: 2,
    Strikes: 2,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.OneToOne,
    BidirectionalDebitCredit: true,
    Icon: 'fal fa-calendar-alt',
    IconKind: 'icon'
  },
  profile: (_profile7 = {
    Rules: {
      Expiration: ExpirationRules.DifferentExpiries,
      SpreadRatio: SizeRatio.OneToOne,
      Direction: DirectionRules.DifferentDirections,
      Strike: StrikeRules.Any
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put
    }]
  }, _defineProperty(_profile7, OrderActionDirection.Long, {
    Strength: 4,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile7, OrderActionDirection.Short, {
    Strength: 4,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile7, "MarginRequirements", (_MarginRequirements7 = {}, _defineProperty(_MarginRequirements7, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // buying later expiring put with strike K1 and selling earlier expiring put with strike K2
    // if IRA Aaccount then 	// margin is the maximum of zero and strike difference
    // 	margin = Max( 0, K1 – K2 ) * quantity * Multiplier
    // else
    // 	X = Last(S)
    // 	if S is Index then
    // 		margin = Min( Max( 0, K1 – K2 ), Max( 0.1 * K2, 0.15 * (X – Max( 0, X – K2 ) ) ) ) * quantity * Multiplier
    // 	else
    // 		margin = Min( Max( 0, K1 – K2 ), Max( 0.1 * K2, 0.20 * (X – Max( 0, X – K2 ) ) ) ) * quantity * Multiplier
    // 	fi
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;

    if (isIRA) {
      return Math.max(0, K1 - K2);
    } else {
      var x = quotes[0].LastTradePrice;
      var symbol = quotes[0].Symbol;

      if (isIndexSymbol(symbol)) {
        return Math.min(Math.max(0, K1 - K2), Math.max(0.1 * K2, 0.15 * (x - Math.max(0, x - K2))));
      } else {
        return Math.min(Math.max(0, K1 - K2), Math.max(0.1 * K2, 0.2 * (x - Math.max(0, x - K2))));
      }
    }
  }), _defineProperty(_MarginRequirements7, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling later expiring put with strike K1 and buying earlier expiring put with strike K2
    // // margin is the margin of the short put with strike K1 based on estimate of current value X of the
    // // underlying symbol S
    // X = Last(S)
    // if S is Index then
    // 	margin = Max( 0.1 * K1, 0.15 * (X – Max( 0, X – K1 ) ) ) * quantity * Multiplier
    // else
    // 	margin = Max( 0.1 * K1, 0.20 * (X – Max( 0, X – K1 ) ) ) * quantity * Multiplier
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;

    if (isIndexSymbol(symbol)) {
      return Math.max(0.1 * K1, 0.15 * (x - Math.max(0, x - K1)));
    } else {
      return Math.max(0.1 * K1, 0.2 * (x - Math.max(0, x - K1)));
    }
  }), _MarginRequirements7)), _profile7)
};
export var CallVertical = {
  info: {
    Name: 'Call Vertical',
    Symbol: 'CV',
    LegsAmount: 2,
    Strikes: 2,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.OneToOne,
    SvgPath: 'call-vertical',
    IconKind: 'svg'
  },
  profile: (_profile8 = {
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: StrikeRules.IncreasingOrder,
      SpreadRatio: SizeRatio.OneToOne,
      Direction: DirectionRules.DifferentDirections
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }]
  }, _defineProperty(_profile8, OrderActionDirection.Long, {
    Strength: 8,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile8, OrderActionDirection.Short, {
    Strength: 6,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile8, "MarginRequirements", (_MarginRequirements8 = {}, _defineProperty(_MarginRequirements8, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements8, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // selling call with lower strike K1 and buying call with higher strike K2, same expiration
    // if IRA Aaccount then 	// margin is the maximum risk
    // 	margin = | K2 – K1 | * quantity * Multiplier
    // else  	// margin will be the margin of the short call with the strike K1
    // 	X = Last(S)
    // 	if S is Index then
    // 		margin = Max( 0.1 * X, 0.15 * (X – Max( 0, K1 – X ) ) ) * quantity * Multiplier
    // 	else	// Missing MktAsk shall be treated as zero
    // 		if MktAsk > 0 then
    // 			X = Max( Last(S), MktAsk(S) )
    // 		fi
    // 		margin = Max( 0.1 * X, 0.20 * (X – Max( 0, K1 – X ) ) ) * quantity * Multiplier
    // 	fi
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    var strikeDiff = Math.abs(K2 - K1);

    if (isIRA) {
      return strikeDiff;
    }

    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;
    var ask = quotes[0].AskPrice;

    if (isIndexSymbol(symbol)) {
      var margin = Math.max(0.1 * x, 0.15 * (x - Math.max(0, K1 - x)));
      return Math.min(strikeDiff, margin);
    } else {
      if (ask > 0) {
        x = Math.max(x, ask);
      }

      var _margin = Math.max(0.1 * x, 0.2 * (x - Math.max(0, K1 - x)));

      return Math.min(strikeDiff, _margin);
    }
  }), _MarginRequirements8)), _profile8)
};
export var PutVertical = {
  info: {
    Name: 'Put Vertical',
    Symbol: 'PV',
    LegsAmount: 2,
    Strikes: 2,
    PermissionLevel: 1,
    SizeRatio: SizeRatio.OneToOne,
    SvgPath: 'put-vertical',
    IconKind: 'svg'
  },
  profile: (_profile9 = {
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: StrikeRules.DecreasingOrder,
      SpreadRatio: SizeRatio.OneToOne,
      Direction: DirectionRules.DifferentDirections
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }]
  }, _defineProperty(_profile9, OrderActionDirection.Long, {
    Strength: 8,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile9, OrderActionDirection.Short, {
    Strength: 6,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile9, "MarginRequirements", (_MarginRequirements9 = {}, _defineProperty(_MarginRequirements9, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements9, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // selling put with higher strike K1 and buying put with lower strike K2, same expiration
    // if IRA Aaccount then 	// margin is the maximum risk
    // 	margin = | K1 – K2 | * quantity * Multiplier
    // else  	// margin will be the margin of the short put with the strike K1
    // 	X = Last(S)
    // 	if S is Index then
    // 		margin = Max( 0.1 * K, 0.15 * (X – Max( 0, X - K1 ) ) ) * quantity * Multiplier
    // 	else
    // 		margin = Max( 0.1 * K, 0.20 * (X – Max( 0, X – K1 ) ) ) * quantity * Multiplier
    // 	fi
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    var strikeDiff = Math.abs(K1 - K2);

    if (isIRA) {
      return strikeDiff;
    }

    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;

    if (isIndexSymbol(symbol)) {
      var margin = Math.max(0.1 * x, 0.15 * (x - Math.max(0, x - K1)));
      return Math.min(strikeDiff, margin);
    } else {
      var _margin2 = Math.max(0.1 * x, 0.2 * (x - Math.max(0, x - K1)));

      return Math.min(strikeDiff, _margin2);
    }
  }), _MarginRequirements9)), _profile9)
};
export var CallButterfly = {
  info: {
    Name: 'Call Butterfly',
    Symbol: 'CF',
    LegsAmount: 3,
    Strikes: 3,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneTwoOne,
    SvgPath: 'call-butterfly',
    IconKind: 'svg'
  },
  profile: (_profile10 = {
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: StrikeRules.EquidistantStrikes,
      SpreadRatio: SizeRatio.OneTwoOne,
      Direction: DirectionRules.Butterfly
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 2,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile10, OrderActionDirection.Long, {
    Strength: 4,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile10, OrderActionDirection.Short, {
    Strength: 3,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile10, "MarginRequirements", (_MarginRequirements10 = {}, _defineProperty(_MarginRequirements10, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements10, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    //|K2 - K1|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K2 - K1);
  }), _MarginRequirements10)), _profile10)
};
export var PutButterfly = {
  info: {
    Name: 'Put Butterfly',
    Symbol: 'PF',
    LegsAmount: 3,
    Strikes: 3,
    PermissionLevel: 2,
    SizeRatio: SizeRatio.OneTwoOne,
    SvgPath: 'put-butterfly',
    IconKind: 'svg'
  },
  profile: (_profile11 = {
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: StrikeRules.EquidistantStrikes,
      SpreadRatio: SizeRatio.OneTwoOne,
      Direction: DirectionRules.Butterfly
    },
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 2,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile11, OrderActionDirection.Long, {
    Strength: 4,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile11, OrderActionDirection.Short, {
    Strength: 3,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile11, "MarginRequirements", (_MarginRequirements11 = {}, _defineProperty(_MarginRequirements11, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements11, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    //|K1 - K2|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K1 - K2);
  }), _MarginRequirements11)), _profile11)
};
export var Straddle = {
  info: {
    Name: 'Straddle',
    Symbol: 'SD',
    LegsAmount: 2,
    Strikes: 1,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOne,
    SvgPath: 'straddle',
    IconKind: 'svg'
  },
  profile: (_profile12 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile12, OrderActionDirection.Long, {
    Strength: 7,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile12, OrderActionDirection.Short, {
    Strength: 0,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile12, "Rules", {
    Expiration: ExpirationRules.SameExpiry,
    Strike: StrikeRules.SameStrikes,
    SpreadRatio: SizeRatio.OneToOne,
    Direction: DirectionRules.SameDirections
  }), _defineProperty(_profile12, "MarginRequirements", (_MarginRequirements12 = {}, _defineProperty(_MarginRequirements12, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements12, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling a call and a put with the same strike
    // X = Last(S)
    // if S is Index then
    // 	margin = Max( Max( 0.1*X, 0.15*(X–Max(0,K–X))) , Max(0.1*K, 0.15*(X–Max(0,X-K)))) * quantity * Multiplier
    // else	// Missing MktAsk shall be treated as zero
    // 	if MktAsk > 0 then
    // 		X = Max( Last(S), MktAsk(S) )  // MktAsk(S) cannot be infinity either
    // 	margin = Max( Max( 0.1*X, 0.20*(X–Max(0,K–X))), Max(0.1*K, 0.20*(X–Max(0,X-K)))) * quantity * Multiplier
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length <= 0) {
      return 0;
    }

    var K = legsArray[0].Strike;
    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;

    if (isIndexSymbol(symbol)) {
      return Math.max(Math.max(0.1 * x, 0.15 * Math.max(0, K - x)), Math.max(0.1 * K, 0.15 * Math.max(0, x - K)));
    } else {
      var ask = quotes[0].AskPrice;

      if (ask > 0) {
        x = Math.max(x, ask);
      }

      return Math.max(Math.max(0.1 * x, 0.2 * Math.max(0, K - x)), Math.max(0.1 * K, 0.2 * Math.max(0, x - K)));
    }
  }), _MarginRequirements12)), _profile12)
};
export var Strangle = {
  info: {
    Name: 'Strangle',
    Symbol: 'SG',
    LegsAmount: 2,
    Strikes: 2,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOne,
    SvgPath: 'strangle',
    IconKind: 'svg'
  },
  profile: (_profile13 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile13, OrderActionDirection.Long, {
    Strength: 7,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile13, OrderActionDirection.Short, {
    Strength: 1,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile13, "Rules", {
    Expiration: ExpirationRules.SameExpiry,
    Strike: StrikeRules.CallHigherThanPut,
    SpreadRatio: SizeRatio.OneToOne,
    Direction: DirectionRules.SameDirections
  }), _defineProperty(_profile13, "MarginRequirements", (_MarginRequirements13 = {}, _defineProperty(_MarginRequirements13, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements13, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // // selling a call and a put with different strikes, K1 Call strike, K2 Put Strike
    // X = Last(S)
    // if S is Index then
    // 	margin = Max( Max( 0.1*X, 0.15*(X–Max(0,K1–X))) , Max(0.1*K2, 0.15*(X–Max(0,X-K2)))) * quantity * Multiplier
    // else	// Missing MktAsk shall be treated as zero
    // 	if MktAsk > 0 then
    // 		X = Max( Last(S), MktAsk(S) )  // MktAsk(S) cannot be infinity either
    // 	margin = Max( Max( 0.1*X, 0.20*(X–Max(0,K1–X))), Max(0.1*K2, 0.20*(X–Max(0,X-K2)))) * quantity * Multiplier
    // fi
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    var x = quotes[0].LastTradePrice;
    var symbol = quotes[0].Symbol;

    if (isIndexSymbol(symbol)) {
      return Math.max(Math.max(0.1 * x, 0.15 * Math.max(0, K1 - x)), Math.max(0.1 * K2, 0.15 * Math.max(0, x - K2)));
    } else {
      var ask = quotes[0].AskPrice;

      if (ask > 0) {
        x = Math.max(x, ask);
      }

      return Math.max(Math.max(0.1 * x, 0.2 * Math.max(0, K1 - x)), Math.max(0.1 * K2, 0.2 * Math.max(0, x - K2)));
    }
  }), _MarginRequirements13)), _profile13)
};
export var CallCondor = {
  info: {
    Name: 'Call Condor',
    Symbol: 'CR',
    LegsAmount: 4,
    Strikes: 4,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOneToOneToOne,
    SvgPath: 'call-condor',
    IconKind: 'svg'
  },
  profile: (_profile14 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile14, OrderActionDirection.Long, {
    Strength: 5,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile14, OrderActionDirection.Short, {
    Strength: 4,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile14, "Rules", {
    Expiration: ExpirationRules.SameExpiry,
    Strike: [StrikeRules.IncreasingOrder, StrikeRules.EquidistantStrikesByTwos],
    SpreadRatio: SizeRatio.OneToOneToOneToOne,
    Direction: DirectionRules.Condor
  }), _defineProperty(_profile14, "MarginRequirements", (_MarginRequirements14 = {}, _defineProperty(_MarginRequirements14, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements14, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    //|K2 - K1|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K2 - K1);
  }), _MarginRequirements14)), _profile14)
};
export var PutCondor = {
  info: {
    Name: 'Put Condor',
    Symbol: 'PR',
    LegsAmount: 4,
    Strikes: 4,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOneToOneToOne,
    SvgPath: 'put-condor',
    IconKind: 'svg'
  },
  profile: (_profile15 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: [StrikeRules.DecreasingOrder, StrikeRules.EquidistantStrikesByTwos],
      SpreadRatio: SizeRatio.OneToOneToOneToOne,
      Direction: DirectionRules.Condor
    },
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }]
  }, _defineProperty(_profile15, OrderActionDirection.Long, {
    Strength: 5,
    StrategyType: StrategyType.Bear
  }), _defineProperty(_profile15, OrderActionDirection.Short, {
    Strength: 4,
    StrategyType: StrategyType.Bull
  }), _defineProperty(_profile15, "MarginRequirements", (_MarginRequirements15 = {}, _defineProperty(_MarginRequirements15, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements15, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    //|K1 - K2|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K1 - K2);
  }), _MarginRequirements15)), _profile15)
};
export var IronButterfly = {
  info: {
    Name: 'Iron Butterfly',
    Symbol: 'IF',
    LegsAmount: 4,
    Strikes: 3,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOneToOneToOne,
    SvgPath: 'iron-butterfly',
    IconKind: 'svg'
  },
  profile: (_profile16 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: [StrikeRules.IronButterfly, StrikeRules.EquidistantStrikesByTwos, StrikeRules.CallFirstPutsSecond],
      SpreadRatio: SizeRatio.OneToOneToOneToOne,
      Direction: DirectionRules.Iron
    },
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }]
  }, _defineProperty(_profile16, OrderActionDirection.Long, {
    Strength: 6,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile16, OrderActionDirection.Short, {
    Strength: 2,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile16, "MarginRequirements", (_MarginRequirements16 = {}, _defineProperty(_MarginRequirements16, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements16, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // |K1 - K2|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K1 - K2);
  }), _MarginRequirements16)), _profile16)
};
export var IronCondor = {
  info: {
    Name: 'Iron Condor',
    Symbol: 'IR',
    LegsAmount: 4,
    Strikes: 4,
    PermissionLevel: 4,
    SizeRatio: SizeRatio.OneToOneToOneToOne,
    SvgPath: 'iron-condor',
    IconKind: 'svg'
  },
  profile: (_profile17 = {
    OrderClass: OrderClass.Multileg,
    Bidirectional: true,
    Rules: {
      Expiration: ExpirationRules.SameExpiry,
      Strike: [StrikeRules.IronCondor, StrikeRules.EquidistantStrikesByTwos, StrikeRules.CallFirstPutsSecond],
      SpreadRatio: SizeRatio.OneToOneToOneToOne,
      Direction: DirectionRules.Iron
    },
    Legs: [{
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Short
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Call,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Long
    }, {
      SpreadRatio: 1,
      AssetType: AssetType.Option,
      OptionType: OptionType.Put,
      Direction: OrderActionDirection.Short
    }]
  }, _defineProperty(_profile17, OrderActionDirection.Long, {
    Strength: 5,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile17, OrderActionDirection.Short, {
    Strength: 3,
    StrategyType: StrategyType.Volatility
  }), _defineProperty(_profile17, "MarginRequirements", (_MarginRequirements17 = {}, _defineProperty(_MarginRequirements17, OrderActionDirection.Long, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    return 0;
  }), _defineProperty(_MarginRequirements17, OrderActionDirection.Short, function (legs, quotes, isLimitOrder, offerPrice, isIRA) {
    // |K1 - K2|
    var legsArray = Object.values(legs);

    if (legsArray.length < 2) {
      return 0;
    }

    var K1 = legsArray[0].Strike;
    var K2 = legsArray[1].Strike;
    return Math.abs(K1 - K2);
  }), _MarginRequirements17)), _profile17)
}; // export const Collar: TradingStrategy = {
//   info: {
//     Name: 'Collar',
//     Symbol: 'CO',
//     LegsAmount: 3,
//     Strikes: 2,
//     PermissionLevel: 4,
//     SizeRatio: SizeRatio.HundredToOneToOne,
//     SvgPath: 'collar',
//     IconKind: 'svg',
//   },
//   profile: {
//     OrderClass: OrderClass.Multileg,
//     Bidirectional: true,
//     Legs: [
//       {
//         SpreadRatio: 100,
//         AssetType: AssetType.Equity,
//         Direction: OrderActionDirection.Long,
//       },
//       {
//         SpreadRatio: 1,
//         AssetType: AssetType.Option,
//         OptionType: OptionType.Call,
//         Direction: OrderActionDirection.Short,
//       },
//       {
//         SpreadRatio: 1,
//         AssetType: AssetType.Option,
//         OptionType: OptionType.Put,
//         Direction: OrderActionDirection.Long,
//       },
//     ],
//     [OrderActionDirection.Long]: {
//       Strength: 8,
//       StrategyType: StrategyType.Bull,
//     },
//     [OrderActionDirection.Short]: {
//       Strength: 8,
//       StrategyType: StrategyType.Bear,
//     },
//     Rules: {
//       Expiration: ExpirationRules.SameExpiry,
//       Strike: StrikeRules.CallHigherThanPut,
//       SpreadRatio: SizeRatio.HundredToOneToOne,
//     },
//     MarginRequirements: {
//       [OrderActionDirection.Long]: (legs, quotes, isLimitOrder, offerPrice, isIRA) => {
//         // // buying shares and selling a call with strike K1 and buying a put with strike K2
//         // // margin is the same as for covered call
//         // // margin value is based on estimated price X of shares
//         // // Px(S) is the client’s specified price for the strategy
//         // if isLimitOrder then
//         // 	if MktAsk(S) > 0 then
//         // 		X = Min( MktAsk(S), Px(S) + Max(0, MktAsk(S) – K1)  // if there is available market offer in S greater than 0
//         // 	else // Px(S) is the client’s specified price for the strategy
//         // 		X = Min( Last(S), Px(S) + Max(0, Last(S) – K1)
//         // 	fi
//         // else
//         // 	if MktAsk(S) > 0 then
//         // 		X = MktAsk(S)  // Market best offer will be a good estimate for execution price
//         // 	else
//         // 		X = Last(S)  // Last price will be a good estimate for execution price X
//         // 	fi
//         // fi
//         // margin = ( X < 5 ? X : 0.5 * X ) * quantity * Multiplier;
//         const legsArray = Object.values(legs);
//         if (legsArray.length < 2) {
//           return 0;
//         }
//         const K1 = (legsArray[0] as OrderLegDTOState).Strike;
//         let x: number;
//         const ask = quotes[0].AskPrice;
//         if (isLimitOrder) {
//           if (ask > 0) {
//             x = Math.min(ask, offerPrice + Math.max(0, ask - K1));
//           } else {
//             x = Math.min(quotes[0].LastTradePrice, offerPrice + Math.max(0, quotes[0].LastTradePrice - K1));
//           }
//         } else {
//           if (ask > 0) {
//             x = ask;
//           } else {
//             x = quotes[0].LastTradePrice;
//           }
//         }
//         return x < 5 ? x : 0.5 * x;
//       },
//       [OrderActionDirection.Short]: (legs, quotes, isLimitOrder, offerPrice, isIRA) => {
//         // if MktAsk(S) > 0 then
//         // 	X = MktAsk(S)
//         // else
//         // 	X = Last(S)
//         // fi
//         // margin = MAX( 0.5 * X + MAX( 0, K2 – X ), X < 2.5 ? 2.5 : ( X < 5 ? X : (X < 50 /3.0 ? 5 : 0.3 * X ) ) )
//         const legsArray = Object.values(legs);
//         if (legsArray.length < 2) {
//           return 0;
//         }
//         let x: number = quotes[0].LastTradePrice;
//         const ask = quotes[0].AskPrice;
//         const K2 = (legsArray[1] as OrderLegDTOState).Strike;
//         if (ask > 0) {
//           x = ask;
//         }
//         return Math.max(0.5 * x + Math.max(0, K2 - x), x < 2.5 ? 2.5 : x < 5 ? x : x < 50 / 3.0 ? 5 : 0.3 * x);
//       },
//     },
//   },
// };

export var Strategies = [Shares, Call, Put, CallVertical, CallCalendar, PutVertical, PutCalendar, Straddle, Strangle, CoveredCall, MarriedPut, CallButterfly, PutButterfly, CallCondor, PutCondor, IronButterfly, IronCondor];
export var IndexStrategies = [Call, Put, CallVertical, CallCalendar, PutVertical, PutCalendar, Straddle, Strangle, CallButterfly, PutButterfly, CallCondor, PutCondor, IronButterfly, IronCondor];