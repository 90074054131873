import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { PublicOfficialModel, DefaultPublicOfficial } from '../../types';
import { FieldValue } from './';

const PublicOfficial: React.FC<{ value: PublicOfficialModel | undefined }> = ({ value }) => {
  const publicOfficial = value || DefaultPublicOfficial;
  return (
    <FieldValue value={publicOfficial.name}>
      {publicOfficial.name}
      <br />
      <Text id="disclosuresEntityPublicOfficials.role" type="field" />
      {': '}
      <FieldValue value={publicOfficial.role} />
      <br />
      <Text id="disclosuresEntityPublicOfficials.title" type="field" />
      {': '}
      <FieldValue value={publicOfficial.title} />
      <br />
      <Text id="disclosuresEntityPublicOfficials.organization" type="field" />
      {': '}
      <FieldValue value={publicOfficial.organization} />
      <br />
      <Text id="disclosuresEntityPublicOfficials.familyNames" type="field" />
      {': '}
      <FieldValue value={publicOfficial.familyNames} />
    </FieldValue>
  );
};

const PublicOfficials: React.FC<{ value: PublicOfficialModel[] | undefined }> = ({ value }) => {
  return (
    <>
      {_.map(value, (p, i) => (
        <p key={`publicOfficial${i}`}>
          <strong>
            <Text id="publicOfficials.name" data={{ number: i + 1 }} />
          </strong>
          <br />
          <PublicOfficial value={p} />
        </p>
      ))}
    </>
  );
};

export const PublicOfficialsValue = PublicOfficials;
