import React from 'react';
export var AddIcon = function AddIcon(_ref) {
  var active = _ref.active,
      inverse = _ref.inverse,
      size = _ref.size;
  return React.createElement("span", {
    className: "fa-stack add-icon".concat(size ? " fa-".concat(size) : '')
  }, React.createElement("i", {
    className: "fas fa-circle fa-stack-2x"
  }), React.createElement("i", {
    className: "fal ".concat(active ? 'fa-minus' : 'fa-plus', " fa-stack-1x").concat(inverse ? ' fa-inverse' : '')
  }));
};