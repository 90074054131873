import * as _ from 'lodash';

var getEnvValue = function getEnvValue(key) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var keyVal = "".concat(prefix).concat(key);
  var strVal = process.env[keyVal];
  return strVal;
};

function getValueOrDefault(key, prefix, getValue) {
  return function (defaultValue) {
    var keyVal = "".concat(prefix).concat(key);
    var strVal = process.env[keyVal];
    var envVal = getValue ? getValue(strVal) : strVal;
    return _.isNil(envVal) ? defaultValue : envVal;
  };
}

var value = function value(key, getValue) {
  return getValueOrDefault(key, '', getValue);
};

var isTrue = function isTrue(value) {
  return value && ['1', 'true', 'True', 'TRUE', 'yes'].includes(value) ? true : false;
};

export var ConfigUtils = {
  value: value,
  getValueOrDefault: getValueOrDefault,
  getEnvValue: getEnvValue,
  isTrue: isTrue
};