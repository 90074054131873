import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import dayjs from 'dayjs';
import { APINotAuthorizedResponseCode, ResponseCodes, SuccessResponseCode } from '@tradingblock/types';
import { InitialState, ApplicationUIState, ApplicationDataState } from './types';
import { UiActions, RootApplicationAction, ApplicationActions, TokenActions } from './actions';
import { getNumberOfSectionsInApplicationStep, getVisibleSectionsForApplicationStep } from '../services';
import { ApplicationStep, DefaultApplicationModel } from '../types';

const ui = (state = InitialState.ui, action: RootApplicationAction): ApplicationUIState => {
  switch (action.type) {
    case getType(UiActions.setSiteGrp): {
      const siteGrp = action.payload || '';
      return {
        ...state,
        siteGrp,
      };
    }
    case getType(UiActions.setClearer): {
      const clearer = action.payload || '';
      return {
        ...state,
        clearer,
      };
    }
    case getType(UiActions.setAffiliate): {
      const affiliate = action.payload || '';
      return {
        ...state,
        affiliate,
      };
    }
    case getType(UiActions.setAuthToken): {
      const { apiToken, storageToken } = action.payload || { apiToken: undefined, storageToken: undefined };
      return {
        ...state,
        apiToken,
        storageToken,
      };
    }
    case getType(ApplicationActions.receiveApplicationStatus): {
      const { responseCode } = action.payload;
      const apiRequestFailed = responseCode === APINotAuthorizedResponseCode;
      return {
        ...state,
        apiTokenExpired: apiRequestFailed ? true : state.apiTokenExpired,
      };
    }
    case getType(TokenActions.uiTokenExpired): {
      return {
        ...state,
        storageTokenExpired: true,
      };
    }
    case getType(ApplicationActions.requestSaveApplication): {
      return {
        ...state,
        scrollToSection: false,
      };
    }
    case getType(UiActions.setScrollToSection): {
      return {
        ...state,
        scrollToSection: action.payload.scrollToSection,
      };
    }
    case getType(UiActions.showNextSection): {
      return {
        ...state,
        visibleSections: state.visibleSections + 1,
        scrollToSection: action.payload.scrollToSection,
      };
    }
    case getType(UiActions.setStep): {
      const { step, application } = action.payload;
      const currentStep = state.step;
      const nextNumberOfSections = getNumberOfSectionsInApplicationStep(step, application);
      const nextVisibleSections = getVisibleSectionsForApplicationStep(step, application) || 1;

      if (
        step === currentStep &&
        state.numberOfSections === nextNumberOfSections &&
        state.visibleSections === nextVisibleSections
      ) {
        console.warn('attempting to update application step state to current values. skipping');
        return state;
      }
      return {
        ...state,
        step,
        numberOfSections: nextNumberOfSections,
        visibleSections: nextVisibleSections,
      };
    }
    case getType(UiActions.setNextPage): {
      const nextPage = action.payload;
      return {
        ...state,
        nextPage,
      };
    }
    case getType(UiActions.clearNextPage): {
      return {
        ...state,
        nextPage: undefined,
      };
    }
    case getType(ApplicationActions.requestUiData): {
      return {
        ...state,
        isFetching: true,
      };
    }
    case getType(ApplicationActions.receiveUiData): {
      const { responseCode, payload } = action.payload;
      const { userRequirements, heardAbout } = payload || { userRequirements: undefined, heardAbout: undefined };
      if (responseCode !== SuccessResponseCode) {
        console.warn('Fetching UI data failed: ', ResponseCodes[responseCode] && ResponseCodes[responseCode].name);
      }
      return {
        ...state,
        isFetching: false,
        userRequirements,
        heardAbout,
      };
    }

    default:
      return state;
  }
};

const data = (state = InitialState.data, action: RootApplicationAction): ApplicationDataState => {
  switch (action.type) {
    case getType(ApplicationActions.requestApplicationStatus): {
      return {
        ...state,
        isFetchingStatus: true,
        status: undefined,
      };
    }
    case getType(ApplicationActions.receiveApplicationStatus): {
      const { payload, responseCode } = action.payload;
      const success = responseCode === SuccessResponseCode;
      return {
        ...state,
        isFetchingStatus: false,
        status: success ? payload : null,
      };
    }
    case getType(ApplicationActions.requestSaveApplication): {
      const { saveStep, saveType } = action.payload;
      return {
        ...state,
        isSaving: true,
        saveStep,
        saveType,
        createdUser: undefined,
        createdAccount: undefined,
        errorCode: undefined,
      };
    }
    case getType(ApplicationActions.receiveSaveApplication): {
      const { application, saveStep, saveType } = action.payload;
      return {
        ...state,
        isSaving: false,
        application,
        saveStep,
        saveType,
      };
    }
    case getType(ApplicationActions.requestPartialSaveApplication): {
      return {
        ...state,
        isSaving: true,
        saveType: 'partial',
      };
    }
    case getType(ApplicationActions.receivePartialSaveApplication): {
      const { application } = action.payload;
      return {
        ...state,
        isSaving: false,
        application,
      };
    }
    case getType(UiActions.showNextSection): {
      return {
        ...state,
        saveStep: undefined,
        saveType: undefined,
      };
    }
    case getType(UiActions.clearNextPage): {
      return {
        ...state,
        saveStep: undefined,
        saveType: undefined,
      };
    }
    case getType(ApplicationActions.requestCreateUser): {
      return {
        ...state,
        isSaving: true,
        saveStep: ApplicationStep.SecureAccount,
        saveType: 'continue',
        errorCode: undefined,
      };
    }
    case getType(ApplicationActions.receiveCreateUser): {
      const { errors, responseCode } = action.payload;
      const success = responseCode === SuccessResponseCode;
      const errorCode = success ? undefined : responseCode;
      return {
        ...state,
        isSaving: false,
        saveStep: undefined,
        saveType: undefined,
        createdUser: success,
        errorCode,
        errorMessages: errors,
      };
    }
    case getType(ApplicationActions.requestCreateAccount): {
      return {
        ...state,
        isSaving: true,
        errorCode: undefined,
      };
    }
    case getType(ApplicationActions.receiveCreateAccount): {
      const { payload, responseCode, errors } = action.payload;
      const success = responseCode === SuccessResponseCode;
      const errorCode = success ? undefined : responseCode;
      const application = {
        ...(state.application || DefaultApplicationModel),
        accountId: success && payload ? payload.accountId : undefined,
        applicationSentOn: dayjs()
          .utc()
          .toDate(),
      };
      return {
        ...state,
        isSaving: false,
        application: success ? application : state.application,
        createdAccount: success,
        errorCode,
        errorMessages: errors ? errors : undefined,
      };
    }
    case getType(ApplicationActions.requestDuplicateAccountCheck): {
      return {
        ...state,
        duplicateAccountCheck: {
          isFetching: true,
        },
      };
    }
    case getType(ApplicationActions.receiveDuplicateAccountCheck): {
      const { payload, responseCode } = action.payload;
      const success = responseCode === SuccessResponseCode;
      const duplicateAccountCheck = {
        isFetching: false,
        error: !success && payload ? true : false,
        errorMessage: !success && payload ? payload[0].toString() : undefined,
      };
      return {
        ...state,
        duplicateAccountCheck,
      };
    }
    case getType(ApplicationActions.requestCreatePartialEntityAccount): {
      return {
        ...state,
        isSaving: true,
        errorCode: undefined,
      };
    }
    case getType(ApplicationActions.receiveCreatePartialEntityAccount): {
      const { payload, responseCode } = action.payload;
      const success = responseCode === SuccessResponseCode;
      const errorCode = success ? undefined : responseCode;
      const application = {
        ...(state.application || DefaultApplicationModel),
        accountId: success && payload ? payload.accountId : undefined,
        applicationSentOn: dayjs()
          .utc()
          .toDate(),
      };
      return {
        ...state,
        isSaving: false,
        application: success ? application : state.application,
        createdAccount: success,
        errorCode,
      };
    }

    default:
      return state;
  }
};

export const RootReducer = combineReducers({
  ui,
  data,
});
