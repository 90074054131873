import React from 'react';
import { Field } from 'formik';
import { BaseFieldProps, FormGroup, TextboxField } from '@tradingblock/components';
import { PublicOfficialModel } from '../../types';

interface PublicOfficialFieldProps extends BaseFieldProps {
  value?: PublicOfficialModel;
}

export const PublicOfficialField: React.FC<PublicOfficialFieldProps> = ({ id, value }) => {
  const prefix = id ? `${id}.` : '';
  return (
    <>
      <FormGroup>
        <Field component={TextboxField} id={`${prefix}name`} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id={`${prefix}role`} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id={`${prefix}title`} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id={`${prefix}organization`} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id={`${prefix}familyNames`} />
      </FormGroup>
    </>
  );
};
