import React, { useMemo } from 'react';
import _ from 'lodash';
import { ApplicationStepProps, EntityAccountType, ApplicationFormSection, ApplicationType } from '../../../types';
import { useData } from '../../../state/selectors';
import {
  PrimaryAccountHolderContactSection,
  JointAccountHolderContactSection,
  UgmaAccountHolderContactSection,
} from './account-information/AccountHolderContactSection';
import {
  PrimaryAccountHolderPersonalInfoSection,
  JointAccountHolderPersonalInfoSection,
  UgmaAccountHolderPersonalInfoSection,
} from './account-information/AccountHolderPersonalInfoSection';
import { EntitySection } from './account-information/EntitySection';
import { EntityFinancialActivitySection } from './account-information/EntityFinancialActivitySection';

export const AccountInformationForm: React.FC<ApplicationStepProps> = ({
  getText,
  values,
  setValues,
  errors,
  touched,
  submitCount,
}) => {
  const visibleSections = useData(d => d.ui.visibleSections);
  const sections = useMemo(() => {
    let allSections: React.FC<ApplicationFormSection>[] = [];
    // if (values.entityAccountType) {
    //   // entity details
    //   allSections = [...allSections, EntitySection];
    //   if (values.entityAccountType !== EntityAccountType.PersonalTrust) {
    //     // non-trust entity financial activity
    //     allSections = [...allSections, EntityFinancialActivitySection];
    //   }
    // }
    // primary account holder contact / personal info
    allSections = [...allSections, PrimaryAccountHolderContactSection, PrimaryAccountHolderPersonalInfoSection];
    if (values.type === ApplicationType.Joint) {
      // joint account holder contact / personal info
      allSections = [...allSections, JointAccountHolderContactSection, JointAccountHolderPersonalInfoSection];
    }
    if (values.type === ApplicationType.UGMA) {
      allSections = [...allSections, UgmaAccountHolderContactSection, UgmaAccountHolderPersonalInfoSection];
    }

    if(values.type === ApplicationType.Entity) {
      allSections = []
    }

    return _.take(allSections, visibleSections || 1);
  }, [values.entityAccountType, values.type, visibleSections]);
  return (
    <>
      {_.map(sections, (ChildSection, index) => (
        <ChildSection
          key={`ChildSection-${index}`}
          getText={getText}
          values={values}
          setValues={setValues}
          errors={errors}
          touched={touched}
          submitCount={submitCount}
        />
      ))}
    </>
  );
};
