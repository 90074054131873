import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Section, Text, useLocalize, Errors as BaseErrors } from '@tradingblock/components';
import { BaseFormProps, ApplicationModel } from '../types';
import { getErrorData, getPrefixTextPropsForField, getErrorKeyForField, getITINTextPropsForField } from '../services';

interface ErrorsProps extends Pick<BaseFormProps<object>, 'errors'> {
  values?: ApplicationModel;
  submitCount?: number;
}

export const Errors: React.FC<ErrorsProps> = ({ errors, submitCount, values, children }) => {
  const { getCleanTranslationKey } = useLocalize();
  const cleanErrors = useMemo(
    () =>
      _.reduce(
        errors,
        (errs: {}, err, key) => {
          if (
            err &&
            key &&
            (getCleanTranslationKey(key) !== getCleanTranslationKey(_.last(_.keys(errs)) || '') ||
              err !== _.last(_.values(errs)))
          ) {
            return { ...errs, [key]: err };
          }
          return errs;
        },
        {}
      ),
    [errors, getCleanTranslationKey]
  );

  const errorPrefixes = useMemo(
    () =>
      _.reduce(
        cleanErrors,
        (prefixes: { [key: string]: JSX.Element }, _err, key) => {
          const prefixTextProps = getPrefixTextPropsForField(key, values);
          if (prefixTextProps) {
            return {
              ...prefixes,
              [key]: <Text {...prefixTextProps} />,
            };
          }
          return prefixes;
        },
        {}
      ),
    [cleanErrors, values]
  );

  const errorDefaults = useMemo(
    () =>
      _.reduce(
        cleanErrors,
        (defaults: { [key: string]: JSX.Element }, _err, key) => {
          const itinTextProps = getITINTextPropsForField(key);
          if (itinTextProps) {
            return {
              ...defaults,
              itin: <Text {...itinTextProps} />,
            };
          }
          return defaults;
        },
        {}
      ),
    [cleanErrors]
  );

  const renderError = useCallback(
    (err: string, key: string) => {
      // if error has spaces, must be raw error text returned from api instead of error text key
      if (err && /\s+/.test(err)) {
        return err;
      }
      return (
        <Text id={getErrorKeyForField(key, err)} type="error" data={getErrorData(key, errorPrefixes, errorDefaults)} />
      );
    },
    [errorPrefixes, errorDefaults]
  );

  if (_.isEmpty(cleanErrors) || submitCount === 0) {
    return null;
  }

  return (
    <Section>
      <BaseErrors errors={cleanErrors} renderError={renderError} />
      {children}
    </Section>
  );
};
