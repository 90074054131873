export var DefaultEntitlementsItem = {
  AccountNumber: '',
  ApprovedUserMarketDataEntitlementType: '',
  ApprovingUserName: '',
  FirstName: '',
  LastName: '',
  PrimaryAccountIdAssociatedWithUser: 0,
  RequestDateTime: '',
  RequestedMarketDataEntitlementType: '',
  UserId: 0,
  UserLevel: 0,
  UserName: '',
  Id: 0
};
export var MarketDataEntitlementTypes;

(function (MarketDataEntitlementTypes) {
  MarketDataEntitlementTypes["Undefined"] = "Undefined";
  MarketDataEntitlementTypes["ProfessionalUser"] = "ProfessionalUser";
  MarketDataEntitlementTypes["NonProfessionalUser"] = "NonProfessionalUser";
  MarketDataEntitlementTypes["DelayedQuotesOnlyUser"] = "DelayedQuotesOnlyUser";
})(MarketDataEntitlementTypes || (MarketDataEntitlementTypes = {}));

export var AllMarketDataEntitlementTypes = [MarketDataEntitlementTypes.Undefined, MarketDataEntitlementTypes.ProfessionalUser, MarketDataEntitlementTypes.NonProfessionalUser, MarketDataEntitlementTypes.DelayedQuotesOnlyUser];