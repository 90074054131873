import React from 'react';
import { Language } from 'react-localize-redux';
import { DateModel } from '../../types';
import { getDateFromModel, isValidDate } from '../../services';
import { FieldValue } from './';

const Date: React.FC<{ value: DateModel | null | undefined; activeLanguage: Language }> = ({ value }) => {
  const date = value && isValidDate(value) && getDateFromModel(value);
  return <FieldValue value={!!date}>{date && date.format('MMMM, D, YYYY')}</FieldValue>;
};

export const DateValue = Date;
