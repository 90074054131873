import React, { useEffect } from 'react';
import { Field } from 'formik';
import { Section, CheckboxField, TextboxField, FormGroup } from '@tradingblock/components';
import { ApplicationStepProps, PersonType } from '../../../types';
import {
  updateFieldsDependentOnAddTrustedContact,
  showBeneficiariesFields,
  showOwnersOfficersFields,
  showTrustedContactFields,
  showTenantsInCommonInterestPercentFields,
} from '../../../services';
import { PersonField, PhoneField } from '../../fields';
import { BeneficiariesFields } from './beneficiaries/BeneficiariesFields';
import { OwnersOfficersFields } from './beneficiaries/OwnersOfficersFields';
import { InCommonInterestPercentFields } from './beneficiaries/InCommonInterestPercentFields';

export const BeneficiariesForm: React.FC<ApplicationStepProps> = ({ getText, values, errors, setValues }) => {
  const showBeneficiaries = showBeneficiariesFields(values);
  const showOwnersOfficers = showOwnersOfficersFields(values);
  const showTrustedContact = showTrustedContactFields(values);
  const showTenantsInCommonInterestPercent = showTenantsInCommonInterestPercentFields(values);

  return (
    <>
      {showBeneficiaries && (
        <>
          <Section>
            <h2 className="fields-title">{getText('beneficiaries.header')}</h2>
            <p>{getText('beneficiaries', 'description')}</p>
            <BeneficiariesFields values={values} setValues={setValues} getText={getText} errors={errors} max={10} />
          </Section>
        </>
      )}

      {showOwnersOfficers && (
        <>
          <Section>
            <h2 className="fields-title">{getText('ownersOfficers.header')}</h2>
            <p>{getText('ownersOfficers', 'description')}</p>
            <OwnersOfficersFields values={values} setValues={setValues} getText={getText} max={10} />
          </Section>
        </>
      )}

      {showTenantsInCommonInterestPercent && (
        <>
          <Section>
            <h2 className="fields-title">{getText('tenantsInCommon.header')}</h2>
            <p>{getText('tenantsInCommon', 'description')}</p>
            <InCommonInterestPercentFields values={values} setValues={setValues} getText={getText} errors={errors} />
          </Section>
        </>
      )}

      {showTrustedContact && (
        <>
          <Section>
            <h2 className="fields-title">{getText('trustedContact.header')}</h2>
            <p>{getText('trustedContact', 'description')}</p>
            <p>
              <Field
                component={CheckboxField}
                id="addTrustedContact"
                onSetValue={(addTrustedContact: boolean) =>
                  setValues(updateFieldsDependentOnAddTrustedContact(values, addTrustedContact))
                }
              />
            </p>

            {values.addTrustedContact && (
              <>
                <p className="mute">{getText('trustedContact')}</p>
                <Field component={PersonField} id="trustedContact" personType={PersonType.TrustedContact} />
                <FormGroup>
                  <Field
                    component={TextboxField}
                    id="trustedContact.email"
                    type="email"
                    inputMode="email"
                    defaultLabelId="email"
                    preventPaste={true}
                  />
                </FormGroup>
                <FormGroup>
                  <Field component={PhoneField} id="trustedContact.phones[0]" defaultLabelId="phone" />
                </FormGroup>
              </>
            )}
          </Section>
        </>
      )}
    </>
  );
};
