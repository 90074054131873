import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
export var StepperInput = function StepperInput(_ref) {
  var name = _ref.name,
      options = _ref.options,
      value = _ref.value,
      error = _ref.error,
      onblur = _ref.onblur,
      onchange = _ref.onchange;

  var _useState = useState(value),
      _useState2 = _slicedToArray(_useState, 2),
      stepperValue = _useState2[0],
      setStepperValue = _useState2[1];

  useEffect(function () {
    return setStepperValue(value);
  }, [value]);

  var onChange = function onChange(newValue) {
    if (onchange) {
      onchange(undefined, newValue);
    } else {
      setStepperValue(newValue);
    }
  };

  return React.createElement("div", {
    className: "stepper"
  }, React.createElement("div", {
    className: "btn-group btn-block btn-darken ".concat(error ? 'invalid' : '')
  }, _.map(options || [], function (o, i) {
    return React.createElement("button", {
      key: "btn-".concat(name, "-").concat(i),
      id: "btn-".concat(name, "-").concat(i),
      className: "btn ".concat(o.value === stepperValue ? 'btn-dark btn-active' : 'btn-link'),
      onClick: function onClick() {
        return onChange(o.value);
      },
      onBlur: onblur,
      type: "button",
      disabled: o.disabled
    }, o.label || o.value);
  })));
};