import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import { withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { useLocalize } from '../../hooks';

var ButtonComponent = function ButtonComponent(_a) {
  var type = _a.type,
      translate = _a.translate,
      children = _a.children,
      rest = tslib_1.__rest(_a, ["type", "translate", "children"]);

  var _useLocalize = useLocalize(),
      getTranslationId = _useLocalize.getTranslationId;

  var title = useMemo(function () {
    var text = translate(getTranslationId({
      id: "btns.".concat(type)
    }));
    return _.isString(text) ? text : "[".concat(type.toUpperCase(), "]");
  }, [translate, type]);
  var btnProps = Object.assign({
    title: title
  }, _.pick(rest, ['onClick', 'tabIndex', 'className']));
  return React.createElement("button", Object.assign({
    type: "button"
  }, btnProps), children);
};

export var LocalizeButton = withLocalize(ButtonComponent);