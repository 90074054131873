import { getType, ActionType } from 'typesafe-actions';
import { Middleware, Dispatch } from 'redux';
import _ from 'lodash';
import { GenericErrorResponseCode, SuccessResponseCode, CreateUserData, SecurityChallenge } from '@tradingblock/types';
import { TryLogin, ApiFactory } from '@tradingblock/api';
import { RootApplicationAction, ApplicationActions, UiActions } from '../actions';
import { ApplicationState } from '../types';
import { buildApiClient } from '../../context';
import {
  cleanApplicationForSubmission,
  validateToken,
  getUploadDocumentTagForUploadKey,
  cleanApplicationForStorage,
  cleanEntityApplicaitonForPartialSubmission,
} from '../../services';
import { trySaveApplication } from '../../api';
import { config } from '../../config';
import { UploadKey } from '../../types';
import { data } from '../selectors';

// reference https://redux.js.org/advanced/middleware/ for best practices

export const applicationMiddleware: Middleware<Dispatch<RootApplicationAction>, ApplicationState> = middleware => (
  next: Dispatch<RootApplicationAction>
) => (action: RootApplicationAction) => {
  try {
    const result = next(action);
    const state = middleware.getState();

    switch (action.type) {
      case getType(ApplicationActions.requestUiData):
        fetchUiData(middleware.dispatch, action);
        break;
      case getType(ApplicationActions.requestApplicationStatus):
        handleApplicationStatus(middleware.dispatch, state, action);
        break;
      case getType(ApplicationActions.requestSaveApplication):
        handleSaveApplication(middleware.dispatch, action);
        break;
      case getType(ApplicationActions.requestPartialSaveApplication):
        handlePartialSaveApplication(middleware.dispatch, action);
        break;
      case getType(ApplicationActions.requestCreateUser):
        handleCreateUser(middleware.dispatch, state, action);
        break;
      case getType(ApplicationActions.requestUploadDocuments):
        handleUploadDocuments(middleware.dispatch, state, action);
        break;
      case getType(ApplicationActions.requestCreateAccount):
        handleCreateAccount(middleware.dispatch, state, action);
        break;
      case getType(ApplicationActions.requestDuplicateAccountCheck):
        handleDuplicateAccountCheck(middleware.dispatch, state, action);
        break;
      case getType(ApplicationActions.requestCreatePartialEntityAccount):
        handleCreatePartialEntityAccount(middleware.dispatch, state, action);
        break;
    }
    return result;
  } catch (err) {
    console.error('applicationMiddleware :: Caught an exception for action ', action, err);
  }
};

const fetchUiData = async (
  dispatch: Dispatch<RootApplicationAction>,
  action: ActionType<typeof ApplicationActions.requestUiData>
) => {
  const { siteConfigId } = action.payload;
  const api = buildApiClient();
  const isVirtual = config.isVirtual;
  try {
    const responseUserRequirements = await api.application.getUserRequirements();
    const responseHeardAbout = !isVirtual
      ? await api.application.getHeardAbout(siteConfigId)
      : { payload: [], responseCode: SuccessResponseCode };
    const response = {
      responseCode: responseUserRequirements.responseCode || responseHeardAbout.responseCode,
      payload: {
        userRequirements: responseUserRequirements.payload,
        heardAbout: responseHeardAbout.payload,
      },
    };
    dispatch(ApplicationActions.receiveUiData(response));
  } catch (err) {
    console.error('applicationMiddleware :: fetching UI data for application failed: ', err);
    dispatch(ApplicationActions.receiveUiData({ payload: undefined, responseCode: GenericErrorResponseCode }));
  }
};

const handleDuplicateAccountCheck = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestDuplicateAccountCheck>
) => {
  const api = buildApiClient(undefined, state.ui.apiToken);
  try {
    const response = await api.application.duplicateAccountCheck(action.payload);
    dispatch(ApplicationActions.receiveDuplicateAccountCheck(response));
  } catch (err) {
    console.error('applicationMiddleware :: checking for duplicate account failed: ', err);
  }
};

const handleApplicationStatus = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestApplicationStatus>
) => {
  const api = buildApiClient(undefined, state.ui.apiToken);
  try {
    const response = await api.application.getApplicationStatus(action.payload.accountId);
    dispatch(ApplicationActions.receiveApplicationStatus(response));
  } catch (err) {
    console.error('applicationMiddleware :: fetching status for application failed: ', err);
    dispatch(ApplicationActions.receiveApplicationStatus({ payload: null, responseCode: GenericErrorResponseCode }));
  }
};

const handleSaveApplication = async (
  dispatch: Dispatch<RootApplicationAction>,
  action: ActionType<typeof ApplicationActions.requestSaveApplication>
) => {
  const { application, saveStep, saveType, storage } = action.payload;

  let result = true;
  const data = cleanApplicationForStorage(application);
  if (storage && storage.config.authToken) {
    // try to save application data
    result = await trySaveApplication(storage, data);
  } else {
    // for now ignore saving if we don't have authToken
    console.warn('applicationMiddleware :: SKIPPED saving application b/c no authToken ', data);
  }
  if (result) {
    dispatch(ApplicationActions.receiveSaveApplication({ application: data, saveStep, saveType }));
  } else {
    dispatch(ApplicationActions.receiveSaveApplication({ application: undefined, saveStep, saveType }));
  }
};

const handlePartialSaveApplication = async (
  dispatch: Dispatch<RootApplicationAction>,
  action: ActionType<typeof ApplicationActions.requestPartialSaveApplication>
) => {
  const { application, storage } = action.payload;
  const data = cleanApplicationForStorage(application);
  let result = true;
  if (storage && storage.config.authToken) {
    // try to save application data
    result = await trySaveApplication(storage, data);
  } else {
    // for now ignore saving if we don't have authToken
    console.warn('applicationMiddleware :: SKIPPED saving application b/c no authToken ', data);
  }

  if (result) {
    dispatch(ApplicationActions.receivePartialSaveApplication({ application: data }));
  } else {
    // dispatch(ApplicationActions.receivePartialSaveApplication({application: undefined}));
  }
};

const handleCreateUser = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestCreateUser>
) => {
  const api = buildApiClient();
  const { application } = action.payload;

  const securityChallenges: SecurityChallenge[] = _.map(application.securityChallenges as SecurityChallenge[], s => ({
    securityQuestionType: s.securityQuestionType,
    answer: s.answer,
  }));
  const createData: CreateUserData = {
    // "createInstantAccount" should only be true for virtual environment
    createInstantAccount: config.isVirtual,
    firstName: (application.primaryAccountHolder && application.primaryAccountHolder.firstName) || '',
    lastName: (application.primaryAccountHolder && application.primaryAccountHolder.lastName) || '',
    email: (application.primaryAccountHolder && application.primaryAccountHolder.email) || '',
    userName: application.userName || '',
    passwordSecret: application.password || '',
    securityChallenges,
    siteId: data.ui.siteId(state),
  };

  try {
    // try to create the new user
    const response = await api.application.createUser(createData);

    // if createUser succeeded and not for virtual application, try to login so we can store authToken
    if (response.responseCode === SuccessResponseCode && !config.isVirtual) {
      const authToken = await TryLogin(
        { username: createData.userName, password: createData.passwordSecret, clientType: 'application' },
        { host: config.loginUrl, subdirectory: config.subdirectory }
      ).then(({ token, success }) => (success ? token : undefined));
      const tokenRes = await validateToken(authToken || '');
      if (!tokenRes) {
        throw new Error('TryLogin failed for new user credentials');
      }
      // store new token in state
      dispatch(UiActions.setAuthToken({ apiToken: tokenRes.decoded.token, storageToken: tokenRes.value }));
    }
    dispatch(ApplicationActions.receiveCreateUser(response));
  } catch (err) {
    console.error('applicationMiddleware :: creating user for application failed: ', err, createData);
    dispatch(ApplicationActions.receiveCreateUser({ payload: undefined, responseCode: GenericErrorResponseCode }));
  }
};

const handleUploadDocuments = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestUploadDocuments>
) => {
  const api = buildApiClient(undefined, state.ui.apiToken);
  const { documents, uploadKey } = action.payload;
  _.forEach(documents, d => uploadDocument(api, dispatch, d, uploadKey));
};

const uploadDocument = async (
  api: ReturnType<typeof ApiFactory>,
  dispatch: Dispatch<RootApplicationAction>,
  document: File,
  uploadKey: UploadKey
) => {
  try {
    const response = await api.application.uploadDocument(document, getUploadDocumentTagForUploadKey(uploadKey));
    dispatch(
      ApplicationActions.receiveUploadDocument({ ...response, payload: { document: response.payload, uploadKey } })
    );
  } catch (err) {
    console.error('applicationMiddleware :: uploading document for application failed: ', err, document);
    dispatch(
      ApplicationActions.receiveUploadDocument({
        payload: { document: { filename: document.name }, uploadKey },
        responseCode: GenericErrorResponseCode,
      })
    );
  }
};

const handleCreateAccount = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestCreateAccount>
) => {
  const api = buildApiClient(undefined, state.ui.apiToken);
  const { application } = action.payload;

  try {
    const heardAbout = state.ui.heardAbout;
    const data = cleanApplicationForSubmission(application, heardAbout);
    const response = await api.application.createAccount(data);
    dispatch(ApplicationActions.receiveCreateAccount(response));
  } catch (err) {
    console.error('applicationMiddleware :: creating account for application failed: ', err, application);
    dispatch(ApplicationActions.receiveCreateAccount({ payload: undefined, responseCode: GenericErrorResponseCode }));
  }
};

const handleCreatePartialEntityAccount = async (
  dispatch: Dispatch<RootApplicationAction>,
  state: ApplicationState,
  action: ActionType<typeof ApplicationActions.requestCreatePartialEntityAccount>
) => {
  const api = buildApiClient(undefined, state.ui.apiToken);
  const { application } = action.payload;

  try {
    const heardAbout = state.ui.heardAbout;
    const data = await cleanEntityApplicaitonForPartialSubmission(application, heardAbout);
    const response = await api.application.createAccount(data);
    dispatch(ApplicationActions.receiveCreatePartialEntityAccount(response));
  } catch (err) {
    console.error(
      'applicationMiddleware :: creating partial entity account for application failed: ',
      err,
      application
    );
    dispatch(
      ApplicationActions.receiveCreatePartialEntityAccount({
        payload: undefined,
        responseCode: GenericErrorResponseCode,
      })
    );
  }
};
