import * as tslib_1 from "tslib";
import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { useLocalize } from '../../../hooks';
import { Text } from '../Text';
import { SelectDropdownInput } from '../../fields';

var SelectDropdownFieldComponent = function SelectDropdownFieldComponent(_a) {
  var form = _a.form,
      field = _a.field,
      id = _a.id,
      options = _a.options,
      defaultLabelId = _a.defaultLabelId,
      optionsDefaultLabelId = _a.optionsDefaultLabelId,
      label = _a.label,
      rawOptionLabels = _a.rawOptionLabels,
      useRawOptions = _a.useRawOptions,
      labelPrefix = _a.labelPrefix,
      onChanged = _a.onChanged,
      translate = _a.translate,
      siteGroup = _a.siteGroup,
      _a$isSearchable = _a.isSearchable,
      isSearchable = _a$isSearchable === void 0 ? true : _a$isSearchable,
      errorImmediately = _a.errorImmediately,
      rest = tslib_1.__rest(_a, ["form", "field", "id", "options", "defaultLabelId", "optionsDefaultLabelId", "label", "rawOptionLabels", "useRawOptions", "labelPrefix", "onChanged", "translate", "siteGroup", "isSearchable", "errorImmediately"]);

  var values = form.values,
      errors = form.errors,
      touched = form.touched,
      submitCount = form.submitCount;

  var value = _.get(values, id);

  var error = _.get(touched, id) ? _.get(errors, id) : undefined;

  var _useLocalize = useLocalize(),
      getTranslationId = _useLocalize.getTranslationId,
      getFieldTranslationId = _useLocalize.getFieldTranslationId;

  var getValueTranslationId = useCallback(function (value) {
    return getFieldTranslationId({
      id: id,
      defaultLabelId: optionsDefaultLabelId || defaultLabelId,
      option: value
    });
  }, [id, defaultLabelId, optionsDefaultLabelId, getFieldTranslationId]);
  var getOptionValue = useCallback(function (option) {
    // for searchable value, get raw translation (using non-html data to return plain string)
    var val = translate(getTranslationId({
      id: getValueTranslationId(option),
      type: 'field'
    }), {
      ndash: '-'
    });
    return _.isString(val) ? val : option;
  }, [translate, getTranslationId, getValueTranslationId]);
  var getOptionLabel = useCallback(function (option) {
    if (useRawOptions) {
      if (rawOptionLabels) {
        return rawOptionLabels[option] || option;
      }

      return option;
    }

    return React.createElement(Text, {
      id: getValueTranslationId(option),
      type: "field"
    });
  }, [useRawOptions, rawOptionLabels, getValueTranslationId]);
  var selectOptions = useMemo(function () {
    return _.map(options, function (o) {
      // clean the option value, since it's used for search (so we also store raw value - not displayed, used for matching original option on change)
      return {
        value: o ? useRawOptions ? o : getOptionValue(o) : o,
        label: o ? getOptionLabel(o) : React.createElement("div", null, React.createElement("hr", null)),
        raw_value: o
      };
    });
  }, [options, getOptionValue, getOptionLabel, useRawOptions]);

  var onChange = function onChange(_e, value) {
    if (value) {
      if (onChanged) {
        onChanged(value);
      } else {
        form.setFieldValue(id, value);
      } // Checks to see if the dropdown is part of a phone array, if so, format the id for the corresponding phoneNumber input and select the input


      var isPhoneArray = _.toLower(id).includes('phones');

      if (isPhoneArray) {
        var formattedId = id.substring(0, id.lastIndexOf('.')) + '.phoneNumber';
        var phoneNumberEl = document.getElementById(formattedId);
        phoneNumberEl.select();
      }
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(SelectDropdownInput, Object.assign({
    name: id,
    label: label || React.createElement(Text, {
      id: getFieldTranslationId({
        id: id,
        defaultLabelId: defaultLabelId
      }),
      type: "field",
      data: labelPrefix && {
        prefix: labelPrefix
      },
      siteGroup: siteGroup
    }),
    isSearchable: isSearchable,
    value: value,
    error: error,
    options: selectOptions,
    onblur: field.onBlur,
    onchange: onChange
  }, rest)), error && (!!submitCount || errorImmediately) && React.createElement("span", {
    className: "error"
  }, React.createElement(Text, {
    id: id,
    textKey: "".concat(error),
    type: 'error',
    siteGroup: siteGroup
  })));
};

export var SelectDropdownField = withLocalize(SelectDropdownFieldComponent);