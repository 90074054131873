import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import React from 'react';
import _ from 'lodash';

var getFlattenedErrors = function getFlattenedErrors(data) {
  return _.reduce(_.values(data), function (acc, d) {
    if (_.isObject(d)) {
      return [].concat(_toConsumableArray(acc), _toConsumableArray(getFlattenedErrors(d)));
    }

    return [].concat(_toConsumableArray(acc), [d]);
  }, []);
};

export var getFormikErrors = function getFormikErrors(data) {
  return getFlattenedErrors(data);
};
export var Errors = function Errors(_ref) {
  var errors = _ref.errors,
      renderError = _ref.renderError;
  return React.createElement("ul", {
    className: "error"
  }, _.map(errors, function (err, k) {
    return React.createElement("li", {
      key: "err".concat(k)
    }, renderError ? renderError(err, k) : err);
  }));
};