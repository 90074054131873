import React from 'react';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
import { BaseFieldProps, Text, AddIcon, LocalizeButton as Button } from '@tradingblock/components';
import { PublicOfficialModel } from '../../types';
import { PublicOfficialField } from './';

interface PublicOfficialsArrayFieldProps extends BaseFieldProps {
  value?: PublicOfficialModel[];
}

export const PublicOfficialsArrayField: React.FC<PublicOfficialsArrayFieldProps> = ({ id, value }) => {
  return (
    <FieldArray
      name={id}
      render={arrHelpers => (
        <>
          {_.map(value, (_p, i) => (
            <div key={`publicOfficial${i}`} className="form-group-removable form-group-removable-titled">
              <h2 className="fields-title">
                <Text id="publicOfficials.name" data={{ number: i + 1 }} />
              </h2>
              {_.size(value) > 1 && (
                <div className="remove">
                  <Button type="remove" onClick={() => arrHelpers.remove(i)} tabIndex={-1}>
                    >
                    <i className="fal fa-lg fa-times-circle" />
                  </Button>
                </div>
              )}
              <Field component={PublicOfficialField} id={`${id}[${i}]`} />
            </div>
          ))}
          <p>
            <button
              onClick={e => {
                e.preventDefault();
                arrHelpers.push({});
              }}
              type="button"
            >
              <AddIcon />
              <strong>
                <Text id="publicOfficials.add" />
              </strong>
            </button>
          </p>
        </>
      )}
    />
  );
};
