import React from 'react';
export var Svg = function Svg(_ref) {
  var path = _ref.path,
      label = _ref.label,
      root = _ref.root;
  var rootPath = root || '/images/sprites.svg';
  return React.createElement("svg", {
    "aria-label": label
  }, React.createElement("use", {
    href: "".concat(rootPath, "#").concat(path)
  }));
};