import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ContactPhone, Section, Text } from '@tradingblock/components';
import { PageTitle } from '../components';
import { ApplicationLoader } from '../components/application';
import { ApplicationStep } from '../types';
import { getApplicationStepUrl } from '../services';
import { config } from '../config';
import { useData } from '../state/selectors';

const PendingPage: React.FC = () => {
  const application = useData(d => d.data.application);
  const siteGrpParam = process.env.REACT_APP_TB_SITE_GROUP;

  const dashboardLogoutUrl = `${config.dashboardUrl}/logout`;

  const type = useMemo(() => {
    if (application && application.latestStepCompleted === ApplicationStep.Sign && application.applicationSentOn) {
      return 'unapproved';
    }
    return 'incomplete';
  }, [application]);

  const gotoApplication = useCallback(() => {
    window.location.href = getApplicationStepUrl(
      (application && application.latestStepSaved) || ApplicationStep.SecureAccount
    );
  }, [application]);

  return (
    <ApplicationLoader defaultRedirect="/">
      <PageTitle page="pending" />
      <Section>
        <div className="step-title">
          <h1>
            <Text id={type} page="pending" />
          </h1>
        </div>
      </Section>
      <Section>
        <p>
          <Text
            id={type}
            page="pending"
            textKey="description"
            siteGroup={siteGrpParam}
            data={{
              phone: <ContactPhone siteGroup={siteGrpParam} />,
            }}
          />
        </p>
        {type === 'incomplete' && (
          <p>
            <button className="btn btn-block btn-primary btn-icon" type="button" onClick={gotoApplication}>
              <Text id="continue" page="pending" />
            </button>
          </p>
        )}
        <p>
          <Link className="btn btn-block btn-primary btn-icon" to={`/logout#next=${dashboardLogoutUrl}`}>
            <Text id="btns.sign-out" />
          </Link>
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default PendingPage;
