import { Section } from '@tradingblock/components';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useData } from '../../../state/selectors';
import { ApplicationFormSection, ApplicationStep, ApplicationStepProps, ClearerType } from '../../../types';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { ClearerTypeSection } from './clearer-type/ClearerTypeSection';
import { shouldRemoveClearerOption } from '../../../hooks/useApplicationSteps';
import { updateApplication } from '../../../services';
import { ApplicationActions } from '../../../state/actions';
import { useDispatch } from 'react-redux';
import { useStore } from '../../../context';

export const ClearerTypeForm: React.FC<ApplicationStepProps> = ({ getText, ...sectionProps }) => {
  const authToken = useData(d => d.ui.apiToken);
  const visibleSections = useData(d => d.ui.visibleSections);
  const heardAbout = useData(d => d.ui.heardAbout);
  const dispatch = useDispatch();
  const storage = useStore();
  const shouldSkipStep = shouldRemoveClearerOption(sectionProps.values.hearAboutUsId, sectionProps.values.repId, heardAbout);

  useEffect(() => {
    if (shouldSkipStep) {
      // update status fields on application
      const data = updateApplication(
        ApplicationStep.ClearerType,
        true,
        true,
        {
          ...sectionProps.values,
          clearer: ClearerType.Apex
        },
        visibleSections
      );
      // save application
      dispatch(ApplicationActions.requestSaveApplication({
        application: data,
        saveType: 'continue',
        saveStep: ApplicationStep.ClearerType,
        storage
      }));
    }
  }, [heardAbout, dispatch, storage, shouldSkipStep]);

  const sections = useMemo(() => {
    let allSections: React.FC<ApplicationFormSection>[] = [];
    if (!shouldSkipStep) {
      allSections.push(ClearerTypeSection);
    }
    return _.take(allSections, visibleSections || 1);
  }, [visibleSections]);
  

  return (
    <>
      {_.map(sections, (ChildSection, index) => (
        <ChildSection key={`ChildSection-${index}`} getText={getText} {...sectionProps} />
      ))}

      {!config.isVirtual && !authToken && (!visibleSections || visibleSections <= 1) && (
        <Section>
          <h2 className="fields-title">{getText('signin')}</h2>
          <p>
            {getText('signin', 'description', {
              data: { signinLink: <Link to="/auth">{getText('signin.signinLink')}</Link> },
            })}
          </p>
        </Section>
      )}
    </>
  );
};
