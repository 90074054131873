import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { useState } from 'react';
import { HelpIcon, LocalizeButton } from '..';
export var FormGroup = function FormGroup(_ref) {
  var className = _ref.className,
      title = _ref.title,
      header = _ref.header,
      help = _ref.help,
      _ref$toggleableHelp = _ref.toggleableHelp,
      toggleableHelp = _ref$toggleableHelp === void 0 ? false : _ref$toggleableHelp,
      children = _ref.children;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showHelpText = _useState2[0],
      setShowHelpText = _useState2[1]; //Callback used to toggle help text


  var helpButtonClickCallback = function helpButtonClickCallback(e) {
    e.preventDefault();
    setShowHelpText(!showHelpText);
  };

  return React.createElement("div", {
    className: "form-group ".concat(className || '')
  }, header ? header : title && React.createElement("h2", {
    className: "fields-title"
  }, title), help && !toggleableHelp && React.createElement("div", {
    className: "field-help mute"
  }, help), help && toggleableHelp ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col d-flex"
  }, React.createElement("div", {
    className: "flex-fill"
  }, children), React.createElement(LocalizeButton, {
    className: "ml-3",
    type: "info",
    onClick: helpButtonClickCallback
  }, React.createElement(HelpIcon, {
    iconSize: 'lg'
  })))), React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col mt-2"
  }, showHelpText && React.createElement(React.Fragment, null, React.createElement(HelpIcon, null), " ", help)))) : children);
};