import { createStandardAction, ActionType } from 'typesafe-actions';
import { ApiResponse } from '@tradingblock/api';
import {
  CreateAccountInfo,
  UserRequirementsInfo,
  UploadedDocument,
  HeardAboutInfo,
  ApplicationStatus,
} from '@tradingblock/types';
import {
  ApplicationStep,
  ApplicationModel,
  SaveType,
  UploadDocumentTag,
  UploadKey,
  DuplicateAccountCheckRequest,
  DuplicateAccountCheckResponse,
  EntityPartialSubmissionModel,
} from '../types';
import { useStore } from '../context';

const UI_SET_SITE_GRP = '@ui/SET_SITE_GRP';
const UI_SET_AUTH_TOKEN = '@ui/SET_AUTH_TOKEN';
const UI_SET_STEP = '@ui/SET_STEP';
const UI_SHOW_NEXT_SECTION = '@ui/SHOW_NEXT_SECTION';
const UI_SCROLL_TO_SECTION = '@ui/SCROLL_TO_SECTION';
const UI_SET_NEXT_PAGE = '@ui/SET_NEXT_PAGE';
const UI_CLEAR_NEXT_PAGE = '@ui/CLEAR_NEXT_PAGE';
const UI_SET_CLEARER = '@ui/SET_CLEARER';
const UI_SET_AFFILIATE = '@ui/SET_AFFILIATE';

export const UiActions = {
  setSiteGrp: createStandardAction(UI_SET_SITE_GRP)<string>(),
  setAuthToken: createStandardAction(UI_SET_AUTH_TOKEN)<{ apiToken: string; storageToken: string } | undefined>(),
  setStep: createStandardAction(UI_SET_STEP)<{ step: ApplicationStep; application: ApplicationModel }>(),
  showNextSection: createStandardAction(UI_SHOW_NEXT_SECTION)<{ scrollToSection: boolean }>(),
  setScrollToSection: createStandardAction(UI_SCROLL_TO_SECTION)<{ scrollToSection: boolean }>(),
  setNextPage: createStandardAction(UI_SET_NEXT_PAGE)<string>(),
  clearNextPage: createStandardAction(UI_CLEAR_NEXT_PAGE)(),
  setClearer: createStandardAction(UI_SET_CLEARER)<string>(),
  setAffiliate: createStandardAction(UI_SET_AFFILIATE)<string>(),
};

const APP_UI_DATA_REQUEST = '@app/UI_DATA_REQUEST';
const APP_UI_DATA_RECEIVE = '@app/UI_DATA_RECEIVE';
const APP_APPLICATION_STATUS_REQUEST = '@app/APPLICATION_STATUS_REQUEST';
const APP_APPLICATION_STATUS_RECEIVE = '@app/APPLICATION_STATUS_RECEIVE';
const APP_SAVE_APPLICATION_REQUEST = '@app/SAVE_APPLICATION_REQUEST';
const APP_SAVE_APPLICATION_RECEIVE = '@app/SAVE_APPLICATION_RECEIVE';
const APP_CREATE_USER_REQUEST = '@app/CREATE_USER_REQUEST';
const APP_CREATE_USER_RECEIVE = '@app/CREATE_USER_RECEIVE';
const APP_UPLOAD_FILES_REQUEST = '@app/UPLOAD_FILE_REQUEST';
const APP_UPLOAD_FILE_RECEIVE = '@app/UPLOAD_FILE_RECEIVE';
const APP_UPLOAD_FILE_REMOVE = '@app/UPLOAD_FILE_REMOVE';
const APP_CREATE_ACCOUNT_REQUEST = '@app/CREATE_ACCOUNT_REQUEST';
const APP_CREATE_ACCOUNT_RECEIVE = '@app/CREATE_ACCOUNT_RECEIVE';
const APP_DUPLICATE_ACCOUNT_CHECK_REQUEST = '@app/DUPLICATE_ACCOUNT_CHECK_REQUEST';
const APP_DUPLICATE_ACCOUNT_CHECK_RECEIVE = '@app/DUPLICATE_ACCOUNT_CHECK_RECEIVE';
const APP_CREATE_PARTIAL_ENTITY_REQUEST = '@app/CREATE_PARTIAL_ENTITY_REQUEST';
const APP_CREATE_PARTIAL_ENTITY_RECEIVE = '@app/CREATE_PARTIAL_ENTITY_RECEIVE';

export const ApplicationActions = {
  requestUiData: createStandardAction(APP_UI_DATA_REQUEST)<{ siteConfigId: number }>(),
  receiveUiData: createStandardAction(APP_UI_DATA_RECEIVE)<
    ApiResponse<{ userRequirements: UserRequirementsInfo; heardAbout: HeardAboutInfo[] } | undefined>
  >(),
  requestApplicationStatus: createStandardAction(APP_APPLICATION_STATUS_REQUEST)<{ accountId: number }>(),
  receiveApplicationStatus: createStandardAction(APP_APPLICATION_STATUS_RECEIVE)<
    ApiResponse<ApplicationStatus | null>
  >(),
  requestSaveApplication: createStandardAction(APP_SAVE_APPLICATION_REQUEST)<{
    application: ApplicationModel;
    saveType: SaveType;
    saveStep?: ApplicationStep;
    storage: ReturnType<typeof useStore>;
  }>(),
  receiveSaveApplication: createStandardAction(APP_SAVE_APPLICATION_RECEIVE)<{
    application: ApplicationModel | undefined;
    saveType: SaveType;
    saveStep?: ApplicationStep;
  }>(),
  requestPartialSaveApplication: createStandardAction(APP_SAVE_APPLICATION_REQUEST)<{
    application: ApplicationModel;
    storage: ReturnType<typeof useStore>;
  }>(),
  receivePartialSaveApplication: createStandardAction(APP_SAVE_APPLICATION_RECEIVE)<{
    application: ApplicationModel;
  }>(),
  requestCreateUser: createStandardAction(APP_CREATE_USER_REQUEST)<{ application: ApplicationModel }>(),
  receiveCreateUser: createStandardAction(APP_CREATE_USER_RECEIVE)<ApiResponse<undefined>>(),
  requestUploadDocuments: createStandardAction(APP_UPLOAD_FILES_REQUEST)<{
    documents: File[];
    uploadKey: UploadKey;
    tag: UploadDocumentTag;
  }>(),
  receiveUploadDocument: createStandardAction(APP_UPLOAD_FILE_RECEIVE)<
    ApiResponse<{ document: UploadedDocument; uploadKey: UploadKey }>
  >(),
  removeUploadDocument: createStandardAction(APP_UPLOAD_FILE_REMOVE)<{
    document: UploadedDocument;
    index: number;
    uploadKey: UploadKey;
  }>(),
  requestCreateAccount: createStandardAction(APP_CREATE_ACCOUNT_REQUEST)<{ application: ApplicationModel }>(),
  receiveCreateAccount: createStandardAction(APP_CREATE_ACCOUNT_RECEIVE)<ApiResponse<CreateAccountInfo | undefined>>(),
  requestDuplicateAccountCheck: createStandardAction(APP_DUPLICATE_ACCOUNT_CHECK_REQUEST)<
    DuplicateAccountCheckRequest
  >(),
  receiveDuplicateAccountCheck: createStandardAction(APP_DUPLICATE_ACCOUNT_CHECK_RECEIVE)<
    ApiResponse<DuplicateAccountCheckResponse>
  >(),
  requestCreatePartialEntityAccount: createStandardAction(APP_CREATE_PARTIAL_ENTITY_REQUEST)<{
    application: ApplicationModel;
  }>(),
  receiveCreatePartialEntityAccount: createStandardAction(APP_CREATE_PARTIAL_ENTITY_RECEIVE)<
    ApiResponse<CreateAccountInfo | undefined>
  >(),
};

export const TokenActions = {
  uiTokenExpired: createStandardAction('@uiToken/expired')(),
};

const allActions = {
  ...TokenActions,
  ...UiActions,
  ...ApplicationActions,
};

export type RootApplicationAction = ActionType<typeof allActions>;
