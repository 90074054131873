import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import {
  AllAccountHolderDisclosureKeys,
  AccountHolderDisclosureKey,
  AccountHolderDisclosureContingentFields,
  BooleanToggleType,
  SummaryStepProps,
  AccountHolderType,
  AccountHolderField,
  DefaultAccountHolder,
} from '../../../../types';
import { FieldValue } from '../../../values';

interface AccountHolderDisclosuresSummaryProps extends SummaryStepProps {
  type: AccountHolderType;
}

const AccountHolderDisclosuresSummary: React.FC<AccountHolderDisclosuresSummaryProps> = ({
  type,
  application,
  getText,
}) => {
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = (application && application[fieldPrefix]) || DefaultAccountHolder;
  if (!accountHolder) {
    return null;
  }
  return (
    <>
      <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
        {getText(`accountHolderDisclosures.${type}`)}
      </h3>
      {_.map(AllAccountHolderDisclosureKeys, (d: AccountHolderDisclosureKey, i) => {
        return (
          <div key={`disclosure${i}`}>
            <p>{getText(`accountHolderDisclosures.${d}`)}</p>
            <p>
              <strong>
                <FieldValue
                  value={accountHolder.disclosures && accountHolder.disclosures[d]}
                  textPrefix="defaults.boolean"
                />
              </strong>
              {accountHolder.disclosures &&
                accountHolder.disclosures[d] === BooleanToggleType.Yes &&
                _.map(AccountHolderDisclosureContingentFields[d], id => (
                  <span key={id}>
                    <br />
                    <Text id={`defaults.${id}`} type="field" />
                    {': '}
                    <FieldValue value={accountHolder[id] as string}>
                      <strong>{accountHolder[id]}</strong>
                    </FieldValue>
                  </span>
                ))}
            </p>
          </div>
        );
      })}
    </>
  );
};

export const PrimaryAccountHolderDisclosuresSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderDisclosuresSummary type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderDisclosuresSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderDisclosuresSummary type={AccountHolderType.Joint} {...props} />;
};
