import _ from 'lodash';
var MoneyBlockSiteGrp = 'mb';

var isMoneyBlockUrl = function isMoneyBlockUrl(siteGroup) {
  return siteGroup === MoneyBlockSiteGrp;
};

var isMoneyBlockOrigin = function isMoneyBlockOrigin(origin) {
  return origin === MoneyBlockSiteGrp;
};

export var isMoneyBlockAccount = function isMoneyBlockAccount(accountNumber) {
  // MoneyBlock account numbers start with "3OD"
  return _.take(accountNumber, 3).join('') === '3OD';
};

var isMoneyBlockSite = function isMoneyBlockSite(siteGroup, accountNumber, origin) {
  return isMoneyBlockUrl(siteGroup) || isMoneyBlockAccount(accountNumber) || isMoneyBlockOrigin(origin);
};

export var GetSiteName = function GetSiteName(siteGroup, origin, accountNumber) {
  if (isMoneyBlockSite(siteGroup, accountNumber, origin)) {
    return 'MoneyBlock';
  }

  return 'TradingBlock';
};
export var GetSiteGrp = function GetSiteGrp(siteGroup, origin, accountNumber) {
  if (isMoneyBlockSite(siteGroup, accountNumber, origin)) {
    return MoneyBlockSiteGrp;
  }

  return '';
};
export var getSiteLogo = function getSiteLogo(siteGroup, type, accountNumber) {
  // for logo mark, site branding not needed
  if (type === 'mark') {
    return "logo-".concat(type);
  }

  var logo = "logo-".concat(GetSiteName(siteGroup, accountNumber).toLowerCase());

  if (type) {
    return "".concat(logo, "-").concat(type);
  }

  return logo;
};