import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useReducer, useCallback } from 'react';
import constate from 'constate';
import _ from 'lodash';

var pageReducer = function pageReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_NAV':
      {
        return Object.assign({}, state, {
          nav: Object.assign({}, state.nav, {
            collapsed: action.payload.collapsed
          })
        });
      }

    default:
      return state;
  }
};

export var usePageContext = constate(function () {
  var _useReducer = useReducer(pageReducer, {
    nav: {
      collapsed: false
    }
  }),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var navActions = {
    toggle: useCallback(function (isOpen) {
      var navCollapsed = _.isBoolean(isOpen) ? isOpen : !state.nav.collapsed;
      dispatch({
        type: 'TOGGLE_NAV',
        payload: {
          collapsed: navCollapsed
        }
      });
    }, [dispatch, state.nav.collapsed])
  };
  var actions = {
    navActions: navActions
  };
  return [state, actions];
});
export var usePageNav = function usePageNav() {
  var _usePageContext = usePageContext(),
      _usePageContext2 = _slicedToArray(_usePageContext, 2),
      nav = _usePageContext2[0].nav,
      navActions = _usePageContext2[1].navActions;

  return [nav, navActions];
};