import React, { useMemo, useCallback } from 'react';
import { LocalizeContextProps, TranslatePlaceholderData, withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { Text, useLocalize } from '@tradingblock/components';
import { FieldRequirementType } from '../../types';

interface FieldRequirementProps extends LocalizeContextProps {
  type: FieldRequirementType;
  min: number | undefined;
  max?: number;
  valid?: boolean;
  data?: TranslatePlaceholderData;
  color?: string;
}

const FieldRequirementContent: React.FC<FieldRequirementProps> = ({ type, min, max, valid, data, translate, color }) => {
  const { getTranslationId } = useLocalize();

  const formatNumberAsTranslatedDigit = useCallback(
    (value: number | undefined) => {
      if (value && value < 10) {
        const translatedDigit = translate(getTranslationId({ id: `digits.${value}`, type: 'page' }));
        if (_.isString(translatedDigit)) {
          return translatedDigit;
        }
      }
      return `${value}`;
    },
    [translate, getTranslationId]
  );

  const fieldLabel = useMemo(() => {
    switch (type) {
      case 'length-min':
      case 'length-range':
      case 'special-min':
      case 'special-acceptable':
        return 'characters';
      case 'lower-min':
      case 'upper-min':
        return 'letters';
      case 'digit-min':
        return 'digits';
      case 'contains-username':
      case 'equals-email':
        return type;
    }
  }, [type]);

  const typeLabel = useMemo(() => {
    const fieldTypeId = `${fieldLabel}-label.${!min || min !== 1 ? 'plural' : 'singular'}`;
    const translatedLabel = translate(getTranslationId({ id: fieldTypeId, type: 'page' }));
    return _.isString(translatedLabel) ? translatedLabel : fieldLabel;
  }, [fieldLabel, min, translate, getTranslationId]);

  const textData = useMemo(
    () => ({
      ...data,
      min: max ? min : formatNumberAsTranslatedDigit(min),
      max,
      label: typeLabel,
    }),
    [data, min, max, typeLabel, formatNumberAsTranslatedDigit]
  );

  if (!min) {
    return null;
  }
  return (
    <li style={{ color: color ? color : 'initial' }}>
      {!_.isUndefined(valid) && (
        <>
          <i className={`fa ${valid ? 'fa-check valid' : 'fa-exclamation-circle error'}`} />{' '}
        </>
      )}
      <Text id={`fieldRequirements.${type}`} page="secure" data={textData} />
    </li>
  );
};

export const FieldRequirement = withLocalize(FieldRequirementContent);
