import React, { useMemo, useCallback, useEffect } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { LanguageType, AllLanguageTypes } from '@tradingblock/types';
import {
  Section,
  FormGroup,
  StepperField,
  SelectDropdownField,
  Text,
  ContactEmail,
  ContactPhone,
} from '@tradingblock/components';
import { PersonType, ApplicationFormSection } from '../../../../types';
import { PersonField } from '../../../fields';
import { useData } from '../../../../state/selectors';
import { getHeardAbout } from '../../../../services';
import config from '../../../../config';

export const UserInfoSection: React.FC<ApplicationFormSection> = ({
  getText,
  values,
  setValues,
  setActiveLanguage,
}) => {
  const authToken = useData(d => d.ui.apiToken);
  const showLanguageSelection = false;
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;
  const affiliate = useData(d => d.ui.affiliate);

  const heardAbout = useData(d => d.ui.heardAbout);
  const heardAboutSelected = useMemo(() => getHeardAbout(heardAbout, values.hearAboutUsId), [values, heardAbout]);

  const heardAboutOptions = useMemo(() => _.map(heardAbout, h => `${h.heardAboutId}`), [heardAbout]);
  // if siteGroup is MB we need to filter out the "Other" option, automatically select the first option and disable the dropdown
  if (siteGroup === 'mb') {
    const otherIndex = _.findIndex(heardAbout, h => h.description === 'Other');
    if (otherIndex > -1) {
      heardAboutOptions.splice(otherIndex, 1);
    }
  }
  useEffect(() => {
    if (siteGroup === 'mb') {
      if (!values.hearAboutUsId) {
        setValues({
          ...values,
          hearAboutUsId: heardAboutOptions[0],
        });
      }
    }
  }, [heardAboutOptions, siteGroup, values, setValues]);

  const heardAboutOptionsLabels = useMemo(() => {
    return _.reduce(
      heardAbout,
      (acc: { [option: string]: string }, h) => ({ ...acc, [`${h.heardAboutId}`]: h.description }),
      {}
    );
  }, [heardAbout]);

  if (siteGroup === 'mb') {
    // remove the "Other" option from heardAboutSelected.representatives if it exists
    const otherIndex = _.findIndex(heardAboutSelected && heardAboutSelected.representatives, r => r.repId === 42);
    if (otherIndex > -1 && heardAboutSelected && heardAboutSelected.representatives) {
      heardAboutSelected.representatives.splice(otherIndex, 1);
    }
  }

  const representativesOptions = useMemo(
    () =>
      heardAboutSelected &&
      heardAboutSelected.representatives &&
      _.map(heardAboutSelected.representatives, r => `${r.repId}`),
    [heardAboutSelected]
  );
  const representativesOptionsLabels = useMemo(() => {
    if (heardAboutSelected && heardAboutSelected.representatives) {
      return _.reduce(
        heardAboutSelected.representatives,
        (acc: { [option: string]: string }, r) => ({ ...acc, [`${r.repId}`]: r.description }),
        {}
      );
    }
    return undefined;
  }, [heardAboutSelected]);

  const onHeardAboutChanged = useCallback(
    (hearAboutUsId: number) => {
      const heardAboutToSelect = getHeardAbout(heardAbout, hearAboutUsId);
      setValues({
        ...values,
        hearAboutUsId,
        repId: heardAboutToSelect && heardAboutToSelect.repId,
      });
    },
    [heardAbout, values, setValues]
  );

  const assistanceEl = document.getElementById('assistance');

  return (
    <Section>
      <h2 className="fields-title">
        {getText(!authToken ? `registration${config.isVirtual ? '-virtual' : ''}` : 'authenticated')}
      </h2>
      <p className="mute">
        {!authToken && <>{getText('registration', 'description')} </>}
        {getText('registration.help', undefined, {
          data: {
            helpLink: (
              <button
                onClick={e => {
                  e.preventDefault();
                  if (assistanceEl) {
                    assistanceEl.style.display === 'none'
                      ? (assistanceEl.style.display = 'block')
                      : (assistanceEl.style.display = 'none');
                  }
                }}
              >
                {getText('registration.helpLink')}
              </button>
            ),
          },
        })}
      </p>
      <div id="assistance" style={{ display: 'none' }}>
        <h2 className="fields-title">
          <Text id="contact.content" />
        </h2>
        <p>
          <Text
            id="contact.content"
            textKey="description"
            siteGroup={siteGroup}
            data={{
              email: <ContactEmail siteGroup={siteGroup} />,
              phone: <ContactPhone siteGroup={siteGroup} />,
            }}
          />
        </p>
      </div>

      {showLanguageSelection && (
        <FormGroup>
          <Field
            component={StepperField}
            id="languageType"
            options={AllLanguageTypes}
            onChanged={(languageType: LanguageType) => {
              setValues({
                ...values,
                languageType,
              });
              if (setActiveLanguage) {
                setActiveLanguage(languageType);
              }
            }}
          />
        </FormGroup>
      )}

      <Field component={PersonField} id="primaryAccountHolder" personType={PersonType.AccountHolder} />

      {!config.isVirtual && (
        <>
          <FormGroup>
            <Field
              component={SelectDropdownField}
              id="hearAboutUsId"
              isSearchable={false}
              useRawOptions={true}
              options={heardAboutOptions}
              rawOptionLabels={heardAboutOptionsLabels}
              onChanged={onHeardAboutChanged}
              siteGroup={siteGroup}
              disabled={affiliate}
            />
          </FormGroup>
          {representativesOptions && (
            <FormGroup>
              <Field
                component={SelectDropdownField}
                id="repId"
                isSearchable={false}
                useRawOptions={true}
                options={representativesOptions}
                rawOptionLabels={representativesOptionsLabels}
                disabled={affiliate && values.repId}
              />
            </FormGroup>
          )}
        </>
      )}
    </Section>
  );
};
