import _ from 'lodash';
import {
  Country,
  UserRequirementsInfo,
  TradeAuthorizationType,
  ExperienceYearType,
  JointAccountType,
} from '@tradingblock/types';
import {
  AllApplicationSteps,
  AllCharacteristicsKeys,
  ExperienceKey,
  ApplicationModel,
  PhoneModel,
  ApplicationStep,
  BooleanToggleType,
  ErrorType,
  ApplicationType,
  EmploymentType,
  BeneficiaryType,
  PersonType,
  DateModel,
  DataValue,
  RE_EMAIL,
  RE_SSN,
  RE_EIN,
  RE_PO_BOX,
  TotalSecurityChallenges,
  EntityAccountType,
  DefaultAccountHolder,
  AccountHolderModel,
  ForeignDueDiligenceModel,
  AccountDisclosureKey,
  AccountHolderDisclosureKey,
  AllAccountHolderDisclosureKeys,
  AllEntityAccountDisclosureKeys,
  AllEntityNonTrustAccountDisclosureKeys,
  TradeGoalType,
  AccountHolderField,
  DefaultForeignDueDiligence,
  RetirementAccountType,
  RE_VALID_FIRST_NAME,
  RE_VALID_LAST_NAME,
  RE_VALID_FIRST_NAME_WITH_NUMBERS,
  RE_VALID_LAST_NAME_WITH_NUMBERS,
  RE_VALID_MIDDLE_INITIAL,
  RE_VALID_SUFFIX,
  RE_PREFIX_PATTERN,
} from '../types';
import {
  getOwnerNamesForSignStep,
  getDateFromModel,
  getAllExperienceKeysForApplication,
  isUserNameValid,
  isPasswordValid,
} from './';
import {
  getAllAgreementKeysForApplication,
  showBeneficiariesFields,
  showOwnersOfficersFields,
  showTrustedContactFields,
  showTenantsInCommonInterestPercentFields,
} from './Form';
import config from '../config';
import dayjs from 'dayjs';
import xml2js from 'xml2js';

export const isValidDate = (date: DateModel) => {
  const dayjs = getDateFromModel(date);
  // need to compare dayjs parts to original date parts, since dayjs.isValid doesn't check for non-existant dates (e.g. Feb 31)
  return (
    dayjs &&
    dayjs.year() === _.toNumber(date.year) &&
    dayjs.month() + 1 === _.toNumber(date.month) && // like Date object, month is zero-indexed
    dayjs.date() === _.toNumber(date.day)
  );
};

const getError = (value: DataValue, error: ErrorType) => {
  if (!value && !_.isNumber(value)) {
    return error;
  }
  return undefined;
};

const getDuplicateError = (value: DataValue, message: string | string[] | undefined) => {
  if (!value && !_.isNumber(value)) {
    return message;
  }
  return undefined;
};

const getRequiredError = (value: DataValue) => {
  return getError(value, ErrorType.Required);
};

const getInvalidError = (value: DataValue) => {
  return getError(value, ErrorType.Invalid);
};

const getDuplicateAccountError = (value: DataValue, message?: string | string[]) => {
  return getDuplicateError(value, message);
};

const getAmountError = (value: DataValue) => {
  return getError(value, ErrorType.Amount);
};

const getMismatchError = (value: DataValue) => {
  return getError(value, ErrorType.Mismatch);
};

const getEmailError = (value: string | null | undefined) => {
  return getRequiredError(value) || getInvalidError(value && RE_EMAIL.test(value));
};

const getInvalidDateError = (value: DateModel | undefined) => {
  return value && getInvalidError(isValidDate(value));
};

const getContingentError = (value: DataValue) => {
  return getError(value, ErrorType.Contingent);
};

const getInvalidLengthError = (value: DataValue) => {
  return getError(value, ErrorType.InvalidLength);
};

const getInvalidLegalNameError = (value: DataValue) => {
  return getError(value, ErrorType.InvalidLegalName);
};

export const validateApplicationStepAsync = async (
  step: ApplicationStep | undefined,
  values: ApplicationModel,
  visibleSections: number | undefined,
  requirements?: UserRequirementsInfo,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  let errors = {};
  switch (step) {
    case ApplicationStep.SecureAccount:
      errors = validateSecureAccount(values, visibleSections, requirements);
      break;
    case ApplicationStep.ClearerType:
      errors = validateClearerType(values);
      break;
    case ApplicationStep.AccountType:
      errors = validateAccountType(values);
      break;
    case ApplicationStep.InvestingProfile:
      errors = validateInvestingProfile(values, visibleSections);
      break;
    case ApplicationStep.AccountInformation:
      errors = validateAccountInformation(values, visibleSections, duplicateAccountCheck);
      break;
    case ApplicationStep.Beneficiaries:
      errors = validateBeneficiaries(values, duplicateAccountCheck);
      break;
    case ApplicationStep.Agreements:
      errors = validateAgreements(values, visibleSections);
      break;
    case ApplicationStep.Sign:
      errors = validateSign(values);
      break;
  }

  return new Promise(resolve => resolve(_.pickBy(errors, err => !!err))).then(errs => {
    if (!_.isEmpty(errs)) {
      if (/localhost/.test(window.location.host)) {
        console.warn(`validateApplicationStepAsync :: INVALID: `, errs);
      }
      // throw errs;
    }
    return errs;
  });
};

// for use on summary to validate all steps, except 1st (secure), and optionally which final steps (agreements/sign)
export const validateApplication = (
  application: ApplicationModel,
  finalStepsToValidate: ApplicationStep[] = [],
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  const errors = _.reduce(
    AllApplicationSteps,
    (acc: { [key: string]: ErrorType | false | undefined }, step) => {
      switch (step) {
        case ApplicationStep.ClearerType:
          return {
            ...acc,
            ...validateClearerType(application),
          };
        case ApplicationStep.AccountType:
          return {
            ...acc,
            ...validateAccountType(application),
          };
        case ApplicationStep.InvestingProfile:
          return {
            ...acc,
            ...validateInvestingProfile(application, undefined),
          };
        case ApplicationStep.AccountInformation:
          return {
            ...acc,
            ...validateAccountInformation(application, undefined, duplicateAccountCheck),
          };
        case ApplicationStep.Beneficiaries:
          return {
            ...acc,
            ...validateBeneficiaries(application, duplicateAccountCheck),
          };
        case ApplicationStep.Agreements:
          if (_.includes(finalStepsToValidate, ApplicationStep.Agreements)) {
            return {
              ...acc,
              ...validateAgreements(application, undefined),
            };
          }
          return acc;
        default:
          return acc;
      }
    },
    {}
  );

  // if the application is a partialEntitySubmission then only return the errors for the ClearerType & AccountType steps
  if (application.isPartialEntitySubmission) {
    return _.pick(errors, [ApplicationStep.ClearerType, ApplicationStep.AccountType]);
  }
  return _.pickBy(errors, err => !!err);
};

const validateSecureAccount = (
  values: ApplicationModel,
  visibleSections: number | undefined,
  requirements: UserRequirementsInfo | undefined
) => {
  let errorsSection1 = {
    ...validatePerson(values, 'primaryAccountHolder', PersonType.AccountHolder),
  };

  // "heard about" fields not shown on virtual application
  if (!config.isVirtual) {
    errorsSection1 = {
      ...errorsSection1,
      hearAboutUsId: getRequiredError(values.hearAboutUsId),
      repId: values.hearAboutUsId ? getRequiredError(values.repId) : undefined,
    };
  }

  // Section #2
  let errorsSection2 = {};
  if (!visibleSections || visibleSections > 1) {
    errorsSection2 = {
      userName:
        getRequiredError(values.userName) ||
        getInvalidError(values.userName && isUserNameValid(values.userName, requirements, values)),
      password:
        getRequiredError(values.password) ||
        getInvalidError(values.password && isPasswordValid(values.password, requirements, values)),
      ...(values.password
        ? {
            passwordConfirm:
              getRequiredError(values.passwordConfirm) ||
              getInvalidError(_.isEqual(values.password, values.passwordConfirm)),
          }
        : undefined),
      ..._.reduce(
        _.range(0, TotalSecurityChallenges),
        (acc: { [key: string]: string | undefined }, i) => {
          return {
            ...acc,
            [`securityChallenges[${i}].securityQuestionType`]:
              getRequiredError(
                values.securityChallenges &&
                  values.securityChallenges[i] &&
                  values.securityChallenges[i].securityQuestionType
              ) ||
              getInvalidError(
                _.every(
                  values.securityChallenges,
                  (sc, idx) =>
                    idx === i ||
                    !values.securityChallenges ||
                    !values.securityChallenges[i] ||
                    sc.securityQuestionType !== values.securityChallenges[i].securityQuestionType
                )
              ),
            [`securityChallenges[${i}].answer`]: getRequiredError(
              values.securityChallenges && values.securityChallenges[i] && values.securityChallenges[i].answer
            ),
          };
        },
        {}
      ),
    };
  }

  return {
    ...errorsSection1,
    ...errorsSection2,
  };
};

const validateClearerType = (values: ApplicationModel) => {
  return {
    clearer: getRequiredError(values.clearer),
  };
};

const validateAccountType = (values: ApplicationModel) => {
  return {
    ownerCountry: getRequiredError(values.ownerCountry), // || getInvalidError(_.includes(AcceptedCountries, values.ownerCountry)),
    ...(values.type === ApplicationType.Individual
      ? {
          retirementAccount: getRequiredError(values.retirementAccount),
          retirementAccountType:
            values.retirementAccount === BooleanToggleType.Yes && getRequiredError(values.retirementAccountType),
          simpleIraPlanName:
            values.retirementAccount === BooleanToggleType.Yes &&
            values.retirementAccountType === RetirementAccountType.Simple &&
            getRequiredError(values.simpleIraPlanName),
          beneficiaryIraDecedentName:
            values.retirementAccount === BooleanToggleType.Yes &&
            (values.retirementAccountType === RetirementAccountType.Beneficiary ||
              values.retirementAccountType === RetirementAccountType.RothBeneficiary) &&
            getRequiredError(values.beneficiaryIraDecedentName),
        }
      : undefined),
    ...(values.type === ApplicationType.Joint
      ? {
          jointAccountType: getRequiredError(values.jointAccountType),
          jointCommunityPropertyState:
            values.jointAccountType === JointAccountType.Community &&
            getRequiredError(values.jointCommunityPropertyState),
        }
      : undefined),
    ...(values.type === ApplicationType.Entity
      ? {
          entityAccountType: getRequiredError(values.entityAccountType),
        }
      : undefined),
  };
};

const validateInvestingProfile = (values: ApplicationModel, visibleSections: number | undefined) => {
  const errorsSection1 = {
    tradeGoalType: getRequiredError(values.tradeGoalType),
    investmentObjectiveType: getRequiredError(values.investmentObjectiveType),
  };

  // Section #2
  let errorsSection2 = {};
  if (!visibleSections || visibleSections > 1) {
    errorsSection2 = {
      ...(values.tradeGoalType !== TradeGoalType.StocksBonds
        ? _.reduce(
            getAllExperienceKeysForApplication(values),
            (acc: { [key: string]: string | undefined }, key: ExperienceKey) => {
              return {
                ...acc,
                [`experience.${key}.years`]: getRequiredError(
                  values.experience && values.experience[key] && values.experience[key].years
                ),
                [`experience.${key}.tradesPerYear`]: getRequiredError(
                  values.experience &&
                    values.experience[key] &&
                    values.experience[key].years &&
                    values.experience[key].years !== ExperienceYearType.Zero
                    ? values.experience[key].tradesPerYear
                    : true
                ),
              };
            },
            {}
          )
        : {}),
      annualIncome: getRequiredError(values.annualIncome),
      totalNetWorth: getRequiredError(values.totalNetWorth),
      liquidNetWorth: getRequiredError(values.liquidNetWorth),
      ..._.reduce(
        AllCharacteristicsKeys,
        (acc: { [key: string]: string | undefined }, key) => {
          return {
            ...acc,
            [`characteristics.${key}`]: getRequiredError(_.get(values, `characteristics.${key}`)),
          };
        },
        {}
      ),
    };
  }

  return {
    ...errorsSection1,
    ...errorsSection2,
  };
};

// TODO: Implement taxId validation for entity applications
const validateAccountInformation = (
  values: ApplicationModel,
  visibleSections: number | undefined,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  let optionalSections = 0;
  // Section #1 (optional, visible for entity owner type)
  let errorsSection1 = {};

  // Comment this out due to possible old code for Entity Validation that is not needed
  // if (values.type === ApplicationType.Entity) {
  //   optionalSections++;
  //   errorsSection1 = {
  //     'entity.entityName': getRequiredError(values.entity && values.entity.entityName),
  //     'entity.entityTaxId': getRequiredError(values.entity && values.entity.entityTaxId),
  //     ...(values.entityAccountType === EntityAccountType.PersonalTrust
  //       ? {
  //           ...validateDate(values, 'entity.trustCreatedDate'),
  //         }
  //       : {
  //           'entity.entityYourTitle': getRequiredError(values.entity && values.entity.entityYourTitle),
  //           'entity.usStateOfRegistration':
  //             values.ownerCountry === Country.UnitedStatesOfAmerica
  //               ? getRequiredError(values.entity && values.entity.usStateOfRegistration)
  //               : undefined,
  //           'entity.businessNature': getRequiredError(values.entity && values.entity.businessNature),
  //         }),
  //     ...validateAddress(values, 'entity.address'),
  //     ...(values.entityAccountType !== EntityAccountType.PersonalTrust
  //       ? {
  //           'entity.businessPhone': getRequiredError(values.entity && values.entity.businessPhone),
  //         }
  //       : undefined),
  //   };
  // }

  // Section #2 (optional, visible for entity owner type AND non-trust entity types)
  let errorsSection2 = {};
  if (
    values.type === ApplicationType.Entity &&
    values.entityAccountType !== EntityAccountType.PersonalTrust &&
    (!visibleSections || visibleSections > 1)
  ) {
    optionalSections++;
    errorsSection2 = {
      'entity.officeLocations': getRequiredError(values.entity && values.entity.officeLocations),
      'entity.accountActivity': getRequiredError(values.entity && values.entity.accountActivity),
      'entity.initialDepositAmount': getRequiredError(values.entity && values.entity.initialDepositAmount),
      'entity.initialDepositMethod': getRequiredError(values.entity && values.entity.initialDepositMethod),
      'entity.primarySourceOfFunds': getRequiredError(values.entity && values.entity.primarySourceOfFunds),
      'entity.expectedWithdrawalFrequency': getRequiredError(
        values.entity && values.entity.expectedWithdrawalFrequency
      ),
      'entity.primaryBankName': getRequiredError(values.entity && values.entity.primaryBankName),
      ..._.reduce(
        values.entity && values.entity.tradingBlockAccounts,
        (acc: { [key: string]: string | undefined }, account, i) => {
          return {
            ...acc,
            [`entity.tradingBlockAccounts[${i}].accountNumber`]: getRequiredError(account.accountNumber),
            [`entity.tradingBlockAccounts[${i}].accountName`]: getRequiredError(account.accountName),
          };
        },
        {}
      ),
    };
  }

  const primaryAccountHolderPrefix = 'primaryAccountHolder';
  // Section #3
  let errorsSection3 = {};
  if (!visibleSections || visibleSections > optionalSections) {
    errorsSection3 = {
      ...validatePhones(values, primaryAccountHolderPrefix),
      ...validateAddress(values, `${primaryAccountHolderPrefix}.address`, true),
      ...(values.primaryAccountHolder && values.primaryAccountHolder.differentMailingAddress
        ? validateAddress(values, `${primaryAccountHolderPrefix}.mailingAddress`)
        : undefined),
    };
  }
  // Section #4
  let errorsSection4 = {};
  if (!visibleSections || visibleSections > 1 + optionalSections) {
    errorsSection4 = validateAccountHolder(
      values,
      values.primaryAccountHolder || DefaultAccountHolder,
      primaryAccountHolderPrefix,
      duplicateAccountCheck
    );
  }

  const jointAccountHolderPrefix = 'secondaryAccountHolder';
  // Section #5 (optional, visible for joint owner type)
  let errorsSection5 = {};
  if (
    (values.type === ApplicationType.Joint || values.type === ApplicationType.UGMA) &&
    (!visibleSections || visibleSections > 2 + optionalSections)
  ) {
    errorsSection5 = {
      ...validatePerson(values, jointAccountHolderPrefix, PersonType.AccountHolder),
      ...validatePhones(values, jointAccountHolderPrefix),
      ...validateAddress(values, `${jointAccountHolderPrefix}.address`, true),
      ...(values.secondaryAccountHolder && values.secondaryAccountHolder.differentMailingAddress
        ? validateAddress(values, `${jointAccountHolderPrefix}.mailingAddress`)
        : undefined),
    };
  }
  // Section #6 (optional, visible for joint owner type)
  let errorsSection6 = {};
  if (
    (values.type === ApplicationType.Joint || values.type === ApplicationType.UGMA) &&
    (!visibleSections || visibleSections > 3 + optionalSections)
  ) {
    errorsSection6 = validateAccountHolder(
      values,
      values.secondaryAccountHolder || DefaultAccountHolder,
      jointAccountHolderPrefix,
      duplicateAccountCheck
    );
  }

  return {
    ...errorsSection1,
    ...errorsSection2,
    ...errorsSection3,
    ...errorsSection4,
    ...errorsSection5,
    ...errorsSection6,
  };
};

const validateAccountHolder = (
  values: ApplicationModel,
  accountHolder: AccountHolderModel,
  accountHolderPrefix: string,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  const errorMessage = (duplicateAccountCheck && duplicateAccountCheck.errorMessage) || '';
  const isForeign =
    accountHolder &&
    accountHolder.citizenshipCountry &&
    accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica;
  // need to validate ssn only if it is not foreign
  return {
    ...validatePersonDetails(values, accountHolderPrefix, false, duplicateAccountCheck),
    ...(!values.entityAccountType
      ? {
          [`${accountHolderPrefix}.maritalStatus`]: getRequiredError(accountHolder.maritalStatus),
          [`${accountHolderPrefix}.dependents`]:
            getRequiredError(accountHolder.dependents) ||
            getInvalidError(
              _.isNumber(accountHolder.dependents) &&
                accountHolder.dependents > -1 &&
                _.parseInt(`${accountHolder.dependents}`) === _.toNumber(accountHolder.dependents)
            ),
        }
      : undefined),
    ...(accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica
      ? {
          [`${accountHolderPrefix}.citizenshipCountry`]: getRequiredError(accountHolder.citizenshipCountry),
        }
      : undefined),
    ...(accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica
      ? {
          [`${accountHolderPrefix}.birthCountry`]: getRequiredError(accountHolder.birthCountry),
        }
      : undefined),
    ...(accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica &&
    accountHolder.address.country === Country.UnitedStatesOfAmerica &&
    !values.entityAccountType
      ? {
          [`${accountHolderPrefix}.visaType`]: getRequiredError(accountHolder.visaType),
          ...validateDate(values, `${accountHolderPrefix}.visaExpirationDate`),
        }
      : undefined),
    ...(values.type !== ApplicationType.Entity || values.entityAccountType === EntityAccountType.PersonalTrust
      ? {
          [`${accountHolderPrefix}.employmentType`]: getRequiredError(accountHolder.employmentType),
          ...(accountHolder.employmentType === EmploymentType.Employed
            ? {
                [`${accountHolderPrefix}.jobPosition`]: getRequiredError(accountHolder.jobPosition),
                [`${accountHolderPrefix}.yearsEmployed`]: getRequiredError(accountHolder.yearsEmployed),
                [`${accountHolderPrefix}.employer`]: getRequiredError(accountHolder.employer),
                ...validateAddress(values, `${accountHolderPrefix}.employerAddress`),
              }
            : undefined),
        }
      : undefined),
    // need to validate ssn only if it is not foreign
    ...(!isForeign
      ? {
          [`${accountHolderPrefix}.ssn`]:
            getRequiredError(accountHolder.ssn) ||
            getInvalidError(RE_SSN.test(accountHolder.ssn)) ||
            getDuplicateAccountError(
              duplicateAccountCheck && !duplicateAccountCheck.error && errorMessage !== '',
              errorMessage
            ),
        }
      : undefined),

    ...validateForeignDueDiligence(
      accountHolder,
      values.foreignDueDiligence || DefaultForeignDueDiligence,
      accountHolderPrefix
    ),
    ...validateForeignTaxWithholding(accountHolder, accountHolderPrefix, values),
  };
};

const validateForeignDueDiligence = (
  accountHolder: AccountHolderModel,
  foreignDueDiligence: ForeignDueDiligenceModel,
  accountHolderPrefix: string
) => {
  if (
    accountHolderPrefix != 'primaryAccountHolder' ||
    accountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica ||
    accountHolder.address.country === Country.UnitedStatesOfAmerica
  ) {
    return undefined;
  }

  return {
    [`foreignDueDiligence.initialDepositAmount`]: getRequiredError(foreignDueDiligence.initialDepositAmount),
    [`foreignDueDiligence.initialDepositType`]: getRequiredError(foreignDueDiligence.initialDepositType),
    [`foreignDueDiligence.expectedWithdrawalFrequency`]: getRequiredError(
      foreignDueDiligence.expectedWithdrawalFrequency
    ),
    [`foreignDueDiligence.primaryBanking[0]`]: getRequiredError(foreignDueDiligence.primaryBanking[0]),
    ...(foreignDueDiligence.referredToBroker === false
      ? {
          [`foreignDueDiligence.initialContact`]: getRequiredError(foreignDueDiligence.initialContact),
        }
      : {
          [`foreignDueDiligence.referredName`]: getRequiredError(foreignDueDiligence.referredName),
          [`foreignDueDiligence.referredRelationship`]: getRequiredError(foreignDueDiligence.referredRelationship),
        }),
  };
};

const validateForeignTaxWithholding = (
  accountHolder: AccountHolderModel,
  accountHolderPrefix: string,
  values: ApplicationModel
) => {
  const { foreignTaxWithholding } = accountHolder;
  const { jurisdictionDoesNotIssueTIN, notIssuedForeignTIN } = foreignTaxWithholding;
  // RQD only requires that the tax identification number be provided
  // all other fields are specific to Apex accounts
  const isRQDCleared = values.clearer === 'RQD';
  const hasTIN = accountHolder.foreignTaxWithholding.taxIdentificationNumber;

  if (accountHolder.address.country === Country.UnitedStatesOfAmerica) {
    return undefined;
  }

  if (isRQDCleared) {
    return {
      [`${accountHolderPrefix}.foreignTaxWithholding.taxIdentificationNumber`]: getRequiredError(
        !hasTIN ? false : true
      ),
    };
  } else {
    if (
      !accountHolder.foreignTaxWithholding.taxIdentificationNumber &&
      accountHolder.mailingAddress &&
      accountHolder.mailingAddress.country === Country.UnitedStatesOfAmerica &&
      accountHolder.address.country !== Country.UnitedStatesOfAmerica
    ) {
      return {
        [`${accountHolderPrefix}.foreignTaxWithholding.taxIdentificationNumber`]: getRequiredError(
          !jurisdictionDoesNotIssueTIN && !notIssuedForeignTIN ? false : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.jurisdictionDoesNotIssueTIN`]: getRequiredError(
          accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN === null ? false : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.notIssuedForeignTIN`]:
          getRequiredError(accountHolder.foreignTaxWithholding.notIssuedForeignTIN === null ? false : true) ||
          getInvalidError(
            !accountHolder.foreignTaxWithholding.taxIdentificationNumber &&
              (accountHolder.foreignTaxWithholding.notIssuedForeignTIN === BooleanToggleType.No &&
                accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN === BooleanToggleType.No)
          )
            ? false
            : true,
        [`${accountHolderPrefix}.foreignTaxWithholding.letterOfExplanation`]: getRequiredError(
          accountHolder.foreignTaxWithholding.letterOfExplanation === null ||
            _.isEmpty(accountHolder.foreignTaxWithholding.letterOfExplanation)
            ? false
            : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.foreignTINNotRequiredExplanation`]: getRequiredError(
          accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation === null ||
            _.isEmpty(accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation)
            ? false
            : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.treatyCountry`]: getRequiredError(
          accountHolder.foreignTaxWithholding.treatyCountry === null ? false : true
        ),
      };
    }
    if (
      !accountHolder.foreignTaxWithholding.taxIdentificationNumber &&
      accountHolder.address.country !== Country.UnitedStatesOfAmerica
    ) {
      return {
        [`${accountHolderPrefix}.foreignTaxWithholding.taxIdentificationNumber`]: getRequiredError(
          !jurisdictionDoesNotIssueTIN && !notIssuedForeignTIN ? false : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.jurisdictionDoesNotIssueTIN`]:
          getRequiredError(accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN === null ? false : true) ||
          getInvalidError(
            !accountHolder.foreignTaxWithholding.taxIdentificationNumber &&
              (accountHolder.foreignTaxWithholding.notIssuedForeignTIN === BooleanToggleType.No &&
                accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN === BooleanToggleType.No)
          )
            ? false
            : true,
        [`${accountHolderPrefix}.foreignTaxWithholding.notIssuedForeignTIN`]:
          getRequiredError(accountHolder.foreignTaxWithholding.notIssuedForeignTIN === null ? false : true) ||
          getInvalidError(
            !accountHolder.foreignTaxWithholding.taxIdentificationNumber &&
              (accountHolder.foreignTaxWithholding.notIssuedForeignTIN === BooleanToggleType.No &&
                accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN === BooleanToggleType.No)
          )
            ? false
            : true,
        [`${accountHolderPrefix}.foreignTaxWithholding.treatyCountry`]: getRequiredError(
          accountHolder.foreignTaxWithholding.treatyCountry === null ? false : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.foreignTINNotRequiredExplanation`]:
          getRequiredError(
            !jurisdictionDoesNotIssueTIN &&
              (accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation === null ||
                _.isEmpty(accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation))
              ? false
              : true
          ) ||
          getInvalidLengthError(
            jurisdictionDoesNotIssueTIN &&
              accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation &&
              accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation.length <= 255
              ? false
              : true
          ),
      };
    }

    if (
      accountHolder.mailingAddress &&
      accountHolder.mailingAddress.country === Country.UnitedStatesOfAmerica &&
      accountHolder.address.country !== Country.UnitedStatesOfAmerica
    ) {
      return {
        [`${accountHolderPrefix}.foreignTaxWithholding.letterOfExplanation`]: getRequiredError(
          accountHolder.foreignTaxWithholding.letterOfExplanation === null ? false : true
        ),
        [`${accountHolderPrefix}.foreignTaxWithholding.treatyCountry`]: getRequiredError(
          accountHolder.foreignTaxWithholding.treatyCountry === null ? false : true
        ),
      };
    }
    if (!accountHolder.foreignTaxWithholding.treatyCountry) {
      return {
        [`${accountHolderPrefix}.foreignTaxWithholding.treatyCountry`]: getRequiredError(
          accountHolder.foreignTaxWithholding.treatyCountry === null ? false : true
        ),
      };
    }
  }
};

const validateBeneficiaries = (
  values: ApplicationModel,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  const showBeneficiaries = showBeneficiariesFields(values);
  const showOwnersOfficers = showOwnersOfficersFields(values);
  const showTrustedContact = showTrustedContactFields(values);
  const showTenantsInCommonInterestPercent = showTenantsInCommonInterestPercentFields(values);

  return {
    ...(showBeneficiaries ? validateBeneficiariesFields(values, duplicateAccountCheck) : {}),
    ...(showOwnersOfficers ? validateOwnersOfficersFields(values, duplicateAccountCheck) : {}),
    ...(showTrustedContact ? validateTrustedContactFields(values) : {}),
    ...(showTenantsInCommonInterestPercent ? validateTenantsInCommonPercent(values) : {}),
  };
};

const validateBeneficiariesFields = (
  values: ApplicationModel,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  const primaryBeneficiaries = values.beneficiaries
    ? values.beneficiaries.filter(b => b.beneficiaryAccountType === 'Primary')
    : [];
  const contingentBeneficiaries = values.beneficiaries
    ? values.beneficiaries.filter(b => b.beneficiaryAccountType === 'Contingent')
    : [];
  const totalPrimaryPercent = _.sumBy(primaryBeneficiaries, b => _.toNumber(b.percent));
  const totalContingentPercent = _.sumBy(contingentBeneficiaries, b => _.toNumber(b.percent));

  const allSSNs = _.map(values.beneficiaries, b => b.ssn);

  const ssnMatchErrors = _.reduce(
    allSSNs,
    (acc, ssn, i) => {
      if (allSSNs.filter(s => s === ssn).length > 1) {
        acc[`beneficiaries[${i}].ssn`] = getInvalidError(ssn);
      }
      return acc;
    },
    {} as { [errorString: string]: ErrorType | undefined }
  );

  return {
    ...ssnMatchErrors,
    ..._.reduce(
      values.beneficiaries,
      (acc, b, i) => ({
        ...acc,
        [`beneficiaries[${i}].beneficiaryAccountType`]: getRequiredError(b.beneficiaryAccountType),
        [`beneficiaries[${i}].beneficiaryType`]: getRequiredError(b.beneficiaryType),
        ...(b.beneficiaryType === BeneficiaryType.Individual
          ? {
              [`beneficiaries[${i}].relationship`]: getRequiredError(b.relationship),
              ...validatePersonDetails(values, `beneficiaries[${i}]`, true, duplicateAccountCheck),
            }
          : {
              // BeneficiaryType.Trust
              [`beneficiaries[${i}].trustName`]: getRequiredError(b.trustName),
              [`beneficiaries[${i}].ein`]: getRequiredError(b.ein) || getInvalidError(b.ein && RE_EIN.test(b.ein)),
            }),
        ...validateAddress(values, `beneficiaries[${i}].address`),
        [`beneficiaries[${i}].ssn`]: getRequiredError(b.ssn) || getInvalidError(RE_SSN.test(b.ssn)),
        [`beneficiaries[${i}].percent`]:
          getRequiredError(b.percent) ||
          getInvalidError(b.percent && _.isInteger(b.percent)) ||
          getAmountError(b.beneficiaryAccountType === 'Primary' ? totalPrimaryPercent === 100 : true) ||
          getAmountError(b.beneficiaryAccountType === 'Contingent' ? totalContingentPercent === 100 : true) ||
          getContingentError(totalPrimaryPercent !== 100 && totalContingentPercent > 0 ? false : true),
      }),
      {}
    ),
  };
};

const validateOwnersOfficersFields = (
  values: ApplicationModel,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  return {
    ..._.reduce(
      values.ownersOfficers,
      (acc, o, i) => ({
        ...acc,
        ...validatePersonDetails(values, `ownersOfficers[${i}]`, false, duplicateAccountCheck),
        ...validateAddress(values, `ownersOfficers[${i}].address`),
        [`ownersOfficers[${i}].isOwner`]: getRequiredError(o.isOwner || o.isOfficer),
      }),
      {}
    ),
  };
};

const validateTrustedContactFields = (values: ApplicationModel) => {
  if (values.addTrustedContact) {
    let emailError = getEmailError(values.trustedContact && values.trustedContact.email);
    if (!emailError) {
      // validate that email is different from primary account holder
      emailError = getMismatchError(
        values.trustedContact &&
          values.primaryAccountHolder &&
          values.trustedContact.email !== values.primaryAccountHolder.email
      );
    }

    // validate that the first and last name are different from the primary account holder
    let nameError;
    if (values.trustedContact && values.primaryAccountHolder) {
      nameError = getMismatchError(
        values.trustedContact &&
          values.primaryAccountHolder &&
          (values.trustedContact.firstName !== values.primaryAccountHolder.firstName ||
            values.trustedContact.lastName !== values.primaryAccountHolder.lastName)
      );
    }

    // validate that the address is different from the primary account holder
    let addressError;
    if (values.trustedContact && values.primaryAccountHolder) {
      addressError = getMismatchError(
        values.trustedContact &&
          values.primaryAccountHolder &&
          !_.isEqual(values.trustedContact.address, values.primaryAccountHolder.address)
      );
    }

    return {
      ...validatePerson(values, 'trustedContact', PersonType.TrustedContact),
      ...validateAddress(values, 'trustedContact.address'),
      'trustedContact.email': emailError,
      ...validatePhone(_.first(values.trustedContact && values.trustedContact.phones), 'trustedContact.phones[0]'),
      'trustedContact.firstName': nameError && addressError,
      'trustedContact.lastName': nameError && addressError,
      'trustedContact.address.address1': addressError && nameError,
    };
  }
  return {};
};

const validateTenantsInCommonPercent = (values: ApplicationModel) => {
  const primaryPercent = values.primaryAccountHolder.jointTenantsInCommonInterestPercent;
  const secondaryPercent = values.secondaryAccountHolder
    ? values.secondaryAccountHolder.jointTenantsInCommonInterestPercent
    : null;

  if (primaryPercent && secondaryPercent) {
    const totalPercent = primaryPercent + secondaryPercent;

    return {
      [`primaryAccountHolder.jointTenantsInCommonInterestPercent`]:
        getRequiredError(primaryPercent === null ? false : true) || getAmountError(totalPercent === 100),
      [`secondaryAccountHolder.jointTenantsInCommonInterestPercent`]:
        getRequiredError(secondaryPercent === null ? false : true) || getAmountError(totalPercent === 100),
    };
  } else {
    return {
      [`primaryAccountHolder.jointTenantsInCommonInterestPercent`]: getRequiredError(
        primaryPercent === null ? false : true
      ),
      [`secondaryAccountHolder.jointTenantsInCommonInterestPercent`]: getRequiredError(
        primaryPercent === null ? false : true
      ),
    };
  }
};

function removeUndefinedKeys(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Recursively process each property of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = removeUndefinedKeys(obj[key]);

      // Remove properties with undefined values
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  if (_.isEmpty(obj)) {
    return undefined;
  }
  return obj;
}

const validateTradingAuthorization = (values: ApplicationModel) => {
  if (values.tradingAuthorization && values.tradeAuthorization === BooleanToggleType.Yes) {
    const disclosures = values.tradingAuthorization.disclosures;
    const industryEmployed = disclosures && disclosures.industryEmployed;
    const stakeholder = disclosures && disclosures.stakeholder;
    const type = values.tradingAuthorization.type;
    const signedName = values.tradingAuthorization.signedName;
    const { firstName, lastName } = values.tradingAuthorization;
    const nameMatch = firstName && lastName && signedName && signedName === `${firstName} ${lastName}`;

    const isForeign =
      values.tradingAuthorization &&
      values.tradingAuthorization.citizenshipCountry &&
      values.tradingAuthorization.citizenshipCountry !== Country.UnitedStatesOfAmerica;

    const errors = {
      [`tradingAuthorization.type`]: getRequiredError(values.tradingAuthorization.type),
      ...validatePhone(
        _.first(values.tradingAuthorization && values.tradingAuthorization.phones),
        'tradingAuthorization.phones[0]'
      ),
      [`tradingAuthorization.email`]: getEmailError(values.tradingAuthorization && values.tradingAuthorization.email),

      ...validatePerson(values, 'tradingAuthorization', PersonType.TradeAuthorization),
      [`tradingAuthorization.disclosures.industryEmployed`]: getRequiredError(
        type === TradeAuthorizationType.Full ? industryEmployed : true
      ),
      [`tradingAuthorization.disclosures.stakeholder`]: getRequiredError(
        type === TradeAuthorizationType.Full ? stakeholder : true
      ),
      [`tradingAuthorization.disclosuresIndustryEmployedFirmName`]: getRequiredError(
        industryEmployed === BooleanToggleType.Yes
          ? values.tradingAuthorization.disclosuresIndustryEmployedFirmName
          : true
      ),
      [`tradingAuthorization.disclosuresStakeholderTickerSymbol`]: getRequiredError(
        stakeholder === BooleanToggleType.Yes ? values.tradingAuthorization.disclosuresStakeholderTickerSymbol : true
      ),
      [`tradingAuthorization.signedName`]: getRequiredError(signedName) || getMismatchError(nameMatch),
      [`tradingAuthorization.ssnSecret`]:
        (!isForeign &&
          (getRequiredError(_.get(values, `tradingAuthorization.ssnSecret`)) ||
            getInvalidError(RE_SSN.test(_.get(values, `tradingAuthorization.ssnSecret`))))) ||
        undefined,
      ['tradingAuthorization.employmentType']: getRequiredError(values.tradingAuthorization.employmentType),
      [`tradingAuthorization.employer`]:
        // check if employment type is employed, if so check if employer is populated
        // if employer is populated check that it is below 60 characters and return error if not
        (values.tradingAuthorization.employmentType === EmploymentType.Employed &&
          getRequiredError(values.tradingAuthorization.employer)) ||
        (values.tradingAuthorization.employmentType === EmploymentType.Employed &&
          getInvalidLengthError(
            values.tradingAuthorization.employer && values.tradingAuthorization.employer.length <= 60
          )),
      [`tradingAuthorization.jobPosition`]:
        values.tradingAuthorization.employmentType === EmploymentType.Employed &&
        getRequiredError(values.tradingAuthorization.jobPosition),
      ['tradingAuthorization.dateOfBirth']: { ...validateDate(values, 'tradingAuthorization.dateOfBirth', true) },
    };

    const filteredErrors = removeUndefinedKeys(errors);
    return filteredErrors;
  } else {
    return {};
  }
};

const validateAgreements = (values: ApplicationModel, visibleSections: number | undefined) => {
  let optionalSections = 0;

  // Section #1
  const errorsSection1 = {
    tradeAuthorization: getRequiredError(values.tradeAuthorization),
    ...validateTradingAuthorization(values),
    ...validateAccountHolderDisclosures(values, 'primaryAccountHolder'),
  };

  // Section #2 (optional, visible for joint owner type)
  let errorsSection2 = {};
  if (values.type === ApplicationType.Joint && (!visibleSections || visibleSections > 1)) {
    optionalSections++;
    errorsSection2 = validateAccountHolderDisclosures(values, 'secondaryAccountHolder');
  }

  // Section #3
  let errorsSection3 = {};
  if (!visibleSections || visibleSections > 1 + optionalSections) {
    errorsSection3 = {
      ...validateAccountDisclosures(values),
      ...validateServiceProfile(values),
      ...validateMarketDataQuestionnaire(values),
      disclosuresForeignBankUSAgent: getRequiredError(
        values.accountDisclosures && values.accountDisclosures.foreignBank === BooleanToggleType.Yes
          ? values.disclosuresForeignBankUSAgent
          : true
      ),
    };
    if (values.type === ApplicationType.Entity) {
      errorsSection3 = {
        ...errorsSection3,
        accountDisclosures:
          _.get(errorsSection3, 'accountDisclosures') ||
          getRequiredError(
            _.every(
              AllEntityAccountDisclosureKeys,
              (d: AccountDisclosureKey) => values.accountDisclosures && !!values.accountDisclosures[d]
            )
          ),
      };

      if (values.entityAccountType !== EntityAccountType.PersonalTrust) {
        errorsSection3 = {
          ...errorsSection3,
          accountDisclosures:
            _.get(errorsSection3, 'accountDisclosures') ||
            getRequiredError(
              _.every(
                AllEntityNonTrustAccountDisclosureKeys,
                (d: AccountDisclosureKey) => values.accountDisclosures && !!values.accountDisclosures[d]
              )
            ),
          disclosuresEntityNegativeNewsInfo: getRequiredError(
            values.accountDisclosures && values.accountDisclosures.entityNegativeNews === BooleanToggleType.Yes
              ? values.disclosuresEntityNegativeNewsInfo
              : true
          ),
          ...(values.accountDisclosures && values.accountDisclosures.entityPublicOfficials === BooleanToggleType.Yes
            ? validatePublicOfficialsFields(values)
            : {}),
        };
      }
    }
  }

  // Section #4
  let errorsSection4 = {};
  if (!visibleSections || visibleSections > 2 + optionalSections) {
    errorsSection4 = {
      agreeToElectronicConsent: getRequiredError(values.agreeToElectronicConsent),
      ...validateAccountAgreements(values),
    };
  }

  return {
    ...errorsSection1,
    ...errorsSection2,
    ...errorsSection3,
    ...errorsSection4,
  };
};

const validateAccountHolderDisclosures = (values: ApplicationModel, accountHolderPrefix: AccountHolderField) => {
  const accountHolder = values[accountHolderPrefix];
  const disclosures = accountHolder && accountHolder.disclosures;
  const accountDisclosures = values.accountDisclosures;
  return {
    [`${accountHolderPrefix}.disclosures`]: getRequiredError(
      _.every(AllAccountHolderDisclosureKeys, (d: AccountHolderDisclosureKey) => disclosures && !!disclosures[d])
    ),
    [`${accountHolderPrefix}.disclosures.stakeholder`]: getRequiredError(
      disclosures && disclosures.stakeholder === null ? false : true
    ),
    [`${accountHolderPrefix}.disclosures.govOfficial`]: getRequiredError(
      disclosures && disclosures.govOfficial === null ? false : true
    ),
    [`${accountHolderPrefix}.disclosures.irsWithholding`]: getRequiredError(
      disclosures && disclosures.irsWithholding === null ? false : true
    ),
    [`${accountHolderPrefix}.disclosures.industryEmployed`]: getRequiredError(
      disclosures && disclosures.industryEmployed === null ? false : true
    ),

    [`${accountHolderPrefix}.disclosuresStakeholderTickerSymbol`]: getRequiredError(
      disclosures && disclosures.stakeholder === BooleanToggleType.Yes
        ? accountHolder && accountHolder.disclosuresStakeholderTickerSymbol
        : true
    ),
    [`${accountHolderPrefix}.disclosuresIndustryEmployedFirmName`]: getRequiredError(
      disclosures && disclosures.industryEmployed === BooleanToggleType.Yes
        ? accountHolder && accountHolder.disclosuresIndustryEmployedFirmName
        : true
    ),
    [`${accountHolderPrefix}.disclosuresGovOfficialImmediateFamily`]: getRequiredError(
      disclosures && disclosures.govOfficial === BooleanToggleType.Yes
        ? accountHolder && accountHolder.disclosuresGovOfficialImmediateFamily
        : true
    ),
    [`${accountHolderPrefix}.disclosuresGovOfficialPoliticalOrg`]: getRequiredError(
      disclosures && disclosures.govOfficial === BooleanToggleType.Yes
        ? accountHolder && accountHolder.disclosuresGovOfficialPoliticalOrg
        : true
    ),
  };
};

const validateAccountDisclosures = (values: ApplicationModel) => {
  const accountDisclosures = values.accountDisclosures;

  return {
    [`accountDisclosures.foreignBank`]: getRequiredError(
      accountDisclosures && accountDisclosures.foreignBank === null ? false : true
    ),
    [`accountDisclosures.foreignShellBank`]: getRequiredError(
      accountDisclosures && accountDisclosures.foreignShellBank === null ? false : true
    ),
    [`accountDisclosures.foreignFinancialInstitution`]: getRequiredError(
      accountDisclosures && accountDisclosures.foreignFinancialInstitution === null ? false : true
    ),
    [`accountDisclosures.proprietarySecuritiesAccount`]: getRequiredError(
      accountDisclosures && accountDisclosures.proprietarySecuritiesAccount === null ? false : true
    ),
  };
};

const validateMarketDataQuestionnaire = (values: ApplicationModel) => {
  const marketData = values.marketData;
  const marketDataType = values.marketDataType;

  const isProCheck = (values: ApplicationModel) => {
    const { primaryAccountHolder } = values;
    const { disclosures } = primaryAccountHolder;
    const { marketData } = values;

    if (
      disclosures.industryEmployed === BooleanToggleType.Yes ||
      disclosures.stakeholder === BooleanToggleType.Yes ||
      disclosures.govOfficial === BooleanToggleType.Yes ||
      marketData.personalUse === BooleanToggleType.No ||
      marketData.associationRegistration === BooleanToggleType.Yes ||
      marketData.benefitsReceived === BooleanToggleType.Yes ||
      marketData.capitalTrading === BooleanToggleType.Yes ||
      marketData.corporationTrading === BooleanToggleType.Yes ||
      marketData.investmentProfessional === BooleanToggleType.Yes ||
      marketData.secCftcRegistration === BooleanToggleType.Yes ||
      marketData.jobFunctions === BooleanToggleType.Yes
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPro = isProCheck(values);

  const {
    associationRegistration,
    benefitsReceived,
    capitalTrading,
    investmentProfessional,
    jobFunctions,
    personalUse,
    secCftcRegistration,
  } = marketData;

  if (!personalUse) {
    return {
      [`marketData.personalUse`]: getRequiredError(personalUse),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    investmentProfessional === BooleanToggleType.Yes &&
    capitalTrading === BooleanToggleType.Yes &&
    isPro &&
    !marketDataType
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
      [`marketData.capitalTrading`]: getRequiredError(capitalTrading),
      [`marketData.benefitsReceived`]: getRequiredError(benefitsReceived),
      [`marketDataType`]: getRequiredError(marketDataType),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    investmentProfessional === BooleanToggleType.Yes &&
    capitalTrading === BooleanToggleType.Yes &&
    !isPro
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
      [`marketData.capitalTrading`]: getRequiredError(capitalTrading),
      [`marketData.benefitsReceived`]: getRequiredError(benefitsReceived),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    investmentProfessional === BooleanToggleType.Yes &&
    isPro &&
    !marketDataType
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
      [`marketData.capitalTrading`]: getRequiredError(capitalTrading),
      [`marketDataType`]: getRequiredError(marketDataType),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    investmentProfessional === BooleanToggleType.Yes &&
    !isPro
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
      [`marketData.capitalTrading`]: getRequiredError(capitalTrading),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    isPro &&
    !marketDataType
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
      [`marketDataType`]: getRequiredError(marketDataType),
    };
  }

  if (
    personalUse === BooleanToggleType.Yes &&
    (associationRegistration === BooleanToggleType.Yes ||
      secCftcRegistration === BooleanToggleType.Yes ||
      jobFunctions === BooleanToggleType.Yes) &&
    !isPro
  ) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
      [`marketData.investmentProfessional`]: getRequiredError(investmentProfessional),
    };
  }

  if (personalUse === BooleanToggleType.Yes && (!associationRegistration || !secCftcRegistration || !jobFunctions)) {
    return {
      [`marketData.associationRegistration`]: getRequiredError(associationRegistration),
      [`marketData.secCftcRegistration`]: getRequiredError(secCftcRegistration),
      [`marketData.jobFunctions`]: getRequiredError(jobFunctions),
    };
  }
};

const validateServiceProfile = (values: ApplicationModel) => {
  const serviceProfile = values.serviceProfile;

  return {
    [`serviceProfile.issuerDirectCommunication`]: getRequiredError(
      serviceProfile && serviceProfile.issuerDirectCommunication === null ? false : true
    ),
  };
};

const validateAccountAgreements = (values: ApplicationModel) => {
  const agreementValues = values.agreements;
  const agreeToElectronicConsent = values.agreeToElectronicConsent;
  const accountAgreementKeys = getAllAgreementKeysForApplication(values);
  let errorAgreements = [];
  let errors: { [key: string]: any } = {};

  if (agreeToElectronicConsent) {
    for (let index = 0; index < accountAgreementKeys.length; index++) {
      const agreementKey = accountAgreementKeys[index];
      const value = _.get(agreementValues, agreementKey);
      if (value === null || value === 'no') {
        errorAgreements.push(agreementKey);
      }
    }

    for (let index = 0; index < errorAgreements.length; index++) {
      const error = errorAgreements[index];
      errors[error] = 'error';
    }

    const agreements = {
      agreements: errors,
    };

    if (_.isEmpty(errors)) {
      return null;
    }
    return agreements;
  } else {
    return null;
  }
};

const validatePublicOfficialsFields = (values: ApplicationModel) => {
  return {
    ..._.reduce(
      values.disclosuresEntityPublicOfficials,
      (acc, p, i) => ({
        ...acc,
        [`disclosuresEntityPublicOfficials[${i}].name`]: getRequiredError(p.name),
        [`disclosuresEntityPublicOfficials[${i}].role`]: getRequiredError(p.role),
        [`disclosuresEntityPublicOfficials[${i}].title`]: getRequiredError(p.title),
        [`disclosuresEntityPublicOfficials[${i}].organization`]: getRequiredError(p.organization),
        [`disclosuresEntityPublicOfficials[${i}].familyNames`]: getRequiredError(p.familyNames),
      }),
      {}
    ),
  };
};

const validateSign = (values: ApplicationModel) => {
  const ownerNames = getOwnerNamesForSignStep(values);
  return {
    ..._.reduce(
      ownerNames,
      (acc: { [key: string]: string | undefined }, name, i) => {
        return {
          ...acc,
          [`signedNames[${i}]`]:
            getRequiredError(values.signedNames && values.signedNames[i]) ||
            getInvalidError(values.signedNames && values.signedNames[i] && values.signedNames[i] === name),
        };
      },
      {}
    ),
    'entity.secretaryName':
      values.type === ApplicationType.Entity &&
      _.includes([EntityAccountType.CCorporation, EntityAccountType.SCorporation], values.entityAccountType)
        ? getRequiredError(values.entity && values.entity.secretaryName)
        : undefined,
  };
};

const validateDate = (values: ApplicationModel, key: string, testOver18 = false) => {
  const prefix = `${key}.`;
  const date = dayjs(
    `${_.get(values, `${prefix}month`)}/${_.get(values, `${prefix}day`)}/${_.get(values, `${prefix}year`)}`
  );

  return {
    [`${prefix}month`]: getRequiredError(_.get(values, `${prefix}month`)),
    [`${prefix}day`]: getRequiredError(_.get(values, `${prefix}day`)) || getInvalidDateError(_.get(values, key)),
    [`${prefix}year`]:
      getRequiredError(_.get(values, `${prefix}year`)) ||
      (testOver18 && getInvalidError(date.isValid() && date.isBefore(dayjs().subtract(18, 'year')))),
  };
};

// Promise parser for parsing xml to json
const promisesParser = (string: string) => {
  return new Promise(function(resolve, reject) {
    xml2js.parseString(string, (err, result) => {
      if (err) {
        return reject(err);
      } else {
        return resolve(result);
      }
    });
  });
};

// USPS Address Verification
const uspsVerification = async (values: ApplicationModel, field?: string) => {
  const prefix = `${field || 'address'}.`;
  const baseURL = config.uspsBaseUrl;
  const uspsId = config.uspsId;

  const requestConfig = {
    Headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'GET',
    },
    method: 'get',
  };

  // User Input - Address Values
  let address = {
    address1: _.get(values, `${prefix}address1`),
    address2: _.get(values, `${prefix}address2`),
    city: _.get(values, `${prefix}city`),
    state: _.get(values, `${prefix}state`),
    postalCode: _.get(values, `${prefix}postalCode`),
    country: _.get(values, `${prefix}country`),
  };

  const { address1, address2, city, state, postalCode, country } = address;

  // Build XML Request
  const xml = `<AddressValidateRequest USERID="${uspsId}"><Address ID="0"><Address1>${encodeURIComponent(
    address1
  )}</Address1><Address2>${encodeURIComponent(
    address2
  )}</Address2><City>${city}</City><State>${state}</State><Zip5>${postalCode}</Zip5><Zip4></Zip4></Address></AddressValidateRequest>`;

  // Utility function to simplify address for comparison
  // Removes common suffixes, unit indicators, directional abbreviations, and whitespace
  const simplifyAddress = (address: string) => {
    return address
      .toUpperCase()
      .replace(
        /\b(STREET|ST\.?|ROAD|RD\.?|AVENUE|AVE\.?|BOULEVARD|BLVD\.?|DRIVE|DR\.?|LANE|LN\.?|COURT|CT\.?|CIRCLE|CIR\.?|TERRACE|TER\.?|PLACE|PL\.?|LOOP|LOOP|HIGHWAY|HWY\.?|PARKWAY|PKWY\.?|ALLEY|ALY\.?|PLAZA|PLZ\.?|PATH|PATH|WAY|WAY)\b/gi,
        '' // Remove common street suffixes
      )
      .replace(/\b(UNIT|APT|APARTMENT|SUITE|STE|#)\b/gi, '') // Normalize unit indicators
      .replace(/\b(SOUTH|S\.?|NORTH|N\.?|EAST|E\.?|WEST|W\.?)\b/gi, '') // Normalize directional abbreviations
      .replace(/\s+/g, ' ') // Replace multiple whitespace with a single space
      .trim();
  };

  // Function to check if addresses are flipped in USPS validation response based on simplified comparison
  const isFlipped = (address1: string, address2: string) => {
    return _.includes(simplifyAddress(address1), simplifyAddress(address2));
  };

  // If address has been provided, excluding postal code, and country is US, then perform USPS verification
  try {
    if (address1 && city && state && country === Country.UnitedStatesOfAmerica) {
      const response = await fetch(`${baseURL}${xml}`, requestConfig);
      const xmlText = await response.text();
      const xmlResult: any = await promisesParser(xmlText);
      const { AddressValidateResponse } = xmlResult;
      const { Address } = AddressValidateResponse;

      const { City, State, Zip5, Zip4, Address1, Address2, Error } = Address[0];
      if (Error) {
        // Handle API error response
        const { Number, Source, Description } = Error[0];
        return {
          error: {
            number: Number[0],
            source: Source[0],
            description: Description[0],
          },
        };
      } else {
        // Extract address lines from API response
        let address1Value = Address1 ? Address1[0] : null;
        let address2Value = Address2 ? Address2[0] : null;

        // Correct the address lines if they have been flipped
        if (!address1Value) {
          address1Value = address2Value;
          address2Value = null;
        } else if (Address1 && isFlipped(address1, address2Value)) {
          // Swap the address lines using array destructuring
          [address1Value, address2Value] = [address2Value, address1Value];
        }

        // Return the corrected and standardized address components
        return {
          city: City[0] || '',
          state: State[0] || '',
          zip5: Zip5[0] || '',
          zip4: Zip4[0] || '',
          uspsAddress1: address1Value || '',
          uspsAddress2: address2Value || null,
        };
      }
    }
  } catch (err) {
    console.error(err);
  }
};

// Function to get USPS response from storage
const getUspsResponseFromStorage = (prefix: any) => {
  const uspsResponseFromStorage = localStorage.getItem(`${prefix}uspsResponse`);
  return uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined' ? uspsResponseFromStorage : null;
};

// Async function to handle USPS verification
const getUSPS = async (values: ApplicationModel, field: string | undefined) => {
  await new Promise(resolve => setTimeout(resolve, 100));
  return await uspsVerification(values, field);
};

// Function to validate the address using the USPS response
const validateWithUspsResponse = (
  uspsResponse: any,
  address: any,
  prefix: any,
  values: ApplicationModel,
  field: string
) => {
  let isValidAddress = false;
  let uspsResponseValue;

  // Destructuring address object to get individual address components
  const { address1, address2, city, state, country } = address;

  // Check if a USPS response exists and is not 'undefined'
  if (uspsResponse && uspsResponse !== 'undefined') {
    // Parse the USPS response
    uspsResponseValue = JSON.parse(uspsResponse);

    // Extract relevant data from the parsed USPS response
    const { city, state, zip5, error, uspsAddress1, uspsAddress2 } = uspsResponseValue;

    // Format the city name for comparison
    const formattedCityCapitalized = _.capitalize(city);
    const startCaseCity = _.startCase(formattedCityCapitalized);

    // Compare each component of the address with the USPS response
    const isCityEqual = _.toLower(startCaseCity) === _.toLower(_.get(values, `${prefix}city`));
    const isZipEqual = zip5 === _.get(values, `${prefix}postalCode`);
    const isStateEqual = state === _.get(values, `${prefix}state`);
    const isAddressEqual = uspsAddress2
      ? uspsAddress1 === _.get(values, `${prefix}address1`).toUpperCase() &&
        uspsAddress2 === _.get(values, `${prefix}address2`).toUpperCase()
      : uspsAddress1 === _.get(values, `${prefix}address1`).toUpperCase();
    const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual && isAddressEqual;

    // Set the flag based on the address comparison results
    isValidAddress = isAddressCorrect;
  }
  // If address has been provided, excluding postal code, country is US but the current address is not valid, then perform USPS verification to retrieve valid address items or errors
  if (
    (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) ||
    (address1 && address2 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress)
  ) {
    // Set USPS Verification result to localStorage
    getUSPS(values, field).then(result => {
      localStorage.setItem(`${prefix}uspsResponse`, JSON.stringify(result));
    });
  }

  if (uspsResponseValue && address.country === Country.UnitedStatesOfAmerica) {
    const { city, state, zip5, error, uspsAddress1, uspsAddress2 } = uspsResponseValue;
    if (error && address.address1 && address.city && address.state && address.postalCode) {
      return {
        [`${prefix}postalCode`]: `${error.description.trim()} Please review the entered address.`,
        [`${prefix}city`]: `${error.description.trim()} Please review the entered address.`,
        [`${prefix}state`]: `${error.description.trim()} Please review the entered address.`,
        [`${prefix}address1`]: `${error.description.trim()} Please review the entered address.`,
      };
    }

    if (!address.address1 || !address.city) {
      localStorage.setItem(`${prefix}uspsResponse`, 'undefined');
    }

    if (city && state && zip5) {
      // Formatting for error message display of USPS response
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);
      const isCityEqual = _.toLower(startCaseCity) === _.toLower(_.get(values, `${prefix}city`));
      const isZipEqual = zip5 === _.get(values, `${prefix}postalCode`);
      const isStateEqual = state === _.get(values, `${prefix}state`);
      const isAddress1Equal = uspsAddress1 === _.get(values, `${prefix}address1`).toUpperCase();
      const isAddress2Equal = uspsAddress2 === _.get(values, `${prefix}address2`).toUpperCase();

      const formattedAddress1 = uspsAddress1
        .toLowerCase()
        .split(' ')
        .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const formattedAddress2 = uspsAddress2
        ? uspsAddress2
            .toLowerCase()
            .split(' ')
            .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        : null;

      // Required Errors
      if (!address.postalCode) {
        return {
          [`${prefix}postalCode`]: getRequiredError(_.get(values, `${prefix}postalCode`)),
        };
      }

      if (!address.city) {
        return {
          [`${prefix}city`]: getRequiredError(_.get(values, `${prefix}city`)),
        };
      }

      // USPS Verification Errors
      let errors = {};
      if (!isCityEqual) {
        errors = {
          ...errors,
          [`${prefix}city`]: `Please verify city. USPS Provided City: ${startCaseCity}`,
        };
      }
      if (!isZipEqual) {
        errors = {
          ...errors,
          [`${prefix}postalCode`]: `Please verify the zip code. USPS Provided Zip Code: ${zip5}`,
        };
      }
      if (!isStateEqual) {
        errors = {
          ...errors,
          [`${prefix}state`]: `Please verify the state. USPS Provided State: ${state}`,
        };
      }
      if (!isAddress1Equal) {
        errors = {
          ...errors,
          [`${prefix}address1`]: `Please utilize the USPS provided formatting: ${formattedAddress1}`,
        };
      }
      if (uspsAddress2 && !isAddress2Equal) {
        errors = {
          ...errors,
          [`${prefix}address2`]: `Please utilize the USPS provided formatting: ${formattedAddress2}`,
        };
      }
      return errors;
    }
  }
};

// TODO: Review localStorage usage
const validateAddress = (values: ApplicationModel, field?: string, validatePoBox = false) => {
  const prefix = `${field || 'address'}.`;
  const isMailing = _.toLower(prefix).includes('mailing');
  // are there other countries we need to require state for?
  const stateOrPostalCodeRequired = _.get(values, `${prefix}country`) === Country.UnitedStatesOfAmerica;

  const address = {
    address1: _.get(values, `${prefix}address1`),
    address2: _.get(values, `${prefix}address2`),
    city: _.get(values, `${prefix}city`),
    state: _.get(values, `${prefix}state`),
    postalCode: _.get(values, `${prefix}postalCode`),
    country: _.get(values, `${prefix}country`),
  };

  let uspsResponseFromStorage = getUspsResponseFromStorage(prefix);

  const uspsValidationErrors = validateWithUspsResponse(
    uspsResponseFromStorage,
    address,
    prefix,
    values,
    field || 'address'
  );
  if (Object.keys(uspsValidationErrors || {}).length > 0) {
    return uspsValidationErrors || {};
  }

  if (!isMailing) {
    return {
      [`${prefix}country`]: getRequiredError(_.get(values, `${prefix}country`)),
      [`${prefix}address1`]:
        getRequiredError(_.get(values, `${prefix}address1`)) ||
        getInvalidError(!RE_PO_BOX.test(_.get(values, `${prefix}address1`))) ||
        getInvalidLengthError(_.get(values, `${prefix}address1`).length <= 30) ||
        (_.get(values, `${prefix}address1`) && getInvalidError(_.trim(_.get(values, `${prefix}address1`)) !== '')),
      [`${prefix}address2`]:
        (_.get(values, `${prefix}address2`) && getInvalidError(!RE_PO_BOX.test(_.get(values, `${prefix}address2`)))) ||
        (_.get(values, `${prefix}address2`) &&
          getInvalidLengthError(_.get(values, `${prefix}address2`).length <= 30)) ||
        (_.get(values, `${prefix}address2`) && getInvalidError(_.trim(_.get(values, `${prefix}address2`)) !== '')),
      [`${prefix}postalCode`]: getRequiredError(
        stateOrPostalCodeRequired ? _.get(values, `${prefix}postalCode`) : true
      ),
      [`${prefix}city`]: getRequiredError(_.get(values, `${prefix}city`)),
      [`${prefix}state`]: getRequiredError(stateOrPostalCodeRequired ? _.get(values, `${prefix}state`) : true),
    };
  } else if (isMailing) {
    return {
      [`${prefix}country`]: getRequiredError(_.get(values, `${prefix}country`)),
      [`${prefix}address1`]:
        getRequiredError(_.get(values, `${prefix}address1`)) ||
        (_.get(values, `${prefix}address1`) && getInvalidError(_.trim(_.get(values, `${prefix}address1`)) !== '')),
      [`${prefix}address2`]:
        _.get(values, `${prefix}address2`) && getInvalidError(_.trim(_.get(values, `${prefix}address2`)) !== ''),
      [`${prefix}postalCode`]: getRequiredError(
        stateOrPostalCodeRequired ? _.get(values, `${prefix}postalCode`) : true
      ),
      [`${prefix}city`]: getRequiredError(_.get(values, `${prefix}city`)),
      [`${prefix}state`]: getRequiredError(stateOrPostalCodeRequired ? _.get(values, `${prefix}state`) : true),
    };
  }
};

const validatePhones = (values: ApplicationModel, field?: string) => {
  const prefix = field ? `${field}.` : '';
  return _.reduce(
    _.get(values, `${prefix}phones`) || [{ type: undefined, phoneNumber: undefined }],
    (acc: { [key: string]: string | undefined }, phone: PhoneModel, i: number) => {
      return {
        ...acc,
        ...validatePhone(phone, `${prefix}phones[${i}]`, i === 0),
      };
    },
    {}
  );
};

const validatePhoneNumber = (value: string) => {
  // Correct the regex pattern to allow multiple digits, underscores, and hyphens
  var regex = /^[0-9_-]+$/;
  // Check if the value matches the regex and if its length (not including "-") is greater than 10
  return regex.test(value) && value.replace(/-/g, '').length >= 10;
};

const validatePhone = (value: PhoneModel | undefined, field: string, validateAllFields = true) => {
  return {
    [`${field}.type`]: getRequiredError(validateAllFields || (value && value.phoneNumber) ? value && value.type : true),
    [`${field}.phoneNumber`]:
      getRequiredError(validateAllFields || (value && value.type) ? value && value.phoneNumber : true) ||
      getInvalidError(
        validateAllFields || (value && value.phoneNumber) ? value && validatePhoneNumber(value.phoneNumber) : true
      ),
  };
};

const validatePerson = (
  values: ApplicationModel,
  field: string | undefined,
  contactType?: PersonType | false,
  validateName = true
) => {
  const prefix = field ? `${field}.` : '';
  const isDevelopmentEnv = process.env.REACT_APP_ENVIRONMENT === 'dev';

  return {
    [`${prefix}firstName`]:
      (validateName &&
        (getRequiredError(_.get(values, `${prefix}firstName`)) ||
          getInvalidError(
            isDevelopmentEnv
              ? RE_VALID_FIRST_NAME_WITH_NUMBERS.test(_.get(values, `${prefix}firstName`))
              : RE_VALID_FIRST_NAME.test(_.get(values, `${prefix}firstName`))
          ))) ||
      getInvalidLengthError(_.get(values, `${prefix}firstName`) && _.get(values, `${prefix}firstName`).length <= 40) ||
      getInvalidLegalNameError(
        _.get(values, `${prefix}firstName`).length + _.get(values, `${prefix}lastName`).length <= 60
      ),
    // middle initial should either be an empty string or a single letter
    [`${prefix}middleInitial`]:
      _.get(values, `${prefix}middleInitial`) &&
      getInvalidError(RE_VALID_MIDDLE_INITIAL.test(_.get(values, `${prefix}middleInitial`))),
    [`${prefix}lastName`]:
      (validateName &&
        (getRequiredError(_.get(values, `${prefix}lastName`)) ||
          getInvalidError(
            isDevelopmentEnv
              ? RE_VALID_LAST_NAME_WITH_NUMBERS.test(_.get(values, `${prefix}lastName`))
              : RE_VALID_LAST_NAME.test(_.get(values, `${prefix}lastName`))
          ))) ||
      getInvalidLengthError(_.get(values, `${prefix}lastName`) && _.get(values, `${prefix}lastName`).length <= 40) ||
      getInvalidLegalNameError(
        _.get(values, `${prefix}firstName`).length + _.get(values, `${prefix}lastName`).length <= 60
      ),
    // suffix should either be an empty string or a string of letters
    [`${prefix}suffix`]:
      _.get(values, `${prefix}suffix`) &&
      (getInvalidError(RE_VALID_SUFFIX.test(_.get(values, `${prefix}suffix`))) ||
        getInvalidLengthError(_.get(values, `${prefix}suffix`).length <= 10) ||
        getInvalidError(_.get(values, `${prefix}suffix`) && !RE_PREFIX_PATTERN.test(_.get(values, `${prefix}suffix`)))),

    ...(contactType !== PersonType.AccountHolder ? validateAddress(values, `${prefix}address`) : {}),
    ...(contactType === PersonType.AccountHolder
      ? {
          [`${prefix}email`]: getEmailError(_.get(values, `${prefix}email`)),
          ...(_.get(values, `${prefix}email`)
            ? {
                [`${prefix}emailConfirm`]:
                  getRequiredError(_.get(values, `${prefix}emailConfirm`)) ||
                  getInvalidError(_.isEqual(_.get(values, `${prefix}email`), _.get(values, `${prefix}emailConfirm`))),
              }
            : undefined),
        }
      : {}),
  };
};

const validatePersonDetails = (
  values: ApplicationModel,
  field: string | undefined,
  validateName = true,
  duplicateAccountCheck?: {
    isFetching: boolean;
    error?: boolean;
    errorMessage?: string[] | string | undefined;
  }
) => {
  const prefix = field ? `${field}.` : '';
  const { isFetching, error, errorMessage } = duplicateAccountCheck || {
    isFetching: false,
    error: false,
    errorMessage: undefined,
  };

  const isForeign =
    values.primaryAccountHolder &&
    values.primaryAccountHolder.citizenshipCountry &&
    values.primaryAccountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica;
  if (values.type === 'Ugma' && prefix === 'secondaryAccountHolder.') {
    return {
      ...validatePerson(values, field, false, validateName),
      ...validateDate(values, `${prefix}dateOfBirth`, false),
      [`${prefix}ssn`]:
        (!isForeign &&
          (getRequiredError(_.get(values, `${prefix}ssn`)) ||
            getInvalidError(RE_SSN.test(_.get(values, `${prefix}ssn`))))) ||
        error
          ? errorMessage
          : undefined,
    };
  }
  return {
    ...validatePerson(values, field, false, validateName),
    ...validateDate(values, `${prefix}dateOfBirth`, true),
    [`${prefix}ssn`]:
      (!isForeign &&
        (getRequiredError(_.get(values, `${prefix}ssn`)) ||
          getInvalidError(RE_SSN.test(_.get(values, `${prefix}ssn`))))) ||
      error
        ? errorMessage
        : undefined,
  };
};
