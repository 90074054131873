import React from 'react';
import { Text } from '@tradingblock/components';
import {
  SummaryStepProps,
  AccountHolderType,
  PersonType,
  AccountHolderField,
  DefaultAccountHolder,
} from '../../../../types';
import { PhonesValue, AddressValue, PersonValue } from '../../../values';

interface AccountHolderContactSummaryProps extends SummaryStepProps {
  type: AccountHolderType;
}

const AccountHolderContactSummary: React.FC<AccountHolderContactSummaryProps> = ({ type, application, getText }) => {
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = (application && application[fieldPrefix]) || DefaultAccountHolder;
  const isUgma = type === 'ugma';

  if (!application || !accountHolder) {
    return null;
  }

  return (
    <>
      <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
        {isUgma ? getText('ugmaAccountHolder') : getText(fieldPrefix)}
      </h3>

      {type !== 'primary' && (
        <p>
          <PersonValue value={accountHolder} personType={PersonType.AccountHolder} />
        </p>
      )}

      <h3 className="fields-title">{getText('defaults.phones')}</h3>
      <PhonesValue value={accountHolder.phones} />

      <h3 className="fields-title">{getText('accountHolders.addresses')}</h3>
      <p>
        <strong>{getText('accountHolders.address.label')}</strong>
        <br />
        <AddressValue value={accountHolder.address} />
      </p>
      <p>
        <strong>{getText('accountHolders.mailingAddress.label')}</strong>
        <br />
        {accountHolder.differentMailingAddress && <AddressValue value={accountHolder.mailingAddress} />}
        {!accountHolder.differentMailingAddress && <Text id="accountHolders.sameMailingAddress" type="field" />}
      </p>
    </>
  );
};

export const PrimaryAccountHolderContactSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderContactSummary type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderContactSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderContactSummary type={AccountHolderType.Joint} {...props} />;
};

export const UgmaAccountHolderContactSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderContactSummary type={AccountHolderType.Ugma} {...props} />;
};
