import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { Text, useLocalize } from '@tradingblock/components';
import { TradeGoalType, ApplicationModel, BooleanToggleType } from '../../types';

interface TradeGoalTextProps extends LocalizeContextProps {
  application: ApplicationModel | undefined;
  type: TradeGoalType;
}

const TradeGoalTextContent: React.FC<TradeGoalTextProps> = ({ application, type, translate }) => {
  const { getTranslationId } = useLocalize();

  const tradeGoalOptionPrefix = useMemo(() => {
    if (application && application.retirementAccount === BooleanToggleType.Yes && type === TradeGoalType.AllAbovePlusPutWriting) {
      const prefix = translate(getTranslationId({ id: 'tradeGoalType.prefix-ira', type: 'field' }));
      return _.isString(prefix) ? prefix : '';
    }
    return '';
  }, [application, type, translate, getTranslationId]);

  return <Text id={`tradeGoalType.${type}`} type="field" data={{ prefix: tradeGoalOptionPrefix }} />;
};

export const TradeGoalText = withLocalize(TradeGoalTextContent);
