import { isString, verify } from '@tradingblock/api';
import { LoginJwtValue } from '@tradingblock/types';
import { config } from '../config';

export const delayedScrollTo = (offset: number, offsetPadding = 125) => {
  window.setTimeout(() => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: offset ? offset - offsetPadding : offsetPadding });
  }, 10);
};

export const validateToken = async (id_token: string) => {
  const pubKey = await fetch(`${config.dataUrl}/publickey`).then(res => res.json());

  try {
    var decoded = await verify(pubKey.key, id_token, { algorithms: ['RS256'], ignoreExpiration: false, clientType: 'application' });
    if (!decoded || isString(decoded)) {
      console.error('jwt response was invalid, an object was expected ', decoded);
      return null;
    } else {
      const jwtValues = decoded as LoginJwtValue;
      return { value: id_token, decoded: jwtValues };
    }
  } catch (err) {
    console.log('validate token failed', err);
    return null;
  }
};
