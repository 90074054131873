import { LanguageType } from './Language';
import { BooleanToggleType, FlexibilityType } from './AccountManagement';
export var InvestigationDocumentName;

(function (InvestigationDocumentName) {
  InvestigationDocumentName["Name"] = "Name";
  InvestigationDocumentName["DateOfBirth"] = "DateOfBirth";
  InvestigationDocumentName["TaxId"] = "TaxId";
  InvestigationDocumentName["Address"] = "Address";
  InvestigationDocumentName["ForeignerSignature"] = "Signature";
  InvestigationDocumentName["ForeignerGovermentId"] = "GovernmentId";
  InvestigationDocumentName["AffiliateApproval"] = "AffiliateApproval";
})(InvestigationDocumentName || (InvestigationDocumentName = {}));

export var InvestigationDocuments;

(function (InvestigationDocuments) {
  InvestigationDocuments["DriversLicense"] = "DriversLicense";
  InvestigationDocuments["StateIdCard"] = "StateIdCard";
  InvestigationDocuments["Passport"] = "Passport";
  InvestigationDocuments["MilitaryId"] = "MilitaryId";
  InvestigationDocuments["SSNCard"] = "SsnCard";
  InvestigationDocuments["IRSW9Form"] = "IrsW9Form";
  InvestigationDocuments["SSALetter"] = "SsaLetter";
  InvestigationDocuments["IRSITINLetter"] = "IrsItinLetter";
  InvestigationDocuments["ForeignerSignature"] = "Signature";
  InvestigationDocuments["ForeignerGovermentId"] = "GovernmentId";
  InvestigationDocuments["AffiliateApproval"] = "AffiliateApproval";
})(InvestigationDocuments || (InvestigationDocuments = {}));

export var AccountApplicationState;

(function (AccountApplicationState) {
  AccountApplicationState["Accepted"] = "Accepted";
  AccountApplicationState["New"] = "New";
  AccountApplicationState["Pending"] = "Pending";
  AccountApplicationState["InvestigationSubmitted"] = "InvestigationSubmitted";
  AccountApplicationState["ReadyForBackOffice"] = "ReadyForBackOffice";
  AccountApplicationState["BackOffice"] = "BackOffice";
  AccountApplicationState["AccountSetup"] = "AccountSetup";
  AccountApplicationState["Complete"] = "Complete";
  AccountApplicationState["ActionRequired"] = "ActionRequired";
  AccountApplicationState["Rejected"] = "Rejected";
  AccountApplicationState["Suspended"] = "Suspended";
  AccountApplicationState["Error"] = "Error";
  AccountApplicationState["Cancelled"] = "Cancelled";
  AccountApplicationState["PendingSubmit"] = "PendingSubmit";
})(AccountApplicationState || (AccountApplicationState = {}));

export var AccountInvestigationState;

(function (AccountInvestigationState) {
  AccountInvestigationState["New"] = "New";
  AccountInvestigationState["Pending"] = "Pending";
  AccountInvestigationState["Indeterminate"] = "Indeterminate";
  AccountInvestigationState["Accepted"] = "Accepted";
  AccountInvestigationState["Rejected"] = "Rejected";
  AccountInvestigationState["Appealed"] = "Appealed";
  AccountInvestigationState["Error"] = "Error";
  AccountInvestigationState["Cancelled"] = "Cancelled";
})(AccountInvestigationState || (AccountInvestigationState = {}));

export var UploadedDocumentState;

(function (UploadedDocumentState) {
  UploadedDocumentState["PendingReview"] = "PendingReview";
  UploadedDocumentState["Submitted"] = "Submitted";
  UploadedDocumentState["Rejected"] = "Rejected";
  UploadedDocumentState["Accepted"] = "Accepted";
})(UploadedDocumentState || (UploadedDocumentState = {}));

export var DefaultSecurityChallenge = {
  securityQuestionType: '',
  answer: ''
};
export var ApplicationStep;

(function (ApplicationStep) {
  ApplicationStep["AccountType"] = "account";
  ApplicationStep["Agreements"] = "agreements";
  ApplicationStep["Beneficiaries"] = "beneficiaries";
  ApplicationStep["ClearerType"] = "clearerType";
  ApplicationStep["AccountInformation"] = "information";
  ApplicationStep["InvestingProfile"] = "investing";
  ApplicationStep["SecureAccount"] = "secure";
  ApplicationStep["Sign"] = "sign";
})(ApplicationStep || (ApplicationStep = {}));

export var ApplicationType;

(function (ApplicationType) {
  ApplicationType["Entity"] = "Entity";
  ApplicationType["Individual"] = "Individual";
  ApplicationType["Joint"] = "Joint";
  ApplicationType["UGMA"] = "Ugma";
})(ApplicationType || (ApplicationType = {}));

export var RetirementAccountType;

(function (RetirementAccountType) {
  RetirementAccountType["Beneficiary"] = "Beneficiary";
  RetirementAccountType["CoverDell"] = "CoverDell";
  RetirementAccountType["Rollover"] = "Rollover";
  RetirementAccountType["Roth"] = "Roth";
  RetirementAccountType["SEP"] = "SEP";
  RetirementAccountType["Simple"] = "Simple";
  RetirementAccountType["Traditional"] = "Traditional";
})(RetirementAccountType || (RetirementAccountType = {}));

export var JointAccountType;

(function (JointAccountType) {
  JointAccountType["Community"] = "Community";
  JointAccountType["Survivor"] = "Survivor";
  JointAccountType["TenantsInCommon"] = "TenantsInCommon";
  JointAccountType["TenantsInEntirety"] = "TenantsInEntirety";
})(JointAccountType || (JointAccountType = {}));

export var EntityAccountType;

(function (EntityAccountType) {
  EntityAccountType["BusinessTrust"] = "BusinessTrust";
  EntityAccountType["CCorporation"] = "CCorporation";
  EntityAccountType["LlcCCorporation"] = "LlcCCorporation";
  EntityAccountType["LlcSCorporation"] = "LlcSCorporation";
  EntityAccountType["Partnership"] = "Partnership";
  EntityAccountType["PersonalTrust"] = "PersonalTrust";
  EntityAccountType["SCorporation"] = "SCorporation";
})(EntityAccountType || (EntityAccountType = {}));

export var MaritalStatusType;

(function (MaritalStatusType) {
  MaritalStatusType["Divorced"] = "Divorced";
  MaritalStatusType["Married"] = "Married";
  MaritalStatusType["Single"] = "Single";
  MaritalStatusType["Widowed"] = "Widowed";
})(MaritalStatusType || (MaritalStatusType = {}));

export var VisaType;

(function (VisaType) {
  VisaType["E1"] = "E1";
  VisaType["E2"] = "E2";
  VisaType["E3"] = "E3";
  VisaType["F1"] = "F1";
  VisaType["K1"] = "K1";
  VisaType["G4"] = "G4";
  VisaType["H1B"] = "H1B";
  VisaType["L1"] = "L1";
  VisaType["O1"] = "O1";
  VisaType["TN1"] = "TN1";
})(VisaType || (VisaType = {}));

export var BusinessNature;

(function (BusinessNature) {
  BusinessNature["Agriculture"] = "Agriculture";
  BusinessNature["Communications"] = "Communications";
  BusinessNature["Construction"] = "Construction";
  BusinessNature["ElectricGas"] = "ElectricGas";
  BusinessNature["Finance"] = "Finance";
  BusinessNature["Fishing"] = "Fishing";
  BusinessNature["Forestry"] = "Forestry";
  BusinessNature["Insurance"] = "Insurance";
  BusinessNature["Manufacturing"] = "Manufacturing";
  BusinessNature["Mining"] = "Mining";
  BusinessNature["PublicAdministration"] = "PublicAdministration";
  BusinessNature["RealEstate"] = "RealEstate";
  BusinessNature["RetailTrade"] = "RetailTrade";
  BusinessNature["SanitaryServices"] = "SanitaryServices";
  BusinessNature["Services"] = "Services";
  BusinessNature["Transportation"] = "Transportation";
  BusinessNature["WholesaleTrade"] = "WholesaleTrade";
})(BusinessNature || (BusinessNature = {}));

export var AccountActivityType;

(function (AccountActivityType) {
  AccountActivityType["ActiveTrading"] = "ActiveTrading";
  AccountActivityType["LongTermInvesting"] = "LongTermInvesting";
  AccountActivityType["ShortTermInvesting"] = "ShortTermInvesting";
})(AccountActivityType || (AccountActivityType = {}));

export var SourceOfFundsType;

(function (SourceOfFundsType) {
  SourceOfFundsType["CorporateIncome"] = "CorporateIncome";
  SourceOfFundsType["InvestmentCapital"] = "InvestmentCapital";
  SourceOfFundsType["Other"] = "Other";
})(SourceOfFundsType || (SourceOfFundsType = {}));

export var WithdrawalFrequencyType;

(function (WithdrawalFrequencyType) {
  WithdrawalFrequencyType["Frequent"] = "Frequent";
  WithdrawalFrequencyType["Occasional"] = "Occasional";
  WithdrawalFrequencyType["Rare"] = "Rare";
})(WithdrawalFrequencyType || (WithdrawalFrequencyType = {}));

export var BeneficiaryAccountType;

(function (BeneficiaryAccountType) {
  BeneficiaryAccountType["Contingent"] = "Contingent";
  BeneficiaryAccountType["Primary"] = "Primary";
})(BeneficiaryAccountType || (BeneficiaryAccountType = {}));

export var BeneficiaryType;

(function (BeneficiaryType) {
  BeneficiaryType["Individual"] = "Individual";
  BeneficiaryType["Trust"] = "Trust";
})(BeneficiaryType || (BeneficiaryType = {}));

export var BeneficiariesRelationshipsType;

(function (BeneficiariesRelationshipsType) {
  BeneficiariesRelationshipsType["Spouse"] = "Spouse";
  BeneficiariesRelationshipsType["Child"] = "Child";
  BeneficiariesRelationshipsType["Parent"] = "Parent";
  BeneficiariesRelationshipsType["Other"] = "Other";
})(BeneficiariesRelationshipsType || (BeneficiariesRelationshipsType = {}));

export var BeneficiariesRelationships = [BeneficiariesRelationshipsType.Spouse, BeneficiariesRelationshipsType.Parent, BeneficiariesRelationshipsType.Child, BeneficiariesRelationshipsType.Other];
export var DeliveryType;

(function (DeliveryType) {
  DeliveryType["Electronic"] = "Electronic";
  DeliveryType["Paper"] = "Paper";
})(DeliveryType || (DeliveryType = {}));

export var ElectronicFormat;

(function (ElectronicFormat) {
  ElectronicFormat["Html"] = "Html";
  ElectronicFormat["PlainText"] = "PlainText";
})(ElectronicFormat || (ElectronicFormat = {}));

export var AllDeliveryTypes = [DeliveryType.Electronic, DeliveryType.Paper];
export var AllElectronicFormats = [ElectronicFormat.Html, ElectronicFormat.PlainText];
export var ProSubscriptionType;

(function (ProSubscriptionType) {
  ProSubscriptionType["Delayed"] = "Delayed";
  ProSubscriptionType["RealTime"] = "Realtime";
})(ProSubscriptionType || (ProSubscriptionType = {}));

export var DuplicateStatements;

(function (DuplicateStatements) {
  DuplicateStatements["None"] = "None";
  DuplicateStatements["PostalMail"] = "PostalMail";
  DuplicateStatements["Email"] = "Email";
})(DuplicateStatements || (DuplicateStatements = {}));

export var AllDuplicateStatementOptions = [DuplicateStatements.PostalMail, DuplicateStatements.Email];
export var TradeAuthorizationType;

(function (TradeAuthorizationType) {
  TradeAuthorizationType["Full"] = "Full";
  TradeAuthorizationType["Limited"] = "Limited";
})(TradeAuthorizationType || (TradeAuthorizationType = {}));

export var AllTradeAuthorizationTypes = [TradeAuthorizationType.Full, TradeAuthorizationType.Limited];
export var DefaultAddress = {
  country: '',
  address1: '',
  address2: '',
  postalCode: null,
  city: '',
  state: null
};
export var DefaultPhone = {
  type: '',
  phoneNumber: ''
};
export var DefaultPersonDetails = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  suffix: '',
  email: '',
  dateOfBirth: {
    day: undefined,
    month: undefined,
    year: undefined
  },
  ssn: '',
  address: DefaultAddress,
  phones: [DefaultPhone]
};
export var DefaultAccountHolder = Object.assign({}, DefaultPersonDetails, {
  emailConfirm: '',
  maritalStatus: '',
  dependents: '',
  birthCountry: null,
  citizenshipCountry: null,
  employmentType: '',
  disclosuresStakeholderTickerSymbol: '',
  disclosuresIndustryEmployedFirmName: '',
  disclosuresGovOfficialImmediateFamily: '',
  disclosuresGovOfficialPoliticalOrg: '',
  jointTenantsInCommonInterestPercent: 0,
  disclosures: {
    govOfficial: null,
    industryEmployed: null,
    irsWithholding: null,
    stakeholder: null
  },
  differentMailingAddress: false,
  mailingAddress: null,
  employer: '',
  employerAddress: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: null,
    postalCode: null
  },
  visaType: null,
  visaExpirationDate: null,
  jobPosition: '',
  yearsEmployed: null,
  foreignTaxWithholding: {
    taxIdentificationNumber: null,
    letterOfExplanation: null,
    jurisdictionDoesNotIssueTIN: null,
    notIssuedForeignTIN: null,
    treatyCountry: null,
    foreignTINNotRequiredExplanation: null
  }
});
export var DefaultApplicationModel = {
  // Secure Account
  languageType: LanguageType.English,
  ownerUsername: '',
  password: '',
  passwordConfirm: '',
  securityChallenges: [DefaultSecurityChallenge, DefaultSecurityChallenge, DefaultSecurityChallenge],
  // Account Type
  type: ApplicationType.Individual,
  flexibilityType: FlexibilityType.Margin,
  // Investor Profile
  tradeGoalType: '',
  investmentObjectiveType: '',
  annualIncome: '',
  totalNetWorth: '',
  liquidNetWorth: '',
  characteristics: {
    risk: '',
    bracket: '',
    horizon: '',
    liquidity: ''
  },
  // Account Information
  primaryAccountHolder: DefaultAccountHolder,
  ownerCountry: '',
  retirementAccount: undefined,
  simpleIraPlanName: undefined,
  beneficiaryIraDecedentName: undefined,
  jointAccountType: undefined,
  // Agreements & Disclosures
  agreeToElectronicConsent: null,
  deliveryType: DeliveryType.Electronic,
  disclosuresForeignBankUSAgent: '',
  disclosuresEntityNegativeNewsInfo: '',
  disclosuresEntityPublicOfficials: [],
  accountDisclosures: {
    foreignBank: null,
    foreignFinancialInstitution: null,
    proprietarySecuritiesAccount: null,
    foreignShellBank: null,
    entityIssuesBearerShares: null,
    entityNegativeNews: null,
    entityPublicOfficials: null,
    entityServicesForeignShellBanks: null
  },
  agreements: {
    corporateCash: null,
    corporateMargin: null,
    customerAndPrivacyPolicy: null,
    entityMargin: null,
    entityOption: null,
    iRARothAdoption: null,
    iRASepAdoption: null,
    iRASimpleAdoption: null,
    iRACoverDellAdoption: null,
    jointCommunity: null,
    jointSurvivor: null,
    jointTenantsInCommon: null,
    jointTenantsInEntirety: null,
    limitedMargin: null,
    llcAccount: null,
    margin: null,
    option: null,
    riskDisclosure: null,
    electronicConsent: null,
    onlineServices: null,
    exchangeDataAgreement: null,
    clientRelationshipSummary: null,
    apiAgreement: null,
    rqdDisclosure: null,
    rqdClearingDisclosures: null,
    rqdCustomerAgreement: null,
    rqdCustomerInformationBrochure: null,
    rqdExecutionDisclosures: null,
    rqdLimitedMarginAgreement: null,
    rqdLimitedTradingAuthorization: null,
    rqdMargin: null,
    rqdOption: null,
    rqdPowerOfAttorney: null,
    rqdPrivacyNotice: null,
    rqdPrivacyPolicy: null,
    rqdResponsibilitiesOfIbAndCarryingBroker: null,
    rqdSpecialStatementForUncoveredOptionWriters: null
  },
  // Trade Experience
  experience: {
    commodities: {
      years: undefined,
      tradesPerYear: undefined
    },
    bonds: {
      years: undefined,
      tradesPerYear: undefined
    },
    stocks: {
      years: undefined,
      tradesPerYear: undefined
    },
    options: {
      years: undefined,
      tradesPerYear: undefined
    },
    margins: {
      years: undefined,
      tradesPerYear: undefined
    }
  },
  // Market Data Questionnaire
  marketData: {
    employmentFunctions: null,
    personalUse: null,
    secCftcRegistration: null,
    associationRegistration: null,
    jobFunctions: null,
    investmentProfessional: null,
    capitalTrading: null,
    corporationTrading: BooleanToggleType.No,
    benefitsReceived: null
  },
  marketDataType: null,
  // Sign & Submit
  signedNames: [],
  hearAboutUsId: undefined,
  beneficiaries: [],
  foreignDueDiligence: {
    expectedWithdrawalFrequency: '',
    initialContact: '',
    initialDepositAmount: 0,
    initialDepositType: '',
    primaryBanking: [''],
    referredName: '',
    referredRelationship: '',
    referredToBroker: true
  }
};