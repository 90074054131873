import {
  LanguageType,
  UploadedDocument,
  SecurityQuestionType,
  Country,
  InvestigationDocuments,
  UploadedDocumentState,
  AccountType,
  TradingAuthorizationModel,
} from '@tradingblock/types';
import {
  ApplicationType,
  FlexibilityType,
  TradeGoalType,
  InvestmentObjectiveType,
  BooleanToggleType,
  DeliveryType,
  BeneficiaryType,
  EmploymentType,
  ApplicationStep,
  RiskToleranceType,
  TaxbracketType,
  TimeHorizonType,
  LiquidityNeedType,
  VisaType,
  RetirementAccountType,
  MonetaryRangeType,
  JointAccountType,
  YearsEmployed,
  BeneficiaryAccountType,
  PhoneType,
  EntityAccountType,
  MismatchedDataType,
} from './';
import {
  BusinessNature,
  AccountActivityType,
  SourceOfFundsType,
  WithdrawalFrequencyType,
  ExperienceYearType,
  MaritalStatusType,
  IncomeRangeType,
  TradeExperienceYearType,
  IssuerDirectCommunicationType,
  ProSubscriptionType,
  ClearerType,
} from './Enums';

export type AccountDisclosureKey =
  | 'entityIssuesBearerShares'
  | 'entityNegativeNews'
  | 'entityPublicOfficials'
  | 'entityServicesForeignShellBanks'
  | 'foreignBank'
  | 'foreignFinancialInstitution'
  | 'foreignShellBank'
  | 'proprietarySecuritiesAccount';

export const AllAccountDisclosureKeys: AccountDisclosureKey[] = [
  'foreignBank',
  'foreignShellBank',
  'foreignFinancialInstitution',
  'proprietarySecuritiesAccount',
];
export const AllEntityAccountDisclosureKeys: AccountDisclosureKey[] = [
  'entityIssuesBearerShares',
  'entityServicesForeignShellBanks',
];
export const AllEntityNonTrustAccountDisclosureKeys: AccountDisclosureKey[] = [
  'entityNegativeNews',
  'entityPublicOfficials',
];

export type AccountHolderDisclosureKey = 'industryEmployed' | 'stakeholder' | 'irsWithholding' | 'govOfficial';
export const AllAccountHolderDisclosureKeys: AccountHolderDisclosureKey[] = [
  'stakeholder',
  'industryEmployed',
  'govOfficial',
  'irsWithholding',
];

export type ServiceProfileKey = 'issuerDirectCommunication';
export const AllServiceProfileKeys: ServiceProfileKey[] = ['issuerDirectCommunication'];

export type MarketDataQuestionnaireKey =
  | 'personalUse'
  | 'secCftcRegistration'
  | 'associationRegistration'
  | 'jobFunctions'
  | 'investmentProfessional'
  | 'capitalTrading'
  | 'corporationTrading'
  | 'benefitsReceived';
export const AllMarketDataRegulatoryKeys: MarketDataQuestionnaireKey[] = [
  'secCftcRegistration',
  'associationRegistration',
  'jobFunctions',
];
export const AllMarketDataInvestmentProKeys: MarketDataQuestionnaireKey[] = ['investmentProfessional'];
export const AllMarketDataCapitalTradingKeys: MarketDataQuestionnaireKey[] = ['capitalTrading'];
export const AllMarketDataBenefitsKeys: MarketDataQuestionnaireKey[] = ['benefitsReceived'];

export type AgreementKey =
  | 'corporateCash'
  | 'corporateMargin'
  | 'customerAndPrivacyPolicy'
  | 'entityMargin'
  | 'entityOption'
  | 'iRARothAdoption'
  | 'iRASepAdoption'
  | 'iRASimpleAdoption'
  | 'iRACoverDellAdoption'
  | 'jointCommunity'
  | 'jointSurvivor'
  | 'jointTenantsInCommon'
  | 'jointTenantsInEntirety'
  | 'limitedMargin'
  | 'llcAccount'
  | 'margin'
  | 'option'
  | 'riskDisclosure'
  | 'electronicConsent'
  | 'onlineServices'
  | 'exchangeDataAgreement'
  | 'clientRelationshipSummary'
  | 'apiAgreement'
  | 'rqdCustomerAgreement'
  | 'rqdOption'
  | 'rqdMargin'
  | 'rqdLimitedMargin'
  | 'rqdExecutionDisclosures'
  | 'rqdClearingDisclosures'
  | 'rqdCustomerInformationBrochure'
  | 'rqdPrivacyPolicy'
  | 'rqdPrivacyNotice'
  | 'rqdResponsibilitiesOfIbAndCarryingBroker'
  | 'rqdSpecialStatementForUncoveredOptionWriters'
  | 'rqdPowerOfAttorney'
  | 'rqdLimitedTradingAuthorization';

export type CharacteristicsKey = 'bracket' | 'horizon' | 'liquidity' | 'risk';
export const AllCharacteristicsKeys: CharacteristicsKey[] = ['risk', 'bracket', 'horizon', 'liquidity'];

export type ExperienceKey = 'bonds' | 'commodities' | 'margins' | 'options' | 'stocks';
export const AllExperienceKeys: ExperienceKey[] = ['stocks', 'options', 'commodities', 'bonds', 'margins'];

export type UploadKey =
  | 'articlesOfIncorporation'
  | 'articlesOfOrganization'
  | 'partnershipAgreement'
  | 'photoId'
  | 'socialSecurity'
  | 'trusteeCertification'
  | 'visa';

export type UploadDocumentTag = 'AffiliatedApproval' | 'Document' | 'IdDocument' | 'Other' | 'SignatureImage';

export type DataValue = string | number | boolean | null | undefined;

export interface AddressModel {
  country: string;
  address1: string;
  address2: string;
  postalCode: string | null;
  city: string;
  state: string | null;
}

export interface DateModel {
  month?: number;
  day?: number;
  year?: number;
}

export interface ExperienceModel {
  years?: ExperienceYearType;
  tradesPerYear?: TradeExperienceYearType;
}

export interface PhoneModel {
  type: PhoneType | '';
  phoneNumber: string;
}

export interface TradingBlockAccountModel {
  accountNumber: string;
  accountName: string;
}

export interface UploadModel {
  documentIds?: string[];
  documents?: UploadedDocument[];
  skip?: boolean;
}
export interface PersonModel {
  firstName: string;
  lastName: string;
  address: AddressModel;
  email: string;
  phones: PhoneModel[];
  middleInitial: string;
  suffix: string;
}

export interface ForeignDueDiligenceModel {
  expectedWithdrawalFrequency: string;
  initialContact: string;
  initialDepositAmount?: number;
  initialDepositType?: string;
  primaryBanking: string[];
  referredName: string;
  referredRelationship: string;
  referredToBroker: boolean;
}

export interface PersonDetailsModel extends PersonModel {
  dateOfBirth: DateModel;
  ssn: string;
}

export interface AccountHolderModel extends PersonDetailsModel {
  // edited on secure account step
  emailConfirm: string;
  // edited on account information step
  differentMailingAddress: boolean | null;
  mailingAddress: AddressModel | null;
  maritalStatus: MaritalStatusType | '';
  dependents: number | '';
  citizenshipCountry: string | null;
  birthCountry: string | null;
  visaType: VisaType | null;
  visaExpirationDate: DateModel | null;
  employmentType: EmploymentType | '';
  jobPosition: string | null;
  yearsEmployed: YearsEmployed | null;
  employer: string | null;
  employerAddress: AddressModel | null;
  // edited on agreements step
  disclosures:
    | {
        [key in AccountHolderDisclosureKey]?: BooleanToggleType;
      }
    | {
        govOfficial: BooleanToggleType | null;
        stakeholder: BooleanToggleType | null;
        industryEmployed: BooleanToggleType | null;
        irsWithholding: BooleanToggleType | null;
      };
  disclosuresStakeholderTickerSymbol: string;
  disclosuresIndustryEmployedFirmName: string;
  disclosuresGovOfficialImmediateFamily: string;
  disclosuresGovOfficialPoliticalOrg: string;
  jointTenantsInCommonInterestPercent: number | null;
  foreignTaxWithholding: {
    taxIdentificationNumber: string | null;
    letterOfExplanation: string | null;
    jurisdictionDoesNotIssueTIN: BooleanToggleType | boolean | null;
    notIssuedForeignTIN: BooleanToggleType | boolean | null;
    treatyCountry: string | null;
    foreignTINNotRequiredExplanation: string | null;
  };
}

export interface BeneficiaryModel extends Exclude<PersonDetailsModel, 'email' | 'phones'> {
  beneficiaryAccountType: BeneficiaryAccountType | '';
  beneficiaryType: BeneficiaryType | '';
  relationship?: string;
  trustName?: string;
  ein?: string;
  percent?: number | null;
}

export interface OwnerOfficerModel extends Exclude<PersonDetailsModel, 'email' | 'phones'> {
  isOfficer?: boolean;
  isOwner?: boolean;
}

export interface OwnerEntityModel {
  entityName: string;
  entityTaxId: string;
  trustCreatedDate?: DateModel;
  entityYourTitle?: string;
  usStateOfRegistration?: string;
  businessNature?: BusinessNature;
  address?: AddressModel;
  businessPhone?: string;
  officeLocations?: string;
  accountActivity?: AccountActivityType;
  initialDepositAmount?: number;
  initialDepositMethod?: string;
  primarySourceOfFunds?: SourceOfFundsType;
  expectedWithdrawalFrequency?: WithdrawalFrequencyType;
  primaryBankName?: string;
  hasTradingBlockAccounts?: boolean;
  tradingBlockAccounts?: TradingBlockAccountModel[];
  // edited on sign step
  secretaryName: string;
}

export interface PublicOfficialModel {
  name: string;
  role: string;
  title: string;
  organization: string;
  familyNames: string;
}

export interface SecurityChallengeModel {
  securityQuestionType: SecurityQuestionType | '';
  answer: string;
}

export interface ApplicationModel {
  // mongo storage fields
  _id?: string;
  objectKey?: string;
  createdOn?: string;

  // application status
  latestStepSaved?: ApplicationStep;
  latestStepVisibleSections?: number;
  latestStepCompleted?: ApplicationStep;
  lastSavedOn?: Date;
  applicationSentOn?: Date;
  // accountId will only be available after account has been successfully created
  accountId?: number;
  clearer: ClearerType | null;
  confirmedMismatchedDataTypes?: MismatchedDataType[] | null;

  // Secure Account
  languageType: LanguageType;
  // firstName / lastName / email stored on primaryAccountHolder
  hearAboutUsId?: number | string;
  repId?: number | string;
  userName: string;
  password: string;
  passwordConfirm: string;
  securityChallenges: SecurityChallengeModel[];

  // Account Type
  ownerCountry?: string | null;
  type: ApplicationType;
  retirementAccount?: BooleanToggleType | null;
  retirementAccountType?: RetirementAccountType | null;
  simpleIraPlanName?: string;
  beneficiaryIraDecedentName?: string;
  jointAccountType?: JointAccountType | null;
  jointCommunityPropertyState?: string | null;
  entityAccountType?: EntityAccountType | null;
  flexibilityType: FlexibilityType;

  // Investor Profile
  tradeGoalType: TradeGoalType | '';
  investmentObjectiveType: InvestmentObjectiveType | '';
  experience?:
    | {
        [key in ExperienceKey]: ExperienceModel;
      }
    | null;
  annualIncome: IncomeRangeType | '';
  totalNetWorth: MonetaryRangeType | '';
  liquidNetWorth: MonetaryRangeType | '';
  characteristics: {
    risk: RiskToleranceType | '';
    bracket: TaxbracketType | '';
    horizon: TimeHorizonType | '';
    liquidity: LiquidityNeedType | '';
  };

  // Account Information
  primaryAccountHolder: AccountHolderModel;
  secondaryAccountHolder?: AccountHolderModel | null;
  entity?: OwnerEntityModel | null;

  // Beneficiaries & Users
  beneficiaries: BeneficiaryModel[] | null;
  ownersOfficers?: OwnerOfficerModel[] | null;
  addTrustedContact?: boolean;
  trustedContact?: PersonModel | null;

  // Agreements & Disclosures
  agreeToElectronicConsent?: boolean | null;
  deliveryType: DeliveryType;
  tradeAuthorization?: BooleanToggleType | null;
  tradingAuthorization?: TradingAuthorizationModel | null;
  // account holder disclosures stored in AccountHolderModel
  accountDisclosures?:
    | {
        [key in AccountDisclosureKey]?: BooleanToggleType;
      }
    | {
        foreignBank: BooleanToggleType | null;
        foreignShellBank: BooleanToggleType | null;
        foreignFinancialInstitution: BooleanToggleType | null;
        proprietarySecuritiesAccount: BooleanToggleType | null;
        entityIssuesBearerShares: BooleanToggleType | null;
        entityNegativeNews: BooleanToggleType | null;
        entityPublicOfficials: BooleanToggleType | null;
        entityServicesForeignShellBanks: BooleanToggleType | null;
      };
  serviceProfile?:
    | {
        [key in ServiceProfileKey]?: IssuerDirectCommunicationType;
      }
    | {
        issuerDirectCommunication: IssuerDirectCommunicationType | null;
      };
  disclosuresForeignBankUSAgent: string;
  disclosuresEntityNegativeNewsInfo: string;
  disclosuresEntityPublicOfficials: PublicOfficialModel[];
  agreements:
    | {
        [key in AgreementKey]?: BooleanToggleType;
      }
    | {
        corporateCash: BooleanToggleType | null;
        corporateMargin: BooleanToggleType | null;
        customerAndPrivacyPolicy: BooleanToggleType | null;
        entityMargin: BooleanToggleType | null;
        entityOption: BooleanToggleType | null;
        iRARothAdoption: BooleanToggleType | null;
        iRASepAdoption: BooleanToggleType | null;
        iRASimpleAdoption: BooleanToggleType | null;
        iRACoverDellAdoption: BooleanToggleType | null;
        jointCommunity: BooleanToggleType | null;
        jointSurvivor: BooleanToggleType | null;
        jointTenantsInCommon: BooleanToggleType | null;
        jointTenantsInEntirety: BooleanToggleType | null;
        limitedMargin: BooleanToggleType | null;
        llcAccount: BooleanToggleType | null;
        margin: BooleanToggleType | null;
        option: BooleanToggleType | null;
        riskDisclosure: BooleanToggleType | null;
        electronicConsent: BooleanToggleType | null;
        onlineServices: BooleanToggleType | null;
        exchangeDataAgreement: BooleanToggleType | null;
        clientRelationshipSummary: BooleanToggleType | null;
        apiAgreement: BooleanToggleType | null;
        rqdCustomerAgreement: BooleanToggleType | null;
        rqdOption: BooleanToggleType | null;
        rqdMargin: BooleanToggleType | null;
        rqdLimitedMargin: BooleanToggleType | null;
        rqdExecutionDisclosures: BooleanToggleType | null;
        rqdClearingDisclosures: BooleanToggleType | null;
        rqdCustomerInformationBrochure: BooleanToggleType | null;
        rqdPrivacyPolicy: BooleanToggleType | null;
        rqdPrivacyNotice: BooleanToggleType | null;
        rqdResponsibilitiesOfIbAndCarryingBroker: BooleanToggleType | null;
        rqdSpecialStatementForUncoveredOptionWriters: BooleanToggleType | null;
        rqdPowerOfAttorney: BooleanToggleType | null;
        rqdLimitedTradingAuthorization: BooleanToggleType | null;
      };
  agreeToAll: BooleanToggleType | null;

  // Foreign Due Diligence
  foreignDueDiligence?: ForeignDueDiligenceModel | null;

  // Sign & Submit
  signedNames: string[];
  isPartialEntitySubmission?: boolean | null;

  // Market Data Questionnaire
  marketData: {
    employmentFunctions: string | null;
    personalUse: BooleanToggleType | null;
    secCftcRegistration: BooleanToggleType | null;
    associationRegistration: BooleanToggleType | null;
    jobFunctions: BooleanToggleType | null;
    investmentProfessional: BooleanToggleType | null;
    capitalTrading: BooleanToggleType | null;
    corporationTrading: BooleanToggleType | null;
    benefitsReceived: BooleanToggleType | null;
  };
  marketDataType: ProSubscriptionType | null;
}

type AccountDisclosureContingentField = keyof Pick<
  ApplicationModel,
  'disclosuresEntityNegativeNewsInfo' | 'disclosuresEntityPublicOfficials' | 'disclosuresForeignBankUSAgent'
>;

export const AccountDisclosureContingentFields: Partial<
  { [key in AccountDisclosureKey]: AccountDisclosureContingentField[] }
> = {
  entityNegativeNews: ['disclosuresEntityNegativeNewsInfo'],
  entityPublicOfficials: ['disclosuresEntityPublicOfficials'],
  foreignBank: ['disclosuresForeignBankUSAgent'],
};

type AccountHolderDisclosureContingentField = keyof Pick<
  AccountHolderModel,
  | 'disclosuresGovOfficialImmediateFamily'
  | 'disclosuresGovOfficialPoliticalOrg'
  | 'disclosuresIndustryEmployedFirmName'
  | 'disclosuresStakeholderTickerSymbol'
>;

export const AccountHolderDisclosureContingentFields: Partial<
  { [key in AccountHolderDisclosureKey]: AccountHolderDisclosureContingentField[] }
> = {
  govOfficial: ['disclosuresGovOfficialImmediateFamily', 'disclosuresGovOfficialPoliticalOrg'],
  industryEmployed: ['disclosuresIndustryEmployedFirmName'],
  stakeholder: ['disclosuresStakeholderTickerSymbol'],
};

export type AccountHolderField = keyof Pick<ApplicationModel, 'primaryAccountHolder' | 'secondaryAccountHolder'>;

export const DefaultOwnerEntityModel: OwnerEntityModel = {
  entityName: '',
  entityTaxId: '',
  secretaryName: '',
};

export const DefaultTradingBlockAccount: TradingBlockAccountModel = {
  accountNumber: '',
  accountName: '',
};

export const DefaultPhone: PhoneModel = {
  type: '',
  phoneNumber: '',
};

export const DefaultAddress: AddressModel = {
  country: '',
  address1: '',
  address2: '',
  postalCode: null,
  city: '',
  state: null,
};

export const DefaultPersonDetails: PersonDetailsModel = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  suffix: '',
  email: '',
  dateOfBirth: {
    day: undefined,
    month: undefined,
    year: undefined,
  },
  ssn: '',
  address: DefaultAddress,
  phones: [
    DefaultPhone, // needed to show initial blank phone
  ],
};

export const DefaultTradingAuthorization: TradingAuthorizationModel = {
  address: DefaultAddress,
  phones: [
    DefaultPhone, // needed to show initial blank phone
  ],
  email: undefined,
  dateOfBirth: {
    day: undefined,
    month: undefined,
    year: undefined,
  },
  employmentType: undefined,
  employer: '',
  ssnSecret: '',
  firstName: '',
  lastName: '',
  disclosures: {
    industryEmployed: undefined,
    stakeholder: undefined,
  },
  signedName: '',
  disclosuresIndustryEmployedFirmName: undefined,
  disclosuresStakeholderTickerSymbol: undefined,
  type: undefined,
  citizenshipCountry: Country.UnitedStatesOfAmerica,
};

export const DefaultAccountHolder: AccountHolderModel = {
  ...DefaultPersonDetails,
  emailConfirm: '',
  maritalStatus: '',
  dependents: '',
  birthCountry: null,
  citizenshipCountry: null,
  employmentType: '',
  disclosuresStakeholderTickerSymbol: '',
  disclosuresIndustryEmployedFirmName: '',
  disclosuresGovOfficialImmediateFamily: '',
  disclosuresGovOfficialPoliticalOrg: '',
  jointTenantsInCommonInterestPercent: 0,
  disclosures: {
    govOfficial: null,
    industryEmployed: null,
    irsWithholding: null,
    stakeholder: null,
  },
  differentMailingAddress: false,
  mailingAddress: null,
  employer: '',
  employerAddress: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: null,
    postalCode: null,
  },
  visaType: null,
  visaExpirationDate: null,
  jobPosition: '',
  yearsEmployed: null,
  foreignTaxWithholding: {
    taxIdentificationNumber: null,
    letterOfExplanation: null,
    jurisdictionDoesNotIssueTIN: null,
    notIssuedForeignTIN: null,
    treatyCountry: null,
    foreignTINNotRequiredExplanation: null,
  },
};

export const DefaultSecondaryAccountHolder: AccountHolderModel = {
  ...DefaultPersonDetails,
  emailConfirm: '',
  maritalStatus: '',
  dependents: '',
  birthCountry: Country.UnitedStatesOfAmerica,
  citizenshipCountry: Country.UnitedStatesOfAmerica,
  employmentType: '',
  disclosuresStakeholderTickerSymbol: '',
  disclosuresIndustryEmployedFirmName: '',
  disclosuresGovOfficialImmediateFamily: '',
  disclosuresGovOfficialPoliticalOrg: '',
  jointTenantsInCommonInterestPercent: 0,
  disclosures: {
    govOfficial: null,
    industryEmployed: null,
    irsWithholding: null,
    stakeholder: null,
  },
  differentMailingAddress: false,
  mailingAddress: null,
  employer: '',
  employerAddress: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: null,
    postalCode: null,
  },
  visaType: null,
  visaExpirationDate: null,
  jobPosition: '',
  yearsEmployed: null,
  foreignTaxWithholding: {
    taxIdentificationNumber: null,
    letterOfExplanation: null,
    jurisdictionDoesNotIssueTIN: null,
    notIssuedForeignTIN: null,
    treatyCountry: null,
    foreignTINNotRequiredExplanation: null,
  },
};

export const DefaultUgmaAccountHolder: AccountHolderModel = {
  ...DefaultPersonDetails,
  emailConfirm: '',
  maritalStatus: '',
  dependents: '',
  birthCountry: Country.UnitedStatesOfAmerica,
  citizenshipCountry: Country.UnitedStatesOfAmerica,
  employmentType: '',
  disclosuresStakeholderTickerSymbol: '',
  disclosuresIndustryEmployedFirmName: '',
  disclosuresGovOfficialImmediateFamily: '',
  disclosuresGovOfficialPoliticalOrg: '',
  jointTenantsInCommonInterestPercent: 0,
  disclosures: {
    govOfficial: BooleanToggleType.No,
    industryEmployed: BooleanToggleType.No,
    irsWithholding: BooleanToggleType.No,
    stakeholder: BooleanToggleType.No,
  },
  differentMailingAddress: false,
  mailingAddress: null,
  employer: '',
  employerAddress: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: null,
    postalCode: null,
  },
  visaType: null,
  visaExpirationDate: null,
  jobPosition: '',
  yearsEmployed: null,
  foreignTaxWithholding: {
    taxIdentificationNumber: null,
    letterOfExplanation: null,
    jurisdictionDoesNotIssueTIN: null,
    notIssuedForeignTIN: null,
    treatyCountry: null,
    foreignTINNotRequiredExplanation: null,
  },
};

export const DefaultForeignDueDiligence: ForeignDueDiligenceModel = {
  expectedWithdrawalFrequency: '',
  initialContact: '',
  initialDepositAmount: 0,
  initialDepositType: '',
  primaryBanking: [''],
  referredName: '',
  referredRelationship: '',
  referredToBroker: true,
};

export interface ForeignTaxWithholdingModel {
  taxIdentificationNumber: string | null;
  letterOfExplanation: string | null;
  jurisdictionDoesNotIssueTIN: BooleanToggleType | null;
  notIssuedForeignTIN: BooleanToggleType | null;
  treatyCountry: string | null;
}

export const DefaultForeignTaxWithholding: ForeignTaxWithholdingModel = {
  taxIdentificationNumber: null,
  letterOfExplanation: null,
  jurisdictionDoesNotIssueTIN: null,
  notIssuedForeignTIN: null,
  treatyCountry: null,
};

export const DefaultPublicOfficial: PublicOfficialModel = {
  name: '',
  role: '',
  title: '',
  organization: '',
  familyNames: '',
};

export const DefaultSecurityChallenge: SecurityChallengeModel = {
  securityQuestionType: '',
  answer: '',
};

export const DefaultPublicOfficials: PublicOfficialModel[] = [DefaultPublicOfficial];

export const AccountDisclosureContingentFieldsDefaults: Partial<
  { [key in AccountDisclosureContingentField]: PublicOfficialModel[] }
> = {
  disclosuresEntityPublicOfficials: DefaultPublicOfficials,
};

export const DefaultApplicationModel: ApplicationModel = {
  // Secure Account
  languageType: LanguageType.English,
  userName: '',
  password: '',
  passwordConfirm: '',
  securityChallenges: [DefaultSecurityChallenge, DefaultSecurityChallenge, DefaultSecurityChallenge],

  // Account Type
  type: ApplicationType.Individual,
  flexibilityType: FlexibilityType.Margin,
  clearer: null,

  // Investor Profile
  tradeGoalType: '',
  investmentObjectiveType: '',
  annualIncome: '',
  totalNetWorth: '',
  liquidNetWorth: '',
  characteristics: {
    risk: '',
    bracket: '',
    horizon: '',
    liquidity: '',
  },

  // Account Information
  primaryAccountHolder: DefaultAccountHolder,
  ownerCountry: '',
  retirementAccount: undefined,
  simpleIraPlanName: undefined,
  beneficiaryIraDecedentName: undefined,
  jointAccountType: undefined,

  // Agreements & Disclosures
  agreeToElectronicConsent: null,
  agreeToAll: null,
  deliveryType: DeliveryType.Electronic,
  disclosuresForeignBankUSAgent: '',
  disclosuresEntityNegativeNewsInfo: '',
  disclosuresEntityPublicOfficials: [],
  accountDisclosures: {
    foreignBank: null,
    foreignFinancialInstitution: null,
    proprietarySecuritiesAccount: null,
    foreignShellBank: null,
    entityIssuesBearerShares: null,
    entityNegativeNews: null,
    entityPublicOfficials: null,
    entityServicesForeignShellBanks: null,
  },
  agreements: {
    corporateCash: null,
    corporateMargin: null,
    customerAndPrivacyPolicy: null,
    entityMargin: null,
    entityOption: null,
    iRARothAdoption: null,
    iRASepAdoption: null,
    iRASimpleAdoption: null,
    iRACoverDellAdoption: null,
    jointCommunity: null,
    jointSurvivor: null,
    jointTenantsInCommon: null,
    jointTenantsInEntirety: null,
    limitedMargin: null,
    llcAccount: null,
    margin: null,
    option: null,
    riskDisclosure: null,
    electronicConsent: null,
    onlineServices: null,
    exchangeDataAgreement: null,
    clientRelationshipSummary: null,
    apiAgreement: null,
    rqdCustomerAgreement: null,
    rqdOption: null,
    rqdMargin: null,
    rqdLimitedMargin: null,
    rqdExecutionDisclosures: null,
    rqdClearingDisclosures: null,
    rqdCustomerInformationBrochure: null,
    rqdPrivacyPolicy: null,
    rqdPrivacyNotice: null,
    rqdResponsibilitiesOfIbAndCarryingBroker: null,
    rqdSpecialStatementForUncoveredOptionWriters: null,
    rqdPowerOfAttorney: null,
    rqdLimitedTradingAuthorization: null,
  },

  // Trade Experience
  experience: {
    commodities: {
      years: undefined,
      tradesPerYear: undefined,
    },
    bonds: {
      years: undefined,
      tradesPerYear: undefined,
    },
    stocks: {
      years: undefined,
      tradesPerYear: undefined,
    },
    options: {
      years: undefined,
      tradesPerYear: undefined,
    },
    margins: {
      years: undefined,
      tradesPerYear: undefined,
    },
  },

  // Market Data Questionnaire
  marketData: {
    employmentFunctions: null,
    personalUse: null,
    secCftcRegistration: null,
    associationRegistration: null,
    jobFunctions: null,
    investmentProfessional: null,
    capitalTrading: null,
    corporationTrading: BooleanToggleType.No,
    benefitsReceived: null,
  },
  marketDataType: null,

  // Sign & Submit
  signedNames: [],
  isPartialEntitySubmission: null,
  hearAboutUsId: undefined,
  repId: undefined,
  beneficiaries: [],
  foreignDueDiligence: {
    expectedWithdrawalFrequency: '',
    initialContact: '',
    initialDepositAmount: 0,
    initialDepositType: '',
    primaryBanking: [''],
    referredName: '',
    referredRelationship: '',
    referredToBroker: true,
  },
};

export interface ApplicationInvestigationUploadedDocument {
  miscDocumentId: number;
  documentName: InvestigationDocuments;
  state: UploadedDocumentState;
  description?: string;
  fileName?: string;
  originalFileName?: string;
  takenOn?: string;
  uploadDateTime?: string;
  uploadUserName?: string;
}

export interface DuplicateAccountCheckRequest {
  accountIdToIgnore?: number;
  entityAccountType?: EntityAccountType;
  flexibilityType: FlexibilityType;
  primaryTaxIdSecret?: string;
  retirementAccountType?: RetirementAccountType | null;
  secondaryTaxIdSecret?: string;
  type: ApplicationType;
}

export interface DuplicateAccountCheckResponse {
  [key: string]: string[] | string;
}

export interface EntityPartialSubmissionModel {
  type: AccountType;
  languageType: LanguageType;
  entityAccountType: EntityAccountType;
  deliveryType: DeliveryType;
  flexibilityType: FlexibilityType;
  tradeGoalType: TradeGoalType;
  heardAbout: {
    heardAboutUsId: number;
    officeCode: string;
    repId: number;
  };
  primaryAccountHolder: {
    email: string;
    firstName: string;
    lastName: string;
  };
}
