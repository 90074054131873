import React, { useMemo } from 'react';
import { GetSiteName } from '../../utilities/SiteUtilities';
export var SiteName = function SiteName(_ref) {
  var accountNumber = _ref.accountNumber,
      siteGroup = _ref.siteGroup,
      origin = _ref.origin;
  var siteName = useMemo(function () {
    return GetSiteName(siteGroup || "tb", origin, accountNumber);
  }, [accountNumber, siteGroup, origin]);
  return React.createElement(React.Fragment, null, siteName);
};