import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import _ from 'lodash';
import { Section, Text } from '@tradingblock/components';
import { useDispatcher } from '../components/hooks';
import { ApplicationActions } from '../state/actions';
import { useStore } from '../context';
import { useData } from '../state/selectors';
import { PageTitle, ApplicationLoader, Errors } from '../components';
import { DefaultApplicationModel, ApplicationStep, ApplicationType } from '../types';
import { getApplicationStepUrl } from '../services';
import config from '../config';

const HomePage: React.FC = () => {
  const storage = useStore();
  const dispatch = useDispatcher();

  const authToken = useData(d => d.ui.apiToken);
  const application = useData(d => d.data.application);
  const { isSaving, saveType } = useData(d => d.data.saveInfo);
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;

  const showReset = authToken && /localhost/.test(window.location.host);

  const onResetApplication = () => {
    console.warn('HomePage :: clearing application...');
    const emptyApplication = { ..._.pick(application, 'objectKey'), ...DefaultApplicationModel };
    dispatch(ApplicationActions.requestSaveApplication({ saveType: 'unload', application: emptyApplication, storage }));
  };

  if (config.isVirtual) {
    return <Redirect to={getApplicationStepUrl(ApplicationStep.SecureAccount)} />;
  }

  // isNewOrContinue is a boolean that checks if the application is new or a continuation of an existing application
  // It is true if:
  // - application is falsy (null, undefined, etc.)
  // - application is truthy, application.accountId is falsy and application.type is ApplicationType.Entity and application.isPartialEntitySubmission is falsy
  // - application is an empty object
  const isNewOrContinue =
    !application ||
    (!(application.accountId && application.type === ApplicationType.Entity) &&
      !application.isPartialEntitySubmission) ||
    _.isEmpty(application);

  const isSubmitted = application && !_.isNil(application.applicationSentOn);

  // isCheckStatus is a boolean that checks if the application is eligible for status check
  // It is true if:
  // - application is truthy (not null, undefined, etc.)
  // - application.accountId is not null or undefined
  // - application.type is ApplicationType.Entity and application.isPartialEntitySubmission is truthy
  // The Boolean() function ensures that isCheckStatus is always either true or false, never undefined
  const isCheckStatus = Boolean(
    application &&
      (!_.isNil(application.accountId) ||
        (application.type === ApplicationType.Entity && application.isPartialEntitySubmission))
  );

  return (
    <>
      <ApplicationLoader allowAnonymous empty />
      <PageTitle page="home" />
      <Section>
        <div className="home-title-section container-fluid">
          <div className="row">
            <h1>
              <Text id="header" page="home" siteGroup={siteGrp} />
            </h1>
          </div>
          <div className="row">
            <Text id="description1" page="home" />
          </div>
          <div className="row">
            <Text id="description2" page="home" />
          </div>
          {/* <h1>
            <Text id="title" page="home" siteGroup={siteGrp} />
          </h1>
          <span>
            <Text id="description1" page="home" />
          </span>
          <span>
            <Text id="description2" page="home" />
          </span> */}
        </div>

        {!authToken && (
          <p>
            <Link className="btn btn-block btn-primary btn-icon" to={siteGrp ? `/auth/?grp=${siteGrp}` : '/auth'}>
              <Text id="signIn" page="home" />
            </Link>
          </p>
        )}
        {isNewOrContinue && !isSubmitted && (
          <p>
            <Link
              className="btn btn-block btn-primary btn-icon"
              to={getApplicationStepUrl(
                authToken && !_.isEmpty(application) ? 'summary' : ApplicationStep.SecureAccount,
                siteGrp
              )}
            >
              <Text id={authToken ? 'continue' : 'startNewApplication'} page="home" />
            </Link>
          </p>
        )}
        {isCheckStatus && (
          <p>
            <Link className="btn btn-block btn-primary btn-icon" to="/status">
              <Text id="checkStatus" page="home" />
            </Link>
          </p>
        )}
        {showReset && (
          <>
            <p>
              <button className="btn btn-block btn-primary" type="button" onClick={onResetApplication}>
                Reset application (dev environment testing only)
              </button>
            </p>
            {!isSaving && saveType === 'unload' && (
              <p>
                <strong>Application has been reset!</strong>
              </p>
            )}
          </>
        )}
        {authToken && (
          <p>
            <Link className="btn btn-block btn-outline-dark" to="/logout">
              <Text id="btns.sign-out" />
            </Link>
          </p>
        )}
      </Section>
    </>
  );
};

export default HomePage;
