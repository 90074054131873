import _ from 'lodash';
const isTruthyValue = (value: string | undefined) =>
  value && ['1', 'true', 'True', 'TRUE', 'yes', true].includes(value) ? true : false;
export const config = {
  userPoolId: process.env.REACT_APP_USER_POOL_ID, // no longer used
  userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // no longer used
  dataUrl: process.env.REACT_APP_DATA_URL,
  authRedirect: process.env.REACT_APP_APPLICATION_AUTH_REDIRECT,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  tradingApi: process.env.REACT_APP_TRADING_API_URL,
  dashboardUrl: process.env.REACT_APP_DASHBOARD_URL,
  gaUaTrackingCode: process.env.REACT_APP_GA_UA_TRACKING_CODE_APPLICATION || '',
  ga4TrackingCode: process.env.REACT_APP_GA_TRACKING_ID || '',
  ga4VTTrackingCode: process.env.REACT_APP_VT_GA_TRACKING_ID || '',
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  isVirtual: isTruthyValue(process.env.REACT_APP_IS_VIRTUAL) as boolean,
  publicDir: process.env.TB_PUBLIC_DIR as string,
  subdirectory: (process.env.REACT_APP_TB_SUBDIRECTORY as string)
    ? (process.env.REACT_APP_TB_SUBDIRECTORY as string)
    : '',
  uspsId: process.env.REACT_APP_USPS_ID as string,
  uspsSecret: process.env.REACT_APP_USPS_SECRET as string,
  uspsBaseUrl: process.env.REACT_APP_USPS_BASE_URL as string,
};
type EnvConfigType = typeof config;
export default config;
