export var LanguageType;

(function (LanguageType) {
  LanguageType["Chinese"] = "zh";
  LanguageType["English"] = "en";
  LanguageType["Spanish"] = "es";
})(LanguageType || (LanguageType = {}));

export var DefaultLanguageKeys;

(function (DefaultLanguageKeys) {
  DefaultLanguageKeys["Error"] = "error";
  DefaultLanguageKeys["Label"] = "label";
  DefaultLanguageKeys["Text"] = "text";
})(DefaultLanguageKeys || (DefaultLanguageKeys = {}));