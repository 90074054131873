/* eslint-disable @typescript-eslint/camelcase */
import _ from 'lodash';
export var SuccessResponseCode = 0;
export var APINotAuthorizedResponseCode = 200;
export var APIAgreementResponseCode = 202;
export var APIAgreementResponseUpdateCode = 207;
export var NoAvailableSecurityChallengesResponseCode = 208;
export var SecurityChallengeFailedResponseCode = 209;
export var GenericErrorResponseCode = 99;
export var ApplicationDuplicateUsernameResponseCode = 600;
export var ApplicationDuplicateAccountTypeSSNResponseCode = 211;
export var ResponseCodes = {
  0: {
    name: 'Success',
    description: 'Request was successful'
  },
  98: {
    name: 'InvalidRequestParameters',
    description: 'One or more request parameters are invalid for this type of request'
  },
  99: {
    name: 'GenericError',
    description: 'Generic failure response for request'
  },
  100: {
    name: 'IncorrectUserOrPass',
    description: 'Username or password supplied when logging in was incorrect'
  },
  101: {
    name: 'FailedToDecryptUserPass',
    description: 'Password supplied could not be decrypted'
  },
  102: {
    name: 'FailedToDecryptMessage',
    description: 'Message supplied could not be decrypted'
  },
  103: {
    name: 'IncorrectEntity',
    description: 'Entity name supplied is incorrect'
  },
  104: {
    name: 'ExpiredRequest',
    description: 'Timestamp in login request is expired'
  },
  105: {
    name: 'FailedToParsePayload',
    description: 'Could not parse payload into correct parts'
  },
  106: {
    name: 'MessageIsNull',
    description: 'Message supplied was null'
  },
  107: {
    name: 'FailedToSplitMessage',
    description: 'Could not split message into required JWS parts'
  },
  108: {
    name: 'FailedToDecodeHeader',
    description: 'Could not decode header in JWS'
  },
  109: {
    name: 'FailedToDecodePayload',
    description: 'Could not decode payload in JWS'
  },
  110: {
    name: 'TooManyFailedLoginAttempts',
    description: 'There were too many unsuccessful login attempts for the given user'
  },
  111: {
    name: 'EndUserPasswordDoesNotMeetSecurityRequirements',
    description: "End user's password does not meet our current security requirements; password needs to be changed"
  },
  112: {
    name: 'EndUserPasswordIsTemporaryAndNeedsToBeChanged',
    description: "End user's password is a temporary password; password needs to be changed"
  },
  113: {
    name: 'EndUserPasswordIsExpired',
    description: 'Your password has expired as part of our annual security measures to keep your account safe. Please click the "Reset password" button below and follow the on-screen instructions to set a new password.'
  },
  114: {
    name: 'EndUserHasNoSecurityQuestions',
    description: 'Please contact Account Services at <a href="tel:18004940451">1-800-494-0451</a> to reset your login credentials.'
  },
  115: {
    name: 'FailedToChangePassword',
    description: "Failed to change user's password. Please contact Account Services at <a href=\"tel:18004940451\">1-800-494-0451</a> for more assistance."
  },
  116: {
    name: 'FailedToRegisterForNoPass',
    description: "Failed to register for NoPass. Please contact Account Services at <a href=\"tel:18004940451\">1-800-494-0451</a> for more assistance."
  },
  117: {
    name: 'FailedToLoginViaNoPass',
    description: "Failed to login via NoPass. Please contact Account Services at <a href=\"tel:18004940451\">1-800-494-0451</a> for more assistance."
  },
  118: {
    name: 'UserAlreadyRegisteredForNoPass',
    description: "User is already registered for NoPass. Please register with another user."
  },
  119: {
    name: 'AuthTokenDestructureFailed',
    description: 'Auth token destructure failed.'
  },
  200: {
    name: 'APINotAuthorizedForGivenRequest',
    description: 'API not authorized for given request'
  },
  201: {
    name: 'UserHasAccessToZeroAccounts',
    description: 'User does not have visibility to see any accounts'
  },
  202: {
    name: 'APIAgreementMissing',
    description: 'API Agreement is missing'
  },
  203: {
    name: 'AccountDoesNotHaveApiAgreement',
    description: 'Please login to accept the API Agreement(s)'
  },
  204: {
    name: 'MustSpecifySpecificAccountId',
    description: 'User has visibility to multiple accounts, but no specific account was specified in the request.'
  },
  205: {
    name: 'MustSpecifySpecificSubaccountId',
    description: 'Account has multiple subaccounts but no specific subaccount was specified in the request'
  },
  206: {
    name: 'SubaccountIsNotOwnedBySpecifiedAccount',
    description: 'Subaccount specified in the request does not belong to the given account supplied in the request'
  },
  207: {
    name: 'AgreementMissing',
    description: 'Agreement is missing'
  },
  208: {
    name: 'NoAvailableSecurityChallenges',
    description: 'All security challenges have been marked as failed.'
  },
  209: {
    name: 'SecurityChallengeFailedVerification',
    description: 'Security challenge verification failed.'
  },
  210: {
    name: 'FailedCognitoIdTokenValidation',
    description: 'Cognito generated IdToken failed validation or missing required claims.'
  },
  211: {
    name: 'ApplicationDuplicateAccountTypeSSN',
    description: 'An account with this account type and SSN already exists. Please change the account type or reach out to Account Services at <a href="tel:18004940451">1-800-494-0451</a>.'
  },
  212: {
    name: 'MasterSubaccountCannotBeCreatedOrDeleted',
    description: 'Trying to delete a reserved main subaccount or the account is a master account.'
  },
  213: {
    name: 'AcctSuffixMaxLengthIs3Chars',
    description: 'Account suffix for creating subaccounts must be 3 length or less.'
  },
  214: {
    name: 'SubaccountDeleted',
    description: 'Subaccount is deleted.'
  },
  215: {
    name: 'SubaccountDeleteNotAllowed',
    description: 'This subaccount cannot be deleted.'
  },
  216: {
    name: 'SubaccountDoesNotExist',
    description: 'Subaccount does not exist.'
  },
  217: {
    name: 'NicknameMaxLengthIs15Chars',
    description: 'Nickname length must be length 15 or less.'
  },
  218: {
    name: 'SuffixAlreadyExists',
    description: 'Suffix already exists.'
  },
  219: {
    name: 'NicknameAlreadyExists',
    description: 'Nickname already exists.'
  },
  220: {
    name: 'DescriptionLengthIsMoreThan150Chars',
    description: 'Description is too long. 150 or less characters maximum.'
  },
  221: {
    name: 'SuffixUpdateByUserNotAllowed',
    description: 'Suffix update not allowed by this user.'
  },
  300: {
    name: 'Order error',
    description: function description(message) {
      return message;
    }
  },
  301: {
    name: 'Margin check error',
    description: function description(message) {
      return _.replace(message, 'Margin check error.', '');
    }
  },
  302: {
    name: 'Restricted symbol',
    description: 'This symbol is currently not allowed for trading.'
  },
  303: {
    name: 'InvalidOrderId',
    description: 'OrderId supplied is invalid.'
  },
  304: {
    name: 'OrderNotFound',
    description: 'Order could not be found by the given OrderId.'
  },
  305: {
    name: 'Order placement error',
    description: 'Order Validation Error'
  },
  306: {
    name: 'SubaccountTradeWillResultInNegativeCashBalance',
    description: 'The subaccount trade will result in a negative CashBalance'
  },
  307: {
    name: 'Leveraged, Inverse, or Commodity ETF Warning',
    description: "Warning: Leveraged, Inverse, and Commodity ETFs carry unique risks and may not be suitable for all investors. <a href=\"https://legacy.tradingblock.com/docs/Agreements/Leveraged_Inverse_Commodity_ETF_Disclosure.pdf\" target=\"_blank\">CLICK HERE</a> to learn more. To proceed, click \"Bypass Warnings\"."
  },
  308: {
    name: 'OrderIsInPendingState',
    description: 'The order is in a pending state'
  },
  390: {
    name: 'Insufficient Funds',
    description: 'Insufficient funds to complete the requested operation.'
  },
  400: {
    name: 'UserMustCompleteMarketDataQuestionnaire',
    description: 'User is not allowed to receive any quotes because they have not completed the Market Data Questionnaire, or their questionnaire is expired'
  },
  401: {
    name: 'UserMustCompleteMarketDataEntitlementUpdate',
    description: 'User must complete mkt data entitlement update (usually annual update)'
  },
  500: {
    name: 'AchRelationshipNotFound',
    description: 'The requested ACH relationship was not found.'
  },
  501: {
    name: 'AchRelationshipIsInInvalidStateForOperation',
    description: 'The requested ACH relationship is in a State that is invalid for the requested operation.'
  },
  502: {
    name: 'AchRelationshipApprovalMethodMismatch',
    description: 'The Approval Method specified in the operation does not match the Approval Method of the ACH relationship. Only MicroDeposit is currently supported.'
  },
  503: {
    name: 'TransferBeneficiaryNotFound',
    description: 'The requested transfer beneficiary was not found.'
  },
  505: {
    name: 'TransferRecipientBankNotFound',
    description: 'The requested transfer recipient bank was not found.'
  },
  506: {
    name: 'TransferNotFound',
    description: 'The requested transfer was not found.'
  },
  507: {
    name: 'TransferIsInInvalidStateForOperation',
    description: 'The requested transfer is in a State that is invalid for the requested operation.'
  },
  508: {
    name: 'CashieringRequestFailedValidation',
    description: 'The Cashiering request failed validation.'
  },
  509: {
    name: 'TransferBeneficiaryMechanismMismatch',
    description: 'The transfer beneficiary Mechanism specified in the operation does not match the Mechanism of the transfer.'
  },
  510: {
    name: 'TransferApprovalWithUnapprovedAchRelationshipFailed',
    description: 'Cannot approve this transfer as it has an unapproved ACH relationship. First approve the relationship before attempting to approve the transfer.'
  },
  511: {
    name: 'AchRelationshipDuplicate',
    description: 'This ACH relationship is a duplicate of an existing relationship.'
  },
  512: {
    name: 'AchRelationshipDeleteWithPendingTransfersFailed',
    description: 'Cannot delete this ACH relationship as it has pending transfers. Remove those first, before attempting to delete the relationship.'
  },
  513: {
    name: 'TransferRecipientBankIsInInvalidStateForOperation',
    description: 'The transfer recipient bank referenced by the transfer is in a State that is invalid for the requested operation.'
  },
  514: {
    name: 'TransferWireInstructionNotFound',
    description: 'The requested transfer wire instruction was not found.'
  },
  515: {
    name: 'TransferWireInstructionIsInInvalidStateForOperation',
    description: 'The transfer wire instruction referenced by the transfer is in a State that is invalid for the requested operation.'
  },
  516: {
    name: 'AchRelationshipAccountIsInInvalidStateForOperation',
    description: 'The account referenced by the ACH relationship is in a State that is invalid for the requested operation.'
  },
  517: {
    name: 'AlertMessageNotFound',
    description: 'The requested alert message was not found.'
  },
  518: {
    name: 'DuplicateAlertMessage',
    description: 'An alert message already exists.'
  },
  545: {
    name: 'TransferIntermediaryNotFound',
    description: 'The requested transfer intermediary was not found.'
  },
  550: {
    name: 'ApexSentinelServiceFailed',
    description: 'The Apex Sentinel service failed while processing the operation.'
  },
  551: {
    name: 'PlaidServiceFailedTheOperation',
    description: 'The Plaid service failed the requested operation.'
  },
  552: {
    name: 'RqdCashieringServiceFailedTheOperation',
    description: 'The RQD Cashiering service failed the requested operation.'
  },
  553: {
    name: 'RqdBalancesServiceFailedTheOperation',
    description: 'The RQD Balances service failed the requested operation.'
  },
  600: {
    name: 'ApplicationDuplicateUsername',
    description: 'The Username you entered is already being used.'
  },
  700: {
    name: 'DocumentMetaDataNotFound',
    description: 'The requested document meta-data was not found.'
  },
  701: {
    name: 'UnsupportedDocumentFileFormat',
    description: 'Unsupported document file format.  Only JPEG, GIF, PNG and PDF are supported.'
  },
  702: {
    name: 'MiscellaneousFileUploadError',
    description: 'The file upload failed.'
  },
  703: {
    name: 'TooManyUploadsForDocument',
    description: 'Too many uploads of the same document.'
  },
  704: {
    name: 'DocumentNotFound',
    description: 'The requested document dat not found.'
  }
};