import { HistoryGetBalancesEndpoint, HistoryGetHistoryBalances } from '../constants';
export function GetBalanceHistory(api) {
  return function (accountId) {
    return api.authenticated().get(HistoryGetHistoryBalances, {
      accountId: accountId
    }, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}
export function HistoryGetBalances(api) {
  return function (payload) {
    return api.authenticated().get(HistoryGetBalancesEndpoint, payload, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}