import React, { useMemo } from 'react';
import { useData } from '../state/selectors';
import { AllApplicationSteps } from '../types';
import { ApplicationModel, ApplicationStep, ClearerType, HeardAboutInfo } from '@tradingblock/types';
import _ from 'lodash';
import { useFormik, useFormikContext } from 'formik';


export function shouldRemoveClearerOption(
  selectedHeardAboutUsId: number | string | undefined,
  selectedHeardAboutUsRepId: number | string | undefined,
  heardAboutUsItems: HeardAboutInfo[] | undefined
) {
  if (heardAboutUsItems && selectedHeardAboutUsId !== undefined) {
    const selectedHeardAboutUs = heardAboutUsItems.find(heardAboutItem => heardAboutItem.heardAboutId.toString() === selectedHeardAboutUsId);
    //Check if there is a rep selected to check if it has RQD office codes
    if (selectedHeardAboutUs !== undefined && selectedHeardAboutUs.representatives !== undefined && selectedHeardAboutUs.representatives.length > 0) {
      if (selectedHeardAboutUsRepId !== undefined) {
        const repOfficeCodes = selectedHeardAboutUs.representatives.find(rep => rep.repId.toString() === selectedHeardAboutUsRepId);
        //If it does not then we delete the clearer step
        if (repOfficeCodes !== undefined && repOfficeCodes.officeCodes.rqd === undefined) {
          return true;
        }
      }
    } else if (selectedHeardAboutUs !== undefined && selectedHeardAboutUs.officeCodes !== undefined) {
      //If we do not have a reps attached to the hear about us item. Then we need to use the root office codes.
      //If there is not any rqd then we delete the clearer step.
      if (selectedHeardAboutUs.officeCodes.rqd === undefined) {
        return true;
      }
    }
  }
  return false;
}

/**
 * A hook that checks what steps should be available base on 
 * @returns {ApplicationStep[]} application steps allowed for the app
 */
export function useApplicationSteps() {
  const heardAbout = useData(d => d.ui.heardAbout);
  const context = useFormikContext<ApplicationModel>();

  const allApplicationSteps = useMemo(() => {
    let applicationSteps = _.clone(AllApplicationSteps);
    
    if (context && context.values && shouldRemoveClearerOption(context.values.hearAboutUsId, context.values.repId, heardAbout)) {
      const clearerTypeStepIdIndex = applicationSteps.findIndex(step => step === ApplicationStep.ClearerType);
      if (clearerTypeStepIdIndex) {
        delete applicationSteps[clearerTypeStepIdIndex];
      }
    }
    return applicationSteps;
  }, [heardAbout, context]);

  return allApplicationSteps;
}