import * as tslib_1 from "tslib";
import React from 'react';
export var Button = function Button(_a) {
  var children = _a.children,
      className = _a.className,
      type = _a.type,
      rest = tslib_1.__rest(_a, ["children", "className", "type"]);

  return React.createElement("button", Object.assign({}, rest, {
    className: "btn ".concat(className || 'btn-outline-light'),
    type: type || 'button'
  }), children);
};