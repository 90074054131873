import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { ExperienceKey, SummaryStepProps, TradeGoalType, ApplicationStep, ExperienceYearType } from '../../../types';
import { getRiskLevelForTradeGoalType, getRiskLevelForInvestmentObjectiveType, getAllExperienceKeysForApplication } from '../../../services';
import { FieldValue } from '../../values';
import { RiskLevelIcon, MismatchedDataModal, TradeGoalsVerification } from '..';

export const InvestingProfileSummary: React.FC<SummaryStepProps> = ({ application, visibleSections, getText, mismatchedDataModal, closeMismatchedDataModal }) => {
  if (!application) {
    return null;
  }
  return (
    <>
      {mismatchedDataModal && mismatchedDataModal.type && (
        <MismatchedDataModal type={mismatchedDataModal.type} onEdit={() => closeMismatchedDataModal({ nextStep: ApplicationStep.InvestingProfile })} onContinue={() => closeMismatchedDataModal({ confirmMismatchedData: true })} onClose={closeMismatchedDataModal}>
          <TradeGoalsVerification application={application} />
        </MismatchedDataModal>
      )}

      <h3 className="fields-title">{getText('tradeGoalType')}</h3>
      <p>
        {application.tradeGoalType && <RiskLevelIcon level={getRiskLevelForTradeGoalType(application.tradeGoalType)} />}
        <FieldValue value={application.tradeGoalType} textPrefix="tradeGoalType" />
      </p>
      <h3 className="fields-title">{getText('investmentObjectiveType')}</h3>
      <p>
        <FieldValue value={application.investmentObjectiveType}>
          {application.investmentObjectiveType && (
            <>
              <RiskLevelIcon level={getRiskLevelForInvestmentObjectiveType(application.investmentObjectiveType)} />
              <FieldValue value={application.investmentObjectiveType} textPrefix="investmentObjectiveType" />
            </>
          )}
        </FieldValue>
      </p>
      {visibleSections === 2 && (
        <>
          {application.tradeGoalType !== TradeGoalType.StocksBonds && (
            <>
              <h3 className="fields-title">{getText('experience')}</h3>
              <p>
                {_.map(getAllExperienceKeysForApplication(application), (ex: ExperienceKey) => {
                  const experience = application.experience && application.experience[ex];
                  return (
                    <span key={`experience.${ex}`}>
                      <Text id={`experience.${ex}`} type="field" />
                      {': '}
                      <FieldValue value={experience && experience.years} textPrefix="defaults.experience.years" />
                      {experience && experience.years && experience.years !== ExperienceYearType.Zero && (
                        <>
                          {', '}
                          <FieldValue value={experience && experience.tradesPerYear} textPrefix="defaults.experience.tradesPerYear" errorPrefix={<Text id={`experience.${ex}.tradesPerYear`} type="field" />} />
                        </>
                      )}
                      <br />
                    </span>
                  );
                })}
              </p>
            </>
          )}

          <h3 className="fields-title">{getText('income-net-worth')}</h3>
          <p>
            <Text id="annualIncome" type="field" />: <FieldValue value={application.annualIncome} textPrefix="defaults.incomeRangeType" />
            <br />
            <Text id="totalNetWorth" type="field" />: <FieldValue value={application.totalNetWorth} textPrefix="defaults.monetaryRangeType" />
            <br />
            <Text id="liquidNetWorth" type="field" />: <FieldValue value={application.liquidNetWorth} textPrefix="defaults.monetaryRangeType" />
          </p>

          <h3 className="fields-title">{getText('characteristics')}</h3>
          <p>
            <Text id="characteristics.risk" type="field" />: <FieldValue value={application.characteristics && application.characteristics.risk} textPrefix="characteristics.risk" />
            <br />
            <Text id="characteristics.bracket" type="field" />: <FieldValue value={application.characteristics && application.characteristics.bracket} textPrefix="characteristics.bracket" />
            <br />
            <Text id="characteristics.horizon" type="field" />: <FieldValue value={application.characteristics && application.characteristics.horizon} textPrefix="characteristics.horizon" />
            <br />
            <Text id="characteristics.liquidity" type="field" />: <FieldValue value={application.characteristics && application.characteristics.liquidity} textPrefix="characteristics.liquidity" />
          </p>
        </>
      )}
    </>
  );
};
