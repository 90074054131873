import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSign, formatNumber } from '../../lib';

var NumberView = function NumberView(_ref) {
  var value = _ref.value,
      currency = _ref.currency,
      roundDecimal = _ref.roundDecimal,
      format = _ref.format,
      integer = _ref.integer,
      locale = _ref.locale,
      percent = _ref.percent,
      prefix = _ref.prefix,
      greeks = _ref.greeks,
      isStrikeField = _ref.isStrikeField,
      precisionOverride = _ref.precisionOverride;
  var num = useMemo(function () {
    return formatNumber(value, {
      currency: currency,
      roundDecimal: roundDecimal,
      format: format,
      integer: integer,
      locale: locale,
      percent: percent,
      prefix: prefix,
      greeks: greeks,
      isStrikeField: isStrikeField,
      precisionOverride: precisionOverride
    });
  }, [value, currency, roundDecimal, format, integer, locale, percent, prefix, greeks, isStrikeField, precisionOverride]);
  return React.createElement(React.Fragment, null, num);
};

var BoldNumberView = function BoldNumberView(props) {
  return React.createElement("strong", null, React.createElement(NumberView, Object.assign({}, props)));
};

var SignedNumberView = function SignedNumberView(_a) {
  var bold = _a.bold,
      hideIcon = _a.hideIcon,
      hidePrefix = _a.hidePrefix,
      precisionOverride = _a.precisionOverride,
      props = tslib_1.__rest(_a, ["bold", "hideIcon", "hidePrefix", "precisionOverride"]);

  var _useSign = useSign(_.toNumber(props.value)),
      _useSign2 = _slicedToArray(_useSign, 2),
      changeUi = _useSign2[1];

  return React.createElement("span", {
    className: changeUi.className
  }, changeUi.icon && !hideIcon && React.createElement("i", {
    className: changeUi.icon
  }), ' ', bold ? React.createElement(BoldNumberView, Object.assign({
    precisionOverride: precisionOverride,
    prefix: !hidePrefix ? changeUi.prefix : null
  }, props)) : React.createElement(NumberView, Object.assign({
    precisionOverride: precisionOverride,
    prefix: !hidePrefix ? changeUi.prefix : null
  }, props)));
};

export var SignedNumberContainer = function SignedNumberContainer(_a) {
  var children = _a.children,
      props = tslib_1.__rest(_a, ["children"]);

  var _useSign3 = useSign(_.toNumber(props.value)),
      _useSign4 = _slicedToArray(_useSign3, 2),
      changeUi = _useSign4[1];

  return React.createElement("span", {
    className: changeUi.className
  }, children);
};
export var Number = React.memo(NumberView);
export var BoldNumber = React.memo(BoldNumberView);
export var SignedNumber = React.memo(SignedNumberView);