var TradingBlockLegacyUrlEnum;

(function (TradingBlockLegacyUrlEnum) {
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["AchRelationshipPdfApplicationUrl"] = 0] = "AchRelationshipPdfApplicationUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["TB_CRS"] = 1] = "TB_CRS";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["MB_CRS"] = 2] = "MB_CRS"; // CustomerServiceUrl,

  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["ETFDisclosureUrl"] = 3] = "ETFDisclosureUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["LegacySiteHomeUrl"] = 4] = "LegacySiteHomeUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["LegacySiteLoginUrl"] = 5] = "LegacySiteLoginUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["LegacySitePwdResetUrl"] = 6] = "LegacySitePwdResetUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["RiskDisclosuresUrl"] = 7] = "RiskDisclosuresUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["RisksOfOptionsUrl"] = 8] = "RisksOfOptionsUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["RisksOfDayTradingUrl"] = 9] = "RisksOfDayTradingUrl";
  TradingBlockLegacyUrlEnum[TradingBlockLegacyUrlEnum["ServicesAgreementUrl"] = 10] = "ServicesAgreementUrl";
})(TradingBlockLegacyUrlEnum || (TradingBlockLegacyUrlEnum = {}));

var TradingBlockUrlEnum;

(function (TradingBlockUrlEnum) {
  TradingBlockUrlEnum[TradingBlockUrlEnum["HomeUrl"] = 0] = "HomeUrl";
  TradingBlockUrlEnum[TradingBlockUrlEnum["VideoTourUrl"] = 1] = "VideoTourUrl";
  TradingBlockUrlEnum[TradingBlockUrlEnum["CustomerServiceUrl"] = 2] = "CustomerServiceUrl";
  TradingBlockUrlEnum[TradingBlockUrlEnum["OpenNewAccountUrl"] = 3] = "OpenNewAccountUrl";
  TradingBlockUrlEnum[TradingBlockUrlEnum["TBFeesUrl"] = 4] = "TBFeesUrl";
  TradingBlockUrlEnum[TradingBlockUrlEnum["MBFeesUrl"] = 5] = "MBFeesUrl";
})(TradingBlockUrlEnum || (TradingBlockUrlEnum = {})); // these urls are currently hosted on the legacy site


export var TradingBlockLegacyUrlsLookup = {
  AchRelationshipPdfApplicationUrl: '/admin/forms/apex/cashiering/ACH_Application.pdf',
  TB_CRS: '/Docs/Agreements/tb/AOS_TradingBlock_Form_CRS.pdf',
  MB_CRS: '/Docs/Agreements/mb/AOS_MoneyBlock_Form_CRS.pdf',
  ETFDisclosureUrl: '/Docs/Agreements/Leveraged_Inverse_Commodity_ETF_Disclosure.pdf',
  LegacySiteHomeUrl: '/default.aspx',
  LegacySiteLoginUrl: '/account/securitieslogin.aspx',
  LegacySitePwdResetUrl: '/account/passwordresetlogin.aspx',
  RiskDisclosuresUrl: '/account/riskanddisclosures.aspx',
  RisksOfOptionsUrl: '/docs/agreements/riskstoc.pdf',
  RisksOfDayTradingUrl: '/account/disclosureview.aspx',
  ServicesAgreementUrl: '/docs/agreements/Online_Services_Agreement_v1.4_20140713.pdf'
};
export var TradingBlockUrlsLookup = Object.assign({}, TradingBlockLegacyUrlsLookup, {
  HomeUrl: '/',
  VideoTourUrl: '/videos',
  CustomerServiceUrl: '/contact',
  OpenNewAccountUrl: '',
  TBFeesUrl: '/pricing-policies',
  MBFeesUrl: '/policies'
});