import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { Section, StepperField, CheckboxField, ExternalLink, Text } from '@tradingblock/components';
import {
  AgreementKey,
  AllBooleanToggleTypes,
  ApplicationFormSection,
  ApplicationStep,
  BooleanToggleType,
} from '../../../../types';
import { getNumberOfAgreementDisclosureSections, getAllAgreementKeysForApplication } from '../../../../services';
import { useStateSelector } from '../../../../state/selectors';
import config from '../../../../config';
import { useDispatch } from 'react-redux';
import { ApplicationActions } from '../../../../state/actions';
import { useStore } from '../../../../context';

export const AccountAgreementsSection: React.FC<ApplicationFormSection> = ({ values, setValues, getText }) => {
  const [clickedAgreements, setClickedAgreements] = useState<AgreementKey[]>([]);
  const disclosureSections = getNumberOfAgreementDisclosureSections(values);
  const agreementCheckboxRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const storage = useStore();
  const onClickedAgreementLink = useCallback(
    (agreement: AgreementKey) => {
      setClickedAgreements([...clickedAgreements, agreement]);
    },
    [clickedAgreements]
  );
  const heardAboutList = useStateSelector(s => s.ui.heardAbout);
  const { hearAboutUsId } = values;

  const heardAbout = heardAboutList && heardAboutList.find(h => h.heardAboutId == hearAboutUsId);
  const apiAgreementRequired = heardAbout && heardAbout.needsApiAgreement;

  const getAgreementDisabledOptions = useCallback(
    (agreement: AgreementKey) => {
      // if haven't clicked agreement, disable toggle buttons
      if (!_.includes(clickedAgreements, agreement)) {
        return AllBooleanToggleTypes;
      }
      return undefined;
    },
    [clickedAgreements]
  );
  
  useEffect(() => {
    if (values.agreeToElectronicConsent === true && agreementCheckboxRef.current !== null) {
      agreementCheckboxRef.current.scrollIntoView();
    }
  }, [agreementCheckboxRef])

  useEffect(() => {
    dispatch(ApplicationActions.requestPartialSaveApplication({ application: values, storage}));
  }, [values.agreeToElectronicConsent]);

  return (
    <>
      <Section id={`${ApplicationStep.Agreements}-section-${2 + disclosureSections}`}>
        <h2 className="fields-title">{getText('agreements')}</h2>

        <p ref={agreementCheckboxRef}>
          <Field component={CheckboxField} id="agreeToElectronicConsent" />
        </p>
        {values.agreeToElectronicConsent && (
          <>
            <p className="mute">{getText('agreements.agreeToAll')}</p>
            {_.map(
              getAllAgreementKeysForApplication(values, apiAgreementRequired),
              (agreementKey: AgreementKey, i: number) => (
                <div key={`agreement${i}`}>
                  <h4>
                    <ExternalLink
                      className="mr-3"
                      href={`${config.tradingApi}/Documents/Agreement/${agreementKey}`}
                      onClick={() => onClickedAgreementLink(agreementKey)}
                    >
                      <i className="far fa-download" /> {getText(`agreements.${agreementKey}`)}
                    </ExternalLink>{' '}
                  </h4>
                </div>
              )
            )}
            <br />

            <Field
              component={StepperField}
              id="agreeToAll"
              options={AllBooleanToggleTypes}
              defaultLabelId="agreements"
              onChanged={(value: BooleanToggleType) => {
                const agreementKeys = getAllAgreementKeysForApplication(values, apiAgreementRequired);
                let agreementKeysObject = {};
                if (value === 'yes') {
                  agreementKeys.forEach(a => {
                    agreementKeysObject = {
                      ...agreementKeysObject,
                      [a]: BooleanToggleType.Yes,
                    };
                  });
                  setValues({ ...values, agreeToAll: BooleanToggleType.Yes, agreements: agreementKeysObject });
                } else {
                  agreementKeys.forEach(a => {
                    agreementKeysObject = {
                      ...agreementKeysObject,
                      [a]: BooleanToggleType.No,
                    };
                  });
                  setValues({ ...values, agreeToAll: BooleanToggleType.No, agreements: agreementKeysObject });
                }
              }}
            />

            {_.some(values.agreements, value => value === BooleanToggleType.No) && (
              <p>
                <strong>
                  {getText('agreements.declined', undefined, {
                    data: {
                      contactUsLink: (
                        <ExternalLink href="/apply/customer-service">{<Text id="contact" />}</ExternalLink>
                      ),
                    },
                  })}
                </strong>
              </p>
            )}
          </>
        )}
      </Section>
    </>
  );
};
