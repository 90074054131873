export var DefaultAccountManagementDetails = {
  email: undefined,
  physicalAddress: undefined,
  mailingAddress: undefined,
  homePhone: undefined,
  mobilePhone: undefined,
  workPhone: undefined
};
export var DefaultAccountUpdateDetails = {
  accountId: 0,
  accountName: '',
  accountNumber: '',
  state: '',
  confirmedDate: undefined,
  created: {
    userId: 0,
    name: '',
    date: undefined,
    reason: ''
  },
  firmRejected: {
    date: undefined,
    name: '',
    reason: '',
    userId: 0
  },
  firmReviewed: {
    date: undefined,
    name: '',
    reason: '',
    userId: 0
  },
  id: 0,
  item: '',
  note: '',
  original: {
    accountHolder: {
      email: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      mailingAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      phones: [{
        phoneNumber: '',
        type: ''
      }],
      maritalStatus: '',
      dependents: 0,
      birthCountry: '',
      citizenshipCountry: '',
      employmentType: '',
      jobPosition: '',
      yearsEmployed: '',
      employer: '',
      employerAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      disclosures: {
        govOfficial: false,
        industryEmployed: false,
        irsWithholding: false,
        stakeholder: false
      },
      disclosuresGovOfficialImmediateFamily: '',
      disclosuresGovOfficialPoliticalOrg: '',
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: ''
    },
    beneficiaries: [],
    flexibilityType: '',
    tradeGoalType: '',
    investmentObjectiveType: '',
    disclosuresForeignBankUSAgent: '',
    experience: {
      bonds: {
        tradesPerYear: '',
        years: ''
      },
      commodities: {
        tradesPerYear: '',
        years: ''
      },
      margins: {
        tradesPerYear: '',
        years: ''
      },
      options: {
        tradesPerYear: '',
        years: ''
      },
      stocks: {
        tradesPerYear: '',
        years: ''
      }
    },
    annualIncome: '',
    totalNetWorth: '',
    liquidNetWorth: '',
    characteristics: {
      risk: '',
      bracket: '',
      horizon: '',
      liquidity: ''
    },
    trustedContact: {
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      email: '',
      firstName: '',
      middleInitial: '',
      lastName: '',
      suffix: '',
      phones: [{
        phoneNumber: '',
        type: ''
      }]
    },
    tradingAuthorization: {
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      citizenshipCountry: '',
      dateOfBirth: '',
      disclosures: {
        industryEmployed: false,
        stakeholder: false
      },
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: '',
      email: '',
      employer: '',
      employmentType: '',
      firstName: '',
      middleInitial: '',
      suffix: '',
      foreignTaxIdCountry: '',
      foreignTaxIdNumber: '',
      jobPosition: '',
      lastName: '',
      phones: [{
        phoneNumber: '',
        type: ''
      }],
      ssnSecret: '',
      type: ''
    },
    accountDisclosures: {
      foreignBank: false,
      foreignFinancialInstitution: false,
      foreignShellBank: false,
      proprietarySecuritiesAccount: false
    },
    serviceProfile: {
      issuerDirectCommunication: ''
    },
    state: ''
  },
  updated: {
    accountHolder: {
      email: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      mailingAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      phones: [{
        phoneNumber: '',
        type: ''
      }],
      maritalStatus: '',
      dependents: 0,
      birthCountry: '',
      citizenshipCountry: '',
      employmentType: '',
      jobPosition: '',
      yearsEmployed: '',
      employer: '',
      employerAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      disclosures: {
        govOfficial: false,
        industryEmployed: false,
        irsWithholding: false,
        stakeholder: false
      },
      disclosuresGovOfficialImmediateFamily: '',
      disclosuresGovOfficialPoliticalOrg: '',
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: ''
    },
    beneficiaries: [],
    flexibilityType: '',
    tradeGoalType: '',
    investmentObjectiveType: '',
    disclosuresForeignBankUSAgent: '',
    experience: {
      bonds: {
        tradesPerYear: '',
        years: ''
      },
      commodities: {
        tradesPerYear: '',
        years: ''
      },
      margins: {
        tradesPerYear: '',
        years: ''
      },
      options: {
        tradesPerYear: '',
        years: ''
      },
      stocks: {
        tradesPerYear: '',
        years: ''
      }
    },
    annualIncome: '',
    totalNetWorth: '',
    liquidNetWorth: '',
    characteristics: {
      risk: '',
      bracket: '',
      horizon: '',
      liquidity: ''
    },
    trustedContact: {
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      email: '',
      firstName: '',
      middleInitial: '',
      suffix: '',
      lastName: '',
      phones: [{
        phoneNumber: '',
        type: ''
      }]
    },
    tradingAuthorization: {
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: ''
      },
      citizenshipCountry: '',
      dateOfBirth: '',
      disclosures: {
        industryEmployed: false,
        stakeholder: false
      },
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: '',
      email: '',
      employer: '',
      employmentType: '',
      firstName: '',
      middleInitial: '',
      foreignTaxIdCountry: '',
      foreignTaxIdNumber: '',
      jobPosition: '',
      suffix: '',
      lastName: '',
      phones: [{
        phoneNumber: '',
        type: ''
      }],
      ssnSecret: '',
      type: ''
    },
    accountDisclosures: {
      foreignBank: false,
      foreignFinancialInstitution: false,
      foreignShellBank: false,
      proprietarySecuritiesAccount: false
    },
    serviceProfile: {
      issuerDirectCommunication: ''
    },
    uploadedDocuments: [{
      accountHolder: 0,
      documentId: 0,
      documentName: '',
      state: undefined
    }]
  },
  responseCode: 0
};
export var DefaultUserUpdateAccountDetails = {
  accountDisclosures: {
    entityIssuesBearerShares: false,
    entityNegativeNews: false,
    entityPublicOfficials: false,
    entityServicesForeignShellBanks: false,
    foreignBank: false,
    foreignFinancialInstitution: false,
    foreignShellBank: false,
    proprietarySecuritiesAccount: false
  },
  accountHolders: [{
    address: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: ''
    },
    birthCountry: '',
    citizenshipCountry: '',
    dependents: 0,
    disclosures: {
      govOfficial: false,
      industryEmployed: false,
      irsWithholding: false,
      stakeholder: false
    },
    disclosuresGovOfficialImmediateFamily: '',
    disclosuresGovOfficialPoliticalOrg: '',
    disclosuresIndustryEmployedFirmName: '',
    disclosuresStakeholderTickerSymbol: '',
    email: '',
    employer: '',
    employerAddress: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: ''
    },
    employmentType: '',
    firstName: '',
    middleInitial: '',
    jobPosition: '',
    jointTenantsInCommonInterestPercent: 0,
    lastName: '',
    suffix: '',
    mailingAddress: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: ''
    },
    maritalStatus: '',
    phones: [{
      phoneNumber: '',
      type: ''
    }],
    ssnSecret: '',
    yearsEmployed: ''
  }],
  accountId: 0,
  accountNumber: '',
  agreements: {
    corporateCash: null,
    corporateMargin: null,
    customerAndPrivacyPolicy: null,
    entityMargin: null,
    entityOption: null,
    iRARothAdoption: null,
    iRASepAdoption: null,
    iRASimpleAdoption: null,
    iRACoverDellAdoption: null,
    jointCommunity: null,
    jointSurvivor: null,
    jointTenantsInCommon: null,
    jointTenantsInEntirety: null,
    limitedMargin: null,
    llcAccount: null,
    margin: null,
    option: null,
    riskDisclosure: null,
    electronicConsent: null,
    onlineServices: null,
    exchangeDataAgreement: null,
    clientRelationshipSummary: null,
    apiAgreement: null,
    rqdCustomerAgreement: null,
    rqdOption: null,
    rqdMargin: null,
    rqdLimitedMargin: null,
    rqdExecutionDisclosures: null,
    rqdClearingDisclosures: null,
    rqdCustomerInformationBrochure: null,
    rqdPrivacyPolicy: null,
    rqdPrivacyNotice: null,
    rqdResponsibilitiesOfIbAndCarryingBroker: null,
    rqdSpecialStatementForUncoveredOptionWriters: null,
    rqdPowerOfAttorney: null,
    rqdLimitedTradingAuthorization: null
  },
  annualIncome: '',
  beneficiaries: [],
  characteristics: {
    bracket: '',
    horizon: '',
    liquidity: '',
    risk: ''
  },
  disclosuresForeignBankUSAgent: '',
  entityAccountType: '',
  experience: {
    bonds: {
      tradesPerYear: '',
      years: ''
    },
    commodities: {
      tradesPerYear: '',
      years: ''
    },
    margins: {
      tradesPerYear: '',
      years: ''
    },
    options: {
      tradesPerYear: '',
      years: ''
    },
    stocks: {
      tradesPerYear: '',
      years: ''
    }
  },
  flexibilityType: '',
  investmentObjectiveType: '',
  jointAccountType: '',
  jointCommunityPropertyState: '',
  lastChanged: {
    address: '',
    email: '',
    phone: ''
  },
  liquidNetWorth: '',
  retirementAccountType: '',
  totalNetWorth: '',
  tradeAuthorization: false,
  tradeAuthorizationAgentName: '',
  tradingAuthorization: {
    address: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: ''
    },
    citizenshipCountry: '',
    dateOfBirth: '',
    disclosures: {
      industryEmployed: false,
      stakeholder: false
    },
    disclosuresIndustryEmployedFirmName: '',
    disclosuresStakeholderTickerSymbol: '',
    email: '',
    employer: '',
    employmentType: '',
    firstName: '',
    middleInitial: '',
    suffix: '',
    foreignTaxIdCountry: '',
    foreignTaxIdNumber: '',
    jobPosition: '',
    lastName: '',
    phones: [{
      phoneNumber: '',
      type: ''
    }],
    ssnSecret: '',
    type: ''
  },
  tradeGoalType: '',
  trustedContact: {
    address: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: ''
    },
    email: '',
    firstName: '',
    middleInitial: '',
    suffix: '',
    lastName: '',
    phones: [{
      phoneNumber: '',
      type: ''
    }]
  },
  serviceProfile: {
    issuerDirectCommunication: ''
  },
  type: '',
  clearer: '',
  communicationPreferences: {
    deliveryType: '',
    preferredElectronicFormat: '',
    duplicateStatements: '',
    subscribeToPromotionalEmails: false
  }
};
export var AccountManagementEntityType;

(function (AccountManagementEntityType) {
  AccountManagementEntityType["Email"] = "Email";
  AccountManagementEntityType["Phone"] = "PhoneNumber";
  AccountManagementEntityType["Address"] = "Address";
  AccountManagementEntityType["MailingAddress"] = "MailingAddress";
  AccountManagementEntityType["TradeGoal"] = "TradeGoal";
  AccountManagementEntityType["Financial"] = "Financial";
  AccountManagementEntityType["Employment"] = "Employment";
  AccountManagementEntityType["TrustedContact"] = "TrustedContact";
  AccountManagementEntityType["Disclosures"] = "Disclosures";
  AccountManagementEntityType["AccountDisclosures"] = "AccountDisclosures";
  AccountManagementEntityType["Experience"] = "Experience";
  AccountManagementEntityType["Test"] = "test";
  AccountManagementEntityType["Flexability"] = "Flexibility";
  AccountManagementEntityType["TradeAuthorization"] = "TradeAuthorization";
  AccountManagementEntityType["ServiceProfile"] = "ServiceProfile";
  AccountManagementEntityType["IndustryEmployed"] = "IndustryEmployed";
  AccountManagementEntityType["Suitability"] = "Suitability";
  AccountManagementEntityType["Beneficiaries"] = "Beneficiaries";
  AccountManagementEntityType["RemoveTradeAuthorization"] = "RemoveTradeAuthorization";
  AccountManagementEntityType["PersonalInformation"] = "PersonalInformation";
})(AccountManagementEntityType || (AccountManagementEntityType = {}));

export var AccountManagementInvestmentObjectives;

(function (AccountManagementInvestmentObjectives) {
  AccountManagementInvestmentObjectives["CapitalPreservation"] = "CapitalPreservation";
  AccountManagementInvestmentObjectives["Income"] = "Income";
  AccountManagementInvestmentObjectives["Growth"] = "Growth";
  AccountManagementInvestmentObjectives["GrowthandIncome"] = "GrowthandIncome";
  AccountManagementInvestmentObjectives["Speculation"] = "Speculation";
})(AccountManagementInvestmentObjectives || (AccountManagementInvestmentObjectives = {}));

export var AnnualIncomeOptions;

(function (AnnualIncomeOptions) {
  AnnualIncomeOptions["LessThan25K"] = "Under $25,000";
  AnnualIncomeOptions["Between25KAnd50K"] = "$25,000 - $50,000";
  AnnualIncomeOptions["Between50KAnd100K"] = "$50,000 - $100,000";
  AnnualIncomeOptions["Between100KAnd200K"] = "$100,000 - $200,000";
  AnnualIncomeOptions["Between200KAnd300K"] = "$200,000 - $300,000";
  AnnualIncomeOptions["Between300KAnd500K"] = "$300,000 - $500,000";
  AnnualIncomeOptions["Between500KAnd1Point2M"] = "$500,000 - $1,200,000";
  AnnualIncomeOptions["Over1Point2M"] = "Over $1,200,000";
})(AnnualIncomeOptions || (AnnualIncomeOptions = {}));

export var AllAnnualIncomeOptions = [AnnualIncomeOptions.LessThan25K, AnnualIncomeOptions.Between25KAnd50K, AnnualIncomeOptions.Between50KAnd100K, AnnualIncomeOptions.Between100KAnd200K, AnnualIncomeOptions.Between200KAnd300K, AnnualIncomeOptions.Between300KAnd500K, AnnualIncomeOptions.Between500KAnd1Point2M, AnnualIncomeOptions.Over1Point2M];
export var UpdatePendingStates = ['PendingConfirm', 'PendingFirmReview', 'PendingReview'];
export var UpdateStates = [].concat(UpdatePendingStates, ['Approved', 'Complete', 'Rejected', 'Canceled', 'Cancelled', 'Submitted']);
export var TimeHorizonType;

(function (TimeHorizonType) {
  TimeHorizonType["Average"] = "Average";
  TimeHorizonType["Long"] = "Long";
  TimeHorizonType["Short"] = "Short";
})(TimeHorizonType || (TimeHorizonType = {}));

export var RiskToleranceType;

(function (RiskToleranceType) {
  RiskToleranceType["High"] = "High";
  RiskToleranceType["Low"] = "Low";
  RiskToleranceType["Medium"] = "Medium";
})(RiskToleranceType || (RiskToleranceType = {}));

export var RiskLevel;

(function (RiskLevel) {
  RiskLevel[RiskLevel["None"] = 0] = "None";
  RiskLevel[RiskLevel["VeryLow"] = 1] = "VeryLow";
  RiskLevel[RiskLevel["Low"] = 2] = "Low";
  RiskLevel[RiskLevel["Moderate"] = 3] = "Moderate";
  RiskLevel[RiskLevel["High"] = 4] = "High";
})(RiskLevel || (RiskLevel = {}));

export var MonetaryRangeType;

(function (MonetaryRangeType) {
  MonetaryRangeType["Between100KAnd200K"] = "Between100KAnd200K";
  MonetaryRangeType["Between1MAnd5M"] = "Between1MAnd5M";
  MonetaryRangeType["Between200KAnd500K"] = "Between200KAnd500K";
  MonetaryRangeType["Between500KAnd1M"] = "Between500KAnd1M";
  MonetaryRangeType["Between50KAnd100K"] = "Between50KAnd100K";
  MonetaryRangeType["LessThan50K"] = "LessThan50K";
  MonetaryRangeType["MoreThan5M"] = "MoreThan5M";
})(MonetaryRangeType || (MonetaryRangeType = {}));

export var LiquidityNeedType;

(function (LiquidityNeedType) {
  LiquidityNeedType["NotImportant"] = "NotImportant";
  LiquidityNeedType["SomewhatImportant"] = "SomewhatImportant";
  LiquidityNeedType["VeryImportant"] = "VeryImportant";
})(LiquidityNeedType || (LiquidityNeedType = {}));

export var InvestmentObjectiveType;

(function (InvestmentObjectiveType) {
  InvestmentObjectiveType["CapitalPreservation"] = "CapitalPreservation";
  InvestmentObjectiveType["Growth"] = "Growth";
  InvestmentObjectiveType["GrowthAndIncome"] = "GrowthAndIncome";
  InvestmentObjectiveType["Income"] = "Income";
  InvestmentObjectiveType["Speculation"] = "Speculation";
})(InvestmentObjectiveType || (InvestmentObjectiveType = {}));

export var InvestmentObjectiveDescription;

(function (InvestmentObjectiveDescription) {
  InvestmentObjectiveDescription["CapitalPreservation"] = "Protect initial investment through securities that minimize potential loss of principal. Returns may not be adequate to offest inflation.";
  InvestmentObjectiveDescription["Growth"] = "Increase value over time through securities with historically moderate to above-average risk of principal loss and higher volatility.";
  InvestmentObjectiveDescription["GrowthAndIncome"] = "Dual investment strategy that seeks both capital appreciation and current income generated through dividends or interest.";
  InvestmentObjectiveDescription["Income"] = "Generate current income through securities with a historically low risk of principal loss.";
  InvestmentObjectiveDescription["Speculation"] = "Assume a higher risk of loss and potentially higher-than-average gain by taking advantage of expected price changes and higher volatility.";
})(InvestmentObjectiveDescription || (InvestmentObjectiveDescription = {}));

export var InvestmentObjectiveLevel;

(function (InvestmentObjectiveLevel) {
  InvestmentObjectiveLevel[InvestmentObjectiveLevel["CapitalPreservation"] = 3] = "CapitalPreservation";
  InvestmentObjectiveLevel[InvestmentObjectiveLevel["Growth"] = 3] = "Growth";
  InvestmentObjectiveLevel[InvestmentObjectiveLevel["GrowthAndIncome"] = 3] = "GrowthAndIncome";
  InvestmentObjectiveLevel[InvestmentObjectiveLevel["Income"] = 3] = "Income";
  InvestmentObjectiveLevel[InvestmentObjectiveLevel["Speculation"] = 5] = "Speculation";
})(InvestmentObjectiveLevel || (InvestmentObjectiveLevel = {}));

export var IncomeRangeType;

(function (IncomeRangeType) {
  IncomeRangeType["Between100KAnd200K"] = "Between100KAnd200K";
  IncomeRangeType["Between200KAnd300K"] = "Between200KAnd300K";
  IncomeRangeType["Between25KAnd50K"] = "Between25KAnd50K";
  IncomeRangeType["Between300KAnd500K"] = "Between300KAnd500K";
  IncomeRangeType["Between500KAnd1Point2M"] = "Between500KAnd1Point2M";
  IncomeRangeType["Between50KAnd100K"] = "Between50KAnd100K";
  IncomeRangeType["LessThan25K"] = "LessThan25K";
  IncomeRangeType["MoreThan1Point2M"] = "MoreThan1Point2M";
})(IncomeRangeType || (IncomeRangeType = {}));

export var ExperienceYearType;

(function (ExperienceYearType) {
  ExperienceYearType["FourToNine"] = "FourToNine";
  ExperienceYearType["One"] = "One";
  ExperienceYearType["TenOrMore"] = "TenOrMore";
  ExperienceYearType["TwoToThree"] = "TwoToThree";
  ExperienceYearType["Zero"] = "Zero";
})(ExperienceYearType || (ExperienceYearType = {}));

export var TradeExperienceYearType;

(function (TradeExperienceYearType) {
  TradeExperienceYearType["ElevenToTwenty"] = "ElevenToTwenty";
  TradeExperienceYearType["OneToFive"] = "OneToFive";
  TradeExperienceYearType["SixToTen"] = "SixToTen";
  TradeExperienceYearType["MoreThanTwenty"] = "MoreThanTwenty";
})(TradeExperienceYearType || (TradeExperienceYearType = {}));

export var TaxbracketType;

(function (TaxbracketType) {
  TaxbracketType["Bottom"] = "Bottom";
  TaxbracketType["Middle"] = "Middle";
  TaxbracketType["Top"] = "Top";
})(TaxbracketType || (TaxbracketType = {}));

export var TradeExperienceUpdateType;

(function (TradeExperienceUpdateType) {
  TradeExperienceUpdateType["Bonds"] = "Bonds";
  TradeExperienceUpdateType["Options"] = "Options";
  TradeExperienceUpdateType["Stocks"] = "Stocks";
  TradeExperienceUpdateType["Commodities"] = "Commodities";
  TradeExperienceUpdateType["Margins"] = "Margins";
})(TradeExperienceUpdateType || (TradeExperienceUpdateType = {}));

export var AllTradeExperienceTypes = [TradeExperienceUpdateType.Bonds, TradeExperienceUpdateType.Options, TradeExperienceUpdateType.Stocks, TradeExperienceUpdateType.Commodities, TradeExperienceUpdateType.Margins];
export var AllIncomeRangeTypes = [IncomeRangeType.LessThan25K, IncomeRangeType.Between25KAnd50K, IncomeRangeType.Between50KAnd100K, IncomeRangeType.Between100KAnd200K, IncomeRangeType.Between200KAnd300K, IncomeRangeType.Between300KAnd500K, IncomeRangeType.Between500KAnd1Point2M, IncomeRangeType.MoreThan1Point2M];
export var AllInvestmentObjectiveTypes = [InvestmentObjectiveType.CapitalPreservation, InvestmentObjectiveType.Income, InvestmentObjectiveType.Growth, InvestmentObjectiveType.GrowthAndIncome, InvestmentObjectiveType.Speculation];
export var AllLiquidityNeedTypes = [LiquidityNeedType.VeryImportant, LiquidityNeedType.SomewhatImportant, LiquidityNeedType.NotImportant];
export var AllMonetaryRangeTypes = [MonetaryRangeType.LessThan50K, MonetaryRangeType.Between50KAnd100K, MonetaryRangeType.Between100KAnd200K, MonetaryRangeType.Between200KAnd500K, MonetaryRangeType.Between500KAnd1M, MonetaryRangeType.Between1MAnd5M, MonetaryRangeType.MoreThan5M];
export var AllTimeHorizonTypes = [TimeHorizonType.Short, TimeHorizonType.Average, TimeHorizonType.Long];
export var AllTaxbracketTypes = [TaxbracketType.Bottom, TaxbracketType.Middle, TaxbracketType.Top];
export var AllExperienceYearTypes = [ExperienceYearType.Zero, ExperienceYearType.One, ExperienceYearType.TwoToThree, ExperienceYearType.FourToNine, ExperienceYearType.TenOrMore];
export var AllTradeExperienceYearTypes = [TradeExperienceYearType.OneToFive, TradeExperienceYearType.SixToTen, TradeExperienceYearType.ElevenToTwenty, TradeExperienceYearType.MoreThanTwenty];
export var AllRiskToleranceTypes = [RiskToleranceType.Low, RiskToleranceType.Medium, RiskToleranceType.High];
export var TradeGoalType;

(function (TradeGoalType) {
  TradeGoalType["StocksBonds"] = "StocksBonds";
  TradeGoalType["AllAbovePlusCallsPuts"] = "AllAbovePlusCallsPuts";
  TradeGoalType["AllAbovePlusOptionSpreads"] = "AllAbovePlusOptionSpreads";
  TradeGoalType["AllAbovePlusPutWriting"] = "AllAbovePlusPutWriting";
  TradeGoalType["AllAbovePlusAllOptionStrategies"] = "AllAbovePlusAllOptionStrategies";
})(TradeGoalType || (TradeGoalType = {}));

export var AllTradeGoalTypes = [TradeGoalType.StocksBonds, TradeGoalType.AllAbovePlusCallsPuts, TradeGoalType.AllAbovePlusOptionSpreads, TradeGoalType.AllAbovePlusPutWriting, TradeGoalType.AllAbovePlusAllOptionStrategies];
export var TradeGoalTypeLevel;

(function (TradeGoalTypeLevel) {
  TradeGoalTypeLevel[TradeGoalTypeLevel["StocksBonds"] = 0] = "StocksBonds";
  TradeGoalTypeLevel[TradeGoalTypeLevel["AllAbovePlusCallsPuts"] = 1] = "AllAbovePlusCallsPuts";
  TradeGoalTypeLevel[TradeGoalTypeLevel["AllAbovePlusOptionSpreads"] = 2] = "AllAbovePlusOptionSpreads";
  TradeGoalTypeLevel[TradeGoalTypeLevel["AllAbovePlusPutWriting"] = 3] = "AllAbovePlusPutWriting";
  TradeGoalTypeLevel[TradeGoalTypeLevel["AllAbovePlusAllOptionStrategies"] = 4] = "AllAbovePlusAllOptionStrategies";
})(TradeGoalTypeLevel || (TradeGoalTypeLevel = {}));

export var BooleanToggleType;

(function (BooleanToggleType) {
  BooleanToggleType["No"] = "no";
  BooleanToggleType["Yes"] = "yes";
})(BooleanToggleType || (BooleanToggleType = {}));

export var AllBooleanToggleTypes = [BooleanToggleType.Yes, BooleanToggleType.No];
export var MFAOptions;

(function (MFAOptions) {
  MFAOptions["NoPass"] = "nopass";
  MFAOptions["SMS"] = "sms";
})(MFAOptions || (MFAOptions = {}));

export var AllMFAOptions = [MFAOptions.NoPass, MFAOptions.SMS];
export var EmploymentType;

(function (EmploymentType) {
  EmploymentType["Employed"] = "Employed";
  EmploymentType["Retired"] = "Retired";
  EmploymentType["Student"] = "Student";
  EmploymentType["Unemployed"] = "Unemployed";
})(EmploymentType || (EmploymentType = {}));

export var IssuerDirectCommunicationType;

(function (IssuerDirectCommunicationType) {
  IssuerDirectCommunicationType["Accept"] = "Accept";
  IssuerDirectCommunicationType["Object"] = "Object";
})(IssuerDirectCommunicationType || (IssuerDirectCommunicationType = {}));

export var AllIssuerDirectCommunicationTypes = [IssuerDirectCommunicationType.Accept, IssuerDirectCommunicationType.Object];
export var EmploymentTypes = [EmploymentType.Employed, EmploymentType.Retired, EmploymentType.Student, EmploymentType.Unemployed];
export var YearsEmployed;

(function (YearsEmployed) {
  YearsEmployed["BetweenZeroAndOne"] = "BetweenZeroAndOne";
  YearsEmployed["BetweenTwoAndFive"] = "BetweenTwoAndFive";
  YearsEmployed["BetweenFiveAndTen"] = "BetweenFiveAndTen";
  YearsEmployed["BetweenTenAndTwenty"] = "BetweenTenAndTwenty";
  YearsEmployed["MoreThanTwenty"] = "MoreThanTwenty";
})(YearsEmployed || (YearsEmployed = {}));

export var AllYearsEmployed = [YearsEmployed.BetweenZeroAndOne, YearsEmployed.BetweenTwoAndFive, YearsEmployed.BetweenFiveAndTen, YearsEmployed.BetweenTenAndTwenty, YearsEmployed.MoreThanTwenty];
export var YearsEmployedDescription;

(function (YearsEmployedDescription) {
  YearsEmployedDescription["BetweenZeroAndOne"] = "1 year or less";
  YearsEmployedDescription["BetweenTwoAndFive"] = "2 - 5 years";
  YearsEmployedDescription["BetweenFiveAndTen"] = "5 - 10 years";
  YearsEmployedDescription["BetweenTenAndTwenty"] = "10 - 20 years";
  YearsEmployedDescription["MoreThanTwenty"] = "20+ years";
})(YearsEmployedDescription || (YearsEmployedDescription = {}));

export var MaxNumberOfPhones = 5;
export var PhoneType;

(function (PhoneType) {
  PhoneType["Home"] = "Home";
  PhoneType["Mobile"] = "Mobile";
  PhoneType["Work"] = "Work";
})(PhoneType || (PhoneType = {}));

export var AllPhoneTypes = [PhoneType.Home, PhoneType.Mobile, PhoneType.Work];
export var ErrorType;

(function (ErrorType) {
  ErrorType["Amount"] = "amount";
  ErrorType["Invalid"] = "invalid";
  ErrorType["Mismatch"] = "mismatch";
  ErrorType["Required"] = "error";
  ErrorType["Stale"] = "stale";
  ErrorType["Upload"] = "upload";
  ErrorType["Contingent"] = "contingent";
})(ErrorType || (ErrorType = {}));

export var FlexibilityType;

(function (FlexibilityType) {
  FlexibilityType["CashOnly"] = "CashOnly";
  FlexibilityType["Margin"] = "Margin";
})(FlexibilityType || (FlexibilityType = {}));

export var AllFlexibilityTypes = [FlexibilityType.Margin, FlexibilityType.CashOnly];
export var RE_EMAIL = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export var ProSubType;

(function (ProSubType) {
  ProSubType["Delayed"] = "Delayed";
  ProSubType["RealTime"] = "Realtime";
})(ProSubType || (ProSubType = {}));

export var DefaultPhoneValues = {
  phoneNumber: '',
  type: ''
};
export var AccountManagementDocuments;

(function (AccountManagementDocuments) {
  AccountManagementDocuments["DriversLicense"] = "DriversLicense";
  AccountManagementDocuments["StateIdCard"] = "StateIdCard";
  AccountManagementDocuments["Passport"] = "Passport";
  AccountManagementDocuments["MilitaryId"] = "MilitaryId";
  AccountManagementDocuments["SsnCard"] = "SsnCard";
  AccountManagementDocuments["SsaLetter"] = "SsaLetter";
  AccountManagementDocuments["IrsItinLetter"] = "IrsItinLetter";
  AccountManagementDocuments["OtherGovernmentId"] = "OtherGovernmentId";
  AccountManagementDocuments["CddDocument"] = "CddDocument";
  AccountManagementDocuments["AllPassingCipResults"] = "AllPassingCipResults";
  AccountManagementDocuments["IrsW9Form"] = "IrsW9Form";
  AccountManagementDocuments["SignatureImage"] = "SignatureImage";
  AccountManagementDocuments["IdDocument"] = "IdDocument";
  AccountManagementDocuments["AffiliateApproval"] = "AffiliateApproval";
  AccountManagementDocuments["Document"] = "Document";
  AccountManagementDocuments["Other"] = "Other";
  AccountManagementDocuments["GovernmentId"] = "GovernmentId";
})(AccountManagementDocuments || (AccountManagementDocuments = {}));

export var AccountManagementBeneficiaryAccountType;

(function (AccountManagementBeneficiaryAccountType) {
  AccountManagementBeneficiaryAccountType["Contingent"] = "Contingent";
  AccountManagementBeneficiaryAccountType["Primary"] = "Primary";
})(AccountManagementBeneficiaryAccountType || (AccountManagementBeneficiaryAccountType = {}));

export var AccountManagementBeneficiaryType;

(function (AccountManagementBeneficiaryType) {
  AccountManagementBeneficiaryType["Individual"] = "Individual";
  AccountManagementBeneficiaryType["Trust"] = "Trust";
})(AccountManagementBeneficiaryType || (AccountManagementBeneficiaryType = {}));

export var AllAccountManagementBeneficiaryTypes = [AccountManagementBeneficiaryType.Individual, AccountManagementBeneficiaryType.Trust];
export var AllAccountManagementBeneficiaryAccountTypes = [AccountManagementBeneficiaryAccountType.Primary, AccountManagementBeneficiaryAccountType.Contingent];