import React, { createContext, useContext, useEffect, useState } from 'react';

// Define the context shape
interface LinkClickedContextType {
  linkClicked: boolean;
  setLinkClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with an undefined default value (it will be set in the provider)
const LinkClickedContext = createContext<LinkClickedContextType | undefined>(undefined);

// Export a custom hook for consuming the context
export const useLinkClicked = () => {
  const context = useContext(LinkClickedContext);
  if (!context) {
    throw new Error('useLinkClicked must be used within a LinkClickedProvider');
  }
  return context;
};

// Context provider component
export const LinkClickedProvider: React.FC = ({ children }) => {
  const [linkClicked, setLinkClicked] = useState(false);

  return <LinkClickedContext.Provider value={{ linkClicked, setLinkClicked }}>{children}</LinkClickedContext.Provider>;
};
