import { useEffect } from 'react';
import { GetUrlParams } from '@tradingblock/api';
import { useDispatcher } from '../components/hooks';
import { UiActions } from '../state/actions';
import { useData } from '../state/selectors';

export const useAffiliate = () => {
  const dispatch = useDispatcher();
  const params = GetUrlParams(window.location.search);
  const affiliate = useData(d => d.ui.affiliate);
  useEffect(() => {
    const affiliate = params['affiliate'];
    if (affiliate && affiliate !== 'undefined') {
      // if arriving with affiliate param, store in state
      dispatch(UiActions.setAffiliate(affiliate));
    }
  }, [params, affiliate, dispatch]);
};
