import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { PersonType, SummaryStepProps } from '../../../types';
import { FieldValue, PersonValue, PhoneValue } from '../../values';
import { showBeneficiariesFields, showOwnersOfficersFields, showTrustedContactFields } from '../../../services';
import { BeneficiariesList } from './beneficiaries/BeneficiariesList';
import { OwnersOfficersList } from './beneficiaries/OwnersOfficersList';

export const BeneficiariesSummary: React.FC<SummaryStepProps> = ({ application, getText, activeLanguage }) => {
  const showBeneficiaries = showBeneficiariesFields(application);
  const showOwnersOfficers = showOwnersOfficersFields(application);
  const showTrustedContact = showTrustedContactFields(application);
  if (!application) {
    return null;
  }
  return (
    <>
      {showBeneficiaries && (
        <>
          <h3 className="fields-title">{getText('beneficiaries')}</h3>
          <BeneficiariesList application={application} getText={getText} activeLanguage={activeLanguage} />
        </>
      )}

      {showOwnersOfficers && (
        <>
          <h3 className="fields-title">{getText('ownersOfficers')}</h3>
          <OwnersOfficersList application={application} getText={getText} activeLanguage={activeLanguage} />
        </>
      )}

      {showTrustedContact && (
        <>
          <h3 className="fields-title">{getText('trustedContact.label')}</h3>
          {!application.addTrustedContact && (
            <p>
              <Text id="skipped" />
            </p>
          )}
          {application.addTrustedContact && (
            <p>
              <PersonValue value={application.trustedContact} personType={PersonType.TrustedContact}>
                <FieldValue value={application.trustedContact && application.trustedContact.email} />
                <br />
                <PhoneValue value={_.first(application.trustedContact && application.trustedContact.phones)} />
              </PersonValue>
            </p>
          )}
        </>
      )}
    </>
  );
};
