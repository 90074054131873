import jwt from 'jsonwebtoken';
import _ from 'lodash';
export var verify = function verify(key, value, _ref) {
  var clientType = _ref.clientType,
      algorithms = _ref.algorithms,
      ignoreExpiration = _ref.ignoreExpiration;
  var clientTypes = _.isArray(clientType) ? clientType : [clientType];

  if (!value) {
    return Promise.resolve(null);
  }

  return new Promise(function (resolve, reject) {
    return jwt.verify(value, key, {
      algorithms: algorithms,
      ignoreExpiration: ignoreExpiration
    }, function (err, payload) {
      if (payload) {
        var _jwt = payload;

        if (!clientTypes.includes(_jwt.clientType)) {
          var expected = clientTypes.join('|');
          reject("Incorrect clientType '".concat(_jwt.clientType, "'. Expected '").concat(expected, "'"));
        }

        return resolve(payload);
      } else {
        console.error('jwt verification failed', err);
        return resolve(null);
      }
    });
  });
};
export var sign = function sign(key, value, opts) {
  try {
    return jwt.sign(value, key, opts);
  } catch (err) {
    console.error('jwt signing error', err);
  }

  return null;
};