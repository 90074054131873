import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Section, Text, Svg, ContactPhone, GetTradingBlockSiteName, ContactEmail } from '@tradingblock/components';
import { getForcedOffRampUrl } from '../services';
import { ApplicationLoader } from '../components/application';
import { PageTitle } from '../components';
import { useData } from '../state/selectors';
import config from '../config';
import { ApplicationInvestigationUploadedDocument, DefaultApplicationModel } from '../types';
import { useDispatcher } from '../components/hooks';
import { ApplicationActions } from '../state/actions';
import {
  ApplicationStatusApplicationDocuments,
  ApplicationStatusInvestigations,
  RequestedInvestigationDocument,
} from '@tradingblock/types';
import { getRequestedParticipantsDocuments } from '@tradingblock/api';

const ThankYouPage: React.FC = () => {
  const application = useData(d => d.data.application);
  const createdUser = useData(d => d.data.createdUser);
  const siteGrpParam = process.env.REACT_APP_TB_SITE_GROUP;
  const offrampUrl = getForcedOffRampUrl(application);
  const isOffline = offrampUrl === '/apply/offline';
  const applicationStatus = useData(d => d.data.status);

  const { accountId } = application || DefaultApplicationModel;
  const { state, investigations, applications } = applicationStatus || {
    state: undefined,
    investigations: [],
    applications: [],
  };
  const authToken = useData(d => d.ui.apiToken);
  const authTokenExpired = useData(d => d.ui.apiTokenExpired);
  const dispatch = useDispatcher();

  useEffect(() => {
    // check for application status if account already created
    if (accountId && authToken && !authTokenExpired) {
      dispatch(ApplicationActions.requestApplicationStatus({ accountId }));
    }
  }, [accountId, dispatch, authToken, authTokenExpired]);

  const requestedParticipantsDocuments = useMemo(() => {
    return getRequestedParticipantsDocuments(investigations, applications);
  }, [investigations, applications]);

  // boolean to check if we have any requested documents
  const areDocumentsRequested = useMemo(() => {
    return requestedParticipantsDocuments.some(
      participant =>
        participant.requestedCatagories.investigations.length > 0 ||
        participant.requestedCatagories.application.length > 0
    );
  }, [requestedParticipantsDocuments]);

  const contentTextKey = config.isVirtual ? 'content-virtual' : 'content';
  const contactTextId = useMemo(() => `contact.${GetTradingBlockSiteName(undefined, siteGrpParam)}`, [siteGrpParam]);

  // since api token not used for virtual application, need to allow anonymous access if created user login
  const allowAnonymous = config.isVirtual && createdUser;

  if (!config.isVirtual && (!application || !application.applicationSentOn)) {
    return <Redirect to="/" />;
  }

  return (
    <ApplicationLoader defaultRedirect="/" allowAnonymous={allowAnonymous}>
      <PageTitle page="thanks" />
      <Section>
        <div className="step-title">
          <h1>
            <Text id="step-title" page="thanks" />
          </h1>
        </div>
      </Section>
      <Section>
        <h2 className="fields-title">
          <Text id={isOffline ? 'content.offline' : contentTextKey} page="thanks" />
        </h2>

        <p>
          <Text id={contentTextKey} page="thanks" textKey="description" />
        </p>

        {!config.isVirtual && (
          <>
            <h2 className="fields-title">
              <Text id="whats-next" page="thanks" />
            </h2>
            <p>
              {!areDocumentsRequested && (
                <Link className="btn btn-block btn-outline-dark" to="/status">
                  <i className="far fa-lg fa-tasks"></i> <Text id="whats-next.check-status" page="thanks" />
                </Link>
              )}
              {areDocumentsRequested && (
                <Link className="btn btn-block btn-outline-warning" to="/status">
                  <i className="far fa-lg fa-tasks"></i> <Text id="whats-next.documents-required" page="thanks" />
                </Link>
              )}
            </p>
            <p>
              {/* eslint-disable-next-line */}
              <a className="btn btn-block btn-outline-dark" href="#">
                <i className="far fa-lg fa-money-check"></i> <Text id="whats-next.fund-account" page="thanks" />
              </a>
            </p>
            <p>
              {/* eslint-disable-next-line */}
              <a className="btn btn-block btn-outline-dark" href="#">
                <i className="far fa-lg fa-video"></i> <Text id="whats-next.first-trade" page="thanks" />
              </a>
            </p>
          </>
        )}
      </Section>
      <Section>
        <p>
          <span className="tb-icon">
            <Svg path="icon-phone" />
          </span>{' '}
          <Text
            id={`${contentTextKey}.help`}
            page="thanks"
            siteGroup={siteGrpParam}
            data={{
              contact: <Text id={contactTextId} page="thanks" />,
              email: <ContactEmail siteGroup={siteGrpParam} />,
              phone: (
                <strong>
                  <ContactPhone siteGroup={siteGrpParam} />
                </strong>
              ),
            }}
          />
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default ThankYouPage;
