import React from 'react';
import _ from 'lodash';
import { Country, State as StateEnum } from '@tradingblock/types';
import { FieldValue } from '.';

const State: React.FC<{ value: string | undefined; country: string | undefined }> = ({ value, country }) => {
  const usState = country === Country.UnitedStatesOfAmerica && _.includes(Object.values(StateEnum), value);
  return <FieldValue value={value} textPrefix={usState ? 'defaults.state' : undefined} />;
};

export const StateValue = State;
