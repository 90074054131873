import React, { useMemo } from 'react';
import _ from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { useLocalize } from '../../hooks';
var externalLinkTextId = 'externalLink';

var ExternalLinkContent = function ExternalLinkContent(_ref) {
  var href = _ref.href,
      className = _ref.className,
      onClick = _ref.onClick,
      translate = _ref.translate,
      children = _ref.children;

  var _useLocalize = useLocalize(),
      getTranslationId = _useLocalize.getTranslationId;

  var ariaLabel = useMemo(function () {
    var val = translate(getTranslationId({
      id: externalLinkTextId
    }));
    return _.isString(val) ? val : _.startCase(externalLinkTextId);
  }, [translate, getTranslationId]);
  return React.createElement("a", {
    href: href,
    className: className,
    target: "_blank",
    rel: "noopener noreferrer",
    "aria-label": "(".concat(ariaLabel, ")"),
    onClick: onClick
  }, children);
};

export var ExternalLink = withLocalize(ExternalLinkContent);