import _ from 'lodash';
export var stringifyObject = function stringifyObject(obj) {
  var cache = [];
  var res = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      } // Store value in our collection


      cache.push(value);
    }

    return value;
  });
  cache = null;
  return res;
};
export var GetUrlParams = function GetUrlParams(href) {
  return _.chain(href).split('?').nth(1).split('&').map(function (v) {
    return _.split(v, '=', 2);
  }).fromPairs().value();
};