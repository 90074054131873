export var AssetType;

(function (AssetType) {
  AssetType[AssetType["Equity"] = 1] = "Equity";
  AssetType[AssetType["Option"] = 2] = "Option";
  AssetType[AssetType["MutualFund"] = 3] = "MutualFund";
  AssetType[AssetType["MoneyMarket"] = 4] = "MoneyMarket";
  AssetType[AssetType["Bond"] = 5] = "Bond";
  AssetType[AssetType["Index"] = 6] = "Index";
})(AssetType || (AssetType = {}));

export var OrderAction;

(function (OrderAction) {
  OrderAction[OrderAction["Buy"] = 1] = "Buy";
  OrderAction[OrderAction["Sell"] = 2] = "Sell";
})(OrderAction || (OrderAction = {}));

export var OrderActionDirection;

(function (OrderActionDirection) {
  OrderActionDirection[OrderActionDirection["Long"] = 1] = "Long";
  OrderActionDirection[OrderActionDirection["Short"] = 2] = "Short";
})(OrderActionDirection || (OrderActionDirection = {}));

export var PositionEffect;

(function (PositionEffect) {
  PositionEffect[PositionEffect["Open"] = 1] = "Open";
  PositionEffect[PositionEffect["Close"] = 2] = "Close";
})(PositionEffect || (PositionEffect = {}));

export var OrderType;

(function (OrderType) {
  /// <summary>
  /// Buy or sell at the current price, whatever that price may be
  /// </summary>
  OrderType[OrderType["Market"] = 1] = "Market"; /// <summary>
  /// Buy or sell at a specific price or better
  /// </summary>

  OrderType[OrderType["Limit"] = 2] = "Limit"; /// <summary>
  /// Converts the order to a market order if the price of the security reaches a certain threshold
  /// </summary>

  OrderType[OrderType["Stop_Market"] = 3] = "Stop_Market"; /// <summary>
  /// Converts the order to a limit order if the price of the security reaches a certain threshold
  /// </summary>

  OrderType[OrderType["Stop_Limit"] = 4] = "Stop_Limit"; /// <summary>
  /// Marks the order to be executed immediately before the market close as a market order
  /// </summary>

  OrderType[OrderType["Market_On_Close"] = 5] = "Market_On_Close";
})(OrderType || (OrderType = {}));

export var OrderClass;

(function (OrderClass) {
  OrderClass[OrderClass["Single"] = 1] = "Single";
  OrderClass[OrderClass["Multileg"] = 2] = "Multileg";
})(OrderClass || (OrderClass = {})); // export type OrderClass = keyof typeof OrderClasses;


export var DebitOrCredit;

(function (DebitOrCredit) {
  DebitOrCredit[DebitOrCredit["Debit"] = 1] = "Debit";
  DebitOrCredit[DebitOrCredit["Credit"] = 2] = "Credit";
})(DebitOrCredit || (DebitOrCredit = {}));

export var OptionType;

(function (OptionType) {
  OptionType[OptionType["Call"] = 1] = "Call";
  OptionType[OptionType["Put"] = 2] = "Put";
})(OptionType || (OptionType = {}));

export var StrategyType;

(function (StrategyType) {
  StrategyType[StrategyType["Bull"] = 1] = "Bull";
  StrategyType[StrategyType["Bear"] = 2] = "Bear";
  StrategyType[StrategyType["Volatility"] = 3] = "Volatility";
})(StrategyType || (StrategyType = {}));

export var SizeRatio;

(function (SizeRatio) {
  SizeRatio["NA"] = "NA";
  SizeRatio["HundredToOne"] = "100:1";
  SizeRatio["HundredToOneToOne"] = "100:1:1";
  SizeRatio["OneToOne"] = "1:1";
  SizeRatio["OneTwoOne"] = "1:2:1";
  SizeRatio["OneToOneToOneToOne"] = "1:1:1:1";
})(SizeRatio || (SizeRatio = {}));

export var BlockType;

(function (BlockType) {
  BlockType["Account"] = "Account";
  BlockType["AccountManagement"] = "AccountManagement";
  BlockType["AccountTransfer"] = "AccountTransfer";
  BlockType["Activity"] = "Activity";
  BlockType["Favorites"] = "Favorites";
  BlockType["OptionChain"] = "OptionChain";
  BlockType["Order"] = "Order";
  BlockType["Positions"] = "Positions";
  BlockType["PriceChart"] = "PriceChart";
  BlockType["Quote"] = "Quote";
  BlockType["Info"] = "Info";
  BlockType["Strategy"] = "Strategy";
  BlockType["AdminTools"] = "AdminTools";
  BlockType["Analytics"] = "Analytics";
  BlockType["AccountStatements"] = "AccountStatements";
  BlockType["SubAccountManagement"] = "Sub Account Management";
})(BlockType || (BlockType = {}));

export var BlockTypeValues = Object.values(BlockType);
export var AlertType;

(function (AlertType) {
  AlertType["danger"] = "danger";
  AlertType["info"] = "info";
  AlertType["success"] = "success";
  AlertType["warning"] = "warning";
})(AlertType || (AlertType = {}));

export var AccountRole;

(function (AccountRole) {
  AccountRole["Cashiering"] = "Cashiering";
})(AccountRole || (AccountRole = {}));

export var AccountType;

(function (AccountType) {
  /// <summary>
  /// An individual account is an account that is owned by one person.
  /// </summary>
  AccountType[AccountType["Individual"] = 1] = "Individual"; /// <summary>
  /// Joint Tenants with Rights of Survivorship accounts are accounts where all the assets
  /// are transferred into the name of the surviving party in the event of one tenant's
  /// death. The surviving party becomes the sole owner of the assets in the account. Both
  /// parties on the account have an equal and undivided interest in the assets in the
  /// account.
  /// </summary>

  AccountType[AccountType["JTWROS"] = 2] = "JTWROS"; /// <summary>
  /// Traditional individual retirement accounts allow individuals to direct pretax income
  /// towards investments that can grow tax-deferred; no capital gains or dividend income
  /// is taxed until it is withdrawn.
  /// </summary>

  AccountType[AccountType["Traditional_IRA"] = 3] = "Traditional_IRA"; /// <summary>
  /// An account that is opened by an individual and managed by a designated trustee for
  /// the benefit of a third-party in accordance with agreed-upon terms.
  /// </summary>

  AccountType[AccountType["Trust"] = 4] = "Trust"; /// <summary>
  /// Individual retirement arrangement rollover accounts are a transfer of funds from a
  /// retirement account into a traditional IRA or a Roth IRA.
  /// </summary>

  AccountType[AccountType["IRA_RollOver"] = 5] = "IRA_RollOver"; /// <summary>
  /// Roth individual retirement accounts are a type of qualified retirement plan that
  /// is very similar to a traditional IRA, but is taxed differently. Roth IRAs are funded
  /// with after-tax dollars so contributions are not tax deductible, but when you start
  /// withdrawing funds, qualified distributions are tax free.
  /// </summary>

  AccountType[AccountType["Roth_IRA"] = 6] = "Roth_IRA"; /// <summary>
  /// Simplified employee pension plan IRAs are retirement savings plans established by
  /// employers, including self-employed individuals, for the benefit of their employees.
  /// Employers may make tax-deductible contributions on behalf of eligible employees,
  /// and are made on a discrtionary basis. If the employer does contribute, it must
  /// contribute the same percentage of compensation to all employees eligible for the
  /// plan.
  /// </summary>

  AccountType[AccountType["SEP_IRA"] = 7] = "SEP_IRA"; /// <summary>
  /// Joint Tenants in Common accounts are owned by at least two people with no rights of
  /// survivorship afforded to any of the account holders. In these accounts, a surviving
  /// tenant of the account does not necessarily acquire the rights and assets of the
  /// deceased person. Instead, each tenant can stipulate in a written will how his/her
  /// assets will be distributed upon his/her death.
  /// </summary>

  AccountType[AccountType["JTIC"] = 8] = "JTIC"; /// <summary>
  /// The corporate resolution will specify which officers may trade in the corporation's
  /// account. In addition, a certified copy of the corporate charter and the company's
  /// bylaws are required to open a margin account. There are two sub-categories:
  /// S-Corp and C-Corp.
  /// </summary>

  AccountType[AccountType["Corporate"] = 9] = "Corporate"; /// <summary>
  /// Limited Liability Company is a corporate structure whereby the members of the
  /// company cannot be held personally liable for the company's debts or liabilities.
  /// LLCs are essentially hybrid entities that combine the characteristics of a
  /// corporation and a partnership or sole proprietorship.
  /// </summary>

  AccountType[AccountType["LLC"] = 10] = "LLC"; /// <summary>
  /// Partnerships are an arrangement by which two or more persons agree to share in all
  /// assets, profits and financial and legal liabilities of a business. Such partnerships
  /// have unlimited liability, which means their personal assets are liable to the
  /// partnership's obligations.
  /// </summary>

  AccountType[AccountType["Partnership"] = 11] = "Partnership"; /// <summary>
  /// Qualified Plans meet Internal Revenue Code Section 401(1) and are therefore eligible
  /// to receive certain tax benefits. They are established by an employer for the benefit
  /// of the company's employees, and give employers a tax break for the contributions
  /// they make for their employees. They may also allow employees to defer a portion of
  /// their salaries into the plan to reduce employees' present income-tax liability by
  /// reducing taxable income.
  /// </summary>

  AccountType[AccountType["Qualified_Plan"] = 12] = "Qualified_Plan"; /// <summary>
  /// If the taxpayer is a sole business owner or professional in their own practice, they
  /// are usually practicing as a sole proprietor. In this type of entity, you report
  /// business profit or loss on your personal tax return.
  /// </summary>

  AccountType[AccountType["Sole_Proprietorship"] = 13] = "Sole_Proprietorship"; /// <summary>
  /// An Investment Club is a group of people who pool their money to make investments.
  /// Usually, investment clubs are organized as partnerships and after the members study
  /// different investments, the group decides to buy or sell based on a majority vote of
  /// the members.
  /// </summary>

  AccountType[AccountType["InvestmentClub"] = 14] = "InvestmentClub"; /// <summary>
  /// Tenants by Entirety accounts are joint accounts in which married couples can hold
  /// the title to a property. In order for one spouse to modify his or her interest in
  /// the property in any way, the consent of both spouses is required by tenants by
  /// entirety. It also provides that when one spouse passes away, the surviving spouse
  /// gains full ownership of the property.
  /// </summary>

  AccountType[AccountType["TBE"] = 15] = "TBE"; /// <summary>
  /// Joint Community Property (also known as marital property) accounts refer to a U.S.
  /// state-level legal distinction of a married individual's assets. Property acquired
  /// by either spouse during a marriage is considered community property, belonging to
  /// both partners of marriage.
  /// </summary>

  AccountType[AccountType["CommunityProperty"] = 16] = "CommunityProperty"; /// <summary>
  /// Coverdell / Educational IRAs allow individuals to contribute up to $2,000 in
  /// after-tax dollars to an educational IRA for each student under the age of 18
  /// years of age. The money is allowed to grow tax deferred and the growth may be
  /// withdrawn tax free, as long as the money is used for educational purposes.
  /// </summary>

  AccountType[AccountType["Coverdell_IRA"] = 17] = "Coverdell_IRA"; /// <summary>
  /// Uniform Gifts to Minors Act (UGMA) and Uniform Trasfers to Minors Act (UTMA)
  /// allow minors to own assets including securities. Individuals can establish UGMA
  /// accounts on behalf of minors or beneficiaries, eliminating the need for an
  /// attorney to establish a special trust fund.
  /// </summary>

  AccountType[AccountType["UGMA"] = 18] = "UGMA";
})(AccountType || (AccountType = {}));

export var MarketStateTypes;

(function (MarketStateTypes) {
  MarketStateTypes["Open"] = "Open";
  MarketStateTypes["Close"] = "Close";
})(MarketStateTypes || (MarketStateTypes = {}));

export var Groups;

(function (Groups) {
  Groups["mb"] = "mb";
  Groups["tb"] = "tb";
})(Groups || (Groups = {}));