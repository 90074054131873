import _ from 'lodash';
import { Country, DeliveryType, HeardAboutInfo, MarketDataEntitlementTypes } from '@tradingblock/types';
import {
  AllExperienceKeys,
  ApplicationStep,
  BooleanToggleType,
  EmploymentType,
  PersonType,
  ApplicationType,
  FlexibilityType,
  ApplicationModel,
  AccountHolderDisclosureContingentFields,
  ExperienceKey,
  EntityAccountType,
  RetirementAccountType,
  AccountDisclosureKey,
  AccountHolderDisclosureKey,
  AccountDisclosureContingentFields,
  JointAccountType,
  AccountDisclosureContingentFieldsDefaults,
  ExperienceYearType,
  ExperienceModel,
  AccountHolderField,
  DefaultAccountHolder,
  FieldsToStrip,
  IncomeRangeType,
  MonetaryRangeType,
  TradeGoalType,
  InvestmentObjectiveType,
  AllApplicationSteps,
  UploadModel,
  UploadKey,
  DefaultPersonDetails,
  AccountHolderModel,
  ForeignDueDiligenceModel,
  DateModel,
  PersonDetailsModel,
  OwnerEntityModel,
  BeneficiaryModel,
  RiskToleranceType,
  ServiceProfileKey,
  DefaultSecondaryAccountHolder,
  DefaultUgmaAccountHolder,
  ClearerType,
  ProSubscriptionType,
  DefaultTradingAuthorization,
  BeneficiaryType,
} from '../types';
import { getNextApplicationStep } from './Application';
import { isNonZeroYear } from './Form';

export const copyAccountHolderPrimaryAddresses = (values: ApplicationModel) => {
  return {
    ...values,
    secondaryAccountHolder: {
      ...DefaultSecondaryAccountHolder,
      ...values.secondaryAccountHolder,
      address: values.primaryAccountHolder && values.primaryAccountHolder.address,
      differentMailingAddress: values.primaryAccountHolder && values.primaryAccountHolder.differentMailingAddress,
      mailingAddress: values.primaryAccountHolder && values.primaryAccountHolder.mailingAddress,
    },
  };
};

const resetApplicationLastStep = (values: ApplicationModel, step = ApplicationStep.AccountType) => {
  const nextStep = getNextApplicationStep(step);
  // only reset step if already completed step
  const resetStep =
    values.latestStepCompleted &&
    _.indexOf(AllApplicationSteps, values.latestStepCompleted) >= _.indexOf(AllApplicationSteps, step);
  // resetting all data entered on agreements/disclosures step
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    latestStepCompleted: resetStep ? step : values.latestStepCompleted,
    latestStepSaved: resetStep ? nextStep : values.latestStepSaved,
    latestStepVisibleSections: resetStep ? 1 : values.latestStepVisibleSections,
    tradeAuthorization: null,
    tradeAuthorizationAgentName: null,
    primaryAccountHolder: {
      ...values.primaryAccountHolder,
      disclosures: {
        govOfficial: null,
        irsWithholding: null,
        stakeholder: null,
        industryEmployed: null,
      },
      disclosuresGovOfficialImmediateFamily: '',
      disclosuresGovOfficialPoliticalOrg: '',
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: '',
    },
    secondaryAccountHolder: values.secondaryAccountHolder && {
      ...values.secondaryAccountHolder,
      disclosuresGovOfficialImmediateFamily: '',
      disclosuresGovOfficialPoliticalOrg: '',
      disclosuresIndustryEmployedFirmName: '',
      disclosuresStakeholderTickerSymbol: '',
    },
    accountDisclosures: {
      foreignBank: null,
      foreignShellBank: null,
      foreignFinancialInstitution: null,
      proprietarySecuritiesAccount: null,
      entityIssuesBearerShares: null,
      entityNegativeNews: null,
      entityPublicOfficials: null,
      entityServicesForeignShellBanks: null,
    },
    serviceProfile: {
      issuerDirectCommunication: null,
    },
    disclosuresForeignBankUSAgent: '',
    disclosuresEntityNegativeNewsInfo: '',
    disclosuresEntityPublicOfficials: [],
    agreeToElectronicConsent: null,
    agreements: {
      corporateCash: null,
      corporateMargin: null,
      customerAndPrivacyPolicy: null,
      entityMargin: null,
      entityOption: null,
      iRARothAdoption: null,
      iRASepAdoption: null,
      iRASimpleAdoption: null,
      iRACoverDellAdoption: null,
      jointCommunity: null,
      jointSurvivor: null,
      jointTenantsInCommon: null,
      jointTenantsInEntirety: null,
      limitedMargin: null,
      llcAccount: null,
      margin: null,
      option: null,
      riskDisclosure: null,
      electronicConsent: null,
      onlineServices: null,
      exchangeDataAgreement: null,
      clientRelationshipSummary: null,
      apiAgreement: null,
      rqdCustomerAgreement: null,
      rqdOption: null,
      rqdMargin: null,
      rqdLimitedMargin: null,
      rqdExecutionDisclosures: null,
      rqdClearingDisclosures: null,
      rqdCustomerInformationBrochure: null,
      rqdPrivacyPolicy: null,
      rqdPrivacyNotice: null,
      rqdResponsibilitiesOfIbAndCarryingBroker: null,
      rqdSpecialStatementForUncoveredOptionWriters: null,
      rqdPowerOfAttorney: null,
      rqdLimitedTradingAuthorization: null,
    },
    signedNames: [],
    uploadedDocuments: null,
  };
};

const resetConfirmedMismatchedDataTypes = (values: ApplicationModel) => {
  return {
    ...values,
    confirmedMismatchedDataTypes: null,
  };
};

export const updateFieldsDependentOnOwnerCountry = (values: ApplicationModel, ownerCountry: Country) => {
  values.primaryAccountHolder.address.country = ownerCountry;
  values.primaryAccountHolder.citizenshipCountry = ownerCountry;
  values.primaryAccountHolder.birthCountry = ownerCountry;
  return {
    ...resetApplicationLastStep(values),
    ownerCountry,
    retirementAccount: ownerCountry !== Country.UnitedStatesOfAmerica ? BooleanToggleType.No : values.retirementAccount,
    retirementAccountType: ownerCountry !== Country.UnitedStatesOfAmerica ? null : values.retirementAccountType,
    jointCommunityPropertyState: null,
  };
};

export const updateFieldsDependentOnApplicationType = (
  values: ApplicationModel,
  type: ApplicationType
): ApplicationModel => {
  return {
    ...resetApplicationLastStep(values),
    type,
    ...(type !== ApplicationType.Individual && values.clearer !== ClearerType.RQD
      ? {
          retirementAccount: null,
          retirementAccountType: null,
          beneficiaries: null,
        }
      : {}),
    ...(type !== ApplicationType.Joint
      ? {
          jointAccountType: null,
          secondaryAccountHolder: null,
          jointCommunityPropertyState: null,
        }
      : {
          secondaryAccountHolder: values.secondaryAccountHolder || DefaultSecondaryAccountHolder,
        }),
    ...(type === ApplicationType.UGMA
      ? {
          secondaryAccountHolder: values.secondaryAccountHolder || DefaultUgmaAccountHolder,
        }
      : {}),
    flexibilityType: type === ApplicationType.UGMA ? FlexibilityType.CashOnly : values.flexibilityType,
    ...(type !== ApplicationType.Entity
      ? {
          entity: null,
          entityAccountType: null,
          ownersOfficers: null,
        }
      : {}),
    primaryAccountHolder: {
      ...values.primaryAccountHolder,
      ...(type === ApplicationType.Entity
        ? {
            dependents: '',
            maritalStatus: '',
            visaType: null,
            visaExpirationDate: null,
          }
        : {}),
      ...(type === ApplicationType.Entity && values.entityAccountType !== EntityAccountType.PersonalTrust
        ? {
            employmentType: '',
            employer: null,
            jobPosition: null,
            yearsEmployed: null,
            employerAddress: {
              address1: '',
              address2: '',
              country: '',
              city: '',
              postalCode: null,
              state: null,
            },
          }
        : {}),
    },
    marketData: {
      ...values.marketData,
      ...(type === ApplicationType.Entity
        ? {
            corporationTrading: BooleanToggleType.Yes,
          }
        : {
            corporationTrading: BooleanToggleType.No,
          }),
    },
  };
};

export const updateFieldsDependentOnRetirementAccount = (
  values: ApplicationModel,
  retirementAccount: BooleanToggleType
) => {
  return {
    ...resetApplicationLastStep(values),
    retirementAccount,
    retirementAccountType: retirementAccount === BooleanToggleType.No ? null : values.retirementAccountType,
    beneficiaries: retirementAccount === BooleanToggleType.No ? null : values.beneficiaries,
  };
};

export const updateFieldsDependentOnRetirementAccountType = (
  values: ApplicationModel,
  retirementAccountType: RetirementAccountType
) => {
  if (
    retirementAccountType === RetirementAccountType.Beneficiary ||
    retirementAccountType === RetirementAccountType.RothBeneficiary
  ) {
    return {
      ...resetApplicationLastStep(values),
      retirementAccountType,
      beneficiaryIraDecedentName: '',
    };
  }
  return {
    ...resetApplicationLastStep(values),
    retirementAccountType,
    flexibilityType:
      retirementAccountType === RetirementAccountType.CoverDell ? FlexibilityType.CashOnly : values.flexibilityType,
  };
};

export const updateFieldsDependentOnJointAccountType = (
  values: ApplicationModel,
  jointAccountType: JointAccountType
) => {
  if (jointAccountType === JointAccountType.Community) {
    return {
      ...resetApplicationLastStep(values),
      jointAccountType,
      jointCommunityPropertyState: null,
    };
  } else {
    return {
      ...resetApplicationLastStep(values),
      jointAccountType,
    };
  }
};

export const updateFieldsDependentOnEntityType = (values: ApplicationModel, entityAccountType: EntityAccountType) => {
  return {
    ...resetApplicationLastStep(values),
    entityAccountType: entityAccountType,
  };
};

export const updateFieldsDependentOnFlexibilityType = (values: ApplicationModel, flexibilityType: FlexibilityType) => {
  return {
    ...resetApplicationLastStep(values),
    flexibilityType,
  };
};

export const updateFieldsDependentOnTradeGoalType = (values: ApplicationModel, tradeGoalType: TradeGoalType) => {
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    tradeGoalType,
  };
};

export const updateFieldsDependentOnInvestmentObjectiveType = (
  values: ApplicationModel,
  investmentObjectiveType: InvestmentObjectiveType
) => {
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    investmentObjectiveType,
    characteristics: {
      ...values.characteristics,
      risk:
        investmentObjectiveType === InvestmentObjectiveType.Speculation
          ? RiskToleranceType.High
          : values.investmentObjectiveType === InvestmentObjectiveType.Speculation
          ? ''
          : values.characteristics.risk,
    },
  };
};

export const updateFieldsDependentOnExperienceYears = (
  values: ApplicationModel,
  key: ExperienceKey,
  value: ExperienceYearType
) => {
  const experience = values.experience || null;
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    experience: _.reduce(
      AllExperienceKeys,
      (acc, exp: ExperienceKey) => {
        if (exp === key) {
          return {
            ...acc,
            [key]: {
              years: value,
              tradesPerYear: isNonZeroYear(value)
                ? experience && experience[key] && experience[key].tradesPerYear
                : null,
            },
          };
        }
        return acc;
      },
      (experience || {}) as { [key in ExperienceKey]: ExperienceModel }
    ),
  };
};

export const updateFieldsDependentOnAnnualIncome = (values: ApplicationModel, annualIncome: IncomeRangeType) => {
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    annualIncome,
  };
};

export const updateFieldsDependentOnTotalNetWorth = (values: ApplicationModel, totalNetWorth: MonetaryRangeType) => {
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    totalNetWorth,
  };
};

export const updateFieldsDependentOnLiquidNetWorth = (values: ApplicationModel, liquidNetWorth: MonetaryRangeType) => {
  return {
    ...resetConfirmedMismatchedDataTypes(values),
    liquidNetWorth,
  };
};

export const updateFieldsDependentOnDifferentMailingAddress = (
  values: ApplicationModel,
  accountHolderField: AccountHolderField,
  differentMailingAddress: boolean
) => {
  return {
    ...values,
    [accountHolderField]: {
      ...values[accountHolderField],
      differentMailingAddress,
      ...(differentMailingAddress
        ? {
            mailingAddress: {
              country: '',
              address1: '',
              address2: '',
              city: '',
              postalCode: null,
              state: null,
            },
          }
        : { mailingAddress: null }),
    },
  };
};

export const updateFieldsDependentOnCitizen = (
  values: ApplicationModel,
  accountHolderField: AccountHolderField,
  citizen: boolean
) => {
  const accountHolder = values[accountHolderField] || DefaultAccountHolder;
  return {
    ...values,
    ...(!citizen && accountHolder.address.country !== Country.UnitedStatesOfAmerica
      ? {
          foreignDueDiligence: {
            expectedWithdrawalFrequency: '',
            initialContact: '',
            initialDepositAmount: 0,
            initialDepositType: '',
            primaryBanking: [''],
            referredName: '',
            referredRelationship: '',
            referredToBroker: true,
          },
        }
      : { foreignDueDiligence: null }),
    [accountHolderField]: {
      ...values[accountHolderField],
      citizenshipCountry: citizen ? Country.UnitedStatesOfAmerica : null,
      ...(!citizen
        ? {
            ssn: null,
            visaType: accountHolder.visaType,
            visaExpirationDate: accountHolder.visaExpirationDate,
          }
        : {
            visaType: null,
            visaExpirationDate: null,
          }),
    },
  };
};

export const updateFieldsDependentOnSecondaryCitizen = (
  values: ApplicationModel,
  accountHolderField: AccountHolderField,
  citizen: boolean
) => {
  const accountHolder = values[accountHolderField] || DefaultAccountHolder;

  return {
    ...values,
    [accountHolderField]: {
      ...values[accountHolderField],
      citizenshipCountry: citizen ? Country.UnitedStatesOfAmerica : null,
      ...(!citizen
        ? {
            ssn: null,
            visaType: accountHolder.visaType,
            visaExpirationDate: accountHolder.visaExpirationDate,
          }
        : {
            visaType: null,
            visaExpirationDate: null,
          }),
    },
  };
};

export const updateFieldsDependentOnEmploymentType = (
  values: ApplicationModel,
  accountHolderField: AccountHolderField,
  employmentType: EmploymentType
) => {
  const accountHolder = values[accountHolderField] || DefaultAccountHolder;
  return {
    ...values,
    [accountHolderField]: {
      ...values[accountHolderField],
      employmentType,
      jobPosition: employmentType !== EmploymentType.Employed ? null : accountHolder.jobPosition,
      yearsEmployed: employmentType !== EmploymentType.Employed ? null : accountHolder.yearsEmployed,
      employer: employmentType !== EmploymentType.Employed ? null : accountHolder.employer || '',
      employerAddress:
        employmentType !== EmploymentType.Employed
          ? null
          : accountHolder.employerAddress || {
              address1: '',
              address2: '',
              country: '',
              city: '',
              postalCode: null,
              state: null,
            },
    },
  };
};

export const updateFieldsDependentOnEmploymentTypeTradeAuth = (
  values: ApplicationModel,
  employmentType: EmploymentType
) => {
  return {
    ...values,
    tradingAuthorization: {
      ...values.tradingAuthorization,
      employmentType,
      jobPosition:
        employmentType !== EmploymentType.Employed
          ? null
          : values.tradingAuthorization && values.tradingAuthorization.jobPosition,
      employer:
        employmentType !== EmploymentType.Employed
          ? null
          : values.tradingAuthorization && values.tradingAuthorization.employer,
    },
  };
};

export const updateFieldsDependentOnAddTrustedContact = (values: ApplicationModel, addTrustedContact: boolean) => {
  return {
    ...values,
    addTrustedContact,
    trustedContact: addTrustedContact ? DefaultPersonDetails : null,
  };
};

export const updateFieldsDependentOnTrustedContactType = (values: ApplicationModel, contactType: PersonType) => {
  return {
    ...values,
    trustedContact: {
      ...values.trustedContact,
      contactType,
      address: {},
      phones: [{}],
      email: '',
      emailConfirm: '',
    },
  };
};

export const updateFieldsDependentOnTradeAuthorization = (
  values: ApplicationModel,
  tradeAuthorization: BooleanToggleType
) => {
  return {
    ...values,
    tradeAuthorization,
    tradingAuthorization: tradeAuthorization === BooleanToggleType.Yes ? DefaultTradingAuthorization : {},
  };
};

export const updateFieldsDependentOnAccountDisclosure = (
  values: ApplicationModel,
  key: AccountDisclosureKey,
  value: BooleanToggleType
) => {
  const contingentFields = AccountDisclosureContingentFields[key] || [];
  return {
    ...values,
    accountDisclosures: {
      ...values.accountDisclosures,
      [key]: value,
    },
    ..._.zipObject(
      contingentFields,
      _.map(contingentFields, f =>
        value === BooleanToggleType.Yes ? values[f] || AccountDisclosureContingentFieldsDefaults[f] : null
      )
    ),
  };
};

export const updateFieldsDependentOnAccountHolderDisclosure = (
  values: ApplicationModel,
  accountHolderField: AccountHolderField,
  key: AccountHolderDisclosureKey,
  value: BooleanToggleType
) => {
  const contingentFields = AccountHolderDisclosureContingentFields[key] || [];
  const accountHolder = values[accountHolderField] || DefaultAccountHolder;
  return {
    ...values,
    [accountHolderField]: {
      ...accountHolder,
      disclosures: {
        ...accountHolder.disclosures,
        [key]: value,
      },
      ..._.zipObject(
        contingentFields,
        _.map(contingentFields, f => (value === BooleanToggleType.Yes ? accountHolder[f] : null))
      ),
    },
  };
};

const getDocumentIdsForUploadModel = (model: UploadModel | undefined) => {
  const documentIds = _.filter(_.map(model && model.documents, d => d.id || ''), id => !_.isEmpty(id));
  return !_.isEmpty(documentIds) ? documentIds : null;
};

export const getHeardAbout = (heardAbout: HeardAboutInfo[] | undefined, hearAboutUsId: number | string | undefined) => {
  return _.find(heardAbout, h => h.heardAboutId === _.toNumber(hearAboutUsId));
};

export const cleanApplicationForStorage = (values: ApplicationModel): ApplicationModel => {
  // don't store sensitive user fields
  return {
    ..._.omit(values, ['userName', 'password', 'passwordConfirm', 'securityChallenges']),
  } as ApplicationModel;
};

export const cleanApplicationForSubmission = (
  values: ApplicationModel,
  heardAboutData: HeardAboutInfo[] | undefined
) => {
  // make sure experience is an empty object
  const experience = values.experience || {};
  const isPrimaryForeign = values.primaryAccountHolder.address.country !== Country.UnitedStatesOfAmerica;

  // transform BooleanToggleType in retirementAccount, tradeAuthorization, accountDisclosures, agreements
  const retirementAccount = cleanBooleanToggleType(values.retirementAccount);
  const tradeAuthorization = cleanBooleanToggleType(values.tradeAuthorization);
  const accountDisclosures = cleanBooleanToggleTypes(values.accountDisclosures);
  const agreements = cleanBooleanToggleTypes(values.agreements);
  const marketD = _.omit(values.marketData, 'employmentFunctions');
  const marketData = cleanBooleanToggleTypes(marketD);
  let foreignDueDiligence = values.foreignDueDiligence;

  if (!isPrimaryForeign) {
    foreignDueDiligence = null;
  }

  // transform heard about data into one object
  const heardAboutInfo = getHeardAbout(heardAboutData, values.hearAboutUsId);
  let officeCode = undefined;
  if (heardAboutInfo) {
    if (heardAboutInfo.officeCodes !== undefined) {
      if (values.clearer === ClearerType.RQD) {
        officeCode = heardAboutInfo.officeCodes['rqd'];
      } else {
        officeCode = heardAboutInfo.officeCodes['apex'];
      }
    } else {
      const repId = _.toNumber(values.repId);
      const reps = heardAboutInfo.representatives;
      if (reps !== undefined) {
        const rep = reps.find(rep => rep.repId === repId);
        if (rep !== undefined) {
          if (values.clearer === ClearerType.RQD) {
            officeCode = rep.officeCodes['rqd'];
          } else {
            officeCode = rep.officeCodes['apex'];
          }
        }
      }
    }
  }

  const heardAbout = {
    hearAboutUsId: (heardAboutInfo && heardAboutInfo.heardAboutId) || 0,
    //TODO: When Toby updates new endpoint we reorder the heardAboutInfo and will probably remove this "clean up" spot
    officeCode: officeCode,
    repId: _.toNumber(values.repId) || 0,
  };

  const tradingAuthorization = {
    ..._.omitBy(values.tradingAuthorization, _.isEmpty),
    dateOfBirth: values.tradingAuthorization && cleanDateModel(values.tradingAuthorization.dateOfBirth),
    disclosures: {
      industryEmployed:
        values.tradingAuthorization &&
        values.tradingAuthorization.disclosures &&
        cleanBooleanToggleType(values.tradingAuthorization.disclosures.industryEmployed),
      stakeholder:
        values.tradingAuthorization &&
        values.tradingAuthorization.disclosures &&
        cleanBooleanToggleType(values.tradingAuthorization.disclosures.stakeholder),
    },
  };

  const cleanValues = _.omitBy(values, _.isEmpty);

  return {
    ..._.omit(cleanValues, FieldsToStrip.root),
    primaryAccountHolder: cleanAccountHolder(cleanValues.primaryAccountHolder),
    secondaryAccountHolder:
      cleanValues.secondaryAccountHolder && cleanAccountHolder(cleanValues.secondaryAccountHolder),
    entity: cleanValues.entity && cleanOwnerEntity(cleanValues.entity),
    beneficiaries: cleanValues.beneficiaries && _.map(cleanValues.beneficiaries, cleanBeneficiary),
    ownersOfficers: cleanValues.ownersOfficers && _.map(cleanValues.ownersOfficers, cleanPersonDetails),
    experience,
    retirementAccount,
    tradingAuthorization: tradeAuthorization ? tradingAuthorization : undefined,
    accountDisclosures,
    agreements,
    heardAbout,
    marketData: {
      employmentFunctions: values.marketData.employmentFunctions || 'N/A',
      ...marketData,
    },
    marketDataType: values.marketDataType || ProSubscriptionType.Delayed,
    foreignDueDiligence,
  };
};

const cleanAccountHolder = (value: AccountHolderModel | null) => {
  // transform person details
  const personDetails = cleanPersonDetails(value);
  // transform DateModel in visaExpirationDate, BooleanToggleType in citizen and disclosures

  const accountHolder = {
    ...personDetails,
    visaExpirationDate: value && cleanDateModel(value.visaExpirationDate),
    disclosures: value && cleanBooleanToggleTypes(value.disclosures),
    foreignTaxWithholding:
      personDetails.address.country !== Country.UnitedStatesOfAmerica
        ? {
            taxIdentificationNumber: value && value.foreignTaxWithholding.taxIdentificationNumber,
            letterOfExplanation: value && value.foreignTaxWithholding.letterOfExplanation,
            treatyCountry: value && value.foreignTaxWithholding.treatyCountry,
            foreignTINNotRequiredExplanation: value && value.foreignTaxWithholding.foreignTINNotRequiredExplanation,
          }
        : null,
    ssnSecret: (value && value.ssn === '') || (value && !value.ssn) ? null : value && value.ssn,
  };
  return _.omit(accountHolder, FieldsToStrip.accountHolder);
};

const cleanBeneficiary = (value: BeneficiaryModel | null) => {
  // transform person details
  const beneficiary = cleanPersonDetails(value);

  if (value && value.beneficiaryType === BeneficiaryType.Trust) {
    return {
      ..._.omit(beneficiary, FieldsToStrip.trustBeneficiary),
      einSecret: value && value.ein,
    };
  }
  // strip values
  return {
    ..._.omit(beneficiary, FieldsToStrip.beneficiary),
    einSecret: value && value.ein,
  };
};

const cleanPersonDetails = (value: PersonDetailsModel | null) => {
  // strip values and transform DateModel in dateOfBirth
  // if the middle intial or suffix is a string of spaces or just an empty string, we want to set it to null

  return {
    ..._.omit(value, FieldsToStrip.person),
    dateOfBirth: value && cleanDateModel(value.dateOfBirth),
    ssnSecret: value && value.ssn,
    middleInitial:
      value && value.middleInitial && value.middleInitial.trim() !== '' ? value.middleInitial.trim() : null,
    suffix: value && value.suffix && value.suffix.trim() !== '' ? value.suffix.trim() : null,
  };
};

const cleanOwnerEntity = (value: OwnerEntityModel | null) => {
  // transform DateModel in trustCreatedDate
  const ownerEntity = {
    ...value,
    trustCreatedDate: value && cleanDateModel(value.trustCreatedDate),
  };
  return _.omit(ownerEntity, FieldsToStrip.entity);
};

const cleanBooleanToggleTypes = (
  values: { [key: string]: BooleanToggleType | null | undefined } | null | undefined
) => {
  return (
    values &&
    _.reduce(
      values,
      (acc: { [key: string]: boolean }, u, key) => {
        return {
          ...acc,
          [key]: cleanBooleanToggleType(u),
        };
      },
      {}
    )
  );
};

const cleanBooleanToggleType = (value: BooleanToggleType | boolean | null | undefined) => {
  return value === BooleanToggleType.Yes || value === true ? true : false;
};

const cleanDateModel = (value: DateModel | null | undefined) => {
  if (value) {
    return `${cleanDatePart(value.year)}-${cleanDatePart(value.month)}-${cleanDatePart(value.day)}`;
  }
  return null;
};

const cleanDatePart = (value: number | undefined) => {
  return value && `${value < 10 ? '0' : ''}${value}`;
};

export const cleanEntityApplicaitonForPartialSubmission = async (values: ApplicationModel, howDidYouHear: any) => {
  const howDidYouHearObject = howDidYouHear.filter((item: any) => item.heardAboutId == values.hearAboutUsId);
  const clearer = values.clearer === ClearerType.RQD ? 'rqd' : 'apex';
  let officeCodeValue;

  // if the howDidYouHearObject contains representatives, we need to pull the rep based on the repId and apply the officeCode there
  // check if the howDidYouHearObject has representatives
  if (howDidYouHearObject[0].representatives !== undefined) {
    // check if the repId is a string or number
    if (typeof values.repId === 'string') {
      // if the repId is a string, we need to convert it to a number
      const repId = parseInt(values.repId);
      // find the rep based on the repId
      const rep = howDidYouHearObject[0].representatives.find((rep: any) => rep.repId === repId);
      // if the rep is found, apply the officeCode
      if (rep !== undefined) {
        officeCodeValue = rep.officeCodes[clearer];
      }
    } else {
      // if the repId is a number, we can use it directly
      const rep = howDidYouHearObject[0].representatives.find((rep: any) => rep.repId === values.repId);
      // if the rep is found, apply the officeCode
      if (rep !== undefined) {
        officeCodeValue = rep.officeCodes[clearer];
      }
    }
  } else {
    // if the howDidYouHearObject does not have representatives, we can apply the officeCode directly
    officeCodeValue = howDidYouHearObject[0].officeCodes[clearer];
  }

  return {
    primaryAccountHolder: {
      email: values.primaryAccountHolder.email,
      firstName: values.primaryAccountHolder.firstName,
      lastName: values.primaryAccountHolder.lastName,
    },
    type: values.type,
    languageType: values.languageType,
    entityAccountType: values.entityAccountType,
    deliveryType: DeliveryType.Electronic,
    flexibilityType: FlexibilityType.Margin,
    tradeGoalType: TradeGoalType.AllAbovePlusCallsPuts,
    clearer: values.clearer,
    heardAbout: {
      hearAboutUsId: typeof values.hearAboutUsId === 'string' ? parseInt(values.hearAboutUsId) : values.hearAboutUsId,
      repId: values.repId,
      officeCode: officeCodeValue,
    },
  };
};
