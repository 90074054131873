import * as tslib_1 from "tslib";
import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
var ReactGridLayout = WidthProvider(Responsive);
export var Grid = function Grid(_a) {
  var id = _a.id,
      children = _a.children,
      storageProvider = _a.storageProvider,
      layouts = _a.layouts,
      isDashboardLocked = _a.isDashboardLocked,
      rest = tslib_1.__rest(_a, ["id", "children", "storageProvider", "layouts", "isDashboardLocked"]);

  return React.createElement(ReactGridLayout, Object.assign({
    layouts: layouts,
    draggableHandle: ".block-drag-handle",
    isDraggable: !isDashboardLocked,
    isResizable: !isDashboardLocked
  }, rest), children);
};