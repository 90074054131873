import * as tslib_1 from "tslib";
import React from 'react';
import _ from 'lodash';
import { useLocalize } from '../../../hooks';
import { Text } from '../';
import { TextareaInput } from '../../fields/Textarea';
export var TextareaField = function TextareaField(_a) {
  var field = _a.field,
      form = _a.form,
      id = _a.id,
      label = _a.label,
      labelPrefix = _a.labelPrefix,
      rest = tslib_1.__rest(_a, ["field", "form", "id", "label", "labelPrefix"]);

  var defaultLabelId = rest.defaultLabelId;
  var values = form.values,
      errors = form.errors,
      touched = form.touched,
      submitCount = form.submitCount;

  var value = _.get(values, id);

  var error = _.get(touched, id) ? _.get(errors, id) : undefined;

  var _useLocalize = useLocalize(),
      getFieldTranslationId = _useLocalize.getFieldTranslationId;

  var isPhoneNumber = _.includes(id, 'phone');

  var formatPhoneNumber = function formatPhoneNumber(value) {
    if (!value) return value;
    var phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;

    if (phoneNumber.length < 7) {
      return "".concat(phoneNumber.slice(0, 3), "-").concat(phoneNumber.slice(3));
    }

    if (phoneNumber.length <= 10) {
      return "".concat(phoneNumber.slice(0, 3), "-").concat(phoneNumber.slice(3, 6), "-").concat(phoneNumber.slice(6));
    }

    if (phoneNumber.length === 11) {
      return "".concat(phoneNumber.slice(0, 1), "-").concat(phoneNumber.slice(1, 4), "-").concat(phoneNumber.slice(4, 7), "-").concat(phoneNumber.slice(7));
    }

    return "".concat(phoneNumber.slice(0, 2), "-").concat(phoneNumber.slice(2, 6), "-").concat(phoneNumber.slice(6));
  };

  var formatValue = function formatValue(value) {
    if (isPhoneNumber && value) {
      return formatPhoneNumber(value);
    } else {
      return value;
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(TextareaInput, Object.assign({
    name: id,
    label: label || React.createElement(Text, {
      id: getFieldTranslationId({
        id: id,
        defaultLabelId: defaultLabelId
      }),
      type: "field",
      data: labelPrefix && {
        prefix: labelPrefix
      }
    }),
    value: formatValue(value),
    error: error,
    onblur: field.onBlur,
    onchange: field.onChange,
    autoComplete: id
  }, rest)), error && !!submitCount && React.createElement("span", {
    className: "error"
  }, React.createElement(Text, {
    id: id,
    textKey: "".concat(error),
    type: 'error',
    data: rest.data
  })));
};