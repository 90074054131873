import React, { useCallback, useMemo, useState } from 'react';
import { Field } from 'formik';
import { Modal as BootstrapModal } from 'react-bootstrap';
import _ from 'lodash';
import {
  Section,
  FormGroup,
  HelpText,
  TextboxField,
  UploadFileField,
  LocalizeButton as Button,
  Modal,
} from '@tradingblock/components';
import { UploadedDocument, ResponseCodes, GenericErrorResponseCode } from '@tradingblock/types';
import { UploadKey, ApplicationStepProps, ApplicationType, EntityAccountType } from '../../../types';
import { getOwnerNamesForSignStep } from '../../../services';
import { useData } from '../../../state/selectors';
import { Errors } from '../..';
import { useDispatcher } from '../../hooks';
import { ApplicationActions } from '../../../state/actions';

export const SignForm: React.FC<ApplicationStepProps> = ({ values, setValues, getText }) => {
  const dispatch = useDispatcher();

  const { isSaving } = useData(d => d.data.saveInfo);
  const uploadState = useData(d => d.data.uploadState);
  const createErrorCode = useData(d => d.data.createErrorCode);
  const errors = useData(d => d.data.createErrorMessages);

  const ownerNames = getOwnerNamesForSignStep(values);

  return (
    <>
      <Section>
        <h2 className="fields-title">{getText('signedNames')}</h2>
        <p>{getText('signedNames', 'description')}</p>

        <p>
          {ownerNames.map((n, i) => (
            <span key={`name${i}`}>
              {i > 0 && i < ownerNames.length && ownerNames.length > 2 ? ', ' : ''}
              {i > 0 && i === ownerNames.length - 1 && <> {getText('signedNames.delimiter')} </>}
              <strong key={`name${i}`}>{n}</strong>
            </span>
          ))}
          :
        </p>

        {ownerNames.map((n, i) => (
          <FormGroup key={`signedNames${i}`}>
            <Field
              component={TextboxField}
              id={`signedNames[${i}]`}
              label={getText('signedNames', undefined, { type: 'field', data: { number: i + 1 } })}
              preventPaste={true}
              data={{ number: i + 1 }}
            />
          </FormGroup>
        ))}
        {values.type === ApplicationType.Entity &&
          _.includes([EntityAccountType.CCorporation, EntityAccountType.SCorporation], values.entityAccountType) && (
            <FormGroup>
              <Field component={TextboxField} id="entity.secretaryName" />
            </FormGroup>
          )}
      </Section>

      {createErrorCode && !isSaving && <Errors errors={errors ? { errors } : { account: 'failed' }} />}
    </>
  );
};
