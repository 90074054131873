import React from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { Section, FormGroup, TextboxField, StepperField } from '@tradingblock/components';
import {
  AccountHolderDisclosureKey,
  AllAccountHolderDisclosureKeys,
  BooleanToggleType,
  AccountHolderDisclosureContingentFields,
  AllBooleanToggleTypes,
  ApplicationFormSection,
  AccountHolderType,
  ApplicationStep,
  AccountHolderField,
  DefaultAccountHolder,
} from '../../../../types';
import { updateFieldsDependentOnAccountHolderDisclosure } from '../../../../services';

interface AccountHolderDisclosuresSectionProps extends ApplicationFormSection {
  type: AccountHolderType;
}

const AccountHolderDisclosuresSection: React.FC<AccountHolderDisclosuresSectionProps> = ({
  type,
  values,
  setValues,
  getText,
}) => {
  const accountHolderIdx = type !== 'primary' ? 1 : 0;
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = values[fieldPrefix] || DefaultAccountHolder;

  // disclosure contingent fields (shown if answered yes)
  const getDisclosureContingentFields = (key: AccountHolderDisclosureKey) => {
    if (!AccountHolderDisclosureContingentFields[key]) {
      return null;
    }
    return (
      <FormGroup>
        {_.map(AccountHolderDisclosureContingentFields[key], id => (
          <div key={id}>
            <Field component={TextboxField} id={`${fieldPrefix}.${id}`} defaultLabelId={id} />
            <br />
          </div>
        ))}
      </FormGroup>
    );
  };

  return (
    <>
      <Section id={`${ApplicationStep.Agreements}-section-${1 + accountHolderIdx}`}>
        <h2 className="fields-title" style={{ textDecoration: 'underline' }}>
          {getText(`accountHolderDisclosures.${type}`)}
        </h2>
        <p>{getText('accountHolderDisclosures', 'description')}</p>

        {_.map(AllAccountHolderDisclosureKeys, (d: AccountHolderDisclosureKey, i) => (
          <div key={`disclosure${i}`}>
            <p>{getText(`accountHolderDisclosures.${d}`)}</p>
            <FormGroup>
              <Field
                component={StepperField}
                id={`${fieldPrefix}.disclosures.${d}`}
                options={AllBooleanToggleTypes}
                defaultLabelId="boolean"
                onChanged={(value: BooleanToggleType) =>
                  setValues(updateFieldsDependentOnAccountHolderDisclosure(values, fieldPrefix, d, value))
                }
              />
            </FormGroup>
            {accountHolder &&
              accountHolder.disclosures &&
              accountHolder.disclosures[d] === BooleanToggleType.Yes &&
              getDisclosureContingentFields(d)}
          </div>
        ))}
      </Section>
    </>
  );
};

export const PrimaryAccountHolderDisclosuresSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderDisclosuresSection type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderDisclosuresSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderDisclosuresSection type={AccountHolderType.Joint} {...props} />;
};
