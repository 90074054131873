import { AssetType, SymbolTypes, OptionExpirationType, OptionType } from '@tradingblock/types';
import _ from 'lodash';
import { SymbolsWithAMPMExpirations } from '../constants';
import { isString } from '../utilities';
export var AssetTypeToSymbolType = function AssetTypeToSymbolType(assType) {
  switch (assType) {
    case AssetType.Index:
      {
        return SymbolTypes.index;
      }

    case AssetType.Equity:
      {
        return SymbolTypes.stock;
      }

    default:
      {
        return AssetType[assType];
      }
  }
};

var isIndex = function isIndex(symbol) {
  return _.first(symbol) === '$';
};

export var isIndexSymbol = function isIndexSymbol(symbol) {
  return isString(symbol) ? isIndex(symbol) : symbol ? symbol.type === SymbolTypes.index || isIndex(symbol.symbol) : false;
};
export var toAssetSymbol = function toAssetSymbol(symbol, assetType, symbolType, partSymb) {
  return Object.assign({
    rootSymbol: symbol,
    underlyingSymbol: symbol,
    name: symbol,
    provider: ''
  }, partSymb || {}, {
    symbol: symbol,
    type: symbolType,
    assetType: assetType
  });
};
export var toAssetSymbolBySymbolType = function toAssetSymbolBySymbolType(symbol, symbolType, partSymb) {
  return toAssetSymbol(symbol, symbolType === SymbolTypes.index ? AssetType.Index : AssetType.Equity, symbolType, partSymb);
};
export var occParseRegex = /([\w]{1,4})[\s]*(\d{2})(\d{2})(\d{2})([PC])(\d*)/;
export var isOCCSymbol = function isOCCSymbol(occSymbol) {
  var data = _.replace(occSymbol, '.', '').match(occParseRegex);

  if (data && data && data.length >= 5) {
    return true;
  }

  return false;
};
export var deriveRootSymbol = function deriveRootSymbol(symbol) {
  if (isOCCSymbol(symbol)) {
    var data = symbol.match(occParseRegex);

    if (data && data && data.length >= 5) {
      return data[1];
    }
  } else {
    return symbol;
  }
};
export var deriveOptionExpirationType = function deriveOptionExpirationType(symbol, underlyingSymbol) {
  if (isOCCSymbol(symbol)) {
    if (SymbolsWithAMPMExpirations.includes(underlyingSymbol)) {
      var cleanRootSymbol = deriveRootSymbol(symbol).trim().toLocaleLowerCase();

      var cleanUnderlying = _.replace(underlyingSymbol, '$', '').trim().toLocaleLowerCase();

      return cleanRootSymbol !== cleanUnderlying ? OptionExpirationType.PM : OptionExpirationType.AM;
    } else {
      return OptionExpirationType.PM;
    }
  }

  return undefined;
};
export var isSymbolEqual = function isSymbolEqual(one, two) {
  if (_.isNil(one) || _.isNil(two)) {
    return false;
  }

  return one.underlyingSymbol === two.underlyingSymbol && one.rootSymbol === two.rootSymbol && one.symbol === two.symbol;
};
export var parseOccSymbol = function parseOccSymbol(occSymbol) {
  var data = occSymbol.match(occParseRegex);

  if (data && data && data.length >= 5) {
    var symbol = data[1]; // since year match is 2 digits, ensure full year is 20** (otherwise Date will parse 2 digit year as 19**)

    var year = 2000 + parseInt(data[2]); // Date object uses zero-indexed month

    var month = parseInt(data[3]) - 1;
    var day = parseInt(data[4]);
    var callput = data[5];
    var price = data[6];
    return {
      symbol: symbol,
      expiration: new Date(year, month, day),
      option: callput ? callput.toLocaleLowerCase() === 'p' ? OptionType.Put : OptionType.Call : undefined,
      strike: price ? parseInt(price) / 1000 : undefined,
      assetType: AssetType.Option
    };
  } else {
    return {
      symbol: occSymbol,
      assetType: AssetType.Equity
    };
  }
};
export function isOptionSymbol(val) {
  return parseOccSymbol(val).assetType === AssetType.Option;
}
export function isOptionAsset(val) {
  return val.assetType === AssetType.Option;
}
export function isMutualFundSymbol(val) {
  return val.startsWith(':');
}