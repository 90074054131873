import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { AllPhoneTypes, PhoneModel } from '../../types';
import { isPhoneFilled } from '../../services';
import {
  BaseFieldProps,
  FormGroup,
  LocalizeButton as Button,
  FloatingLabelWrapper,
  FloatingLabelState,
  TextboxField,
  SelectDropdownField,
  Text,
} from '@tradingblock/components';

interface PhoneFieldProps extends BaseFieldProps {
  onRemove?: () => void;
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  form,
  id,
  disabled,
  defaultLabelId,
  controlledLabel,
  onLabelStateChange,
  onRemove,
}) => {
  const { values, touched, errors, submitCount } = form;
  const value = useMemo(() => _.get(values, id), [values, id]);
  const error = _.get(touched, id) ? _.pickBy(errors, (err, key) => err && _.startsWith(key, id)) : undefined;
  const [floatingLabelState, setFloatingLabelState] = useState<FloatingLabelState>({ isFilled: isPhoneFilled(value) });
  const updateFloatingLabelState = useCallback(
    (state: FloatingLabelState) => {
      setFloatingLabelState(state);
      if (onLabelStateChange) {
        onLabelStateChange(state);
      }
    },
    [onLabelStateChange]
  );
  useEffect(() => {
    if (!controlledLabel) {
      updateFloatingLabelState({ isFilled: isPhoneFilled(value), hasFocus: floatingLabelState.hasFocus });
    }
  }, [value, controlledLabel, floatingLabelState.hasFocus, updateFloatingLabelState]);
  const handleLabelStateChange = (state: FloatingLabelState) => {
    updateFloatingLabelState({
      isFilled: isPhoneFilled(value),
      hasFocus: !_.isUndefined(state.hasFocus) ? state.hasFocus : floatingLabelState.hasFocus,
    });
  };
  const getFieldProps = <FieldProps extends BaseFieldProps>(key: keyof PhoneModel, component: any) => {
    return {
      component,
      id: `${id}.${key}`,
      disabled,
      defaultLabelId: defaultLabelId && `${defaultLabelId}.${key}`,
      controlledLabel: true,
      onLabelStateChange: handleLabelStateChange,
    };
  };

  const getTextKey = () => {
    if (_.includes(error, 'invalid')) {
      return 'invalid';
    }
    if (_.includes(error, 'error')) {
      return 'error';
    }
  };

  return (
    <>
      <FormGroup className={onRemove ? 'form-group-removable form-group-removable-untitled' : ''}>
        <FloatingLabelWrapper
          className={`flabel-multi ${!_.isEmpty(error) ? 'invalid' : ''}`}
          state={floatingLabelState}
          disabled={disabled}
        >
          <div className="row">
            <div className="col-3">
              <Field
                {...getFieldProps('type', SelectDropdownField)}
                options={AllPhoneTypes}
                optionsDefaultLabelId="phoneType"
                autoComplete={'nope'}
                isSearchable={false}
              />
            </div>
            <div className="col-9">
              <Field {...getFieldProps('phoneNumber', TextboxField)} type="tel" inputMode="tel" autoComplete={'nope'} />
            </div>
          </div>
          {onRemove && (
            <div className="remove">
              <Button type="remove" onClick={onRemove} tabIndex={-1}>
                <i className="fal fa-lg fa-times-circle" />
              </Button>
            </div>
          )}
        </FloatingLabelWrapper>
        {!_.isEmpty(error) && !!submitCount && (
          <span className="error">
            <Text id={id} textKey={getTextKey()} type={'error'} />
          </span>
        )}
      </FormGroup>
    </>
  );
};
