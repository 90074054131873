import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _RatioForPrice;

import { OrderAction, AssetType, DebitOrCredit } from '@tradingblock/types';
import _ from 'lodash';
import { isCalendar } from '..';
var RatioForPrice = (_RatioForPrice = {}, _defineProperty(_RatioForPrice, AssetType.Equity.toFixed(), 0.01), _defineProperty(_RatioForPrice, AssetType.Option.toFixed(), 1), _RatioForPrice);

var getLegMarketValueTotalForPrice = function getLegMarketValueTotalForPrice(leg, price) {
  var at = leg.AssetType;
  var priceRatio = RatioForPrice[at.toFixed()];
  var quantity = leg.SpreadRatio || 1;
  return priceRatio * quantity * price;
};

var getAggregatedBidAsk = function getAggregatedBidAsk(legs) {
  return _.reduce(legs, function (acc, l) {
    return Object.assign({}, acc, {
      bid: acc.bid + l.bid,
      ask: acc.ask + l.ask
    });
  }, {
    bid: 0,
    ask: 0
  });
};

var minOrZero = function minOrZero(vals) {
  return _.min(vals) || 0;
};

var maxOrZero = function maxOrZero(vals) {
  return _.max(vals) || 0;
};

var getLegBidAsk = function getLegBidAsk(legs, quotes) {
  return _.reduce(legs, function (acc, leg, legSymbol) {
    var legQuote = _.find(quotes, function (q) {
      return q.Symbol === legSymbol;
    });

    if (legQuote && leg && legSymbol) {
      // const isBuy = leg.Action === OrderAction.Buy;
      // const marketBidPrice = isBuy ? legQuote.BidPrice : legQuote.AskPrice;
      // const marketAskPrice = isBuy ? legQuote.AskPrice : legQuote.BidPrice;
      var legbid = getLegMarketValueTotalForPrice(leg, legQuote.BidPrice);
      var legask = getLegMarketValueTotalForPrice(leg, legQuote.AskPrice);
      return [].concat(_toConsumableArray(acc), [{
        leg: leg,
        bid: legbid,
        ask: legask,
        action: leg.Action
      }]);
    } else {
      return acc;
    }
  }, []);
};

export var calculateOrderPrice = function calculateOrderPrice(legs, quotes, action, gcd, strategy) {
  if (strategy === 'Put Calendar' || strategy === 'Call Calendar') {
    var _option_legs = Object.values(legs).filter(function (l) {
      return l.AssetType === AssetType.Option;
    });

    var option_legs_keys = Object.keys(legs).filter(function (l) {
      return legs[l].AssetType === AssetType.Option;
    });

    if (_option_legs !== undefined && _option_legs.length === 2 && _option_legs[0] !== undefined && _option_legs[1] !== undefined && _option_legs[0].Expiration !== undefined && _option_legs[1].Expiration !== undefined) {
      var later_exp_option = _option_legs[0].Expiration.date > _option_legs[1].Expiration.date ? 0 : 1;
      var earlier_exp_option = _option_legs[0].Expiration.date < _option_legs[1].Expiration.date ? 0 : 1;
      var later_exp_option_quote = quotes.find(function (q) {
        return q.Symbol === option_legs_keys[later_exp_option];
      });
      var earlier_exp_option_quote = quotes.find(function (q) {
        return q.Symbol === option_legs_keys[earlier_exp_option];
      });
      var _totalMultiple = 100;

      if (later_exp_option_quote !== undefined && earlier_exp_option_quote !== undefined) {
        var _bid = later_exp_option_quote.BidPrice - earlier_exp_option_quote.AskPrice;

        var _ask = later_exp_option_quote.AskPrice - earlier_exp_option_quote.BidPrice;

        return {
          bid: _.round(_bid, 2),
          ask: _.round(_ask, 2),
          mid: _.round((_ask - _bid) / 2 + _bid, 3),
          totalMultiple: _totalMultiple,
          gcd: gcd
        };
      }
    }
  }

  var option_legs = Object.values(legs).filter(function (l) {
    return l.AssetType === AssetType.Option;
  });
  var maxRatio = _.max(_.map(legs, function (l) {
    return RatioForPrice[l.AssetType.toFixed()];
  })) || 1;
  var legPrices = getLegBidAsk(legs, quotes);

  var shortLegs = _.filter(legPrices, function (l) {
    return l.action === OrderAction.Sell;
  });

  var longLegs = _.filter(legPrices, function (l) {
    return l.action === OrderAction.Buy;
  });

  var shorts = getAggregatedBidAsk(shortLegs);
  var longs = getAggregatedBidAsk(longLegs);
  var bidValue = action === OrderAction.Buy ? longs.bid - shorts.ask : shorts.bid - longs.ask;
  var askValue = action === OrderAction.Buy ? longs.ask - shorts.bid : shorts.ask - longs.bid;
  var bid = minOrZero([bidValue, askValue]) / gcd / maxRatio;
  var ask = maxOrZero([bidValue, askValue]) / gcd / maxRatio;
  var mid = (ask - bid) / 2 + bid;
  var totalMultiple = option_legs.length > 0 ? 100 : 1;
  return {
    ask: _.round(ask, 2),
    bid: _.round(bid, 2),
    mid: _.round(mid, 2),
    totalMultiple: totalMultiple,
    gcd: gcd
  };
};
export var getDebitOrCredit = function getDebitOrCredit(data) {
  var action = data.action,
      strategy = data.strategy,
      currentDebitOrCredit = data.currentDebitOrCredit,
      price = data.price,
      updateCreditOrDebit = data.updateCreditOrDebit,
      legs = data.legs,
      mid = data.mid,
      match = data.match;
  var orderActionValue = isCalendar(strategy) ? OrderAction.Buy : action;
  var defaultDebitOrCredit = orderActionValue === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
  var debitCredit = currentDebitOrCredit;
  var isCalendarMatch = match && (match.info.Name === 'Call Calendar' || match.info.Name === 'Put Calendar');
  var isCallVerticalMatch = match && match.info.Name === 'Call Vertical';
  var isPutVerticalMatch = match && match.info.Name === 'Put Vertical';
  var isCoveredCallMatch = match && match.info.Name === 'Covered Call';
  var isCallButterflyMatch = match && match.info.Name === 'Call Butterfly';
  var isPutButterflyMatch = match && match.info.Name === 'Put Butterfly';
  var isIronButterflyMatch = match && match.info.Name === 'Iron Butterfly';
  var isIronCondorMatch = match && match.info.Name === 'Iron Condor';
  var isCallCondorMatch = match && match.info.Name === 'Call Condor';
  var isPutCondorMatch = match && match.info.Name === 'Put Condor';
  var isCall = match && match.info.Name === 'Call';
  var isPut = match && match.info.Name === 'Put';

  if (strategy === 'Custom' && _.every(legs, function (l) {
    return l.Action === OrderAction.Buy;
  })) {
    debitCredit = DebitOrCredit.Debit;
  } else if (strategy === 'Custom' && _.every(legs, function (l) {
    return l.Action === OrderAction.Sell;
  })) {
    debitCredit = DebitOrCredit.Credit;
  } else if (updateCreditOrDebit && (mid !== undefined && mid < 0 || price < 0)) {
    debitCredit = defaultDebitOrCredit === DebitOrCredit.Debit ? DebitOrCredit.Credit : DebitOrCredit.Debit;
  } else if (updateCreditOrDebit && (mid !== undefined && mid >= 0 || price >= 0)) {
    debitCredit = defaultDebitOrCredit;
  }

  if (strategy === 'Call Calendar' || strategy === 'Put Calendar') {
    if (price !== undefined) {
      if (price > 0 && action === OrderAction.Buy) {
        debitCredit = DebitOrCredit.Debit;
      } else if (price > 0 && action === OrderAction.Sell) {
        debitCredit = DebitOrCredit.Credit;
      }

      if (price < 0 && action === OrderAction.Buy) {
        debitCredit = DebitOrCredit.Credit;
      } else if (price < 0 && action === OrderAction.Sell) {
        debitCredit = DebitOrCredit.Debit;
      }
    }
  } // find the latest expiring leg


  var findLatestExpiration = function findLatestExpiration(legs) {
    var latestExpiration = null;
    var latestExpirationDate = new Date(0); // Initialize with a very early date

    for (var legKey in legs) {
      var leg = legs[legKey];
      var expirationDate = new Date(leg.Expiration.date);

      if (expirationDate > latestExpirationDate) {
        latestExpirationDate = expirationDate;
        latestExpiration = leg;
      }
    }

    return latestExpiration;
  }; // find the lowest strike leg


  var findLowestStrike = function findLowestStrike(legs) {
    var lowestStrike = null;
    var lowestStrikeValue = 0;

    for (var legKey in legs) {
      var leg = legs[legKey];
      var strike = leg.Strike;

      if (lowestStrike === null || strike < lowestStrikeValue) {
        lowestStrikeValue = strike;
        lowestStrike = leg;
      }
    }

    return lowestStrike;
  };

  var findShareLeg = function findShareLeg(legs) {
    var shareLeg = null;

    for (var legKey in legs) {
      var leg = legs[legKey];

      if (leg.AssetType === AssetType.Equity) {
        shareLeg = leg;
        break;
      }
    }

    return shareLeg;
  };

  if (isCall) {
    var lowestStrikeLeg = findLowestStrike(legs);

    if (lowestStrikeLeg) {
      if (updateCreditOrDebit) {
        debitCredit = lowestStrikeLeg.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isPut) {
    var _lowestStrikeLeg = findLowestStrike(legs);

    if (_lowestStrikeLeg) {
      if (updateCreditOrDebit) {
        debitCredit = _lowestStrikeLeg.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isCallVerticalMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg2 = findLowestStrike(legs);

    if (_lowestStrikeLeg2) {
      if (updateCreditOrDebit) {
        // for a call vertical the order is considered a buy if you are buying the lower strike and selling the higher strike
        debitCredit = _lowestStrikeLeg2.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isPutVerticalMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg3 = findLowestStrike(legs);

    if (_lowestStrikeLeg3) {
      if (updateCreditOrDebit) {
        // for a put vertical the order is considered a buy if you are buying the higher strike and selling the lower strike
        debitCredit = _lowestStrikeLeg3.Action === OrderAction.Buy ? DebitOrCredit.Credit : DebitOrCredit.Debit;
      }
    }
  }

  if (isCoveredCallMatch) {
    // use the share leg action to determine the debit or credit
    var shareLeg = findShareLeg(legs);

    if (shareLeg) {
      if (updateCreditOrDebit) {
        // for a covered call the order is considered a buy if you are buying the share and selling the call
        debitCredit = shareLeg.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isCallButterflyMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg4 = findLowestStrike(legs);

    if (_lowestStrikeLeg4) {
      if (updateCreditOrDebit) {
        // for a call butterfly the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike
        debitCredit = _lowestStrikeLeg4.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isPutButterflyMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg5 = findLowestStrike(legs);

    if (_lowestStrikeLeg5) {
      if (updateCreditOrDebit) {
        // for a put butterfly the order is considered a buy if you are buying the middle strike and selling the lower strike and higher strike
        debitCredit = _lowestStrikeLeg5.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isIronButterflyMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg6 = findLowestStrike(legs);

    if (_lowestStrikeLeg6) {
      if (updateCreditOrDebit) {
        // for an iron butterfly the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike
        debitCredit = _lowestStrikeLeg6.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isIronCondorMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg7 = findLowestStrike(legs);

    if (_lowestStrikeLeg7) {
      if (updateCreditOrDebit) {
        // for an iron condor the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike
        debitCredit = _lowestStrikeLeg7.Action === OrderAction.Sell ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      }
    }
  }

  if (isCallCondorMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg8 = findLowestStrike(legs);

    if (_lowestStrikeLeg8) {
      if (updateCreditOrDebit) {
        if (price > 0) {
          debitCredit = _lowestStrikeLeg8.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
        } else if (price < 0) {
          debitCredit = _lowestStrikeLeg8.Action === OrderAction.Buy ? DebitOrCredit.Credit : DebitOrCredit.Debit;
        }
      }
    }
  }

  if (isPutCondorMatch) {
    // use the lowest strike leg action to determine the debit or credit
    var _lowestStrikeLeg9 = findLowestStrike(legs);

    if (_lowestStrikeLeg9) {
      if (updateCreditOrDebit) {
        if (price > 0) {
          debitCredit = _lowestStrikeLeg9.Action === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
        } else if (price < 0) {
          debitCredit = _lowestStrikeLeg9.Action === OrderAction.Buy ? DebitOrCredit.Credit : DebitOrCredit.Debit;
        }
      }
    }
  }

  return debitCredit;
};
export var getBestPrice = function getBestPrice(_ref) {
  var ask = _ref.ask,
      bid = _ref.bid,
      last = _ref.last,
      defaultValue = _ref.defaultValue,
      isSettlementSet = _ref.isSettlementSet;
  // Normalize ask and bid values to 0 if they are undefined or null, assuming negative prices are invalid
  var askValue = _.isNil(ask) ? 0 : ask;
  var bidValue = _.isNil(bid) ? 0 : bid; // If the settlement is set, return the last price immediately

  if (isSettlementSet === true) {
    return last;
  } // If both bid and ask prices are non-negative


  if (bidValue >= 0 && askValue > 0) {
    // If the last price is less than or equal to the bid, return the bid value
    if (last <= bidValue) {
      return bidValue;
    } // Else if the last price is greater than the ask, return the ask value
    else if (last > askValue) {
        return askValue;
      } // If the default value is set to 'average', compute the average of ask and bid


    if (defaultValue === 'avgBidAsk') {
      // If the bid value is 0, askValue is greater than 0, and last is greater than askValue,
      // return askValue, otherwise return the last price
      if (bidValue === 0) {
        // If ask is greater than 0, and last is greater than ask, return ask, otherwise return last
        if (askValue > 0 && last > askValue) {
          return askValue;
        } else {
          return last;
        }
      } else {
        // Otherwise, return the average of ask and bid
        return (askValue + bidValue) / 2;
      }
    } else {
      // Else if not averaging, return the last price
      return last;
    }
  } else {
    // Else if the bid or ask values are not in the expected range
    if (askValue <= 0) {
      // And ask value is non-positive, return the last price
      return last;
    } else {
      // Else if the ask value is positive but less than the last, return ask, otherwise return last
      if (askValue < last) {
        return askValue;
      } else {
        return last;
      }
    }
  }
};