import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import _ from 'lodash';
import { useLocalize } from '../../../hooks';
import { Text } from '../Text';
import { CheckboxInput } from '../../fields';
export var CheckboxField = function CheckboxField(_a) {
  var id = _a.id,
      type = _a.type,
      value = _a.value,
      label = _a.label,
      defaultLabelId = _a.defaultLabelId,
      onChanged = _a.onChanged,
      onSetValue = _a.onSetValue,
      rest = tslib_1.__rest(_a, ["id", "type", "value", "label", "defaultLabelId", "onChanged", "onSetValue"]);

  var form = rest.form,
      field = rest.field;
  var values = form.values,
      setFieldValue = form.setFieldValue,
      submitCount = form.submitCount,
      errors = form.errors,
      touched = form.touched;
  var error = _.get(touched, id) ? _.get(errors, id) : undefined;
  var inputType = type || 'checkbox';
  var fieldId = "".concat(id, ".").concat(value);

  var _useLocalize = useLocalize(),
      getFieldTranslationId = _useLocalize.getFieldTranslationId;

  var checked = useMemo(function () {
    var currentValue = _.get(values, id);

    return _.isArray(currentValue) ? _.includes(currentValue, value) : value ? currentValue === value : !!currentValue;
  }, [id, values, value]);

  var handleOnChange = function handleOnChange(_e, newValue) {
    if (onSetValue) {
      onSetValue(newValue);
    } else {
      setFieldValue(id, newValue);
    }

    if (onChanged) {
      onChanged(newValue);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(CheckboxInput, Object.assign({
    name: id,
    id: fieldId,
    type: inputType,
    label: label || React.createElement(Text, {
      id: getFieldTranslationId({
        id: id,
        defaultLabelId: defaultLabelId
      }),
      type: "field"
    }),
    checked: checked,
    onchange: handleOnChange,
    onblur: field.onBlur,
    error: error
  }, rest)), id !== 'tradeGoalType' && id !== 'investmentObjectiveType' && error && !!submitCount && React.createElement(React.Fragment, null, React.createElement("br", null), React.createElement("span", {
    className: "error"
  }, React.createElement(Text, {
    id: id,
    textKey: "".concat(error),
    type: 'error'
  }))));
};