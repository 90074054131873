import React from 'react';
import { AddressModel, DefaultAddress } from '../../types';
import { FieldValue } from './';
import { StateValue } from './State';

const Address: React.FC<{ value: AddressModel | null | undefined }> = ({ value }) => {
  const address = value || DefaultAddress;
  return (
    <FieldValue value={address.address1}>
      {address.address1}
      {address.address2 && (
        <>
          <br />
          {address.address2}
        </>
      )}
      {address.city && address.state && (
        <>
          <br />
          {address.city}, <StateValue value={address.state} country={address.country} /> {address.postalCode}
        </>
      )}
      {address.country && (
        <>
          <br />
          <FieldValue value={address.country} textPrefix="defaults.country" />
        </>
      )}
    </FieldValue>
  );
};

export const AddressValue = Address;
