var RequestHeaders = {
  headers: {
    Accept: 'application/json'
  }
};
export var GET_SUBACCOUNT_URL = 'Accounts/Subaccounts/GetSubaccounts';
export var CREATE_SUBACCOUNT_URL = 'Accounts/Subaccounts/CreateSubaccount';
export var UPDATE_SUBACCOUNT_URL = 'Accounts/Subaccounts/UpdateSubaccount';
export var TRANSFER_SUBACCOUNT_POSITIONS = 'Accounts/Subaccounts/TransferPositions';
export var UPDATE_DISTRIBUTION_RATIO = 'Accounts/Subaccounts/UpdateDistributionRatio';
export var DELETE_SUBACCOUNT_URL = 'Accounts/Subaccounts/DeleteSubaccount';
export var subaccounts = function subaccounts(api) {
  return {
    //returns object array
    getSubaccounts: function getSubaccounts(payload) {
      return api.authenticated().get(GET_SUBACCOUNT_URL, payload, RequestHeaders);
    },
    createSubaccount: function createSubaccount(createSubaccountPayload) {
      return api.authenticated().post(CREATE_SUBACCOUNT_URL, createSubaccountPayload, RequestHeaders);
    },
    updateSubaccount: function updateSubaccount(updateSubaccountPayload) {
      return api.authenticated().put(UPDATE_SUBACCOUNT_URL, updateSubaccountPayload, RequestHeaders);
    },
    transferPositions: function transferPositions(transferPositionsPayload) {
      return api.authenticated().put(TRANSFER_SUBACCOUNT_POSITIONS, transferPositionsPayload, RequestHeaders);
    },
    updateDistributionRatio: function updateDistributionRatio(distributionRatioPayload) {
      return api.authenticated().put(UPDATE_DISTRIBUTION_RATIO, distributionRatioPayload, RequestHeaders);
    },
    deleteSubaccount: function deleteSubaccount(deleteSubaccountPayload) {
      return api.authenticated().delete(DELETE_SUBACCOUNT_URL, deleteSubaccountPayload, RequestHeaders);
    }
  };
};