import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';
import {
  Section,
  FormGroup,
  HelpText,
  TextboxField,
  StepperField,
  SelectDropdownField,
  AddressField,
  Text,
  StepperInput,
  TextareaField,
} from '@tradingblock/components';
import { AllStates, getGroupedCountryOptions, Country } from '@tradingblock/types';
import {
  ApplicationFormSection,
  ApplicationStep,
  AllMaritalStatusTypes,
  AllVisaTypes,
  AllEmploymentTypes,
  EmploymentType,
  AllYearsEmployed,
  AllWithdrawalFrequencyTypes,
  AccountHolderType,
  DefaultAccountHolder,
  DefaultForeignDueDiligence,
  ApplicationType,
  EntityAccountType,
  AccountHolderField,
  RE_SSN,
  DuplicateAccountCheckRequest,
  ClearerType,
} from '../../../../types';
import { DateField } from '../../../fields';
import {
  updateFieldsDependentOnCitizen,
  updateFieldsDependentOnEmploymentType,
  getNumberOfApplicationInformationEntitySections,
  updateFieldsDependentOnSecondaryCitizen,
} from '../../../../services';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ApplicationActions } from '../../../../state/actions';

interface AccountHolderPersonalInfoSectionProps extends ApplicationFormSection {
  type: AccountHolderType;
}

const AccountHolderPersonalInfoSection: React.FC<AccountHolderPersonalInfoSectionProps> = ({
  type,
  getText,
  values,
  errors,
  setValues,
}) => {
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = values[fieldPrefix] || DefaultAccountHolder;
  const foreignDueDiligence = values.foreignDueDiligence || DefaultForeignDueDiligence;
  // number of optional entity sections
  const entitySections = getNumberOfApplicationInformationEntitySections(values);
  const totalPreceedingSections = entitySections + (type !== 'primary' ? 2 : 0);
  const isUgma = type === 'ugma';
  const dispatch = useDispatch();

  useEffect(() => {
    let requestValues: DuplicateAccountCheckRequest = {
      flexibilityType: values.flexibilityType,
      primaryTaxIdSecret: values.primaryAccountHolder.ssn,
      retirementAccountType: values.retirementAccount ? values.retirementAccountType : undefined,
      type: values.type,
    };
    // Set up requestValues for individual account types
    if (values.type === ApplicationType.Individual && RE_SSN.test(values.primaryAccountHolder.ssn)) {
      dispatch(ApplicationActions.requestDuplicateAccountCheck(requestValues));
    }
    //TODO: Once Entity applications are allowed through site, ensure that the duplicate account check behaves as expected
    // if (s
    //   values.type === ApplicationType.Entity &&
    //   values.entityAccountType === EntityAccountType.PersonalTrust &&
    //   RE_EIN.test(values.primaryAccountHolder.ssn)
    // ) {
    //   dispatch(ApplicationActions.requestDuplicateAccountCheck(requestValues));
    // }
    // Set up requestValues for Non-Individual/Entity account types
    if (values.type !== ApplicationType.Individual && values.type !== ApplicationType.Entity) {
      // Set up requestValues for secondary account holder
      if (
        fieldPrefix === 'secondaryAccountHolder' &&
        values.secondaryAccountHolder &&
        RE_SSN.test(values.secondaryAccountHolder.ssn) &&
        RE_SSN.test(values.primaryAccountHolder.ssn)
      ) {
        requestValues = {
          ...requestValues,
          secondaryTaxIdSecret: values.secondaryAccountHolder.ssn,
        };
        dispatch(ApplicationActions.requestDuplicateAccountCheck(requestValues));
      }
    }
  }, [
    values.type,
    values.primaryAccountHolder.ssn,
    values.secondaryAccountHolder && values.secondaryAccountHolder.ssn,
  ]);

  return (
    <Section id={`${ApplicationStep.AccountInformation}-section-${2 + totalPreceedingSections}`}>
      <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
        {isUgma ? getText('ugmaAccountHolder') : getText(fieldPrefix)}
      </h3>
      <h2 className="fields-title">{getText('accountHolders.personal-info')}</h2>
      <HelpText help={getText('accountHolders.personal-info.help')}>
        {getText('accountHolders.personal-info', 'description')}
      </HelpText>

      <FormGroup>
        <Field
          component={SelectDropdownField}
          id={`${fieldPrefix}.birthCountry`}
          options={getGroupedCountryOptions()}
          defaultLabelId="birthCountry"
          optionsDefaultLabelId="country"
        />
      </FormGroup>
      <FormGroup>
        <Field component={DateField} id={`${fieldPrefix}.dateOfBirth`} defaultLabelId="dateOfBirth" />
      </FormGroup>
      {values.type !== ApplicationType.Entity && (
        <>
          <FormGroup>
            <Field
              component={SelectDropdownField}
              isSearchable={false}
              id={`${fieldPrefix}.maritalStatus`}
              options={AllMaritalStatusTypes}
              defaultLabelId="maritalStatus"
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`${fieldPrefix}.dependents`}
              defaultLabelId="dependents"
              type="number"
              min="0"
            />
          </FormGroup>
        </>
      )}
      <FormGroup title={getText('accountHolders.citizen')}>
        <StepperInput
          name={`${fieldPrefix}.citizen`}
          value={accountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
              disabled: values.clearer === ClearerType.RQD ? true : false,
            },
          ]}
          onchange={(_, option: boolean) =>
            fieldPrefix === 'primaryAccountHolder'
              ? setValues(updateFieldsDependentOnCitizen(values, fieldPrefix, option))
              : setValues(updateFieldsDependentOnSecondaryCitizen(values, fieldPrefix, option))
          }
        />
      </FormGroup>
      {accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
        <>
          <FormGroup>
            <Field
              component={SelectDropdownField}
              id={`${fieldPrefix}.citizenshipCountry`}
              options={getGroupedCountryOptions()}
              defaultLabelId="citizenshipCountry"
              optionsDefaultLabelId="country"
            />
          </FormGroup>

          {values.type !== ApplicationType.Entity && accountHolder.address.country === Country.UnitedStatesOfAmerica && (
            <>
              <FormGroup help={getText('accountHolders.visaType.help')}>
                <Field
                  component={SelectDropdownField}
                  isSearchable={false}
                  id={`${fieldPrefix}.visaType`}
                  options={AllVisaTypes}
                  defaultLabelId="visaType"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={DateField}
                  id={`${fieldPrefix}.visaExpirationDate`}
                  defaultLabelId="visaExpirationDate"
                />
              </FormGroup>

              <FormGroup>
                <Field
                  component={TextboxField}
                  id={`${fieldPrefix}.ssn`}
                  label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
                  type="password"
                  allowUnmask={true}
                />
              </FormGroup>
            </>
          )}
        </>
      )}

      {accountHolder.address.country !== Country.UnitedStatesOfAmerica && (
        <>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`${fieldPrefix}.foreignTaxWithholding.taxIdentificationNumber`}
              defaultLabelId="taxIdentificationNumber"
            />
          </FormGroup>
          {values.clearer === ClearerType.Apex && (
            <FormGroup>
              <Field
                component={SelectDropdownField}
                id={`${fieldPrefix}.foreignTaxWithholding.treatyCountry`}
                options={getGroupedCountryOptions()}
                defaultLabelId="treatyCountry"
                optionsDefaultLabelId="country"
              />
            </FormGroup>
          )}

          {!accountHolder.foreignTaxWithholding.taxIdentificationNumber && values.clearer === ClearerType.Apex && (
            <>
              <FormGroup title={getText('jurisdictionDoesNotIssueTIN')}>
                <Field
                  component={StepperField}
                  id={`${fieldPrefix}.foreignTaxWithholding.jurisdictionDoesNotIssueTIN`}
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  defaultLabelId="boolean"
                />
              </FormGroup>

              {!accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN && (
                <FormGroup title={getText('notIssuedForeignTIN')}>
                  <Field
                    component={StepperField}
                    id={`${fieldPrefix}.foreignTaxWithholding.notIssuedForeignTIN`}
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    defaultLabelId="boolean"
                  />
                </FormGroup>
              )}
              {accountHolder.foreignTaxWithholding.notIssuedForeignTIN && (
                <FormGroup>
                  <Field
                    component={TextareaField}
                    id={`${fieldPrefix}.foreignTaxWithholding.foreignTINNotRequiredExplanation`}
                    defaultLabelId="foreignTINNotRequiredExplanation"
                  />
                </FormGroup>
              )}
            </>
          )}

          {accountHolder.mailingAddress &&
            accountHolder.mailingAddress.country === Country.UnitedStatesOfAmerica &&
            accountHolder.address.country !== Country.UnitedStatesOfAmerica &&
            values.clearer === ClearerType.Apex && (
              <>
                <FormGroup>
                  <Field
                    component={TextareaField}
                    id={`${fieldPrefix}.foreignTaxWithholding.letterOfExplanation`}
                    defaultLabelId="letterOfExplanation"
                  />
                </FormGroup>
              </>
            )}
        </>
      )}

      {fieldPrefix === 'primaryAccountHolder' && accountHolder.address.country !== Country.UnitedStatesOfAmerica && (
        <>
          <h2 className="fields-title">{getText('foreignDueDiligence')}</h2>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`foreignDueDiligence.initialDepositAmount`}
              defaultLabelId="initialDepositAmount"
              type="number"
              min="1"
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`foreignDueDiligence.initialDepositType`}
              defaultLabelId="initialDepositType"
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={SelectDropdownField}
              isSearchable={false}
              id={`foreignDueDiligence.expectedWithdrawalFrequency`}
              options={AllWithdrawalFrequencyTypes}
              defaultLabelId="expectedWithdrawalFrequency"
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`foreignDueDiligence.primaryBanking[0]`}
              defaultLabelId="primaryBanking"
            />
          </FormGroup>

          <FormGroup title={getText('foreignDueDiligence.referredToBroker')}>
            <Field
              component={StepperField}
              id={`foreignDueDiligence.referredToBroker`}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              defaultLabelId="boolean"
              // onChanged={(referredToBroker: BooleanToggleType) => setValues(updateFieldsDependentOnCitizen(values, fieldPrefix, citizen))}
            />
          </FormGroup>

          {foreignDueDiligence.referredToBroker === true && (
            <>
              <FormGroup>
                <Field component={TextboxField} id={`foreignDueDiligence.referredName`} defaultLabelId="referredName" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id={`foreignDueDiligence.referredRelationship`}
                  defaultLabelId="referredRelationship"
                />
              </FormGroup>
            </>
          )}

          {foreignDueDiligence.referredToBroker === false && (
            <>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id={`foreignDueDiligence.initialContact`}
                  defaultLabelId="initialContact"
                />
              </FormGroup>
            </>
          )}
        </>
      )}

      {accountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica && (
        <FormGroup>
          <Field
            component={TextboxField}
            id={`${fieldPrefix}.ssn`}
            label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
            type="password"
            allowUnmask={true}
          />
        </FormGroup>
      )}

      {(values.type !== ApplicationType.Entity || values.entityAccountType === EntityAccountType.PersonalTrust) && (
        <>
          <h2 className="fields-title mt-5">{getText('accountHolders.employment')}</h2>

          <FormGroup>
            <Field
              component={StepperField}
              id={`${fieldPrefix}.employmentType`}
              options={AllEmploymentTypes}
              defaultLabelId="employmentType"
              onChanged={(employmentType: EmploymentType) =>
                setValues(updateFieldsDependentOnEmploymentType(values, fieldPrefix, employmentType))
              }
            />
          </FormGroup>
          {accountHolder.employmentType === EmploymentType.Employed && (
            <>
              <FormGroup>
                <Field component={TextboxField} id={`${fieldPrefix}.employer`} defaultLabelId="employer" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} id={`${fieldPrefix}.jobPosition`} defaultLabelId="jobPosition" />
              </FormGroup>
              {fieldPrefix === 'primaryAccountHolder' && (
                <FormGroup>
                  <Field
                    component={TextboxField}
                    id={`marketData.employmentFunctions`}
                    defaultLabelId="employmentFunctions"
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  isSearchable={false}
                  id={`${fieldPrefix}.yearsEmployed`}
                  options={AllYearsEmployed}
                  defaultLabelId="yearsEmployed"
                />
              </FormGroup>
              <Field
                component={AddressField}
                id={`${fieldPrefix}.employerAddress`}
                labelPrefix={getText('accountHolders.employment.employer')}
                countryProps={{
                  options: getGroupedCountryOptions(),
                  defaultLabelId: 'address.country',
                  optionsDefaultLabelId: 'country',
                  labelPrefix: getText('accountHolders.employment.employer'),
                }}
                stateProps={{
                  options: AllStates,
                  defaultLabelId: 'address.state',
                  optionsDefaultLabelId: 'state',
                  labelPrefix: getText('accountHolders.employment.employer'),
                }}
              />
            </>
          )}
        </>
      )}
    </Section>
  );
};

export const PrimaryAccountHolderPersonalInfoSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderPersonalInfoSection type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderPersonalInfoSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderPersonalInfoSection type={AccountHolderType.Joint} {...props} />;
};

export const UgmaAccountHolderPersonalInfoSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderPersonalInfoSection type={AccountHolderType.Ugma} {...props} />;
};
