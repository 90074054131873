import React from 'react';
import { Text } from '@tradingblock/components';
import {
  EmploymentType,
  SummaryStepProps,
  AccountHolderType,
  ApplicationType,
  EntityAccountType,
  AccountHolderField,
  DefaultAccountHolder,
  DefaultForeignDueDiligence,
  DefaultForeignTaxWithholding,
} from '../../../../types';
import { FieldValue, AddressValue, DateValue, MaskedValue } from '../../../values';
import { Country } from '@tradingblock/types';

interface AccountHolderPersonalInfoSummaryProps extends SummaryStepProps {
  type: AccountHolderType;
}

const AccountHolderPersonalInfoSummary: React.FC<AccountHolderPersonalInfoSummaryProps> = ({
  type,
  application,
  getText,
  activeLanguage,
}) => {
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = (application && application[fieldPrefix]) || DefaultAccountHolder;
  const foreignDueDiligence = (application && application.foreignDueDiligence) || DefaultForeignDueDiligence;
  const foreignTaxWithholding = accountHolder.foreignTaxWithholding || DefaultForeignTaxWithholding;

  if (!application || !accountHolder) {
    return null;
  }

  const formatBoolean = (value: any) => {
    return value === true ? 'Yes' : 'No';
  };

  return (
    <>
      <h3 className="fields-title">{getText('accountHolders.personal-info')}</h3>
      <p>
        {getText('accountHolders.personal-info.dateOfBirth')}:{' '}
        <DateValue value={accountHolder.dateOfBirth} activeLanguage={activeLanguage} />
        {application.primaryAccountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica && (
          <>
            <br />
            {getText('ssn', undefined, { defaultPage: true, data: { itin: <Text id="itin.label" /> } })}:{' '}
            <MaskedValue value={accountHolder.ssn} />
          </>
        )}
        {application.type !== ApplicationType.Entity && (
          <>
            <br />
            {getText('accountHolders.personal-info.maritalStatus')}:{' '}
            <FieldValue value={accountHolder.maritalStatus} textPrefix="defaults.maritalStatus" />
            <br />
            {getText('accountHolders.personal-info.dependents')}: <FieldValue value={accountHolder.dependents} />
          </>
        )}
      </p>

      <h3 className="fields-title">{getText('accountHolders.citizen')}</h3>
      <p>
        <FieldValue
          value={accountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica ? 'yes' : 'no'}
          textPrefix="defaults.boolean"
        />
        {accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
          <>
            <br />
            {getText('accountHolders.citizen.citizenshipCountry')}:{' '}
            <FieldValue value={accountHolder.citizenshipCountry} textPrefix="defaults.country" />
            <br />
            {getText('accountHolders.citizen.birthCountry')}:{' '}
            <FieldValue value={accountHolder.birthCountry} textPrefix="defaults.country" />
            <br />
            {getText('taxIdentificationNumber')}:{' '}
            {foreignTaxWithholding.taxIdentificationNumber ? (
              <FieldValue value={foreignTaxWithholding.taxIdentificationNumber} />
            ) : (
              'N/A'
            )}
            <br />
            {getText('treatyCountry')}:{' '}
            {foreignTaxWithholding.treatyCountry ? <FieldValue value={foreignTaxWithholding.treatyCountry} /> : 'N/A'}
            <br />
            {foreignTaxWithholding.letterOfExplanation && (
              <>
                {getText('letterOfExplanation')}:
                <br />
                <FieldValue value={foreignTaxWithholding.letterOfExplanation} />
                <br />
              </>
            )}
            {foreignTaxWithholding.jurisdictionDoesNotIssueTIN && (
              <>
                {getText('jurisdictionDoesNotIssueTIN')}:{' '}
                <FieldValue value={formatBoolean(foreignTaxWithholding.jurisdictionDoesNotIssueTIN)} />
                <br />
                {getText('notIssuedForeignTIN')}:{' '}
                <FieldValue value={formatBoolean(foreignTaxWithholding.notIssuedForeignTIN)} />
              </>
            )}
            {foreignTaxWithholding.notIssuedForeignTIN && (
              <>
                {getText('foreignTINNotRequiredExplanation')}: <br />
                <FieldValue value={foreignTaxWithholding.foreignTINNotRequiredExplanation} />
              </>
            )}
            {application.type !== ApplicationType.Entity &&
              accountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica &&
              accountHolder.address.country === Country.UnitedStatesOfAmerica && (
                <>
                  <br />
                  {getText('accountHolders.citizen.visaType')}:{' '}
                  <FieldValue value={accountHolder.visaType} textPrefix="defaults.visaType" />
                  <br />
                  {getText('accountHolders.citizen.visaExpirationDate')}:{' '}
                  <DateValue value={accountHolder.visaExpirationDate} activeLanguage={activeLanguage} />
                  {accountHolder.ssn && (
                    <>
                      <br />
                      {getText('ssn', undefined, { defaultPage: true, data: { itin: <Text id="itin.label" /> } })}:{' '}
                      <MaskedValue value={accountHolder.ssn} />
                    </>
                  )}
                </>
              )}
            {accountHolder.address.country !== Country.UnitedStatesOfAmerica && (
              <>
                <br />
                <span className="fields-title">{getText('foreignDueDiligence')}</span>
                <br />
                {getText('foreignDueDiligence.initialDepositAmount')}:{' '}
                <FieldValue value={foreignDueDiligence.initialDepositAmount} />
                <br />
                {getText('foreignDueDiligence.initialDepositType')}:{' '}
                <FieldValue value={foreignDueDiligence.initialDepositType} />
                <br />
                {getText('foreignDueDiligence.expectedWithdrawalFrequency')}:{' '}
                <FieldValue value={foreignDueDiligence.expectedWithdrawalFrequency} />
                <br />
                {getText('foreignDueDiligence.primaryBanking')}:{' '}
                <FieldValue value={foreignDueDiligence.primaryBanking[0]} />
                {foreignDueDiligence.referredToBroker === true && (
                  <>
                    <br />
                    {getText('foreignDueDiligence.referredToBroker')}:{' '}
                    <FieldValue value={foreignDueDiligence.referredToBroker ? 'Yes' : 'No'} />
                    <br />
                    {getText('foreignDueDiligence.referredName')}:{' '}
                    <FieldValue value={foreignDueDiligence.referredName} />
                    <br />
                    {getText('foreignDueDiligence.referredRelationship')}:{' '}
                    <FieldValue value={foreignDueDiligence.referredRelationship} />
                  </>
                )}
                {foreignDueDiligence.referredToBroker === false && (
                  <>
                    <br />
                    {getText('foreignDueDiligence.initialContact')}:{' '}
                    <FieldValue value={foreignDueDiligence.initialContact} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </p>

      {(application.type !== ApplicationType.Entity ||
        application.entityAccountType === EntityAccountType.PersonalTrust) && (
        <>
          <h3 className="fields-title">{getText('accountHolders.employment')}</h3>
          <p>
            {getText('accountHolders.employment.employmentType')}:{' '}
            <FieldValue value={accountHolder.employmentType} textPrefix="defaults.employmentType" />
            {accountHolder.employmentType === EmploymentType.Employed && (
              <>
                <br />
                {getText('accountHolders.employment.employer')}: <FieldValue value={accountHolder.employer} />
                <br />
                {getText('accountHolders.employment.jobPosition')}: <FieldValue value={accountHolder.jobPosition} />
                <br />
                {getText('accountHolders.employment.yearsEmployed')}:{' '}
                <FieldValue value={accountHolder.yearsEmployed} textPrefix="defaults.yearsEmployed" />
                <br />
                <strong>{getText('accountHolders.employerAddress')}</strong>
                <br />
                <AddressValue value={accountHolder.employerAddress} />
              </>
            )}
          </p>
        </>
      )}
    </>
  );
};

export const PrimaryAccountHolderPersonalInfoSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderPersonalInfoSummary type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderPersonalInfoSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderPersonalInfoSummary type={AccountHolderType.Joint} {...props} />;
};

export const UgmaAccountHolderPersonalInfoSummary: React.FC<SummaryStepProps> = props => {
  return <AccountHolderPersonalInfoSummary type={AccountHolderType.Ugma} {...props} />;
};
