import React, { FocusEvent } from 'react';
import { Field } from 'formik';
import { BaseFieldProps, FormGroup, TextboxField, AddressField } from '@tradingblock/components';
import { AllStates, getGroupedCountryOptions } from '@tradingblock/types';
import { PersonType } from '../../types';

interface PersonFieldProps extends BaseFieldProps {
  personType: PersonType;
}

export const PersonField: React.FC<PersonFieldProps> = ({ id, personType, labelPrefix, form }) => {
  const prefix = id ? `${id}.` : '';
  const { getFieldProps, setFieldValue } = form;
  
  return (
    <>
      <FormGroup>
        <Field
          component={TextboxField}
          id={`${prefix}firstName`}
          defaultLabelId="firstName"
          autoComplete={'firstName'}
          onblur={(e: FocusEvent<HTMLInputElement>) => {
            e.persist();
            const targetValue = e.target.value;
            setFieldValue(`${prefix}firstName`, targetValue.trim(), true);
            const { onBlur } = getFieldProps(`${prefix}firstName`);
            onBlur(e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={TextboxField}
          id={`${prefix}middleInitial`}
          defaultLabelId="middleInitial"
          autoComplete={'middleInitial'}
          onblur={(e: FocusEvent<HTMLInputElement>) => {
            e.persist();
            const targetValue = e.target.value;
            setFieldValue(`${prefix}middleInitial`, targetValue.trim(), true);
            const { onBlur } = getFieldProps(`${prefix}middleInitial`);
            onBlur(e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={TextboxField}
          id={`${prefix}lastName`}
          defaultLabelId="lastName"
          autoComplete={'lastName'}
          onblur={(e: FocusEvent<HTMLInputElement>) => {
            e.persist();
            const targetValue = e.target.value;
            setFieldValue(`${prefix}lastName`, targetValue.trim(), true);
            const { onBlur } = getFieldProps(`${prefix}lastName`);
            onBlur(e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Field
          component={TextboxField}
          id={`${prefix}suffix`}
          defaultLabelId="suffix"
          autoComplete={'suffix'}
          onblur={(e: FocusEvent<HTMLInputElement>) => {
            e.persist();
            const targetValue = e.target.value;
            setFieldValue(`${prefix}suffix`, targetValue.trim(), true);
            const { onBlur } = getFieldProps(`${prefix}suffix`);
            onBlur(e);
          }}
        />
      </FormGroup>

      {personType === PersonType.AccountHolder && (
        <>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`${prefix}email`}
              type="email"
              inputMode="email"
              defaultLabelId="email"
              preventPaste={true}
              autoComplete={`${prefix}email`}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextboxField}
              id={`${prefix}emailConfirm`}
              type="email"
              inputMode="email"
              defaultLabelId="emailConfirm"
              preventPaste={true}
              autoComplete={`${prefix}emailConfirm`}
            />
          </FormGroup>
        </>
      )}

      {personType !== PersonType.AccountHolder && (
        <>
          {personType === PersonType.TradeAuthorization && (
            <FormGroup>
              <Field
                component={TextboxField}
                id={`${prefix}email`}
                type="email"
                inputMode="email"
                defaultLabelId="email"
                preventPaste={true}
                autoComplete={`${prefix}email`}
              />
            </FormGroup>
          )}

          <Field
            component={AddressField}
            id={`${prefix}address`}
            labelPrefix={labelPrefix}
            countryProps={{
              options: getGroupedCountryOptions(),
              defaultLabelId: 'address.country',
              optionsDefaultLabelId: 'country',
              labelPrefix,
            }}
            stateProps={{
              options: AllStates,
              defaultLabelId: 'address.state',
              optionsDefaultLabelId: 'state',
              labelPrefix,
            }}
          />
        </>
      )}
    </>
  );
};
