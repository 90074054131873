import React from 'react';
import { Text, Number } from '@tradingblock/components';
import { SummaryStepProps } from '../../../../types';
import { FieldValue } from '../../../values';
import _ from 'lodash';

export const EntityFinancialActivitySummary: React.FC<SummaryStepProps> = ({ application, getText }) => {
  const entity = application && application.entity;
  if (!application || !entity) {
    return null;
  }
  return (
    <>
      <h3 className="fields-title">{getText('entity.financialActivity')}</h3>
      <p>
        <Text id="entity.officeLocations" type="field" />: <FieldValue value={entity.officeLocations} />
        <br />
        <Text id="entity.accountActivity" type="field" />: <FieldValue value={entity.accountActivity} textPrefix="entity.accountActivity" />
        <br />
        <Text id="entity.initialDepositAmount" type="field" />: <FieldValue value={entity.initialDepositAmount}>{entity.initialDepositAmount && <Number value={entity.initialDepositAmount} currency="USD" />}</FieldValue>
        <br />
        <Text id="entity.initialDepositMethod" type="field" />: <FieldValue value={entity.initialDepositMethod} />
        <br />
        <Text id="entity.primarySourceOfFunds" type="field" />: <FieldValue value={entity.primarySourceOfFunds} textPrefix="entity.primarySourceOfFunds" />
        <br />
        <Text id="entity.expectedWithdrawalFrequency" type="field" />: <FieldValue value={entity.expectedWithdrawalFrequency} textPrefix="entity.expectedWithdrawalFrequency" />
        <br />
        <Text id="entity.primaryBankName" type="field" />: <FieldValue value={entity.primaryBankName} />
      </p>

      {entity.hasTradingBlockAccounts && (
        <p>
          {getText('entity.tradingBlockAccounts')}:
          <br />
          {_.map(entity.tradingBlockAccounts, (account, i) => (
            <span key={`tbAcct${i}`}>
              <Text id="entity.tradingBlockAccounts.accountNumber" type="field" />: <FieldValue value={account.accountNumber} />
              {' / '}
              <Text id="entity.tradingBlockAccounts.accountName" type="field" />: <FieldValue value={account.accountName} />
              {!_.isEqual(account, {}) && <br />}
            </span>
          ))}
        </p>
      )}
    </>
  );
};
