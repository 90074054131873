import React, { useMemo } from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { PersonType, SummaryStepProps } from '../../../types';
import { FieldValue, PersonValue } from '../../values';
import { useData } from '../../../state/selectors';
import { getHeardAbout } from '../../../services';

export const SecureAccountSummary: React.FC<SummaryStepProps> = ({ application }) => {
  const heardAbout = useData(d => d.ui.heardAbout);
  const heardAboutSelected = useMemo(() => application && getHeardAbout(heardAbout, application.hearAboutUsId), [
    application,
    heardAbout,
  ]);
  const representatives = useMemo(() => heardAboutSelected && heardAboutSelected.representatives, [heardAboutSelected]);
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;

  const representativeSelected = useMemo(() => {
    if (representatives && application) {
      return _.find(representatives, r => r.repId === _.toNumber(application.repId));
    }
    return undefined;
  }, [application, representatives]);

  if (!application) {
    return null;
  }

  return (
    <>
      <p>
        <PersonValue value={application.primaryAccountHolder} personType={PersonType.AccountHolder} />
      </p>
      <p>
        <Text id="hearAboutUsId" page="summary" siteGroup={siteGroup} />
        {': '}
        {heardAbout && (
          <FieldValue value={!!heardAboutSelected}>
            <strong>{heardAboutSelected && heardAboutSelected.description}</strong>
          </FieldValue>
        )}
        {representatives && (
          <>
            <br />
            <Text id="repId" page="summary" />
            {': '}
            <FieldValue value={!!representativeSelected}>
              <strong>{representativeSelected && representativeSelected.description}</strong>
            </FieldValue>
          </>
        )}
      </p>
    </>
  );
};
