import { GetBalancesForAccountsURL, GetBalancesForWithdrawalURL, GetBalancesUrl } from '../constants';
export function GetAvailableFunds(api) {
  return function (accountId, subaccountId) {
    return api.authenticated().get(GetBalancesUrl, {
      accountId: accountId,
      subaccountId: subaccountId
    }, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}
export function GetBalances(api) {
  return function (accountId, subaccountId) {
    return api.authenticated().get(GetBalancesUrl, {
      accountId: accountId,
      subaccountId: subaccountId
    }, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}
export function GetBalancesForAccounts(api) {
  return function (items) {
    return api.authenticated().post(GetBalancesForAccountsURL, {
      items: items
    }, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}
export function GetBalancesForWithdrawal(api) {
  return function (accountId) {
    return api.authenticated().get(GetBalancesForWithdrawalURL(accountId), {}, {
      headers: {
        Accept: 'application/json'
      }
    });
  };
}