import React from 'react';
export var Section = function Section(_ref) {
  var id = _ref.id,
      className = _ref.className,
      children = _ref.children;
  return React.createElement("section", {
    id: id
  }, React.createElement("div", {
    className: className || 'form-content'
  }, children));
};