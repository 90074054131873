export var QuoteFeedType = 10;
export var SpreadFeedType = 11;
export var OrderUpdateFeedType = 13;
export var BarUpdateFeedType = 14;
export var SpreadType;

(function (SpreadType) {
  SpreadType[SpreadType["Custom"] = 0] = "Custom";
  SpreadType[SpreadType["Vertical"] = 2] = "Vertical";
  SpreadType[SpreadType["Calendar"] = 3] = "Calendar";
  SpreadType[SpreadType["Butterfly"] = 4] = "Butterfly";
  SpreadType[SpreadType["Condor"] = 5] = "Condor";
  SpreadType[SpreadType["CoveredCall"] = 6] = "CoveredCall";
  SpreadType[SpreadType["IronCondor"] = 7] = "IronCondor";
  SpreadType[SpreadType["IronButterfly"] = 8] = "IronButterfly";
  SpreadType[SpreadType["Straddle"] = 9] = "Straddle";
  SpreadType[SpreadType["Strangle"] = 10] = "Strangle";
  SpreadType[SpreadType["MarriedPut"] = 11] = "MarriedPut";
  SpreadType[SpreadType["CoveredPut"] = 12] = "CoveredPut";
  SpreadType[SpreadType["MarriedCall"] = 13] = "MarriedCall"; // Collar = 14,

  SpreadType[SpreadType["Conversion"] = 15] = "Conversion";
})(SpreadType || (SpreadType = {})); // export interface Spread
//     {
//         Leg[] Legs { get; set; }
//         public double Bid { get; set; }
//         public double Ask { get; set; }
//         public double Last { get; set; }
//         public double Change { get; set; }
//         public SpreadType Type { get; set; }
//         public DateTime SpreadTime { get; set; }
//     }