import React, { useMemo } from 'react';
import { GetTradingBlockDomain } from '../../utilities/site';
export var ContactEmail = function ContactEmail(_ref) {
  var accountNumber = _ref.accountNumber,
      siteGroup = _ref.siteGroup,
      className = _ref.className;
  var contactEmail = useMemo(function () {
    return "service@".concat(GetTradingBlockDomain(accountNumber, siteGroup).toLocaleLowerCase());
  }, [accountNumber, siteGroup]);
  return React.createElement("a", {
    href: "mailto:".concat(contactEmail),
    className: className
  }, contactEmail);
};