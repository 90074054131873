import React from 'react';
import { Section, Text, ContactPhone, ContactEmail } from '@tradingblock/components';
import { ApplicationLoader } from '../components/application';
import { PageTitle } from '../components';
import { useData } from '../state/selectors';

const CustomerServicePage: React.FC = () => {
  const siteGrpParam = process.env.REACT_APP_TB_SITE_GROUP;

  return (
    <ApplicationLoader allowAnonymous>
      <PageTitle page="customer-service" />
      <Section>
        <div className="step-title">
          <h1>
            <Text id="step-title" page="customer-service" />
          </h1>
        </div>
      </Section>
      <Section>
        <h2 className="fields-title">
          <Text id="contact.content" />
        </h2>
        <p>
          <Text
            id="contact.content"
            textKey="description"
            siteGroup={siteGrpParam}
            data={{
              email: <ContactEmail siteGroup={siteGrpParam} />,
              phone: <ContactPhone siteGroup={siteGrpParam} />,
            }}
          />
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default CustomerServicePage;
