import { useEffect } from 'react';
import { useHttpStore } from '@tradingblock/storage';
import { IStorageProvider, HttpProviderConfig } from '@tradingblock/types';
import { config } from '../config';
import { useData } from '../state/selectors';
import { useDispatch } from 'react-redux';
import { applicationApiClient } from '../api/apiClient';

export const useStore = () => {
  const baseDataUrl = config.dataUrl;
  const authToken = useData(d => d.ui.storageToken);
  if (!baseDataUrl) {
    console.warn('baseDataUrl must be set!');
  }
  const storage: IStorageProvider<HttpProviderConfig> = useHttpStore(baseDataUrl || '', authToken);
  useEffect(() => {
    if (authToken && !storage.config.authToken) {
      storage.setConfig({ ...storage.config, authToken });
    }
  }, [authToken, storage]);
  return storage;
};

export const useApplicationStore = () => {
  const dispatch = useDispatch();
  const storage: IStorageProvider<HttpProviderConfig> = useStore();
  const apiClient = applicationApiClient(storage, dispatch);
  return apiClient;
};
