import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import _ from 'lodash';
import { Section, FormGroup, TextboxField, SelectDropdownField } from '@tradingblock/components';
import { SaveTokenInStorage, useLocalStorage } from '@tradingblock/storage';
import {
  AllSecurityQuestionTypes,
  ApplicationStep,
  TotalSecurityChallenges,
  ApplicationFormSection,
  ApplicationModel,
  SecurityChallengeModel,
} from '../../../../types';
import { useStore } from '../../../../context';
import { useData } from '../../../../state/selectors';
import { updateApplication, validateToken } from '../../../../services';
import { useDispatcher } from '../../../hooks';
import { ApplicationActions } from '../../../../state/actions';
import { FieldRequirementsHelp } from '../../../fields';
import config from '../../../../config';

export const UserCredentialsSection: React.FC<ApplicationFormSection> = ({ getText, values, formSubmitted }) => {
  const mounted = useRef<boolean>();
  const storage = useStore();
  const localStorage = useLocalStorage();
  const dispatch = useDispatcher();
  const storageToken = useData(d => d.ui.storageToken);

  const application = useData(d => d.data.application);
  const { isSaving } = useData(d => d.data.saveInfo);
  const visibleSections = useData(d => d.ui.visibleSections);
  const createdUser = useData(d => d.data.createdUser);

  const [username, setUsername] = useState<string>();
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;

  const getSecurityQuestionTypes = useCallback(
    (index: number) => {
      const otherValues = _.filter(values.securityChallenges, (_c, i) => i !== index).map(c => c.securityQuestionType);
      return _.filter(AllSecurityQuestionTypes, t => !_.includes(otherValues, t));
    },
    [values.securityChallenges]
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      // keep track of mounted state of component
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUsernameFromToken = async (token: string) => {
      const validatedToken = await validateToken(token);
      if (validatedToken && mounted.current) {
        setUsername(validatedToken.decoded.username);
      }
    };
    // when token present, use it to get decoded username
    if (storageToken) {
      fetchUsernameFromToken(storageToken);
    }
  }, [storageToken]);

  useEffect(() => {
    const storeTokenInLocalStorage = async (token: string) => {
      await SaveTokenInStorage(localStorage, token);
    };

    // when a new user is created and authenticated, save application to continue to next step
    if (createdUser === true && storageToken && !isSaving) {
      // store token in localstorage first
      storeTokenInLocalStorage(storageToken);

      const saveStep = ApplicationStep.SecureAccount;
      const data = updateApplication(saveStep, false, true, values, visibleSections);
      dispatch(
        ApplicationActions.requestSaveApplication({ application: data, saveType: 'continue', saveStep, storage })
      );
    }
  }, [storageToken, createdUser, isSaving, values, visibleSections, dispatch, storage, localStorage]);

  // if completed virtual application, send to thank you page
  if (createdUser && config.isVirtual) {
    return <Redirect to="/thanks" />;
  }

  // if already signed in, don't show user credential fields
  if (storageToken && !createdUser) {
    return (
      <Section id={`${ApplicationStep.SecureAccount}-section-2`}>
        <h2 className="fields-title">{getText('userName', undefined, { data: { siteGroup } })}</h2>
        <p>
          {getText('authenticated', 'description', { data: { username } })}{' '}
          {getText('authenticated.signout', 'description', {
            data: {
              signoutLink: <Link to="/logout">{getText('authenticated.signout')}</Link>,
            },
          })}
        </p>
      </Section>
    );
  }

  return (
    <Section id={`${ApplicationStep.SecureAccount}-section-2`}>
      <h2 className="fields-title">{getText('userName', undefined, { data: { siteGroup } })}</h2>
      <p>{getText(config.isVirtual ? 'userName-virtual' : 'userName', 'description', { data: { siteGroup } })}</p>

      <FormGroup help={<FieldRequirementsHelp field="userName" values={values} formSubmitted={formSubmitted} />}>
        <Field
          component={TextboxField}
          id="userName"
          autoFocus={
            !application ||
            !application.latestStepSaved ||
            application.latestStepSaved === ApplicationStep.SecureAccount
          }
        />
      </FormGroup>

      <h2 className="fields-title">{getText('password')}</h2>
      <FormGroup help={<FieldRequirementsHelp field="password" values={values} formSubmitted={formSubmitted} />}>
        <Field component={TextboxField} id="password" type="password" allowUnmask={true} preventPaste={true} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="passwordConfirm" type="password" allowUnmask={true} preventPaste={true} />
      </FormGroup>

      <h2 className="fields-title">{getText('securityQuestions')}</h2>
      <p>{getText('securityQuestions', 'description')}</p>

      {_.map(_.range(0, TotalSecurityChallenges), idx => (
        <React.Fragment key={`securityChallenge${idx}`}>
          <div>
            <FormGroup>
              <Field
                component={SelectDropdownField}
                isSearchable={false}
                id={`securityChallenges[${idx}].securityQuestionType`}
                options={getSecurityQuestionTypes(idx)}
              />
            </FormGroup>
            <FormGroup>
              <Field component={TextboxField} id={`securityChallenges[${idx}].answer`} />
            </FormGroup>
          </div>
        </React.Fragment>
      ))}
    </Section>
  );
};
