import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _, { uniqueId } from 'lodash';
import dayjs from 'dayjs';
import { OptionType, AssetType, OptionExpirationType, OrderStatuses } from '@tradingblock/types';
import { OrderAction, PositionEffect, OrderActionDirection, OrderClass, Durations as DurationValues, Durations, DebitOrCredit } from '@tradingblock/types';
import { isIndexSymbol, parseOccSymbol, isOptionAsset } from './SymbolUtilities';
import { Strategies } from '../strategies/strategies';
import { CustomStrategy } from '../strategies/CustomStrategy';
import { isBetweenInclusive } from './DateUtilities';
export var OCCSymbolToOptionData = parseOccSymbol;
export var DeriveLegAssetType = function DeriveLegAssetType(leg) {
  if (_.isNil(leg.OptionType) === false) {
    return AssetType.Option;
  } else if (isIndexSymbol(leg.UnderlyingSymbol)) {
    return AssetType.Index;
  } else {
    return AssetType.Equity;
  }
};
export var GetOCCSymbol = function GetOCCSymbol(symbolData) {
  var underlyingSymbol = symbolData.underlyingSymbol,
      expiration = symbolData.expiration,
      optionType = symbolData.optionType,
      strike = symbolData.strike,
      prefixSymbolName = symbolData.prefixSymbolName;

  if (expiration === undefined || strike === undefined || optionType === undefined) {
    return underlyingSymbol;
  }

  var otype = optionType === OptionType.Put ? 'P' : 'C';
  var rootSymbol = underlyingSymbol;
  rootSymbol = _.replace(underlyingSymbol, '$', '');

  if (isIndexSymbol(underlyingSymbol)) {
    // rootSymbol = _.replace(underlyingSymbol, '$', '');
    if (expiration.optionExpirationType === OptionExpirationType.PM && rootSymbol !== 'NANOS') {
      rootSymbol = "".concat(rootSymbol, "W");
    }
  }

  var dateVal = dayjs(expiration.date).format('YYMMDD'); //const strikeVal = _.padStart((strike * 1000).toString(), 6, '0');

  var strikeVal = strike * 1000;
  var prefix = prefixSymbolName ? '.' : '';
  return "".concat(prefix).concat(rootSymbol, " ").concat(dateVal).concat(otype).concat(strikeVal);
};
export var orderLegOccSymbol = function orderLegOccSymbol(underlyingSymbol, leg) {
  var Expiration = leg.Expiration,
      OptionType = leg.OptionType,
      Strike = leg.Strike;
  var symbolOcc = undefined;

  if (!OptionType || leg.AssetType === AssetType.Equity) {
    symbolOcc = underlyingSymbol.symbol;
  } else if (OptionType && Expiration && Strike) {
    symbolOcc = GetOCCSymbol({
      underlyingSymbol: underlyingSymbol.symbol,
      symbolType: underlyingSymbol.type,
      expiration: Expiration,
      optionType: OptionType,
      strike: Strike
    });
  } else {
    symbolOcc = undefined;
  }

  return symbolOcc;
};
export var toPrefixedOccSymbol = function toPrefixedOccSymbol(symbol) {
  return _.first(symbol) === '.' ? symbol : ".".concat(symbol);
};
export var toRawOccSymbol = function toRawOccSymbol(symbol) {
  return _.isNil(symbol) ? '' : _.first(symbol) === '.' ? _.replace(symbol, '.', '') : symbol;
};
export var AllStrategies = [].concat(_toConsumableArray(Strategies), [CustomStrategy]);
export var getStrategy = function getStrategy(name) {
  return _.find(AllStrategies, function (s) {
    return s.info.Name === name;
  }) || CustomStrategy;
};
export var getKnownStrategy = function getKnownStrategy(name) {
  return _.find(Strategies, function (s) {
    return s.info.Name === name;
  });
};
export var buildOrderLeg = function buildOrderLeg(partOrd, orderAction, assetType, positionEffect, optionType, ratio) {
  return {
    Symbol: partOrd.Symbol,
    UnderlyingSymbol: partOrd.UnderlyingSymbol || partOrd.Symbol,
    Id: uniqueId(),
    SpreadRatio: ratio,
    AssetType: assetType,
    Action: orderAction,
    PositionEffect: positionEffect,
    OptionType: optionType
  };
};
export var transformForApi = function transformForApi(orderDesc) {
  var transformedOrder = orderDesc.kind === 'simple' ? {
    AccountId: orderDesc.accountId,
    SubaccountId: orderDesc.subaccountId,
    UnderlyingSymbol: '',
    Legs: [{
      AssetType: orderDesc.assetType,
      Symbol: orderDesc.symbol,
      Action: orderDesc.action,
      PositionEffect: orderDesc.effect
    }],
    ClientRefId: orderDesc.referenceId,
    OrderClass: OrderClass.Single,
    Quantity: orderDesc.quantity,
    OrderType: orderDesc.orderType,
    Price: orderDesc.price,
    Stop: orderDesc.stop,
    AllOrNone: false,
    Duration: DurationValues.Day,
    Description: '',
    DebitCredit: DebitOrCredit.Debit
  } : Object.assign({}, orderDesc, {
    Legs: _.map(orderDesc.Legs, function (l) {
      return Object.assign({}, l, {
        Symbol: l.OccSymbol || l.Symbol
      });
    })
  });
  var isSpreadOrder = _.keys(transformedOrder.Legs).length > 1 ? true : false;
  var DebitCredit = isSpreadOrder ? transformedOrder.DebitCredit : undefined;
  var finalorder = Object.assign({}, transformedOrder, {
    DebitCredit: DebitCredit
  });
  return transformOrderForApi(finalorder);
};

var getCallPutValue = function getCallPutValue(l) {
  var legSymbol = l.OccSymbol;

  if (legSymbol === undefined) {
    return null;
  }

  var symInfo = parseOccSymbol(legSymbol);

  if (isOptionAsset(symInfo)) {
    return symInfo.option === OptionType.Call ? true : false;
  }

  return null;
};

function isPartialOrderWithLegs(order) {
  var ordLegs = _.get(order, 'Legs', undefined);

  return order && _.isArray(ordLegs) ? true : false;
}

export function transformOrderForApi(order) {
  var Price = Math.abs(order.Price);

  if (isPartialOrderWithLegs(order)) {
    var Legs = _.map(order.Legs, function (l) {
      return Object.assign({}, l, {
        CallPut: getCallPutValue(l)
      });
    });

    return Object.assign({}, order, {
      Price: Price,
      Legs: Legs
    });
  }

  return Object.assign({}, order, {
    Price: Price
  });
}
export var transformFromApi = function transformFromApi(order) {
  var Duration = getDurationFromCode(order.Duration);
  return Object.assign({}, order, {
    Duration: Duration,
    Legs: _.map(order.Legs, function (l) {
      return Object.assign({}, l, {
        Symbol: toRawOccSymbol(l.Symbol),
        OccSymbol: toPrefixedOccSymbol(l.Symbol)
      });
    })
  });
};

var getDurationFromCode = function getDurationFromCode(duration) {
  if (_.isNumber(duration)) {
    var durationCode = _.toNumber(duration);

    switch (durationCode) {
      case 1:
        return Durations.Day;

      case 2:
        return Durations.GTC;

      case 3:
        return Durations.Manual;
    }
  }

  return duration;
};

export var BuildInitialOrder = function BuildInitialOrder(strategy, partOrd) {
  return {
    OrderClass: strategy.profile.OrderClass,
    Legs: _.map(strategy.profile.Legs, function (leg, ind) {
      var legAction = leg.Direction === OrderActionDirection.Long ? OrderAction.Buy : OrderAction.Sell;

      if (leg.Direction === undefined) {
        legAction = ind === 0 ? OrderAction.Buy : OrderAction.Sell;
      }

      return Object.assign({}, leg, buildOrderLeg(partOrd, legAction, leg.AssetType, PositionEffect.Open, leg.OptionType, leg.SpreadRatio));
    })
  };
};
export var getNextLegId = function getNextLegId(existingLegs) {
  var id = _.uniqueId('leg_');

  while (_.keys(existingLegs).includes(id)) {
    id = _.uniqueId('leg_');
  }

  return id;
};
export var orderToReplaceOrder = function orderToReplaceOrder(order) {
  var valOrUndef = function valOrUndef(val) {
    return val && val !== 0 ? val : undefined;
  };

  return {
    AccountId: order.AccountId,
    DebitCredit: order.DebitCredit,
    SubaccountId: valOrUndef(order.SubaccountId),
    OrderId: order.OrderId,
    ClientRefId: valOrUndef(order.ClientRefId),
    OrderType: order.OrderType,
    Price: order.Price,
    Stop: valOrUndef(order.Stop)
  };
};
export var OnlyFilledOrders = function OnlyFilledOrders(orders) {
  return _(orders).filter(function (o) {
    var orderStatus = o.OrderStatus;

    switch (orderStatus) {
      case OrderStatuses.PartiallyFilled:
      case OrderStatuses.Filled:
        {
          return true;
        }

      default:
        return false;
    }
  }).value();
};
export var FilterOrderByDate = function FilterOrderByDate(orders, startDate, endDate) {
  return _.filter(orders, function (order) {
    if (startDate === undefined) {
      return true;
    }

    var endDateVal = endDate || new Date();
    return isBetweenInclusive(order.Date, startDate, endDateVal) ? true : false;
  });
};
export var TodaysFilledOrders = function TodaysFilledOrders(orders) {
  var startDate = dayjs(new Date()).hour(0).toDate();
  var endDate = dayjs(new Date()).toDate();
  return OnlyFilledOrders(FilterOrderByDate(orders, startDate, endDate));
};
export var GetSymbolsFromOrders = function GetSymbolsFromOrders(orders) {
  return _.reduce(orders, function (acc, o) {
    var Legs = o.Legs;
    var orderSymbols = Legs ? _.map(Legs, function (l) {
      return l.Symbol;
    }) : [];

    var updated = _(acc).union(orderSymbols).uniq().value();

    return updated;
  }, []);
};

var toOrderLegWithMetaData = function toOrderLegWithMetaData(o, l) {
  return Object.assign({}, l, {
    UnderlyingSymbol: o.UnderlyingSymbol,
    OrderId: o.OrderId,
    Date: o.Date
  });
};

var toOrderLegsWithMetaData = function toOrderLegsWithMetaData(orders) {
  return _.flatMap(orders, function (o) {
    return o.Legs.map(function (l) {
      return toOrderLegWithMetaData(o, l);
    });
  });
};

export var orderutility = {
  toOrderLegsWithMetaData: toOrderLegsWithMetaData
};
export var isSymbolExpired = function isSymbolExpired(symbol) {
  var symbData = parseOccSymbol(symbol); //not expired

  if (symbData.expiration && symbData.expiration.valueOf() <= new Date().valueOf()) {
    return true;
  }

  return false;
};
export var getDefaultOrderQuantity = function getDefaultOrderQuantity(strategy, _ref) {
  var defaultQuantityForOptionsAndStrategies = _ref.defaultQuantityForOptionsAndStrategies,
      defaultQuantityForShares = _ref.defaultQuantityForShares;

  if (strategy === 'Custom' || strategy === undefined) {
    return 1;
  } else if (strategy === 'Shares') {
    return defaultQuantityForShares || 1;
  } else {
    return defaultQuantityForOptionsAndStrategies || 1;
  }
};
export var getCustomStrategyLegQuantity = function getCustomStrategyLegQuantity(assetType, _ref2) {
  var defaultQuantityForOptionsAndStrategies = _ref2.defaultQuantityForOptionsAndStrategies,
      defaultQuantityForShares = _ref2.defaultQuantityForShares;

  if (assetType === AssetType.Equity) {
    return defaultQuantityForShares || 1;
  } else {
    return defaultQuantityForOptionsAndStrategies || 1;
  }
};
export var findDuplicateLegs = function findDuplicateLegs(legs) {
  var partialLegs = _.map(legs, function (l) {
    var symbolData = parseOccSymbol(l.Symbol);
    var data = {
      spreadRatio: l.SpreadRatio,
      expiration: symbolData.expiration,
      strike: symbolData.strike,
      optionType: symbolData.option
    };
    return {
      symbol: l.Symbol,
      occSymbol: l.OccSymbol,
      data: data
    };
  });

  return _.filter(partialLegs, function (curr, currInd) {
    var eq = _.find(partialLegs, function (e, eInd) {
      return _.isEqual(e.data, curr.data) && eInd !== currInd;
    });

    return eq ? true : false;
  });
};