import _defineProperty from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import Tooltip from 'rc-tooltip';
import * as RcSlider from 'rc-slider';
import Slider from 'rc-slider';
import _ from 'lodash';
var Range = RcSlider.Range;
var createSliderWithTooltip = RcSlider.createSliderWithTooltip;
var RangeTooltip = createSliderWithTooltip(Range);
var Handle = RcSlider.Handle;
export { Handle, RangeTooltip, Range, Slider };

var handle = function handle(props) {
  var value = props.value,
      dragging = props.dragging,
      index = props.index,
      restProps = tslib_1.__rest(props, ["value", "dragging", "index"]);

  return React.createElement(Tooltip, {
    prefixCls: "rc-slider-tooltip",
    overlay: value,
    visible: dragging,
    placement: "top",
    key: index
  }, React.createElement(Handle, Object.assign({
    value: value
  }, restProps)));
};

export var CustomSlider = function CustomSlider(_a) {
  var children = _a.children,
      markers = _a.markers,
      rest = tslib_1.__rest(_a, ["children", "markers"]);

  var marks = useMemo(function () {
    if (markers) {
      return _.reduce(markers, function (acc, m) {
        var mLabel = _.isFunction(m.label) ? m.label(m) : m.label;
        return Object.assign({}, acc, _defineProperty({}, m.value, Object.assign({}, m, {
          label: mLabel
        })));
      }, {});
    }

    return rest.marks;
  }, [markers, rest.marks]);
  return React.createElement(Slider, Object.assign({
    handle: handle
  }, rest, {
    marks: marks
  }));
};