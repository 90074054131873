import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import _, { create } from 'lodash';
import { ExternalLink, GetTradingBlockDomain, Section } from '@tradingblock/components';
import {
  ApplicationDuplicateUsernameResponseCode,
  ApplicationDuplicateAccountTypeSSNResponseCode,
  Country,
  TradingBlockUrlsLookup,
} from '@tradingblock/types';
import { ApplicationStepProps, ApplicationFormSection, ClearerType, BooleanToggleType } from '../../../types';
import { useData, useStateSelector } from '../../../state/selectors';
import { Errors } from '../..';
import { UserInfoSection } from './secure-account/UserInfoSection';
import { UserCredentialsSection } from './secure-account/UserCredentialsSection';
import config from '../../../config';
import { useDispatcher } from '../../hooks';
import { ApplicationActions } from '../../../state/actions';
import { getAffiliateHeardAboutUs } from '../../../services';

export const SecureAccountForm: React.FC<ApplicationStepProps> = ({ getText, ...sectionProps }) => {
  const { setValues, values } = sectionProps;
  const authToken = useData(d => d.ui.apiToken);

  const { isSaving } = useData(d => d.data.saveInfo);
  const visibleSections = useData(d => d.ui.visibleSections);
  const createErrorCode = useData(d => d.data.createErrorCode);
  const createErrorMessages = useData(d => d.data.createErrorMessages);
  const dispatch = useDispatcher();
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;
  const clearer = useStateSelector(s => s.ui.clearer);
  const affiliate: string | undefined = useStateSelector(s => s.ui.affiliate);
  const affiliateValue = affiliate && getAffiliateHeardAboutUs(affiliate);

  useEffect(() => {
    if (affiliate && affiliateValue && !values.hearAboutUsId && !values.repId) {
      setValues({
        ...values,
        hearAboutUsId: affiliateValue.heardAboutId,
        repId: affiliateValue.repId,
      });
    }
  }, [affiliate, affiliateValue]);

  useEffect(() => {
    if (clearer === 'rqd') {
      setValues({
        ...values,
        clearer: ClearerType.RQD,
        ownerCountry: Country.UnitedStatesOfAmerica,
        retirementAccount: BooleanToggleType.No,
        primaryAccountHolder: {
          ...values.primaryAccountHolder,
          address: {
            ...values.primaryAccountHolder.address,
            country: Country.UnitedStatesOfAmerica,
          },
          citizenshipCountry: Country.UnitedStatesOfAmerica,
        },
      });
    } else if (clearer === 'apex') {
      setValues({
        ...values,
        clearer: ClearerType.Apex,
      });
    }
  }, [clearer]);

  useEffect(() => {
    if (siteGroup === 'mb') {
      setValues({
        ...values,
        clearer: ClearerType.Apex,
      });
    }
  }, [siteGroup]);

  const errors = useMemo(() => {
    if (createErrorCode) {
      if (createErrorCode === ApplicationDuplicateAccountTypeSSNResponseCode) {
        return {
          user: `An account with this account type and SSN already exists. Please change the account type or reach out to Account Services for assistance.`,
        };
      }
      if (!_.isEmpty(createErrorMessages)) {
        return { user: `There was a problem creating your user account:` };
      }
      return { user: createErrorCode === ApplicationDuplicateUsernameResponseCode ? 'duplicate' : 'failed' };
    }
    return undefined;
  }, [createErrorCode, createErrorMessages]);

  const sections = useMemo(() => {
    let allSections: React.FC<ApplicationFormSection>[] = [UserInfoSection, UserCredentialsSection];
    return _.take(allSections, visibleSections || 1);
  }, [visibleSections]);

  useEffect(() => {
    dispatch(ApplicationActions.requestUiData({ siteConfigId: siteGroup === 'mb' ? 4 : 2 }));
  }, [siteGroup]);

  const crsFormLink = siteGroup === 'mb' ? TradingBlockUrlsLookup.MB_CRS : TradingBlockUrlsLookup.TB_CRS;
  const domain = GetTradingBlockDomain(undefined, siteGroup);

  // combine domain and crsFormLink
  const crsFormLinkURL = `//legacy.${domain}${crsFormLink}`;

  return (
    <>
      {_.map(sections, (ChildSection, index) => (
        <ChildSection key={`ChildSection-${index}`} getText={getText} {...sectionProps} />
      ))}

      {!config.isVirtual && !authToken && (!visibleSections || visibleSections <= 1) && (
        <Section>
          <h2 className="fields-title">{getText('signin')}</h2>
          <p>
            {getText('signin', 'description', {
              data: { signinLink: <Link to="/auth">{getText('signin.signinLink')}</Link> },
            })}
          </p>
          <p>
            {getText('crs', 'description', {
              data: {
                crsLink: (
                  <ExternalLink href={crsFormLinkURL}>
                    <strong>{getText('crs.crsLink')}</strong>
                  </ExternalLink>
                ),
              },
            })}
          </p>
        </Section>
      )}

      {errors && !isSaving && (
        <Errors errors={errors}>
          {!_.isEmpty(createErrorMessages) && (
            <ul className="error error-list">
              {_.map(createErrorMessages, (err, i) => (
                <li key={`error${i}`}>{err}</li>
              ))}
            </ul>
          )}
        </Errors>
      )}
    </>
  );
};
