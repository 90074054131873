import * as yup from 'yup';
var AssetSymbolSchemaFields = {
  name: yup.string(),
  symbol: yup.string(),
  provider: yup.string()
}; // const Schemas = {
//   OrderAction: yup.number().oneOf([OrderAction.Buy, OrderAction.Sell]),
//   PositionEffect: yup.number().oneOf([PositionEffect.Open, PositionEffect.Close]),
// };

export var AssetSymbolSchema = yup.object(AssetSymbolSchemaFields); // export type AssetSymbol = yup.InferType<typeof AssetSymbolSchema>;