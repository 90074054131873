import _ from 'lodash';
import { getCurrencyType } from '../lib';
/**
 * A function to format number values
 * @param value the number that will be formatted.
 * @param options the formatting options:
 *  currency: if true, the number will be prefixed with a '$' and formatted with 2 decimal places.
 *  roundDecimal: a number detailing how many decimal places the formatted number will have.
 *  format: if true, the number will be formatted to the users locale.
 *  integer: if true, the number will have no decimal places
 *  locale: the locale of the value (ex: 'en-US')
 *  prefix: the string that prefixes the number (ex: '+')
 */

export var formatNumber = function formatNumber(value, options) {
  var _ref = options || {},
      currency = _ref.currency,
      roundDecimal = _ref.roundDecimal,
      format = _ref.format,
      integer = _ref.integer,
      locale = _ref.locale,
      percent = _ref.percent,
      prefix = _ref.prefix,
      greeks = _ref.greeks,
      isStrikeField = _ref.isStrikeField,
      precisionOverride = _ref.precisionOverride;

  var valueAsNumber = typeof value === 'number' ? value : !_.isNil(value) ? parseFloat(value.replace(/[$,]+/g, '')) : 0;

  if (precisionOverride) {
    var precision = valueAsNumber.toLocaleString(locale, {
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 4
    });
    return "".concat(prefix || '').concat(precision);
  }

  var dec = _.isNumber(roundDecimal) ? roundDecimal : integer ? 0 : 2;
  var userLocale = locale || navigator.language;
  var localeOptions = currency ? {
    style: 'currency',
    currency: _.isBoolean(currency) ? getCurrencyType(locale) : currency
  } : format ? {
    minimumFractionDigits: dec,
    maximumFractionDigits: 2
  } : undefined;

  var roundedValue = _.round(valueAsNumber, dec);

  var num = localeOptions ? roundedValue.toLocaleString(userLocale, localeOptions) : percent ? formatPercent(roundedValue) : roundedValue.toFixed(dec); // Implied Volatility is a percentage, but we want to display it as a decimal

  if (greeks && percent) {
    num = formatPercent(valueAsNumber * 100, true);
  } // strike fields should be formatted as integers if they are whole numbers and with 2 decimal places if they are not


  if (isStrikeField) {
    num = valueAsNumber % 1 === 0 ? valueAsNumber.toFixed(0) : valueAsNumber.toFixed(2);
  }

  return "".concat(prefix || '').concat(num);
};
export var formatPercent = function formatPercent(value, isGreek) {
  // if isGreek, we want to format the percentage in the format of 0.00%
  if (isGreek) {
    return "".concat(value.toFixed(2), "%");
  } // if the value is greater than 10, we want to round it to the nearest integer
  // otherwise, we want to round it to 2 decimal places


  var num = Math.abs(value) > 10 ? value > 0 ? _.ceil(value) : _.floor(value) : value.toFixed(2);
  return "".concat(num, "%");
}; // taken from: https://stackoverflow.com/a/45309230

export var parseNumber = function parseNumber(value, locale) {
  var userLocale = locale || navigator.language; // format a known value using Intl.NumberFormat for user's locale...

  var formatExample = Intl.NumberFormat(userLocale).format(1.1); // ... then use known formatted value to determine decimal separator...

  var decimalSeparator = formatExample.charAt(1); // ... then build regex to remove everything except number values and decimal separator

  var formatRegex = new RegExp("[^-+0-9".concat(decimalSeparator, "]"), 'g');
  var normalized = value.replace(formatRegex, '').replace(decimalSeparator, '.');
  return parseFloat(normalized);
};