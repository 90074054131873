import React, { useEffect, FC } from 'react';
import useMount from 'react-use/lib/useMount';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, withRouter, RouteComponentProps } from 'react-router-dom';
import { LocalizeProviderWrapper, useTracking } from '@tradingblock/components';
import { useLocalStorage } from '@tradingblock/storage';
import { DataStore } from './state/store';
import { useApi, useApplication } from './context';
import { delayedScrollTo } from './services';
import { PageHeader, PageFooter } from './components';
import { ApplicationRouter } from './ApplicationRouter';
import { useData } from './state/selectors';
import { useDispatcher } from './components/hooks';
import { ApplicationActions, TokenActions, UiActions } from './state/actions';
import './App.css';
import config from './config';
import ReactGA from 'react-ga4';
import { LinkClickedProvider } from './components/application/form-steps/LinkClickedContext';

// Use vt tracking code if virtual
const TRACKING_ID = config.isVirtual ? config.ga4VTTrackingCode : config.ga4TrackingCode;
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}
const TokenHandler: FC<{}> = () => {
  const isExpired = useData(d => d.ui.storageTokenExpired);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isExpired) {
      console.log('token is expired');
      dispatch(UiActions.setNextPage('/expired'));
    }
  }, [isExpired]);
  return <></>;
};

const AppContent: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatcher();
  const api = useApi();
  const [, { setNavMenuOpen }] = useApplication();

  const currentPage = window.location.pathname;
  const nextPage = useData(d => d.ui.nextPage);

  useEffect(() => {
    // Allow pages that do not need auth tokens to be not tested for expired token...
    if (
      currentPage !== '/' &&
      currentPage !== '/home' &&
      currentPage !== '/apply/secure' &&
      currentPage !== '/auth' &&
      currentPage === nextPage
    ) {
      api.user
        .profile()
        .then(result => {
          if (result.responseCode !== 0) {
            dispatch(TokenActions.uiTokenExpired());
          }
        })
        .catch(() => {
          dispatch(TokenActions.uiTokenExpired());
        });
    }
    if (nextPage) {
      // once arriving on nextPage, reset nextPage in state
      if (currentPage === nextPage) {
        dispatch(UiActions.clearNextPage());
      } else {
        // send to next page
        const redirectTo = nextPage;
        window.setTimeout(() => {
          history.push(redirectTo);
          delayedScrollTo(0, 0);
        }, 1);
      }
    }
  }, [currentPage, nextPage, dispatch, history]);

  const onMenuClose = () => {
    setNavMenuOpen(false);
  };

  return (
    <div className={config.isVirtual ? 'env-virtual' : ''}>
      <PageHeader />
      <TokenHandler />
      <main onClick={onMenuClose}>
        <ApplicationRouter />
      </main>
      <footer className="footer" onClick={onMenuClose}>
        <PageFooter />
      </footer>
    </div>
  );
};

const AppContentWithRouter = withRouter(AppContent);

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={DataStore}>
      <LinkClickedProvider>
        <useLocalStorage.Provider>
          <LocalizeProviderWrapper>
            <useApplication.Provider>
              <BrowserRouter basename={config.subdirectory}>
                <AppContentWithRouter />
              </BrowserRouter>
            </useApplication.Provider>
          </LocalizeProviderWrapper>
        </useLocalStorage.Provider>
      </LinkClickedProvider>
    </Provider>
  );
};

export default App;
