import { useState } from 'react';
import constate from 'constate';
import { MismatchedDataModalState } from '../types';

const ApplicationContext = () => {
  // use as tri-state:
  //  undefined == nothing fetched (default)
  //  true == currently fetching
  //  false == finished fetch
  const [isFetching, setIsFetching] = useState<boolean>();
  // use as tri-state:
  //  undefined == not configured (default)
  //  MismatchedDataModalState == current state
  //  false == modal closed
  const [mismatchedDataModal, setMismatchedDataModal] = useState<MismatchedDataModalState | false>();
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const state = { isFetching, mismatchedDataModal, navMenuOpen, formSubmitted };
  const actions = { setIsFetching, setMismatchedDataModal, setNavMenuOpen, setFormSubmitted };
  const context: [typeof state, typeof actions] = [state, actions];
  return context;
};

export const useApplication = constate(ApplicationContext);
