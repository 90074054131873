import React, { useMemo } from 'react'; // import { Spinner } from 'react-bootstrap';

export var Loading = function Loading(_ref) {
  var size = _ref.size;
  var iconSize = useMemo(function () {
    return size === 'small' ? '1x' : size === 'large' ? '5x' : '3x';
  }, [size]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "fa-".concat(iconSize)
  }, React.createElement(LoadingImage, null)));
};
export function LoadingImage() {
  return React.createElement("i", {
    className: "fas fa-cog fa-spin"
  });
}