import React from 'react';
import { FooterTeaser } from '@tradingblock/components';
import { useData } from '../state/selectors';

export const PageFooter: React.FC = () => {
  const siteGroup = process.env.REACT_APP_TB_SITE_GROUP;
  const origin = useData(d => d.ui.siteGrp);

  return (
    <div className="content">
      <FooterTeaser siteGroup={siteGroup} origin={origin} />
    </div>
  );
};
