import React, { useCallback } from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { AllAccountDisclosureKeys, AccountDisclosureKey, AccountDisclosureContingentFields, BooleanToggleType, SummaryStepProps, ApplicationType, EntityAccountType, AllEntityAccountDisclosureKeys, AllEntityNonTrustAccountDisclosureKeys } from '../../../../types';
import { FieldValue } from '../../../values';
import { PublicOfficialsValue } from '../../../values/PublicOfficials';

export const AccountDisclosuresSummary: React.FC<SummaryStepProps> = ({ application, getText }) => {
  const getDisclosureContingentFields = useCallback(
    (key: AccountDisclosureKey) => {
      if (application && application.accountDisclosures && application.accountDisclosures[key] === BooleanToggleType.Yes && AccountDisclosureContingentFields[key]) {
        const id = _.first(AccountDisclosureContingentFields[key]);
        // special case for public officials
        if (id === 'disclosuresEntityPublicOfficials') {
          return <PublicOfficialsValue value={application[id]} />;
        }

        return (
          <p>
            {_.map(AccountDisclosureContingentFields[key], id => (
              <span key={id}>
                <Text id={id} type="field" />
                {': '}
                <FieldValue key={id} value={application[id] as string}>
                  <strong>{application[id]}</strong>
                </FieldValue>
                <br />
              </span>
            ))}
          </p>
        );
      }
      return null;
    },
    [application]
  );

  if (!application) {
    return null;
  }

  return (
    <>
      <h3 className="fields-title">{getText('accountDisclosures')}</h3>
      {_.map(AllAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
        <div key={`disclosure${i}`}>
          <p>{getText(`accountDisclosures.${d}`)}</p>
          <p>
            <strong>
              <FieldValue value={application.accountDisclosures && application.accountDisclosures[d]} textPrefix="defaults.boolean" />
            </strong>
          </p>
          {getDisclosureContingentFields(d)}
        </div>
      ))}

      {application.type === ApplicationType.Entity && (
        <>
          <hr />
          <p>
            <strong>{getText('accountDisclosures.entityDisclosures')}</strong>
          </p>
          {_.map(AllEntityAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
            <div key={`entityAccountDisclosures${i}`}>
              <p>{getText(`accountDisclosures.${d}`)}</p>
              <p>
                <strong>
                  <FieldValue value={application.accountDisclosures && application.accountDisclosures[d]} textPrefix="defaults.boolean" />
                </strong>
              </p>
              {getDisclosureContingentFields(d)}
            </div>
          ))}

          {application.entityAccountType !== EntityAccountType.PersonalTrust && (
            <>
              <hr />
              <p>
                <strong>{getText('accountDisclosures.entityNonTrustDisclosures')}</strong>
              </p>
              {_.map(AllEntityNonTrustAccountDisclosureKeys, (d: AccountDisclosureKey, i) => (
                <div key={`entityNonTrustAccountDisclosures${i}`}>
                  <p>{getText(`accountDisclosures.${d}`)}</p>
                  <p>
                    <strong>
                      <FieldValue value={application.accountDisclosures && application.accountDisclosures[d]} textPrefix="defaults.boolean" />
                    </strong>
                  </p>
                  {getDisclosureContingentFields(d)}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};
