import React from 'react';
import { Translate } from 'react-localize-redux';
import { useLocalize } from '../../hooks';
import { GetTradingBlockSiteName } from '../../utilities/site';
export var Text = function Text(_ref) {
  var id = _ref.id,
      type = _ref.type,
      page = _ref.page,
      textKey = _ref.textKey,
      data = _ref.data,
      defaultPage = _ref.defaultPage,
      siteGroup = _ref.siteGroup;

  var _useLocalize = useLocalize(),
      getTranslationId = _useLocalize.getTranslationId;

  var defaultData = {
    ndash: '&ndash;',
    prefix: '',
    siteName: GetTradingBlockSiteName(undefined, siteGroup)
  };
  return React.createElement(Translate, {
    id: getTranslationId({
      id: id,
      type: type,
      page: defaultPage ? 'defaults' : page,
      textKey: textKey
    }),
    data: Object.assign({}, defaultData, data),
    options: {
      renderToStaticMarkup: false,
      onMissingTranslation: function onMissingTranslation() {
        return textKey;
      }
    }
  });
};