import axios from 'axios';
import _ from 'lodash';
import dayjs from 'dayjs';
import qs from 'qs';
import { OrderStatuses } from '@tradingblock/types';
import axiosRetry from 'axios-retry'; // export function toApiResponse<D>(resp: ApiResponse<D> | LegacyApiResponse<D>): ApiResponse<D> {
//   const payload: D = _.get(resp, 'Payload', undefined) || _.get(resp, 'payload', undefined);
//   const responseCode: ResponseCode = _.get(resp, 'ResponseCode', undefined) || _.get(resp, 'responseCode', undefined);
//   return {
//     payload,
//     responseCode,
//   };
// }

var clients = {};
export var createAxiosClient = function createAxiosClient(url, token) {
  // Check if we already have a client instance for this url and token
  if (clients["".concat(url, "-").concat(token)] !== undefined) {
    return clients["".concat(url, "-").concat(token)];
  }

  var client = axios.create({
    baseURL: url,
    headers: {
      Authorization: token,
      Accept: 'application/json'
    }
  });
  axiosRetry(client, {
    retries: 6,
    retryCondition: function retryCondition(e) {
      return axiosRetry.isNetworkOrIdempotentRequestError(e) || e.response.status === 429;
    },
    shouldResetTimeout: true,
    retryDelay: function retryDelay(retryCount, error) {
      if (error.response && error.response.headers !== undefined) {
        var retry_after = error.response.headers['retry-after'];
        console.log('Retrying after: ', retry_after);

        if (retry_after) {
          return retry_after * 1000;
        }
      } // Can also just return 0 here for no delay if one isn't specified


      return retryCount * 2000;
    },
    onRetry: function onRetry(retryCount, error, requestConfig) {
      console.log("Retry attempt #".concat(retryCount, " for ").concat(requestConfig.url));
      console.log("Error ", error);
    }
  }); //Create a cache version of clients to prevent multiple clients from being created

  clients["".concat(url, "-").concat(token)] = client;
  return client;
};
export var formatUrl = function formatUrl(url, querystringParams) {
  var delim = url.indexOf('?') > -1 ? '&' : '?';
  var params = querystringParams ? qs.stringify(querystringParams, {
    arrayFormat: 'comma'
  }) : null;
  return "".concat(url).concat(params ? delim + params : '');
};
export function isString(x) {
  return typeof x === 'string';
}
export function getIdNumber(id) {
  return _.toNumber(id);
}
export var DateFormats = {
  ApiFormat: 'MM-DD-YYYY',
  DateTimeApiFormat: 'YYYY-MM-DD hh:mm:ss'
};
export var dateStringOrUndefined = function dateStringOrUndefined(dateOrString, format) {
  //if (dateOrString === undefined || isString(dateOrString)) return dateOrString;
  var dateValue = dateOrString === undefined || isString(dateOrString) ? dayjs(dateOrString).toDate() : dateOrString;

  if (format === undefined) {
    return dayjs(dateValue).toDate().toISOString();
  }

  return dayjs(dateValue).format(format);
};
export function isInfinity(val) {
  return val === 'Infinity';
}
export function isHeartbeat(val) {
  return val.type === 'HeartBeat';
}
export function isExpiredToken(val) {
  return val.type === 'ExpiredToken';
}
export function isOrderUpdate(val) {
  return val.type === 'OrderUpdate';
}
export function isQuote(val) {
  return val.type === 'Quote';
}
export function isSettlement(val) {
  return val.type === 'Settlement';
}
export function getOrderStatus(val) {
  var match = _.map(OrderStatuses, function (s, k) {
    return {
      status: k,
      value: s
    };
  }).find(function (v) {
    return v.value === val;
  });

  return match;
}