import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { LocalizeProvider, withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { AllLanguageTypes, allTranslations, getLocalizeOptions } from '@tradingblock/types';

var LocalizeWrapper = function LocalizeWrapper(_ref) {
  var initialize = _ref.initialize,
      addTranslationForLanguage = _ref.addTranslationForLanguage,
      children = _ref.children;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      localizeInitialized = _useState2[0],
      setLocalizeInitialized = _useState2[1];

  useEffect(function () {
    if (!localizeInitialized) {
      initialize(getLocalizeOptions(AllLanguageTypes));

      _.each(allTranslations, function (translations, lang) {
        addTranslationForLanguage(translations, lang);
      });

      window.setTimeout(function () {
        return setLocalizeInitialized(true);
      }, 10);
    }
  }, [localizeInitialized, initialize, addTranslationForLanguage]); // wait for localize to be initialized with data

  if (!localizeInitialized) {
    return null;
  }

  return React.createElement(React.Fragment, null, children);
};

var LocalizeWrapperWithLocalize = withLocalize(LocalizeWrapper);
export var LocalizeProviderWrapper = function LocalizeProviderWrapper(_ref2) {
  var children = _ref2.children;
  return React.createElement(LocalizeProvider, null, React.createElement(LocalizeWrapperWithLocalize, null, children));
};