import { ApplicationStep } from '../../types';
import { AccountTypeForm } from './form-steps/AccountType';
import { AgreementsForm } from './form-steps/Agreements';
import { BeneficiariesForm } from './form-steps/Beneficiaries';
import { AccountInformationForm } from './form-steps/AccountInformation';
import { InvestingProfileForm } from './form-steps/InvestingProfile';
import { SecureAccountForm } from './form-steps/SecureAccount';
import { SignForm } from './form-steps/Sign';
import { SecureAccountSummary } from './summary-steps/SecureAccount';
import { AccountTypeSummary } from './summary-steps/AccountType';
import { InvestingProfileSummary } from './summary-steps/InvestingProfile';
import { AccountInformationSummary } from './summary-steps/AccountInformation';
import { BeneficiariesSummary } from './summary-steps/Beneficiaries';
import { AgreementsSummary } from './summary-steps/Agreements';
import { ClearerTypeForm } from './form-steps/ClearerType';
import { ClearerTypeSummary } from './summary-steps/ClearerType';

export * from './ApplicationLoader';
export * from './MismatchedDataModal';
export * from './RiskLevelIcon';
export * from './TradeGoalsVerification';
export * from './TradeGoalText';

export const ApplicationFormComponents = {
  [ApplicationStep.SecureAccount]: SecureAccountForm,
  [ApplicationStep.ClearerType]: ClearerTypeForm,
  [ApplicationStep.AccountType]: AccountTypeForm,
  [ApplicationStep.InvestingProfile]: InvestingProfileForm,
  [ApplicationStep.AccountInformation]: AccountInformationForm,
  [ApplicationStep.Beneficiaries]: BeneficiariesForm,
  [ApplicationStep.Agreements]: AgreementsForm,
  [ApplicationStep.Sign]: SignForm,
};

export const ApplicationSummaryComponents = {
  [ApplicationStep.SecureAccount]: SecureAccountSummary,
  [ApplicationStep.ClearerType]: ClearerTypeSummary,
  [ApplicationStep.AccountType]: AccountTypeSummary,
  [ApplicationStep.InvestingProfile]: InvestingProfileSummary,
  [ApplicationStep.AccountInformation]: AccountInformationSummary,
  [ApplicationStep.Beneficiaries]: BeneficiariesSummary,
  [ApplicationStep.Agreements]: AgreementsSummary,
};
