import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { RiskLevel } from '../../types';
import { useLocalize } from '@tradingblock/components';

interface RiskLevelIconProps {
  level: RiskLevel;
}

const RiskLevelIconComponent: React.FC<RiskLevelIconProps & LocalizeContextProps> = ({ level, translate }) => {
  const { getTranslationId } = useLocalize();

  const title = useMemo(() => {
    const text = translate(getTranslationId({ id: `riskLevel.${level}` }));
    return _.isString(text) ? text : `[level ${level}]`;
  }, [level, translate, getTranslationId]);

  return (
    <span className="risk-level" title={title}>
      <span className="risk-level-base">
        <i className="fas fa-lg fa-signal-4" />
      </span>
      <span className={`risk-level-value risk-level-${level}`}>
        <i className="fas fa-lg fa-signal-4" />
      </span>
    </span>
  );
};

export const RiskLevelIcon = withLocalize(RiskLevelIconComponent);
