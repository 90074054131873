import React, { useEffect, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import { Redirect } from 'react-router';
import _ from 'lodash';
import qs from 'qs';
import { IStorageEngine } from '@tradingblock/types';
import { useLocalStorage, TokenFromStorage, SaveTokenInStorage } from '@tradingblock/storage';
import { config } from '../config';
import { validateToken, getApplicationStepUrl } from '../services';
import { useData } from '../state/selectors';
import { useDispatcher } from '../components/hooks';
import { UiActions } from '../state/actions';

const tryGetToken = async (storage: IStorageEngine, tokenFromUrl?: string, tokenFromState?: string) => {
  const tokenFromLS = await TokenFromStorage(storage);
  const tokenVal = tokenFromUrl || tokenFromLS || tokenFromState;

  if (tokenVal) {
    const tokenResult = await validateToken(tokenVal);
    if (!tokenResult) {
      console.warn('token found was invalid', tokenVal);
      return null;
    }
    const { value, decoded } = tokenResult;
    const tokenPayload = { ...decoded, value };
    await SaveTokenInStorage(storage, value);
    return tokenPayload;
  } else {
    console.warn('token not found from url or local storage');
    return null;
  }
};

const AuthenticationPage: React.FC = () => {
  const dispatch = useDispatcher();
  const storage = useLocalStorage();
  const tokenFromState = useData(d => d.ui.storageToken);
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;
  const urlParams = qs.parse(_.drop(window.location.hash, 1).join(''));
  const tokenFromUrl = _.isString(urlParams.id_token) ? (urlParams.id_token as string) : undefined;
  const nextPage = _.isString(urlParams.next)
    ? (urlParams.next as string)
    : getApplicationStepUrl('summary', siteGrp || urlParams.grp);

  const [currentToken, setCurrentToken] = useState<string | null>();

  useMount(() =>
    tryGetToken(storage, tokenFromUrl, tokenFromState).then(tokenRes => {
      if (tokenRes) {
        dispatch(UiActions.setAuthToken({ apiToken: tokenRes.token, storageToken: tokenRes.value }));
        if (window.location.pathname.includes('/auth')) {
          setCurrentToken(tokenRes.token);
        }
      } else {
        setCurrentToken(null);
      }
    })
  );

  useEffect(() => {
    if (currentToken === null) {
      if (!siteGrp) {
        window.location.href = `${config.loginUrl}/?type=application&redirect=${config.authRedirect}/auth?grp=${urlParams.grp}`;
      } else {
        window.location.href = `${config.loginUrl}/?type=application&grp=${siteGrp}&redirect=${config.authRedirect}/auth?grp=${siteGrp}`;
      }
    }
  }, [currentToken, siteGrp]);

  if (!_.isNil(currentToken)) {
    return <Redirect to={{ pathname: nextPage }} />;
  }

  return null;
};

export default AuthenticationPage;
