var isChildNodeOfHtmlElement = function isChildNodeOfHtmlElement(element, parent) {
  if (element && parent) {
    return parent.contains(element);
  }

  return false;
};

export var isChildNodeOfElement = function isChildNodeOfElement(element, parent) {
  return isChildNodeOfHtmlElement(element, document.getElementById(parent));
};
export var isChildNodeOfModal = function isChildNodeOfModal(node) {
  return !!node && isChildNodeOfModalRecursive(node);
};

var isChildNodeOfModalRecursive = function isChildNodeOfModalRecursive(node) {
  if (isModalNode(node)) {
    return true;
  }

  if (node.parentElement) {
    return isChildNodeOfModalRecursive(node.parentElement);
  }

  return false;
};

var isModalNode = function isModalNode(node) {
  var modal = node.getAttribute('aria-modal');
  var role = node.getAttribute('role');
  return modal === 'true' && role === 'dialog';
};