import React, { useCallback } from 'react';
import { Field } from 'formik';
import { Section, HelpText, CheckboxField, AddressField } from '@tradingblock/components';
import { AllStates, AcceptedCountries, getGroupedCountryOptions, Country } from '@tradingblock/types';
import {
  ApplicationFormSection,
  AccountHolderType,
  DefaultAccountHolder,
  PersonType,
  ApplicationStep,
  AccountHolderField,
  ClearerType,
} from '../../../../types';
import { PhonesArrayField, PersonField } from '../../../fields';
import {
  updateFieldsDependentOnDifferentMailingAddress,
  copyAccountHolderPrimaryAddresses,
  getNumberOfApplicationInformationEntitySections,
} from '../../../../services';

interface AccountHolderContactSectionProps extends ApplicationFormSection {
  type: AccountHolderType;
}

const AccountHolderContactSection: React.FC<AccountHolderContactSectionProps> = ({
  type,
  getText,
  values,
  setValues,
}) => {
  const fieldPrefix: AccountHolderField = type === 'primary' ? 'primaryAccountHolder' : 'secondaryAccountHolder';
  const accountHolder = values[fieldPrefix] || DefaultAccountHolder;
  // number of optional entity sections
  const entitySections = getNumberOfApplicationInformationEntitySections(values);
  const totalPreceedingSections = entitySections + (type !== 'primary' ? 2 : 0);
  const isUgma = type === 'ugma';

  const touchAddress = useCallback(() => {
    const address1 = document.getElementById(`${fieldPrefix}.address.address1`);
    if (address1) {
      address1.focus();
      address1.blur();
    }
  }, [fieldPrefix]);

  const onCopyAddress = useCallback(() => {
    setValues(copyAccountHolderPrimaryAddresses(values));
    // when copying primary address, need to 'touch' a field to trigger an update to form validation state
    window.setTimeout(touchAddress, 200);
  }, [values, setValues, touchAddress]);

  return (
    <Section id={`${ApplicationStep.AccountInformation}-section-${1 + totalPreceedingSections}`}>
      <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
        {isUgma ? getText('ugmaAccountHolder') : getText(fieldPrefix)}
      </h3>

      {type !== 'primary' && <Field component={PersonField} id={fieldPrefix} personType={PersonType.AccountHolder} />}
      <h2 className="fields-title">{getText('defaults.phones')}</h2>
      <p>{getText('defaults.phones', 'description')}</p>

      <Field component={PhonesArrayField} id={fieldPrefix} value={accountHolder.phones} />

      <h2 className="fields-title mt-5">{getText('accountHolders.address')}</h2>
      <HelpText help={getText('accountHolders.address.help', 'description')}>
        {getText('accountHolders.address.help')}
      </HelpText>

      {type !== 'primary' && (
        <p>
          <button type="button" onClick={onCopyAddress}>
            <i className="fa fa-clipboard" /> {getText('accountHolders.address.copyPrimaryAddresses')}
          </button>
        </p>
      )}

      <Field
        component={AddressField}
        id={`${fieldPrefix}.address`}
        labelPrefix={getText('accountHolders.address.label')}
        countryProps={{
          options:
            values.clearer === ClearerType.RQD
              ? [Country.Argentina, Country.Chile, Country.Peru, Country.UnitedStatesOfAmerica, Country.Uruguay]
              : AcceptedCountries,
          label: getText('accountHolders.address', 'country.label', { type: 'field' }),
          optionsDefaultLabelId: 'country',
        }}
        addr1Help={getText('accountHolders.address.address1.help')}
        stateProps={{
          options: AllStates,
          defaultLabelId: 'address.state',
          optionsDefaultLabelId: 'state',
          labelPrefix: getText('accountHolders.address.label'),
        }}
      />

      <p>
        <Field
          component={CheckboxField}
          id={`${fieldPrefix}.differentMailingAddress`}
          label={getText('accountHolders.differentMailingAddress', 'label', { type: 'field' })}
          onSetValue={(differentMailingAddress: boolean) =>
            setValues(updateFieldsDependentOnDifferentMailingAddress(values, fieldPrefix, differentMailingAddress))
          }
        />
      </p>

      {accountHolder.differentMailingAddress && (
        <>
          <h2 className="fields-title">{getText('accountHolders.mailingAddress')}</h2>
          <p>{getText('accountHolders.mailingAddress', 'description')}</p>
          <Field
            component={AddressField}
            id={`${fieldPrefix}.mailingAddress`}
            labelPrefix={getText('accountHolders.mailingAddress.label')}
            countryProps={{
              options: getGroupedCountryOptions(),
              defaultLabelId: 'address.country',
              optionsDefaultLabelId: 'country',
              labelPrefix: getText('accountHolders.mailingAddress.label'),
            }}
            stateProps={{
              options: AllStates,
              defaultLabelId: 'address.state',
              optionsDefaultLabelId: 'state',
              labelPrefix: getText('accountHolders.mailingAddress.label'),
            }}
          />
        </>
      )}
    </Section>
  );
};

export const PrimaryAccountHolderContactSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderContactSection type={AccountHolderType.Primary} {...props} />;
};

export const JointAccountHolderContactSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderContactSection type={AccountHolderType.Joint} {...props} />;
};

export const UgmaAccountHolderContactSection: React.FC<ApplicationFormSection> = props => {
  return <AccountHolderContactSection type={AccountHolderType.Ugma} {...props} />;
};
