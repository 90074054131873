export var ReleaseNoteIssueTypes;

(function (ReleaseNoteIssueTypes) {
  ReleaseNoteIssueTypes["NewFeature"] = "NewFeature";
  ReleaseNoteIssueTypes["Improvement"] = "Improvement";
  ReleaseNoteIssueTypes["BugFix"] = "BugFix";
  ReleaseNoteIssueTypes["MarketHoliday"] = "MarketHoliday";
  ReleaseNoteIssueTypes["TechnicalAlert"] = "TechnicalAlert";
  ReleaseNoteIssueTypes["OperationsAlert"] = "OperationsAlert";
  ReleaseNoteIssueTypes["CorporateActionAlert"] = "CorporateActionAlert";
})(ReleaseNoteIssueTypes || (ReleaseNoteIssueTypes = {}));

export var UserLevelTypes;

(function (UserLevelTypes) {
  UserLevelTypes["Undefined"] = "Undefined";
  UserLevelTypes["Admin"] = "Admin";
  UserLevelTypes["Firm"] = "Firm";
  UserLevelTypes["Region"] = "Region";
  UserLevelTypes["Branch"] = "Branch";
  UserLevelTypes["Rep"] = "Rep";
  UserLevelTypes["Account"] = "Account";
})(UserLevelTypes || (UserLevelTypes = {}));

export var AllReleaseNoteIssueTypes = [ReleaseNoteIssueTypes.NewFeature, ReleaseNoteIssueTypes.Improvement, ReleaseNoteIssueTypes.BugFix, ReleaseNoteIssueTypes.MarketHoliday, ReleaseNoteIssueTypes.TechnicalAlert, ReleaseNoteIssueTypes.OperationsAlert, ReleaseNoteIssueTypes.CorporateActionAlert];
export var AllUserLevelTypes = [UserLevelTypes.Account, UserLevelTypes.Admin, UserLevelTypes.Branch, UserLevelTypes.Firm, UserLevelTypes.Region, UserLevelTypes.Rep, UserLevelTypes.Undefined];
export var AlertStatusTypes;

(function (AlertStatusTypes) {
  AlertStatusTypes["Active"] = "Active";
  AlertStatusTypes["Inactive"] = "Inactive";
})(AlertStatusTypes || (AlertStatusTypes = {}));

export var AllAlertStatusTypes = [AlertStatusTypes.Active, AlertStatusTypes.Inactive];