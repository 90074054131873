import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ContactEmail, ContactPhone, Loading, Section, Text } from '@tradingblock/components';
import _ from 'lodash';
import { PageTitle } from '../components';
import { ApplicationLoader } from '../components/application';
import { getApplicationStepUrl } from '../services';
import { useData } from '../state/selectors';
import { DefaultApplicationModel } from '../types';
import { useDispatcher } from '../components/hooks';
import { ApplicationActions } from '../state/actions';
import InvestigationUpload from '../components/fields/InvestigationUpload';
import { UploadedDocuments } from '../components/values';
import { getRequestedParticipantsDocuments } from '@tradingblock/api';

const StatusPage: React.FC = () => {
  const dispatch = useDispatcher();

  const siteGrpParam = process.env.REACT_APP_TB_SITE_GROUP;
  const authToken = useData(d => d.ui.apiToken);
  const authTokenExpired = useData(d => d.ui.apiTokenExpired);
  const application = useData(d => d.data.application);
  const applicationStatus = useData(d => d.data.status);
  const applicationStatusIsFetching = useData(d => d.data.isFetchingStatus);

  const { accountId, clearer } = application || DefaultApplicationModel;
  const { state, investigations, applications } = applicationStatus || {
    state: undefined,
    investigations: [],
    applications: [],
  };

  // Reduce and union both investigations and applications requested documents
  const requestedParticipantsDocuments = useMemo(() => {
    return getRequestedParticipantsDocuments(investigations, applications);
  }, [investigations, applications]);

  // boolean to check if we have any requested documents
  const areDocumentsRequested = useMemo(() => {
    return requestedParticipantsDocuments.some(
      participant =>
        participant.requestedCatagories.investigations.length > 0 ||
        participant.requestedCatagories.application.length > 0
    );
  }, [requestedParticipantsDocuments]);

  useEffect(() => {
    // check for application status if account already created
    if (accountId && authToken && !authTokenExpired) {
      dispatch(ApplicationActions.requestApplicationStatus({ accountId }));
    }
  }, [accountId, dispatch, authToken, authTokenExpired]);

  return (
    <ApplicationLoader defaultRedirect="/">
      <PageTitle page="status" />
      <Section>
        <div className="step-title">
          <h1>
            <Text id="title" page="status" />
          </h1>
        </div>
      </Section>
      {applicationStatusIsFetching && (
        <Section>
          <Loading />
        </Section>
      )}
      {!applicationStatusIsFetching && (
        <Section>
          {!authTokenExpired ? (
            <>
              {(state === 'InvestigationSubmitted' ||
                state === 'ReadyForBackOffice' ||
                state === 'BackOffice' ||
                state === 'AccountSetup') && (
                <>
                  <h4 className="my-4">
                    <Text id="current-status" page="status" />:{' '}
                    <strong>
                      <Text id={`state.${state}`} page="status" />
                    </strong>
                  </h4>
                  <p>
                    <Text id="inProcess" page="status" />
                  </p>
                  <br />
                </>
              )}
              {(state === 'PendingSubmit' ||
                state === 'ActionRequired' ||
                state === 'Suspended' ||
                state === 'Pending') && (
                <>
                  {!areDocumentsRequested && (
                    <>
                      <h4 className="my-4">
                        <Text id="current-status" page="status" />:{' '}
                        <strong>
                          <Text id={`state.${state}`} page="status" />
                        </strong>
                      </h4>
                      <p>
                        <Text id="inProcess" page="status" />
                      </p>
                      <br />
                    </>
                  )}
                  {areDocumentsRequested && (
                    <>
                      <h4 className="my-4">
                        <Text id="current-status" page="status" />:{' '}
                        <strong>
                          <Text id={`state.${state}`} page="status" />
                        </strong>
                      </h4>
                      <p>
                        <Text id="moreInfoNeededDescription" page="status" />
                      </p>
                      <br />
                    </>
                  )}
                </>
              )}
              {(state === 'Error' || state === 'Cancelled' || state === 'Rejected') && (
                <>
                  <h4 className="my-4">
                    <Text id="current-status" page="status" />:{' '}
                    <strong>
                      <Text id={`state.${state}`} page="status" />
                    </strong>
                  </h4>
                  <p>
                    <Text id="accountRejected" page="status" />
                  </p>
                  <br />
                </>
              )}
              {state === 'Complete' && (
                <>
                  <h4 className="my-4">
                    <Text id="current-status" page="status" />:{' '}
                    <strong>
                      <Text id={`state.${state}`} page="status" />
                    </strong>
                  </h4>
                  <p>
                    <Text id="activeAccount" page="status" />
                  </p>
                  <br />
                </>
              )}
              {state === undefined && (
                <>
                  {!areDocumentsRequested && (
                    <>
                      <h4 className="my-4">
                        <Text id="current-status" page="status" />:{' '}
                        <strong>
                          <Text id={`state.PartialEntitySubmission`} page="status" />
                        </strong>
                      </h4>
                      <p>
                        <Text id="inProcess" page="status" />
                      </p>
                      <br />
                    </>
                  )}
                  {areDocumentsRequested && (
                    <>
                      <h4 className="my-4">
                        <Text id="current-status" page="status" />:{' '}
                        <strong>
                          <Text id={`state.PartialEntitySubmission`} page="status" />
                        </strong>
                      </h4>
                      <p>
                        <Text id="moreInfoNeededDescription" page="status" />
                      </p>
                      <br />
                    </>
                  )}
                </>
              )}
              {(siteGrpParam === 'tb' || !siteGrpParam) && (
                <>
                  <Text
                    id="contact.alternate"
                    siteGroup={siteGrpParam}
                    data={{
                      email: <ContactEmail siteGroup={siteGrpParam} />,
                      phone: <ContactPhone siteGroup={siteGrpParam} />,
                    }}
                  />
                  <br />
                  <Text id="contact.phone.TradingBlockInternational" />
                  <br />
                </>
              )}

              {siteGrpParam === 'mb' && (
                <Text
                  id="contact.alternate"
                  siteGroup={siteGrpParam}
                  data={{
                    email: <ContactEmail siteGroup={siteGrpParam} />,
                    phone: <ContactPhone siteGroup={siteGrpParam} />,
                  }}
                />
              )}
              <br />

              {requestedParticipantsDocuments.map(
                (participant, index) =>
                  (participant.requestedCatagories.application.length > 0 ||
                    participant.requestedCatagories.investigations.length > 0 ||
                    participant.uploadedDocuments.length > 0) && (
                    <div key={`${participant.firstName}-${index}`}>
                      {participant.uploadedDocuments.length > 0 && (
                        <>
                          <h4 className="my-4">
                            <Text
                              id={'documentsUploadedForPerson'}
                              page="status"
                              data={{
                                firstName: participant.firstName,
                                middleInitial: participant.middleInitial,
                                lastName: participant.lastName,
                              }}
                            />
                          </h4>
                          <ul>
                            {participant.uploadedDocuments.map((uploadedDocument, index) => (
                              <React.Fragment key={'application_uploaded_document' + index}>
                                <li>
                                  <UploadedDocuments value={uploadedDocument} />
                                </li>
                                <br />
                              </React.Fragment>
                            ))}
                          </ul>
                        </>
                      )}
                      {(participant.requestedCatagories.application.length > 0 ||
                        participant.requestedCatagories.investigations.length > 0) && (
                        <>
                          <h4 className="my-4">
                            <Text
                              id={`moreInfoNeededForPerson`}
                              page="status"
                              data={{
                                firstName: participant.firstName,
                                middleInitial: participant.middleInitial,
                                lastName: participant.lastName,
                              }}
                            />
                          </h4>

                          <ul>
                            {participant.requestedCatagories.application.map((requestedDocument, index) => (
                              <li key={'application_requested_document' + index}>
                                <InvestigationUpload
                                  accountId={accountId ? accountId : -1}
                                  accountHolderId={participant.accountHolderId}
                                  requestedInvestigationDocument={requestedDocument}
                                  type={'application'}
                                  clearer={clearer}
                                />
                              </li>
                            ))}
                            {participant.requestedCatagories.investigations.map((requestedDocument, index) => (
                              <li key={'investigation_requested_document' + index}>
                                <InvestigationUpload
                                  accountId={accountId ? accountId : -1}
                                  investigationId={participant.investigationId}
                                  requestedInvestigationDocument={requestedDocument}
                                  type={'investigation'}
                                  clearer={clearer}
                                />
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  )
              )}
            </>
          ) : (
            <>
              <p>
                <strong>
                  <Text id="token-expired" />
                </strong>
              </p>
              <p>
                <Link className="btn btn-block btn-outline-dark" to="/logout#next=/auth">
                  <Text id="btns.sign-in-again" />
                </Link>
              </p>
            </>
          )}
        </Section>
      )}
      <Section>
        <p>
          <strong>
            <Text id="contact.content" />
          </strong>
          <br />
          <Text
            id="contact.content"
            textKey="description"
            siteGroup={siteGrpParam}
            data={{
              email: <ContactEmail siteGroup={siteGrpParam} />,
              phone: <ContactPhone siteGroup={siteGrpParam} />,
            }}
          />
        </p>
      </Section>
      <Section>
        {!authTokenExpired && (
          <p>
            <Link className="btn btn-block btn-primary btn-icon" to={getApplicationStepUrl('summary')}>
              <Text id="view-summary" page="status" />
            </Link>
          </p>
        )}
        <p>
          <Link className="btn btn-block btn-outline-dark" to="/logout">
            <Text id="btns.sign-out" />
          </Link>
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default StatusPage;
