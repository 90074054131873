import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldArray, Form } from 'formik';
import _ from 'lodash';
import {
  FormGroup,
  AddIcon,
  LocalizeButton as Button,
  TextboxField,
  StepperField,
  AddressField,
  Text,
  SelectDropdownField,
} from '@tradingblock/components';
import {
  AllStates,
  ApiResponse,
  getGroupedCountryOptions,
  DuplicateAccountCheckResponse,
  BeneficiariesRelationships,
} from '@tradingblock/types';
import {
  AllBeneficiaryTypes,
  BeneficiaryType,
  AllBeneficiaryAccountTypes,
  ApplicationStepProps,
  DefaultPhone,
  DuplicateAccountCheckRequest,
  ApplicationType,
  EntityAccountType,
  RE_SSN,
  RE_EIN,
} from '../../../../types';
import { DateField } from '../../../fields';
import { useApi } from '../../../../context';

interface BeneficiariesFieldsProps extends Pick<ApplicationStepProps, 'values' | 'setValues' | 'getText' | 'errors'> {
  max: number;
}

export const BeneficiariesFields: React.FC<BeneficiariesFieldsProps> = ({
  values,
  setValues,
  getText,
  errors,
  max,
}) => {
  const total = _.size(values.beneficiaries);
  const totalPercentageError = errors.beneficiaries;

  //TODO: Once Entity applications are allowed through site, ensure that the duplicate account check behaves as expected
  // interface DuplicateAccountErrors {
  //   message: string[] | string;
  //   index: number;
  // }
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (values.type === ApplicationType.Entity && values.entityAccountType !== EntityAccountType.PersonalTrust) {
  //     const beneficiaries = values.beneficiaries;
  //     const individualBeneficiaryTaxIds = beneficiaries && beneficiaries.map(beneficiary => beneficiary.ssn);
  //     const trustBeneficiaryTaxIds = beneficiaries && beneficiaries.map(beneficiary => beneficiary.ein);

  //     individualBeneficiaryTaxIds &&
  //       individualBeneficiaryTaxIds.forEach((taxId, index) => {
  //         if (taxId && RE_SSN.test(taxId)) {
  //           const requestValues: DuplicateAccountCheckRequest = {
  //             entityAccountType: values.entityAccountType ? values.entityAccountType : undefined,
  //             flexibilityType: values.flexibilityType,
  //             primaryTaxIdSecret: taxId,
  //             type: values.type,
  //           };
  //        dispatch(ApplicationActions.requestDuplicateAccountCheck(requestValues));
  //         }
  //       });

  //     trustBeneficiaryTaxIds &&
  //       trustBeneficiaryTaxIds.forEach((taxId, index) => {
  //         if (taxId && RE_EIN.test(taxId)) {
  //           const requestValues: DuplicateAccountCheckRequest = {
  //             entityAccountType: values.entityAccountType ? values.entityAccountType : undefined,
  //             flexibilityType: values.flexibilityType,
  //             primaryTaxIdSecret: taxId,
  //             type: values.type,
  //           };
  //      dispatch(ApplicationActions.requestDuplicateAccountCheck(requestValues));
  //         }
  //       });
  //   }
  // }, [
  //   values.entityAccountType,
  //   values.flexibilityType,
  //   values.type,
  //   values.beneficiaries && values.beneficiaries.length,
  // ]);

  return (
    <FieldArray
      name="beneficiaries"
      render={arrHelpers => (
        <>
          {_.map(values.beneficiaries, (b, i) => (
            <div key={`beneficiary${i}`} className="form-group-removable form-group-removable-titled">
              <h2 className="fields-title">{getText('beneficiaries.name', undefined, { data: { number: i + 1 } })}</h2>

              <FormGroup>
                <Field
                  component={StepperField}
                  id={`beneficiaries[${i}].beneficiaryAccountType`}
                  options={AllBeneficiaryAccountTypes}
                />
              </FormGroup>
              <div className="remove">
                <Button type="remove" onClick={() => arrHelpers.remove(i)} tabIndex={-1}>
                  <i className="fal fa-lg fa-times-circle" />
                </Button>
              </div>
              <FormGroup>
                <Field
                  component={StepperField}
                  id={`beneficiaries[${i}].beneficiaryType`}
                  options={AllBeneficiaryTypes}
                  onChanged={(beneficiaryType: BeneficiaryType) =>
                    setValues({
                      ...values,
                      beneficiaries: _.map(values.beneficiaries, (b, j) => {
                        if (i === j) {
                          const isIndividual = beneficiaryType === BeneficiaryType.Individual;
                          return {
                            ...b,
                            beneficiaryType,
                            relationship: isIndividual ? b.relationship : undefined,
                            firstName: isIndividual ? b.firstName : '',
                            lastName: isIndividual ? b.lastName : '',
                            middleInitial: isIndividual ? b.middleInitial : '',
                            suffix: isIndividual ? b.suffix : '',
                            dateOfBirth: isIndividual ? b.dateOfBirth : {},
                            ssn: isIndividual ? b.ssn : '',
                            trustName: isIndividual ? undefined : b.trustName,
                            ein: isIndividual ? undefined : b.ein,
                          };
                        }
                        return b;
                      }),
                    })
                  }
                />
              </FormGroup>
              {b.beneficiaryType === BeneficiaryType.Individual && (
                <>
                  <FormGroup>
                    <Field
                      component={SelectDropdownField}
                      isSearchable={false}
                      id={`beneficiaries[${i}].relationship`}
                      options={BeneficiariesRelationships}
                      defaultLabelId="relationship"
                      optionsDefaultLabelId="beneficiaryRelationship"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id={`beneficiaries[${i}].firstName`} defaultLabelId="firstName" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextboxField}
                      id={`beneficiaries[${i}].middleInitial`}
                      defaultLabelId="middleInitial"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id={`beneficiaries[${i}].lastName`} defaultLabelId="lastName" />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id={`beneficiaries[${i}].suffix`} defaultLabelId="suffix" />
                  </FormGroup>
                  <FormGroup>
                    <Field component={DateField} id={`beneficiaries[${i}].dateOfBirth`} defaultLabelId="dateOfBirth" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextboxField}
                      id={`beneficiaries[${i}].ssn`}
                      label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
                      type="password"
                      allowUnmask={true}
                    />
                    {/* {duplicateAccountErrors && duplicateAccountErrors.some(error => error.index === i) && (
                      <span className="error">
                        {duplicateAccountErrors.filter(error => error.index === i).map(error => error.message)}
                      </span>
                    )} */}
                  </FormGroup>
                </>
              )}
              {b.beneficiaryType === BeneficiaryType.Trust && (
                <>
                  <FormGroup>
                    <Field component={TextboxField} id={`beneficiaries[${i}].trustName`} />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id={`beneficiaries[${i}].ein`} type="password" allowUnmask={true} />
                    {/* {duplicateAccountErrors && duplicateAccountErrors.some(error => error.index === i) && (
                      <span className="error">
                        {duplicateAccountErrors.filter(error => error.index === i).map(error => error.message)}
                      </span>
                    )} */}
                  </FormGroup>
                </>
              )}
              <Field
                component={AddressField}
                id={`beneficiaries[${i}].address`}
                labelPrefix={getText('beneficiaries.label')}
                countryProps={{
                  options: getGroupedCountryOptions(),
                  defaultLabelId: 'address.country',
                  optionsDefaultLabelId: 'country',
                  labelPrefix: getText('beneficiaries.label'),
                }}
                stateProps={{
                  options: AllStates,
                  defaultLabelId: 'address.state',
                  optionsDefaultLabelId: 'state',
                  labelPrefix: getText('beneficiaries.label'),
                }}
              />
              <FormGroup>
                <Field
                  component={TextboxField}
                  id={`beneficiaries[${i}].percent`}
                  type="number"
                  min="1"
                  max="100"
                  step="1"
                />
              </FormGroup>
              {totalPercentageError && (
                <p className="error">
                  <strong>{totalPercentageError}</strong>{' '}
                  {getText('beneficiaries.percent', undefined, { type: 'error' })}
                </p>
              )}
            </div>
          ))}
          {total < max && (
            <p>
              <button
                onClick={e => {
                  e.currentTarget.blur();
                  e.preventDefault();
                  arrHelpers.push({
                    beneficiaryAccountType: '',
                    beneficiaryType: '',
                    relationship: '',
                    trustName: '',
                    ein: '',
                    percent: null,
                    dateOfBirth: {
                      day: undefined,
                      month: undefined,
                      year: undefined,
                    },
                    ssn: '',
                    firstName: '',
                    lastName: '',
                    address: {
                      address1: '',
                      address2: '',
                      city: '',
                      postalCode: null,
                      country: '',
                      state: null,
                    },
                    email: '',
                    phones: [DefaultPhone],
                  });
                }}
                type="button"
              >
                <AddIcon />
                <strong>{getText('beneficiaries.add')}</strong>
              </button>{' '}
              (<Text id={`available-counts.${total > 0 ? 'partial' : 'all'}`} data={{ max, left: max - total }} />)
            </p>
          )}
        </>
      )}
    />
  );
};
