export var OptionExpirationType;

(function (OptionExpirationType) {
  OptionExpirationType[OptionExpirationType["AM"] = 1] = "AM";
  OptionExpirationType[OptionExpirationType["PM"] = 2] = "PM";
})(OptionExpirationType || (OptionExpirationType = {}));

export var ExpirationType;

(function (ExpirationType) {
  ExpirationType["Monthly"] = "M";
  ExpirationType["Weekly"] = "W";
  ExpirationType["Quarterly"] = "Q";
})(ExpirationType || (ExpirationType = {}));