import React from 'react';
import _ from 'lodash';
import qs from 'qs';
import { ApplicationLoader } from '../components';

const LogoutPage: React.FC = () => {
  const urlParams = qs.parse(_.drop(window.location.hash, 1).join(''));
  const nextPage = _.isString(urlParams.next) ? (urlParams.next as string) : '/';
  return <ApplicationLoader empty logout={true} defaultRedirect={nextPage} />;
};

export default LogoutPage;
