import React, { useMemo } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { Section, FormGroup, StepperField, TextboxField, SelectDropdownField, Text } from '@tradingblock/components';
import {
  AllDeliveryTypes,
  DeliveryType,
  ApplicationStepProps,
  ApplicationType,
  ApplicationFormSection,
  AllBooleanToggleTypes,
  BooleanToggleType,
  AllEmploymentTypes,
  EmploymentType,
  ClearerType,
} from '../../../types';
import { useData } from '../../../state/selectors';
import {
  PrimaryAccountHolderDisclosuresSection,
  JointAccountHolderDisclosuresSection,
} from './agreements/AccountHolderDisclosuresSection';
import { AccountDisclosuresSection } from './agreements/AccountDisclosuresSection';
import { AccountAgreementsSection } from './agreements/AccountAgreementsSection';
import {
  Country,
  getGroupedCountryOptions,
  TradeAuthorizationType,
  AllTradeAuthorizationTypes,
} from '@tradingblock/types';
import { DateField, PersonField, PhonesArrayField } from '../../fields';
import {
  updateFieldsDependentOnEmploymentTypeTradeAuth,
  updateFieldsDependentOnTradeAuthorization,
} from '../../../services';

export const AgreementsForm: React.FC<ApplicationStepProps> = ({
  values,
  setValues,
  getText,
  errors,
  submitCount,
  touched,
}) => {
  const visibleSections = useData(d => d.ui.visibleSections);
  const sections = useMemo(() => {
    let allSections: React.FC<ApplicationFormSection>[] = [];
    // primary account holder disclosures
    allSections = [...allSections, PrimaryAccountHolderDisclosuresSection];
    if (values.type === ApplicationType.Joint) {
      // joint account holder disclosures
      allSections = [...allSections, JointAccountHolderDisclosuresSection];
    }
    // account disclosures and agreements
    allSections = [...allSections, AccountDisclosuresSection, AccountAgreementsSection];

    return _.take(allSections, visibleSections || 1);
  }, [values.type, visibleSections]);

  return (
    <>
      <Section>
        <h2 className="fields-title">{getText('deliveryType')}</h2>
        <p>{getText('deliveryType.fees')}</p>

        <FormGroup>
          <Field
            component={StepperField}
            id="deliveryType"
            options={AllDeliveryTypes}
            disabledOptions={values.clearer === ClearerType.RQD ? DeliveryType.Paper : null}
          />
        </FormGroup>

        {values.deliveryType === DeliveryType.Paper && getText('deliveryType.fees', 'description')}

        <h2 className="fields-title">{getText('tradeAuthorization')}</h2>
        <p>{getText('tradeAuthorization', 'description')}</p>

        <FormGroup>
          <Field
            component={StepperField}
            id="tradeAuthorization"
            options={AllBooleanToggleTypes}
            defaultLabelId="boolean"
            onChanged={(value: BooleanToggleType) => {
              setValues(updateFieldsDependentOnTradeAuthorization(values, value));
            }}
          />
        </FormGroup>

        {values.tradeAuthorization === BooleanToggleType.Yes && (
          <>
            <h2 className="fields-title">{getText('tradingAuthorization.type')}</h2>
            <p>{getText('tradingAuthorization.type', 'description')}</p>
            <FormGroup className="mb-5" help={getText(`tradingAuthorization.type.help`)}>
              <Field
                component={StepperField}
                id="tradingAuthorization.type"
                options={AllTradeAuthorizationTypes}
                defaultLabelId="authorizationType"
              />
            </FormGroup>
            {values.tradingAuthorization && values.tradingAuthorization.type && (
              <>
                <h2 className="fields-title">{getText('tradingAuthorization.personal-info')}</h2>
                <FormGroup>
                  <Field component={PersonField} id="tradingAuthorization" personType="trade-authorization" />
                </FormGroup>

                <p>{getText('tradingAuthorization.phones', 'description')}</p>
                <FormGroup>
                  <Field
                    component={PhonesArrayField}
                    id={'tradingAuthorization'}
                    value={values.tradingAuthorization && values.tradingAuthorization.phones}
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    component={SelectDropdownField}
                    id={`tradingAuthorization.citizenshipCountry`}
                    options={getGroupedCountryOptions()}
                    defaultLabelId="citizenshipCountry"
                    optionsDefaultLabelId="country"
                  />
                </FormGroup>

                {values.tradingAuthorization &&
                  values.tradingAuthorization.citizenshipCountry === Country.UnitedStatesOfAmerica && (
                    <FormGroup>
                      <Field
                        component={TextboxField}
                        id={`tradingAuthorization.ssnSecret`}
                        label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
                        type="password"
                        allowUnmask={true}
                      />
                    </FormGroup>
                  )}

                {values.tradingAuthorization &&
                  values.tradingAuthorization.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
                    <>
                      <FormGroup>
                        <Field
                          component={TextboxField}
                          id={`tradingAuthorization.foreignTaxIdNumber`}
                          defaultLabelId="taxIdentificationNumber"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={SelectDropdownField}
                          id={`tradingAuthorization.foreignTaxIdCountry`}
                          options={getGroupedCountryOptions()}
                          defaultLabelId="treatyCountry"
                          optionsDefaultLabelId="country"
                        />
                      </FormGroup>
                    </>
                  )}

                <FormGroup>
                  <Field component={DateField} id={`tradingAuthorization.dateOfBirth`} defaultLabelId="dateOfBirth" />
                </FormGroup>

                <h2 className="fields-title">{getText('tradingAuthorization.employment')}</h2>
                <FormGroup>
                  <Field
                    component={StepperField}
                    id={`tradingAuthorization.employmentType`}
                    options={AllEmploymentTypes}
                    defaultLabelId="employmentType"
                    onChanged={(employmentType: EmploymentType) =>
                      setValues(updateFieldsDependentOnEmploymentTypeTradeAuth(values, employmentType))
                    }
                  />
                </FormGroup>
                {values.tradingAuthorization && values.tradingAuthorization.employmentType === EmploymentType.Employed && (
                  <>
                    <FormGroup>
                      <Field component={TextboxField} id={`tradingAuthorization.employer`} defaultLabelId="employer" />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        component={TextboxField}
                        id={`tradingAuthorization.jobPosition`}
                        defaultLabelId="jobPosition"
                      />
                    </FormGroup>
                  </>
                )}

                {values.tradingAuthorization && values.tradingAuthorization.type === TradeAuthorizationType.Full && (
                  <>
                    <h2 className="fields-title">{getText('tradingAuthorization.disclosures')}</h2>
                    <p>{getText('tradingAuthorization.disclosures', 'description')}</p>
                    <p>{getText('tradingAuthorization.disclosures.industryEmployed', 'description')}</p>
                    <FormGroup>
                      <Field
                        component={StepperField}
                        id="tradingAuthorization.disclosures.industryEmployed"
                        options={AllBooleanToggleTypes}
                        defaultLabelId="boolean"
                      />
                    </FormGroup>
                    {values.tradingAuthorization.disclosures &&
                      values.tradingAuthorization.disclosures.industryEmployed === BooleanToggleType.Yes && (
                        <>
                          <FormGroup>
                            <Field
                              component={TextboxField}
                              id="tradingAuthorization.disclosuresIndustryEmployedFirmName"
                              defaultLabelId="disclosuresIndustryEmployedFirmName"
                            />
                          </FormGroup>
                        </>
                      )}

                    <p>{getText('tradingAuthorization.disclosures.stakeholder', 'description')}</p>
                    <FormGroup>
                      <Field
                        component={StepperField}
                        id="tradingAuthorization.disclosures.stakeholder"
                        options={AllBooleanToggleTypes}
                        defaultLabelId="boolean"
                      />
                    </FormGroup>
                    {values.tradingAuthorization.disclosures &&
                      values.tradingAuthorization.disclosures.stakeholder === BooleanToggleType.Yes && (
                        <>
                          <FormGroup>
                            <Field
                              component={TextboxField}
                              id="tradingAuthorization.disclosuresStakeholderTickerSymbol"
                              defaultLabelId="disclosuresStakeholderTickerSymbol"
                            />
                          </FormGroup>
                        </>
                      )}
                  </>
                )}
                <h2 className="fields-title">{getText('tradingAuthorization.signedName')}</h2>
                <FormGroup>
                  <Field
                    component={TextboxField}
                    id="tradingAuthorization.signedName"
                    defaultLabelId="tradeAuthSignedName"
                  />
                </FormGroup>
              </>
            )}
          </>
        )}
      </Section>

      {_.map(sections, (ChildSection, index) => (
        <ChildSection
          key={`ChildSection-${index}`}
          getText={getText}
          values={values}
          setValues={setValues}
          submitCount={submitCount}
          errors={errors}
          touched={touched}
        />
      ))}
    </>
  );
};
