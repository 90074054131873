import _ from 'lodash';
import { DefaultLanguageKeys } from '@tradingblock/types';
export var isFieldValueFilled = function isFieldValueFilled(value) {
  return !!value || _.isNumber(value);
};
export var getCleanTranslationKey = function getCleanTranslationKey(key) {
  return key && key.replace(/\[\d+\]/, '').replace(/\.$/, '');
};

var getTranslationId = function getTranslationId(options) {
  var id = options.id,
      type = options.type,
      page = options.page,
      textKey = options.textKey; // get clean key, no translation keys for multiple fields

  var translateId = getCleanTranslationKey(id);
  var prefix = "".concat(type || 'page', "s");

  if (type === 'page' || !type && !_.startsWith(translateId, 'defaults')) {
    prefix += ".".concat(page || 'defaults');
  }

  var suffix = textKey;

  if (!suffix) {
    if (type === 'field') {
      suffix = DefaultLanguageKeys.Label;
    } else if (type === 'error') {
      if (!_.endsWith(translateId, ".".concat(DefaultLanguageKeys.Error))) {
        suffix = DefaultLanguageKeys.Error;
      }
    } else {
      suffix = DefaultLanguageKeys.Text;
    }
  } // TODO: Review translations, expandedKey logic to correclty accomodate for error keys


  if (!_.endsWith(suffix, DefaultLanguageKeys.Error) && type === 'error') {
    suffix += '.error';
  }

  return "".concat(prefix, ".").concat(translateId).concat(suffix ? ".".concat(suffix) : '');
};

var getFieldTranslationId = function getFieldTranslationId(options) {
  return "".concat(options.defaultLabelId ? "defaults.".concat(options.defaultLabelId) : options.id).concat(options.option ? ".".concat(options.option) : '');
};

export var useLocalize = function useLocalize() {
  return {
    getCleanTranslationKey: getCleanTranslationKey,
    getTranslationId: getTranslationId,
    getFieldTranslationId: getFieldTranslationId
  };
};