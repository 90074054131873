import _ from 'lodash';
import dayjs from 'dayjs';
import { SizeType } from '@tradingblock/types';
import { getClassNames } from '../../utilities';
export var getTableSize = function getTableSize(width, windowWidth) {
  if (windowWidth < 670) {
    return SizeType.sm;
  }

  return width;
};
export var getTableClass = function getTableClass(className, tableClassName, responsiveDisabled) {
  return getClassNames([className, tableClassName, responsiveDisabled ? undefined : 'responsiveTable']);
};
/**
 * Determines if a td should have responsive styling applied to it
 * @param size The current size of the table
 * @return 'pivoted' if the table is small, undefined if the table is not
 */

export var getTableTdClass = function getTableTdClass(size) {
  if (size === SizeType.sm) {
    return 'pivoted';
  }

  return undefined;
};
export var onDateSort = _.memoize(function (rowA, rowB, _columnId, desc) {
  var sort = rowA === rowB ? 0 : rowA > rowB ? 1 : -1;

  if (sort !== 0 && _.isString(rowA) && _.isString(rowB)) {
    sort = dayjs(rowA).isAfter(dayjs(rowB)) ? 1 : -1;
  }

  return sort * (desc ? -1 : 1);
});
/**
 * Calculates the percent width of one column of a table
 *
 * It does this by dividing 100 by the number of columns
 *
 * Thus, evenly distributing the widths
 * @param numColumn the number of columns in one row of the table
 * @returns the percent width as a string
 */

export var calculateColumnWidth = function calculateColumnWidth(numColumn) {
  return numColumn ? "".concat(100 / numColumn, "%") : '0%';
};