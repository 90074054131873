import React from 'react';
import { SummaryStepProps, ClearerType } from '../../../types';
import { FieldValue } from '../../values';

export const ClearerTypeSummary: React.FC<SummaryStepProps> = ({ application, getText }) => {
  if (!application) {
    return null;
  }
  const value = application.clearer === ClearerType.RQD ? 'Zero Commission' : 'Full Service';
  return (
    <>
      <h3 className="fields-title">{getText('defaults.clearerType.summaryTitle')}</h3>
      <p>
        <FieldValue value={value} />
      </p>
    </>
  );
};
