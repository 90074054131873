import dayjs from 'dayjs';
import React from 'react';
import { ApplicationInvestigationUploadedDocument } from '../../types';
import { FieldValue } from './';
import { Text } from '@tradingblock/components';

export const UploadedDocuments: React.FC<{ value: ApplicationInvestigationUploadedDocument }> = ({ value }) => {
  const uploadedDocument = value;
  const { documentName, originalFileName, takenOn, state } = uploadedDocument;

  return (
    <FieldValue value={uploadedDocument.documentName}>
      <span className="fields-title">
        <Text id={`investigationUpload.documentOptions.${documentName}`} type="field" />
      </span>
      <br />
      <span className="mute">Original Document Name: {originalFileName}</span>
      <br />
      <span className="mute">
        Status:{' '}
        <strong>
          <Text id={`investigationUpload.documentStates.${state}`} type="field" />
        </strong>
      </span>
      {takenOn && (
        <>
          <br />
          <span className="mute">Taken On: {dayjs(takenOn).format('MM/DD/YYYY')}</span>
        </>
      )}
    </FieldValue>
  );
};
