import React from 'react';
import { getSiteLogo } from '../../utilities/SiteUtilities';
import { TradingBlockLink } from '.';
import { Svg } from '../basic';
export var SiteLogo = function SiteLogo(_ref) {
  var siteGroup = _ref.siteGroup;
  return React.createElement("div", {
    className: "logo"
  }, React.createElement(TradingBlockLink, {
    siteGroup: siteGroup,
    to: "HomeUrl"
  }, React.createElement("span", {
    className: "logo-mark"
  }, React.createElement(Svg, {
    path: getSiteLogo(siteGroup, 'mark')
  })), React.createElement("span", {
    className: "logo-text"
  }, React.createElement(Svg, {
    path: getSiteLogo(siteGroup, 'text')
  }))));
};