import * as yup from 'yup';
var DashboardInfoSchemaFields = {
  dashboardId: yup.string(),
  title: yup.string(),
  isDeleted: yup.boolean().notRequired(),
  dataLastFetchedOn: yup.date().notRequired()
};
export var LayoutSchemaFields = {
  i: yup.string().notRequired(),
  x: yup.number(),
  y: yup.number(),
  w: yup.number(),
  h: yup.number(),
  minW: yup.number().notRequired(),
  maxW: yup.number().notRequired(),
  minH: yup.number().notRequired(),
  maxH: yup.number().notRequired(),

  /**
   * set by DragEvents (onDragStart, onDrag, onDragStop) and ResizeEvents (onResizeStart, onResize, onResizeStop)
   */
  moved: yup.boolean().notRequired(),

  /**
   * If true, equal to `isDraggable: false` and `isResizable: false`.
   */
  static: yup.boolean().notRequired(),

  /**
   * If false, will not be draggable. Overrides `static`.
   */
  isDraggable: yup.boolean().notRequired(),

  /**
   * If false, will not be resizable. Overrides `static`.
   */
  isResizable: yup.boolean().notRequired()
};
var DashboardConfigurationSchemaFields = Object.assign({}, DashboardInfoSchemaFields, {
  presetBlocks: yup.array().notRequired()
});
var UiSettingsSchemaFields = {
  afterHoursTrading: yup.boolean(),
  muteAllSounds: yup.boolean(),
  showBannerSymbols: yup.boolean(),
  bannerSymbols: yup.array(),
  balancePollingThrottleMs: yup.number(),
  cashieringPollingIntervalMs: yup.number(),
  cashieringPollingInterval: yup.number(),
  cashieringPendingReminderInterval: yup.number(),
  cashieringPendingReminderMaxAttempts: yup.number(),
  gridColumnsLarge: yup.number(),
  gridColumnsMedium: yup.number(),
  gridColumnsSmall: yup.number(),
  hotkeys: yup.boolean(),
  width: yup.number()
};
export var DashboardInfoSchema = yup.object(DashboardInfoSchemaFields);
export var DashboardConfigurationSchema = yup.object(DashboardConfigurationSchemaFields);
export var LayoutSchema = yup.object(LayoutSchemaFields);
export var UiSettingsSchema = yup.object(UiSettingsSchemaFields); // export type DashboardInfo = yup.InferType<typeof DashboardInfoSchema>;
// export type DashboardConfiguration = yup.InferType<typeof DashboardConfigurationSchema>;
// export type Layout = yup.InferType<typeof LayoutSchema>;
// export type UiSettings = yup.InferType<typeof UiSettingsSchema>;