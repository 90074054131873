export var DurationValues = {
  GTC: 'GTC',
  Day: 'Day',
  Manual: 'Manual'
};
export var Durations;

(function (Durations) {
  Durations["GTC"] = "GTC";
  Durations["Day"] = "Day";
  Durations["Manual"] = "Manual";
})(Durations || (Durations = {}));