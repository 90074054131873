import { GetSymbolsUrl } from '../constants';
import _ from 'lodash';
export function GetSymbols(api) {
  return function (query) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    var querystringRequest = _.isString(query) ? {
      query: query,
      limit: limit
    } : query;
    return api.authenticated().get(GetSymbolsUrl, querystringRequest, {
      headers: {
        Accept: 'application/json'
      }
    }).then(function (res) {
      return res.payload || [];
    }).catch(function (reason) {
      console.error('Error trying to fetch symbols', reason);
      return [];
    });
  };
}
export function Symbols(api) {
  return {
    search: function search(query) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
      var querystringRequest = _.isString(query) ? {
        query: query,
        limit: limit
      } : query;
      return api.authenticated().get(GetSymbolsUrl, querystringRequest, {
        headers: {
          Accept: 'application/json'
        }
      }).then(function (res) {
        return res.payload || [];
      }).catch(function (reason) {
        console.error('Error trying to fetch symbols', reason);
        return [];
      });
    },
    warnings: function warnings() {
      return api.authenticated().get('Symbol/WarningSymbols', {}, {
        headers: {
          Accept: 'application/json'
        }
      });
    }
  };
}