import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
export var getRequestedParticipantsDocuments = function getRequestedParticipantsDocuments(investigations, applications) {
  var participants = []; // Create a union on CIP and Application investigation data structure

  var applicationRequestedDocuments = [].concat(_toConsumableArray(investigations ? investigations : []), _toConsumableArray(applications ? applications : []));
  applicationRequestedDocuments.forEach(function (requestedDocumentDetailEntry) {
    // Check if we have an entry for the participant of the applications
    // We need to do this since accounts can have multiple people like
    // Joint accounts.
    var participantIndex = participants.findIndex(function (participant) {
      return requestedDocumentDetailEntry.firstName === participant.firstName && requestedDocumentDetailEntry.lastName === participant.lastName;
    });

    if (participantIndex === -1) {
      // If we do not have an entry for the participant base on the first name
      // and last name. Then, we create one.
      participants.push({
        firstName: requestedDocumentDetailEntry.firstName ? requestedDocumentDetailEntry.firstName : '',
        middleInitial: requestedDocumentDetailEntry.middleInitial ? requestedDocumentDetailEntry.middleInitial : '',
        lastName: requestedDocumentDetailEntry.lastName ? requestedDocumentDetailEntry.lastName : '',
        suffix: requestedDocumentDetailEntry.suffix ? requestedDocumentDetailEntry.suffix : '',
        accountHolderId: requestedDocumentDetailEntry.accountHolderId,
        investigationId: requestedDocumentDetailEntry.investigationId,
        requestedCatagories: {
          application: [],
          investigations: []
        },
        uploadedDocuments: []
      }); // set the participantIndex to the new entry

      participantIndex = participants.length - 1;
    } else {
      if (!participants[participantIndex].accountHolderId && requestedDocumentDetailEntry.accountHolderId) {
        participants[participantIndex].accountHolderId = requestedDocumentDetailEntry.accountHolderId;
      }

      if (participants[participantIndex].investigationId && requestedDocumentDetailEntry.investigationId) {
        participants[participantIndex].investigationId = requestedDocumentDetailEntry.investigationId;
      }
    } //Union the requested document categories


    var categories = [].concat(_toConsumableArray(requestedDocumentDetailEntry.appCategories ? requestedDocumentDetailEntry.appCategories : []), _toConsumableArray(requestedDocumentDetailEntry.cipCategories ? requestedDocumentDetailEntry.cipCategories : [])); // Uniquely read all documentNames that have been uploaded

    var uploadedDocumentsNames = _toConsumableArray(new Set(requestedDocumentDetailEntry.uploadedDocuments ? requestedDocumentDetailEntry.uploadedDocuments.map(function (document) {
      return document.documentName;
    }) : []));

    var uploadedDocuments = _toConsumableArray(new Set(requestedDocumentDetailEntry.uploadedDocuments ? requestedDocumentDetailEntry.uploadedDocuments : []));

    if (uploadedDocuments.length > 0) {
      participants[participantIndex].uploadedDocuments = uploadedDocuments;
    } // If current category is accepted and the


    var activeDocumentCategories = categories.filter(function (category) {
      return category.state !== 'Accepted' && category.state !== 'Pending' && !uploadedDocumentsNames.map(function (uploadedDocumentName) {
        return category.requestedDocuments.includes(uploadedDocumentName);
      }).includes(true);
    }); // If we have an investigationId then we need to set the investigations
    // otherwise it is an application request.

    if (requestedDocumentDetailEntry.investigationId) {
      participants[participantIndex].requestedCatagories.investigations = activeDocumentCategories;
    } else {
      participants[participantIndex].requestedCatagories.application = activeDocumentCategories;
    }
  });
  return participants;
};