export var UserProfileLevel;

(function (UserProfileLevel) {
  UserProfileLevel["Undefined"] = "Undefined";
  UserProfileLevel["Admin"] = "Admin";
  UserProfileLevel["Firm"] = "Firm";
  UserProfileLevel["Region"] = "Region";
  UserProfileLevel["Branch"] = "Branch";
  UserProfileLevel["Rep"] = "Rep";
  UserProfileLevel["Account"] = "Account";
})(UserProfileLevel || (UserProfileLevel = {}));

export var UserRole;

(function (UserRole) {
  UserRole["CashieringApproval"] = "CashieringApproval";
  UserRole["AccountManagementApproval"] = "AccountManagementApproval";
  UserRole["AccountApprovalFIB"] = "AccountApprovalFIB";
  UserRole["AccountApprovalGSP"] = "AccountApprovalGSP";
  UserRole["AccountApprovalREP"] = "AccountApprovalREP";
  UserRole["AccountApprovalROP"] = "AccountApprovalROP";
  UserRole["ManageCommissionCodes"] = "ManageCommissionCodes";
  UserRole["BypassMarginCheck"] = "BypassMarginCheck";
})(UserRole || (UserRole = {}));

export var SecurityQuestionType;

(function (SecurityQuestionType) {
  SecurityQuestionType["BirthCity"] = "birth-city";
  SecurityQuestionType["ChildhoodFutureJobWish"] = "childhood-future-job-wish";
  SecurityQuestionType["ChildhoodPetName"] = "childhood-pet-name";
  SecurityQuestionType["DriversLicenseLast4"] = "drivers-license-last-4";
  SecurityQuestionType["FathersMiddleName"] = "fathers-middle-name";
  SecurityQuestionType["FavoriteBook"] = "favorite-book";
  SecurityQuestionType["FavoriteSportsTeam"] = "favorite-sports-team";
  SecurityQuestionType["MothersMaidenName"] = "mothers-maiden-name";
  SecurityQuestionType["PaternalGrandmothersFirstName"] = "paternal-grandmothers-first-name";
})(SecurityQuestionType || (SecurityQuestionType = {}));

export var MarketDataEntitlementTypeId;

(function (MarketDataEntitlementTypeId) {
  MarketDataEntitlementTypeId[MarketDataEntitlementTypeId["ProfessionalUser"] = 1] = "ProfessionalUser";
  MarketDataEntitlementTypeId[MarketDataEntitlementTypeId["NonProfessionalUser"] = 2] = "NonProfessionalUser";
  MarketDataEntitlementTypeId[MarketDataEntitlementTypeId["DelayedQuotesOnlyUser"] = 3] = "DelayedQuotesOnlyUser";
})(MarketDataEntitlementTypeId || (MarketDataEntitlementTypeId = {}));