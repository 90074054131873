import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { AcceptedCountries } from '@tradingblock/types';
import { getCleanTranslationKey } from '@tradingblock/components';
import {
  AllApplicationSteps,
  ApplicationStep,
  TradeGoalType,
  RiskLevel,
  InvestmentObjectiveType,
  ApplicationType,
  BooleanToggleType,
  RetirementAccountType,
  ApplicationModel,
  AgreementKey,
  EntityAccountType,
  UnsupportedFeatureType,
  MismatchedDataType,
  MismatchedDataRulesForTradeGoalTypes,
  MismatchedDataRules,
  MismatchedDataRuleKey,
  RequiredDataRuleKey,
  RequiredExperienceDataRules,
  RequiredDataRules,
  RequiredDataRulesForTradeGoalTypes,
  ExperienceKey,
  UploadKey,
  UploadDocumentTag,
  MismatchedDataRulesForTradeGoalTypesRetirementAccount,
  AccountHolderModel,
} from '../types';
import {
  getNumberOfSectionsInApplicationStep,
  getAllAgreementKeysForApplication,
  getAllTradeGoalTypesForApplication,
  validateApplication,
} from './';

dayjs.extend(utc);

export const getApplicationStepUrl = (step: ApplicationStep | 'summary', siteGrp?: string) => {
  return siteGrp ? `/apply/${step}?grp=${siteGrp}` : `/apply/${step}`;
};

export const isPreviouslyTouchedApplicationStep = (
  step: ApplicationStep,
  latestStepTouched: ApplicationStep | undefined
) => {
  return latestStepTouched && _.indexOf(AllApplicationSteps, step) <= _.indexOf(AllApplicationSteps, latestStepTouched);
};

export const canAccessApplicationStep = (step: ApplicationStep, application: ApplicationModel) => {
  // has saved the step
  if (isPreviouslyTouchedApplicationStep(step, application.latestStepSaved)) {
    return true;
  }
  // can always see first step
  if (step === ApplicationStep.SecureAccount) {
    return true;
  }
  // If clearer has already been set by param, allow user to skip over the service level selection step
  if (step === ApplicationStep.AccountType && application.clearer) {
    return true;
  }
  // check if has completed the previous step
  return isPreviouslyTouchedApplicationStep(getPrevApplicationStep(step), application.latestStepCompleted);
};

export const isApplicationStepInvalid = (
  step: ApplicationStep,
  application: ApplicationModel | undefined,
  verifyMismatchedData = true,
  siteGrp?: string,
  clearer?: string
) => {
  // If siteGrp is set to 'mb', ClearerType is automatically set and the user should not be allowed to access the step to change it
  if (
    (application && step === ApplicationStep.ClearerType && siteGrp === 'mb') ||
    (application && step === ApplicationStep.ClearerType && clearer)
  ) {
    return true;
  }
  if (application && canAccessApplicationStep(step, application)) {
    // if on final steps, check errors
    if (isFinalApplicationSteps(step)) {
      // invalid if any mismatched data
      if (verifyMismatchedData && _.some(AllApplicationSteps, s => !!checkForMismatchedDataType(application, s))) {
        return true;
      }
      const errors =
        application &&
        validateApplication(application, step === ApplicationStep.Sign ? [ApplicationStep.Agreements] : []);
      // invalid if previous steps have errors
      return !_.isEmpty(errors);
    }
    // step is valid
    return false;
  }
  return true;
};

export const isApplicationComplete = (application: ApplicationModel) => {
  // if final step is valid (no errors or mismatched data), application is complete
  return !isApplicationStepInvalid(ApplicationStep.Sign, application);
};

export const getNextApplicationStep = (latestStep: ApplicationStep) => {
  return AllApplicationSteps[_.indexOf(AllApplicationSteps, latestStep) + 1];
};

export const getPrevApplicationStep = (latestStep: ApplicationStep) => {
  return AllApplicationSteps[_.indexOf(AllApplicationSteps, latestStep) - 1];
};

export const isFinalApplicationSteps = (step: ApplicationStep) => {
  return _.includes([ApplicationStep.Agreements, ApplicationStep.Sign], step);
};

export const updateApplication = (
  latestStep: ApplicationStep,
  incrementSection: boolean,
  isStepCompleted: boolean | undefined,
  values: ApplicationModel,
  sectionsVisible: number,
  isPartialEntitySubmission?: boolean
): ApplicationModel => {
  const numberOfSections = getNumberOfSectionsInApplicationStep(latestStep, values);
  const visibleSections = sectionsVisible || 1;
  const nextStep = getNextApplicationStep(latestStep);

  const data = {
    ...values,
    latestStepSaved: values.latestStepSaved || latestStep,
    latestStepVisibleSections: values.latestStepVisibleSections || 1,
    lastSavedOn: dayjs()
      .utc()
      .toDate(),
  };

  // set step saved if hasn't been saved yet
  if (!isPreviouslyTouchedApplicationStep(latestStep, data.latestStepSaved)) {
    data.latestStepSaved = latestStep;
  }

  // set step save+completed if latest step hasn't be completed yet
  if (isStepCompleted && !isPreviouslyTouchedApplicationStep(latestStep, data.latestStepCompleted)) {
    data.latestStepCompleted = latestStep;
    data.latestStepVisibleSections = 1;
  } else {
    const isFinalSteps = isFinalApplicationSteps(latestStep);
    if ((data.latestStepSaved === latestStep || isFinalSteps) && incrementSection) {
      data.latestStepVisibleSections = visibleSections + (numberOfSections > visibleSections ? 1 : 0);
    }
    if (!nextStep || isFinalSteps) {
      data.latestStepSaved = latestStep || data.latestStepSaved;
    }
    if (isPartialEntitySubmission) {
      data.isPartialEntitySubmission = true;
    }
  }

  return data;
};

const getUnsupportedFeatureType = (values: ApplicationModel | undefined) => {
  // check for unsupported features
  if (values) {
    if (values.ownerCountry && !_.includes(AcceptedCountries, values.ownerCountry)) {
      return UnsupportedFeatureType.Country;
    }
    // if (
    //   _.some(
    //     [values.primaryAccountHolder, values.secondaryAccountHolder],
    //     a => a && a.disclosures && a.disclosures.irsWithholding === BooleanToggleType.Yes
    //   )
    // ) {
    //   return UnsupportedFeatureType.IrsWithholding;
    // }
    if (values.accountDisclosures && values.accountDisclosures.entityIssuesBearerShares === BooleanToggleType.Yes) {
      return UnsupportedFeatureType.BearerShares;
    }
  }
  return undefined;
};

export const getForcedOffRampUrl = (values: ApplicationModel | undefined) => {
  if (values) {
    const unsupportedFeature = getUnsupportedFeatureType(values);
    // unsupported feature
    if (unsupportedFeature) {
      return `/apply/unsupported/${unsupportedFeature}`;
    }
    // offline PDF (for Coverdell IRA type)
    if (
      values.type === ApplicationType.Individual &&
      values.retirementAccount === BooleanToggleType.Yes &&
      values.retirementAccountType === RetirementAccountType.CoverDell
    ) {
      return '/apply/offline';
    }
    // customer service (if declined an agreement)
    if (
      _.some(
        getAllAgreementKeysForApplication(values),
        (a: AgreementKey) => values.agreements && values.agreements[a] === BooleanToggleType.No
      )
    ) {
      return '/apply/customer-service';
    }
  }
  return undefined;
};

export const getTradeGoalsWithDataVerification = (
  values: ApplicationModel | undefined
): { tradeGoalType: TradeGoalType; verified: boolean }[] => {
  const tradeGoalTypes = getAllTradeGoalTypesForApplication(values);
  return _.map(tradeGoalTypes, tradeGoalType => {
    const mismatchedRules = MismatchedDataRulesForTradeGoalTypes[tradeGoalType];
    const requiredRules = RequiredDataRulesForTradeGoalTypes[tradeGoalType];
    const verified =
      verifyMismatchedDataRules(values, mismatchedRules || {}) && verifyRequiredDataRules(values, requiredRules || {});
    return { tradeGoalType, verified };
  });
};

export const getTradeGoalsWithDataVerificationRetirementAccount = (
  values: ApplicationModel | undefined
): { tradeGoalType: TradeGoalType; verified: boolean }[] => {
  const tradeGoalTypes = getAllTradeGoalTypesForApplication(values);
  return _.map(tradeGoalTypes, tradeGoalType => {
    const mismatchedRules = MismatchedDataRulesForTradeGoalTypesRetirementAccount[tradeGoalType];
    const requiredRules = RequiredDataRulesForTradeGoalTypes[tradeGoalType];
    const verified =
      verifyMismatchedDataRules(values, mismatchedRules || {}) && verifyRequiredDataRules(values, requiredRules || {});
    return { tradeGoalType, verified };
  });
};

const verifyMismatchedDataRules = (values: ApplicationModel | undefined, rules: MismatchedDataRules) => {
  const ruleKeys = _.keys(rules);
  return _.every(ruleKeys, (key: MismatchedDataRuleKey) => verifyMismatchedDataRule(values, key, rules[key]));
};

const verifyMismatchedDataRule = (
  values: ApplicationModel | undefined,
  key: MismatchedDataRuleKey,
  mismatchedData: string[] | undefined
) => {
  if (values) {
    return !_.includes(mismatchedData, values[key]);
  }
  return true;
};

const verifyRequiredDataRules = (values: ApplicationModel | undefined, rules: RequiredDataRules) => {
  const ruleKeys = _.keys(rules);
  return _.every(ruleKeys, (key: RequiredDataRuleKey) => {
    if (key === 'experience') {
      return verifyRequiredExperienceDataRule(values, key, rules[key]);
    }
    return verifyRequiredDataRule(values, key, rules[key]);
  });
};

const verifyRequiredDataRule = (
  values: ApplicationModel | undefined,
  key: keyof Pick<ApplicationModel, 'investmentObjectiveType'>,
  requiredData: string | undefined
) => {
  if (values) {
    return values[key] && (!requiredData || values[key] === requiredData);
  }
  return true;
};

const verifyRequiredExperienceDataRule = (
  values: ApplicationModel | undefined,
  key: keyof Pick<ApplicationModel, 'experience'>,
  requiredData: RequiredExperienceDataRules | undefined
) => {
  if (values && requiredData) {
    const experienceKeys = _.keys(requiredData);
    const experience = values[key] || {};
    return _.every(experienceKeys, (e: ExperienceKey) =>
      _.includes(requiredData[e], _.get(experience, `[${e}].years`))
    );
  }
  return true;
};

const getMismatchedDataType = (
  values: ApplicationModel | undefined,
  step: ApplicationStep | undefined
): MismatchedDataType | undefined => {
  if (step === ApplicationStep.InvestingProfile && values && values.retirementAccount !== 'yes') {
    const tradeGoalsWithVerification = getTradeGoalsWithDataVerification(values);
    // if selected trade goal not verified, return mismatched type
    if (values && _.some(tradeGoalsWithVerification, t => !t.verified && t.tradeGoalType === values.tradeGoalType)) {
      return 'investing-profile';
    }
  } else if (step === ApplicationStep.InvestingProfile && values && values.retirementAccount === 'yes') {
    const tradeGoalsWithVerification = getTradeGoalsWithDataVerificationRetirementAccount(values);
    // if selected trade goal not verified, return mismatched type
    if (values && _.some(tradeGoalsWithVerification, t => !t.verified && t.tradeGoalType === values.tradeGoalType)) {
      return 'investing-profile';
    }
  }
  return undefined;
};

export const checkForMismatchedDataType = (
  values: ApplicationModel | undefined,
  step: ApplicationStep | undefined
): MismatchedDataType | undefined => {
  const type = getMismatchedDataType(values, step);
  // console.1warn('checkForMismatchedDataType :: ', type, step, ' step, application values: ', values);

  // only return mismatched data type if not already confirmed
  if (type && !_.includes(values && values.confirmedMismatchedDataTypes, type)) {
    return type;
  }

  return undefined;
};

export const getRiskLevelForTradeGoalType = (type: TradeGoalType) => {
  switch (type) {
    case TradeGoalType.StocksBonds:
      return RiskLevel.None;
    case TradeGoalType.AllAbovePlusCallsPuts:
      return RiskLevel.VeryLow;
    case TradeGoalType.AllAbovePlusOptionSpreads:
      return RiskLevel.Low;
    case TradeGoalType.AllAbovePlusPutWriting:
      return RiskLevel.Moderate;
    case TradeGoalType.AllAbovePlusAllOptionStrategies:
      return RiskLevel.High;
    default:
      throw new Error('Unknown TradeGoalType: ' + type);
  }
};

export const getRiskLevelForInvestmentObjectiveType = (type: InvestmentObjectiveType) => {
  switch (type) {
    case InvestmentObjectiveType.CapitalPreservation:
    case InvestmentObjectiveType.Income:
    case InvestmentObjectiveType.Growth:
    case InvestmentObjectiveType.GrowthAndIncome:
      return RiskLevel.Moderate;
    case InvestmentObjectiveType.Speculation:
      return RiskLevel.High;
    default:
      throw new Error('Unknown InvestmentObjectiveType: ' + type);
  }
};

export const getOwnerNamesForSignStep = (application: Partial<ApplicationModel> | undefined) => {
  // Helper function to format account holder name with type guard
  const formatName = (accountHolder: AccountHolderModel | null | undefined): string => {
    if (!accountHolder) return ''; // Handle null or undefined account holder
    const { firstName = '', middleInitial = '', lastName = '', suffix = '' } = accountHolder;
    return [firstName, middleInitial, lastName, suffix].filter(Boolean).join(' ');
  };

  // Function to get account holders based on application type
  const getAccountHolders = (): (AccountHolderModel | undefined)[] => {
    if (application && application.type === 'Ugma') {
      return [application.primaryAccountHolder].map(holder => holder || undefined);
    } else {
      return [application && application.primaryAccountHolder, application && application.secondaryAccountHolder].map(
        holder => holder || undefined
      );
    }
  };

  // Map and filter account holders to formatted names
  return getAccountHolders()
    .map(formatName)
    .filter(name => name); // Remove any empty names
};
export const getUploadDocumentTagForUploadKey = (key: UploadKey): UploadDocumentTag => {
  switch (key) {
    case 'articlesOfIncorporation':
    case 'articlesOfOrganization':
    case 'partnershipAgreement':
    case 'trusteeCertification':
      return 'AffiliatedApproval'; // ?
    case 'photoId':
    case 'socialSecurity':
    case 'visa':
      return 'IdDocument';
    default:
      throw new Error('Unknown UploadKey: ' + key);
  }
};

export const getErrorData = (
  key: string,
  prefixes: { [key: string]: JSX.Element },
  defaults?: { [key: string]: JSX.Element }
) => {
  let data = { ...(defaults || {}) };
  if (prefixes && prefixes[key]) {
    data = { ...data, prefix: prefixes[key] };
  }
  if (!_.isEmpty(data)) {
    return data;
  }
  const match = /^(signedNames)\[?(\d+)?\]?$/.exec(key);
  if (match) {
    return { number: _.parseInt(match[2]) + 1 };
  }
  return undefined;
};

type DefaultFieldType = 'address' | 'dateOfBirth' | 'phones' | 'ssn';

const getDefaultFieldMatch = (field: string, type: DefaultFieldType) => {
  const match = /^(.+?)(\.)?(address|dateOfBirth|phones|ssn)(?:\[\d+\])?(?:\.(\w+))?$/i.exec(field);
  return match && match[3].toLowerCase() === type ? match : undefined;
};

const getDefaultField = (field: string, type: DefaultFieldType) => {
  const match = getDefaultFieldMatch(field, type);
  if (match) {
    return match[4];
  }
  return undefined;
};

const getDefaultFieldType = (field: string, type: DefaultFieldType) => {
  const match = getDefaultFieldMatch(field, type);
  if (match) {
    const key = (match[1] || '') + (match[2] || '') + (!match[1] || !match[2] ? match[3] : '');
    return getCleanTranslationKey(key);
  }
  return undefined;
};

const getAccountHolderOrDefaultField = (field: string) => {
  return field.replace(/(primaryAccountHolder|secondaryAccountHolder)/, 'accountHolders');
};

export const getPrefixTextPropsForField = (field: string, application: ApplicationModel | undefined) => {
  const isPersonalTrust = application && application.entityAccountType === EntityAccountType.PersonalTrust;
  const beneficiaryStepFields = ['beneficiaries', 'ownersOfficers', 'trustedContact'];

  const addressType = getDefaultFieldType(field, 'address');
  if (addressType) {
    return {
      id: `${getAccountHolderOrDefaultField(addressType)}.${isPersonalTrust ? 'trust' : 'label'}`,
      page: _.includes(beneficiaryStepFields, addressType)
        ? ApplicationStep.Beneficiaries
        : ApplicationStep.AccountInformation,
    };
  }
  const dobType = getDefaultFieldType(field, 'dateOfBirth');
  if (dobType) {
    return {
      id: `${dobType}.label`,
      page: _.includes(beneficiaryStepFields, dobType)
        ? ApplicationStep.Beneficiaries
        : ApplicationStep.AccountInformation,
    };
  }
  const phoneType = getDefaultFieldType(field, 'phones');
  if (phoneType) {
    return {
      id: `${phoneType}.label`,
      page: _.includes(beneficiaryStepFields, phoneType)
        ? ApplicationStep.Beneficiaries
        : ApplicationStep.AccountInformation,
    };
  }
  const ssnType = getDefaultFieldType(field, 'ssn');
  if (ssnType) {
    return {
      id: `${ssnType}.label`,
      page: _.includes(beneficiaryStepFields, ssnType)
        ? ApplicationStep.Beneficiaries
        : ApplicationStep.AccountInformation,
    };
  }
  if (field === 'entity.entityName' || field === 'entity.entityTaxId') {
    return {
      id: `entity.${isPersonalTrust ? 'trust' : 'defaults'}`,
      page: ApplicationStep.AccountInformation,
    };
  }
  // console.warn('getPrefixTextPropsForField :: field', field);
  return undefined;
};

export const getITINTextPropsForField = (field: string) => {
  const ssnType = getDefaultFieldType(field, 'ssn');
  if (ssnType) {
    return {
      id: `itin.label`,
      page: 'defaults',
    };
  }
  return undefined;
};

export const getErrorKeyForField = (field: string, error: string) => {
  const defaultFieldTypes: DefaultFieldType[] = ['address', 'dateOfBirth', 'phones', 'ssn'];
  const defaultFieldType = _.find(defaultFieldTypes, t => !!getDefaultFieldType(field, t));
  if (defaultFieldType) {
    const defaultField = getDefaultField(field, defaultFieldType);
    return `defaults.${defaultFieldType}.${defaultField || error}`;
  }
  return `${getAccountHolderOrDefaultField(field)}.${error}`;
};

// create a map of the affiliates/heardAboutUs values that are currently available for auto-setting via parameters
// can add additional affiliates here as needed
export const getAffiliateHeardAboutUs = (affiliate: string) => {
  const autoSetHeardAboutUs = new Map();
  autoSetHeardAboutUs.set('SPRH', {
    heardAboutId: '30',
    description: 'SpreadHunter',
    repId: 268,
    needsApiAgreement: false,
    officeCodes: {
      apex: '3OC',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('POAR', {
    heardAboutId: '35',
    description: 'Portfolio Armor',
    repId: 333,
    needsApiAgreement: false,
    officeCodes: {
      apex: '3OC',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('NBS', {
    heardAboutId: '18',
    description: 'Newport Brokerage Services (JO1)',
    repId: '159',
    needsApiAgreement: false,
    officeCodes: {
      apex: '3OD',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('ML1', {
    heardAboutId: '18',
    description: 'Michael  Lavelle (ML1)',
    repId: '157',
    needsApiAgreement: false,
    officeCodes: {
      apex: '3OD',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('MN1', {
    heardAboutId: '18',
    description: 'MLN Investments (MN1)',
    repId: '202',
    needsApiAgreement: false,
    officeCodes: {
      apex: '3OD',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('INT', {
    heardAboutId: '31',
    description: 'Interactive Trader',
    needsApiAgreement: true,
    repId: '275',
    officeCodes: {
      apex: '3OC',
      rqd: 'TBC',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('TRSP', {
    heardAboutId: '24',
    description: 'Tradespoon',
    needsApiAgreement: false,
    repId: '18',
    officeCodes: {
      apex: '3OC',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('CAMCA', {
    heardAboutId: '34',
    description: 'Cambria Capital',
  });
  autoSetHeardAboutUs.set('MIPO', {
    heardAboutId: '34',
    description: 'Cambria Capital',
    needsApiAgreement: false,
    repId: '304',
    officeCodes: {
      apex: '3OG',
    },
    serviceLevel: 'Skip',
  });
  autoSetHeardAboutUs.set('TT', {
    heardAboutId: '36',
    description: 'Trade Tool',
    needsApiAgreement: true,
    repId: '275',
    officeCodes: {
      apex: '3OC',
      rqd: 'TBC',
    },
    serviceLevel: 'ZeroOrFull',
  });

  if (autoSetHeardAboutUs.has(affiliate)) {
    return autoSetHeardAboutUs.get(affiliate);
  }
};
