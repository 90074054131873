import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import constate from 'constate';

var AuthenticationContext = function AuthenticationContext() {
  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      authToken = _useState2[0],
      setAuthToken = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isAuthenticating = _useState4[0],
      setIsAuthenticating = _useState4[1];

  var state = {
    authToken: authToken,
    isAuthenticating: isAuthenticating
  };
  var actions = {
    setAuthToken: setAuthToken,
    setIsAuthenticating: setIsAuthenticating
  };
  var context = [state, actions];
  return context;
};

export var useAuthentication = constate(AuthenticationContext);