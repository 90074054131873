import React, { useCallback } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { Country } from '@tradingblock/types';
import { TextboxField, SelectDropdownField } from './';
import { FormGroup } from '../../basic';
export var AddressField = function AddressField(_ref) {
  var id = _ref.id,
      labelPrefix = _ref.labelPrefix,
      countryProps = _ref.countryProps,
      addr1Help = _ref.addr1Help,
      stateProps = _ref.stateProps,
      field = _ref.field;
  var value = field.value;

  var country = _.get(value, "".concat(id, ".country"));

  var getTextboxFieldProps = useCallback(function (field) {
    var fieldProps = {
      component: TextboxField,
      id: "".concat(id, ".").concat(field),
      defaultLabelId: "address.".concat(field)
    };

    if (field === 'state') {
      fieldProps.defaultLabelId = "address.province";
    }

    if (labelPrefix) {
      return Object.assign({}, fieldProps, {
        labelPrefix: labelPrefix
      });
    }

    return fieldProps;
  }, [id, labelPrefix]);
  return React.createElement(React.Fragment, null, React.createElement(FormGroup, null, React.createElement(Field, Object.assign({
    component: SelectDropdownField,
    id: "".concat(id, ".country")
  }, countryProps, {
    autoComplete: "".concat(id, ".country")
  }))), React.createElement(FormGroup, {
    help: addr1Help
  }, React.createElement(Field, Object.assign({}, getTextboxFieldProps('address1')))), React.createElement(FormGroup, null, React.createElement(Field, Object.assign({}, getTextboxFieldProps('address2')))), React.createElement(FormGroup, null, React.createElement(Field, Object.assign({}, getTextboxFieldProps('city')))), React.createElement(FormGroup, null, (country === Country.UnitedStatesOfAmerica || country === '') && React.createElement(React.Fragment, null, React.createElement(Field, Object.assign({
    component: SelectDropdownField,
    id: "".concat(id, ".state")
  }, stateProps, {
    autoComplete: 'HEYOOOO'
  }))), country !== Country.UnitedStatesOfAmerica && country !== '' && React.createElement(React.Fragment, null, React.createElement(Field, Object.assign({}, getTextboxFieldProps('state'))))), React.createElement(FormGroup, null, React.createElement(Field, Object.assign({}, getTextboxFieldProps('postalCode')))));
};