import CryptoJS from 'crypto-js';
var UNSAFE_KEY = 'tradingblock';
export var encode = function encode(source) {
  return CryptoJS.AES.encrypt(JSON.stringify(source), UNSAFE_KEY).toString();
};
export var decode = function decode(text) {
  try {
    var bytes = CryptoJS.AES.decrypt(text, UNSAFE_KEY);
    var jsonStr = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonStr);
  } catch (err) {
    return undefined;
  }
};