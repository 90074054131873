export function useSign(val, iconSize, iconStyle, classNameSuffix, titleSuffix) {
  var iconClassName = "".concat(iconStyle || 'far', " ").concat(iconSize || 'fa-xs');
  var prefix = val > 0 ? '+' : undefined;
  var title = val !== undefined ? "".concat(prefix || '').concat(val.toFixed(2)).concat(titleSuffix || '') : undefined;

  if (val > 0) {
    return [val, {
      className: "pos".concat(classNameSuffix || ''),
      title: title,
      prefix: prefix,
      icon: "".concat(iconClassName, " fa-triangle")
    }];
  }

  if (val < 0) {
    return [val, {
      className: "neg".concat(classNameSuffix || ''),
      title: title,
      prefix: prefix,
      icon: "".concat(iconClassName, " fa-triangle fa-flip-vertical")
    }];
  }

  return [val, {}];
}