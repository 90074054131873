import * as tslib_1 from "tslib";
import React from 'react';
export var FloatingLabelWrapper = function FloatingLabelWrapper(_ref) {
  var state = _ref.state,
      className = _ref.className,
      prefix = _ref.prefix,
      disabled = _ref.disabled,
      children = _ref.children;
  return React.createElement("div", {
    className: "flabel".concat(className ? " ".concat(className) : '').concat(state.isFilled ? ' filled' : '').concat(state.hasFocus ? ' on' : '').concat(disabled ? ' disabled' : ''),
    "data-prefix": prefix
  }, children);
};
export var FloatingLabel = function FloatingLabel(_a) {
  var id = _a.id,
      label = _a.label,
      icon = _a.icon,
      children = _a.children,
      wrapperProps = tslib_1.__rest(_a, ["id", "label", "icon", "children"]);

  return React.createElement(FloatingLabelWrapper, Object.assign({}, wrapperProps), React.createElement("label", {
    className: "flabel-title",
    htmlFor: id
  }, label), React.createElement("div", {
    className: "flabel-value"
  }, children), icon && React.createElement("div", {
    className: "flabel-icon"
  }, icon));
};