import React, { useEffect, useMemo, useCallback } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import {
  Section,
  LoadingImage,
  Text,
  TextOptions,
  Footer,
  Svg,
  GetTradingBlockSiteName,
} from '@tradingblock/components';
import {
  AllApplicationSteps,
  ApplicationStep,
  ApplicationModel,
  DefaultApplicationModel,
  BaseFormProps,
  MismatchedDataModalOptions,
  ApplicationType,
  ClearerType,
} from '../types';
import {
  getApplicationStepUrl,
  updateApplication,
  getForcedOffRampUrl,
  validateApplicationStepAsync,
  checkForMismatchedDataType,
  delayedScrollTo,
  isFinalApplicationSteps,
  isApplicationStepInvalid,
  canAccessApplicationStep,
  isApplicationComplete,
  getNextApplicationStep,
} from '../services';
import { useApplication, useStore } from '../context';
import { PageTitle } from '../components';
import { ApplicationLoader, ApplicationFormComponents } from '../components/application';
import { useData, useStateSelector } from '../state/selectors';
import { useDispatcher } from '../components/hooks';
import { UiActions, ApplicationActions } from '../state/actions';
import config from '../config';
import { ErrorScrollWrapper } from '../components/ErrorScrollWrapper';
import { Optional } from 'utility-types';
import { shouldRemoveClearerOption } from '../hooks/useApplicationSteps';
import { useLinkClicked } from '../components/application/form-steps/LinkClickedContext';

interface ApplyPageProps extends RouteComponentProps<{ step: ApplicationStep }>, LocalizeContextProps {}

const ApplyPage: React.FC<ApplyPageProps> = props => {
  const {
    match: { params },
  } = props;

  const storage = useStore();
  const dispatch = useDispatcher();
  const [{ isFetching }, { setFormSubmitted }] = useApplication();

  const application = useData(d => d.data.application);
  const createdAccount = useData(d => d.data.createdAccount);
  const { numberOfSections, visibleSections, scrollToSection } = useData(d => d.ui.sectionInfo);
  const { isSaving, saveType, saveStep } = useData(d => d.data.saveInfo);
  const step = useData(d => d.ui.step);
  const nextPage = useData(d => d.ui.nextPage);
  const clearer = application && application.clearer;

  const stepIndex = useMemo(() => _.indexOf(AllApplicationSteps, step), [step]);
  const isOnLastSectionOfStep = useMemo(
    () => !!numberOfSections && !!visibleSections && visibleSections >= numberOfSections,
    [numberOfSections, visibleSections]
  );

  // try to update step from url
  useEffect(() => {
    const nextStep = _.includes(AllApplicationSteps, params.step) ? params.step : undefined;
    if (!nextStep) {
      dispatch(UiActions.setNextPage('/'));
    } else if (nextStep !== step && !_.isUndefined(isFetching) && !isFetching && !nextPage) {
      if (
        application &&
        isFinalApplicationSteps(nextStep) &&
        canAccessApplicationStep(nextStep, application) &&
        isApplicationStepInvalid(nextStep, application)
      ) {
        // force user to review errors on summary page if can access next step but it's invalid
        dispatch(UiActions.setNextPage(getApplicationStepUrl('summary')));
      } else if (application && !canAccessApplicationStep(nextStep, application)) {
        // if can't access step, send back to last saved step
        dispatch(
          UiActions.setNextPage(getApplicationStepUrl(application.latestStepSaved || ApplicationStep.SecureAccount))
        );
      } else {
        dispatch(UiActions.setStep({ step: nextStep, application: application || DefaultApplicationModel }));
      }
    }
  }, [params, application, isFetching, step, nextPage, dispatch]);

  // scroll to next visible section
  useEffect(() => {
    if (visibleSections && visibleSections > 1 && scrollToSection) {
      const section = document.getElementById(`${step}-section-${visibleSections}`);
      if (section) {
        delayedScrollTo(section.offsetTop);
      }
    }
  }, [step, visibleSections, scrollToSection]);

  const getNextPage = useCallback(
    (sendToNextPage?: boolean) => {
      if (saveType === 'partial') {
        return getApplicationStepUrl('summary');
      } else if (saveType === 'continue' || saveType === 'confirm-data-mismatch') {
        if (sendToNextPage) {
          // if next step is agreements, send to review/summary first
          if (stepIndex + 1 === _.indexOf(AllApplicationSteps, ApplicationStep.Agreements)) {
            return getApplicationStepUrl('summary');
          } else if (step && isFinalApplicationSteps(step) && isApplicationStepInvalid(step, application)) {
            // send user to summary to review errors if current step isn't invalid
            return getApplicationStepUrl('summary');
          } else if (step === ApplicationStep.SecureAccount && clearer) {
            return getApplicationStepUrl(ApplicationStep.AccountType);
          } else {
            return getApplicationStepUrl(AllApplicationSteps[stepIndex + 1]);
          }
        }
      }
      return undefined;
    },
    [stepIndex, saveType, step, application]
  );

  useEffect(() => {
    if (!isSaving && saveType && saveStep === step && !nextPage) {
      let nextUrl = getNextPage();
      if (saveType === 'continue' || saveType === 'confirm-data-mismatch') {
        // check for any off ramp conditions
        const forcedNextUrl = getForcedOffRampUrl(application);

        // only move past current step if on last section and no errors
        nextUrl = getNextPage(isOnLastSectionOfStep);
        if (forcedNextUrl) {
          nextUrl = forcedNextUrl;
        } else if (!nextUrl) {
          // if staying on same step, increment # of visible sections
          dispatch(UiActions.showNextSection({ scrollToSection: true }));
          // reset form submitted flag
          setFormSubmitted(false);
        }
      }

      if (nextUrl) {
        dispatch(UiActions.setNextPage(nextUrl));
        // reset form submitted flag
        setFormSubmitted(false);
      }
    }
  }, [
    application,
    step,
    nextPage,
    saveStep,
    saveType,
    isSaving,
    isOnLastSectionOfStep,
    getNextPage,
    setFormSubmitted,
    dispatch,
  ]);

  useEffect(() => {
    if (!isSaving && saveType === 'complete') {
      if (createdAccount) {
        // save application to storage
        if (application) {
          dispatch(ApplicationActions.requestSaveApplication({ application, saveType, storage }));
        }
        // send to thank you page
        dispatch(UiActions.setNextPage('/thanks'));
      } else if (_.isUndefined(createdAccount) && application && isApplicationComplete(application)) {
        // create the TB account after application saved and complete
        dispatch(ApplicationActions.requestCreateAccount({ application }));
      }
    }
  }, [saveType, isSaving, createdAccount, application, dispatch, storage]);

  // if already created account, don't allow access to any apply form steps
  if (application && application.accountId) {
    return <Redirect to={getApplicationStepUrl('summary')} />;
  }

  return (
    <ApplicationLoader allowAnonymous={step === ApplicationStep.SecureAccount}>
      {step && <PageTitle page={step} />}
      <ApplyForm {...props} />
    </ApplicationLoader>
  );
};

const ApplyForm: React.FC<ApplyPageProps> = ({ match, setActiveLanguage }) => {
  const storage = useStore();
  const dispatch = useDispatcher();
  const [{ mismatchedDataModal, formSubmitted }, { setMismatchedDataModal, setFormSubmitted }] = useApplication();

  const authToken = useData(d => d.ui.apiToken);
  const application = useData(d => d.data.application);
  const { numberOfSections, visibleSections } = useData(d => d.ui.sectionInfo);
  const { isSaving, saveType } = useData(d => d.data.saveInfo);
  const createErrorCode = useData(d => d.data.createErrorCode);
  const step = useData(d => d.ui.step);
  const nextPage = useData(d => d.ui.nextPage);
  const userRequirements = useData(d => d.ui.userRequirements);
  const siteGrpParam = process.env.REACT_APP_TB_SITE_GROUP;
  const stepIndex = useMemo(() => _.indexOf(AllApplicationSteps, step), [step]);
  const isOnLastSectionOfStep = useMemo(
    () => !!numberOfSections && !!visibleSections && visibleSections >= numberOfSections,
    [numberOfSections, visibleSections]
  );

  const initialValues = useMemo(() => {
    if (application && !_.isEmpty(application)) {
      return { ...application };
    }
    return DefaultApplicationModel;
  }, [application]);

  const closeMismatchedDataModal = useCallback(
    (options?: MismatchedDataModalOptions) => {
      const mismatchedDataType = mismatchedDataModal && mismatchedDataModal.type;
      // use false to mark as closed
      setMismatchedDataModal(false);
      // save application to confirm data mismatch and complete current step
      if (step && mismatchedDataType && options && options.confirmMismatchedData && options.formValues) {
        const applicationWithConfirmation = {
          ...options.formValues,
          confirmedMismatchedDataTypes: [
            mismatchedDataType,
            ...(options.formValues.confirmedMismatchedDataTypes || []),
          ],
        };
        // update status fields on application
        const data = updateApplication(step, false, true, applicationWithConfirmation, visibleSections);
        dispatch(
          ApplicationActions.requestSaveApplication({
            application: data,
            saveType: 'confirm-data-mismatch',
            saveStep: step,
            storage,
          })
        );
      } else if (options && options.scrollToTop) {
        delayedScrollTo(0);
      }
    },
    [mismatchedDataModal, step, visibleSections, setMismatchedDataModal, dispatch, storage]
  );

  const getStepProps = (step: ApplicationStep, props: BaseFormProps<ApplicationModel>) => ({
    ...props,
    getText: (id: string, textKey?: string, options?: TextOptions) => {
      const textOptions = {
        ...options,
        data: {
          ...(options && options.data),
          siteName: GetTradingBlockSiteName(undefined, siteGrpParam),
        },
      };
      return <Text id={id} page={step} textKey={textKey} {...textOptions} />;
    },
    setActiveLanguage,
    mismatchedDataModal,
    closeMismatchedDataModal,
    formSubmitted,
  });

  // build id of submit btn, used for translation id
  const submitButtonId = useMemo(() => {
    if (step === ApplicationStep.Beneficiaries) {
      return 'review';
    }
    if (step === ApplicationStep.Sign) {
      return 'submit';
    }
    if (config.isVirtual && isOnLastSectionOfStep) {
      return 'submit';
    }
    return 'continue';
  }, [step, isOnLastSectionOfStep]);

  const duplicateAccountCheckState = useStateSelector(s => s.data.duplicateAccountCheck);

  // handle validate
  const onValidate = (values: ApplicationModel) => {
    // console.warn('ApplyPage :: onValidate ', step, visibleSections, values);
    // if already logged in, only validate first section of secure acct step
    const visibleSectionsToValidate = authToken && step === ApplicationStep.SecureAccount ? 1 : visibleSections;
    return validateApplicationStepAsync(
      step,
      values,
      visibleSectionsToValidate,
      userRequirements,
      duplicateAccountCheckState
    );
  };

  // handle submit
  const onSubmit = (values: ApplicationModel, formikHelpers: FormikHelpers<ApplicationModel>) => {
    // once valid form submitted, reset touched fields
    formikHelpers.setTouched({});
    // submitting virtual application (first step of application only)
    if (step === ApplicationStep.SecureAccount && isOnLastSectionOfStep && config.isVirtual) {
      dispatch(ApplicationActions.requestCreateUser({ application: values }));
    } else if (step === ApplicationStep.SecureAccount && visibleSections === 1) {
      // skip saving on very first step and section, show next section right away
      dispatch(UiActions.showNextSection({ scrollToSection: true }));
      setFormSubmitted(false);
    } else {
      // check for mismatched data
      const mismatchedDataType = isOnLastSectionOfStep ? checkForMismatchedDataType(values, step) : undefined;
      if (mismatchedDataType) {
        setMismatchedDataModal({ type: mismatchedDataType });
      } else {
        // save and continue
        saveStepAndContinue(values);
      }
    }
  };

  const saveStepAndContinue = async (values: ApplicationModel) => {
    // check for any off ramp conditions
    const forcedNextUrl = getForcedOffRampUrl(values);
    // check if current step is valid
    const isStepValid = step && !isApplicationStepInvalid(step, values);
    // can only move past current step if on last section and no offramp url
    const isStepCompleted = isOnLastSectionOfStep && isStepValid && !forcedNextUrl;
    // check if we should skip clearer and set it to APEX
    const shouldSkipClearer = shouldRemoveClearerOption(values.hearAboutUsId, values.repId, heardAbout);

    // if no authToken, need to create user first
    if (!authToken && isStepCompleted && step === ApplicationStep.SecureAccount) {
      dispatch(ApplicationActions.requestCreateUser({ application: values }));
      return;
    }

    // reset mismatched data modal state
    if (mismatchedDataModal === false) {
      setMismatchedDataModal(undefined);
    }

    // If we should skip clearer step, then we need to set clearer to apex.
    if (shouldSkipClearer) {
      values = {
        ...values,
        clearer: ClearerType.Apex,
      };
    } else if (step === ApplicationStep.SecureAccount && !shouldSkipClearer) {
      // On every update to this page, we will need to ask what commission style they want.
      values = {
        ...values,
        clearer: null,
      };
    }

    // update status fields on application
    const data = updateApplication(
      step || ApplicationStep.ClearerType, // Need to force the next step to be clearer
      !isStepCompleted,
      isStepCompleted,
      values,
      visibleSections
    );
    // if completing last step, use saveType = 'complete'
    const saveType = isStepCompleted && stepIndex + 1 === AllApplicationSteps.length ? 'complete' : 'continue';
    // save application
    dispatch(ApplicationActions.requestSaveApplication({ application: data, saveType, saveStep: step, storage }));
  };

  const onSaveForLater = async (values: ApplicationModel) => {
    // update status fields on application with incrementSection = false, isStepCompleted = false
    const data = updateApplication(step || ApplicationStep.SecureAccount, false, false, values, visibleSections);
    // save application with saveType = 'partial'
    dispatch(
      ApplicationActions.requestSaveApplication({ application: data, saveType: 'partial', saveStep: step, storage })
    );
  };

  const onHandleSubmit = (handler: () => void, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // set submitted flag
    setFormSubmitted(true);
    // fire formik handler
    handler();
  };

  const handlePartialEntityAccountSubmission = (values: ApplicationModel) => {
    const data = updateApplication(step || ApplicationStep.SecureAccount, false, false, values, visibleSections, true);
    // save the application with saveType = 'partial'
    dispatch(
      ApplicationActions.requestSaveApplication({ application: data, saveType: 'partial', saveStep: step, storage })
    );
    if (values.entityAccountType) {
      dispatch(ApplicationActions.requestCreatePartialEntityAccount({ application: data }));
    }
  };

  const heardAbout = useData(d => d.ui.heardAbout);

  const { linkClicked } = useLinkClicked();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={onValidate}
      validateOnChange={false}
    >
      {(props: BaseFormProps<ApplicationModel>) => (
        <ErrorScrollWrapper>
          <form onSubmit={e => onHandleSubmit(props.handleSubmit, e)} autoComplete={'off'}>
            <Section>
              <div className="step-title">
                <h1>{step && <Text id={`step-title${config.isVirtual ? '-virtual' : ''}`} page={step} />}</h1>
              </div>
            </Section>

            {_.map(
              ApplicationFormComponents,
              (Step: any, key: ApplicationStep) =>
                step === key && (
                  <div key={`step${key}`}>
                    <Step {...getStepProps(key, props)} />
                  </div>
                )
            )}
            {props.values.type === ApplicationType.Entity && !props.values.entityAccountType && (
              <span className="error" style={{ display: 'flex', justifyContent: 'center' }}>
                <Text id="entityAccountType" type="error" />
              </span>
            )}
            {step !== ApplicationStep.ClearerType && (
              <Footer
                primaryPanel={
                  <>
                    {props.values.type !== ApplicationType.Entity && (
                      <button className="btn btn-block btn-primary btn-icon" type="submit" disabled={!!isSaving}>
                        {(saveType === 'continue' || saveType === 'complete' || !!nextPage) &&
                        !createErrorCode &&
                        _.isUndefined(mismatchedDataModal) ? (
                          <LoadingImage />
                        ) : (
                          <Text id={`btns.${submitButtonId}`} />
                        )}{' '}
                        <span className="tb-icon">
                          <Svg path="icon-arrow" />
                        </span>
                      </button>
                    )}
                    {props.values.type === ApplicationType.Entity && (
                      <button
                        className="btn btn-block btn-primary btn-icon"
                        type="button"
                        disabled={!!isSaving || !props.values.entityAccountType || !linkClicked}
                        onClick={() => {
                          handlePartialEntityAccountSubmission(props.values);
                        }}
                      >
                        <Text id="btns.submit" />
                      </button>
                    )}
                  </>
                }
                secondaryPanel={
                  step !== ApplicationStep.SecureAccount && (
                    <button
                      className="btn btn-block btn-outline-dark"
                      type="button"
                      disabled={!!isSaving}
                      onClick={() => onSaveForLater(props.values)}
                      tabIndex={-1}
                    >
                      {saveType === 'partial' || !!nextPage ? <LoadingImage /> : <Text id="btns.save-for-later" />}
                    </button>
                  )
                }
              />
            )}
          </form>
        </ErrorScrollWrapper>
      )}
    </Formik>
  );
};

export default withLocalize(ApplyPage);
