import React from 'react';
import { BooleanToggleType, ApplicationType, SummaryStepProps, RetirementAccountType } from '../../../types';
import { FieldValue } from '../../values';

export const AccountTypeSummary: React.FC<SummaryStepProps> = ({ application, getText }) => {
  if (!application) {
    return null;
  }
  return (
    <>
      <h3 className="fields-title">{getText('ownerCountry')}</h3>
      <p>
        <FieldValue value={application.ownerCountry} textPrefix="defaults.country" />
      </p>

      <h3 className="fields-title">{getText('applicationType')}</h3>
      <p>
        <FieldValue value={application.type} textPrefix="defaults.applicationType" />
      </p>

      {application.type === ApplicationType.Individual && (
        <>
          <h3 className="fields-title">{getText('retirementAccount')}</h3>
          <p>
            <FieldValue value={application.retirementAccount} textPrefix="defaults.boolean" />
          </p>
          {application.retirementAccount === BooleanToggleType.Yes && (
            <>
              <h3 className="fields-title">{getText('retirementAccountType')}</h3>
              <p>
                <FieldValue value={application.retirementAccountType} textPrefix="retirementAccountType" />
              </p>
              {application.retirementAccountType === RetirementAccountType.Simple && (
                <>
                  <h3 className="fields-title">{getText('simpleIraPlanName')}</h3>
                  <p>
                    <FieldValue value={application.simpleIraPlanName} />
                  </p>
                </>
              )}
              {application.retirementAccountType === RetirementAccountType.Beneficiary && (
                <>
                  <h3 className="fields-title">{getText('beneficiaryIraDecedentName')}</h3>
                  <p>
                    <FieldValue value={application.beneficiaryIraDecedentName} />
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}

      {application.type === ApplicationType.Joint && (
        <>
          <h3 className="fields-title">{getText('jointAccountType')}</h3>
          <p>
            <FieldValue value={application.jointAccountType} textPrefix="jointAccountType" />
          </p>
        </>
      )}

      {application.type === ApplicationType.Entity && (
        <>
          <h3 className="fields-title">{getText('entityAccountType')}</h3>
          <p>
            <FieldValue value={application.entityAccountType} textPrefix="entityAccountType" />
          </p>
        </>
      )}

      <h3 className="fields-title">{getText('flexibilityType')}</h3>
      <p>
        <FieldValue value={application.flexibilityType} textPrefix="flexibilityType" />
      </p>
    </>
  );
};
