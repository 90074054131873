import _defineProperty from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { renderToStaticMarkup } from 'react-dom/server';
import _ from 'lodash';
import { LanguageType, DefaultLanguageKeys } from '../Language';
import englishErrors from './en/errors.json';
import englishFields from './en/fields.json';
import englishPages from './en/pages.json';
var protectedTranslationKeys = ['description'];

var getTranslationsData = function getTranslationsData(pagesTranslations, fieldsTranslations, errorsTranslations) {
  return {
    errors: formatTranslationsData(errorsTranslations, DefaultLanguageKeys.Error),
    fields: formatTranslationsData(fieldsTranslations, DefaultLanguageKeys.Label),
    pages: formatTranslationsData(pagesTranslations, DefaultLanguageKeys.Text)
  };
};

var formatTranslationsData = function formatTranslationsData(data, expandedKey) {
  return _.reduce(data, function (acc, val, key) {
    if (key === expandedKey || _.includes(protectedTranslationKeys, key)) {
      acc[key] = val;
    } else if (_.isString(val)) {
      acc[key] = _defineProperty({}, expandedKey, val);
    } else {
      // recurse
      acc[key] = formatTranslationsData(val, expandedKey);
    }

    return acc;
  }, {});
};

var en = getTranslationsData(englishPages, englishFields, englishErrors);
export var AllLanguageTypes = [LanguageType.English, LanguageType.Spanish, LanguageType.Chinese];
export var allTranslations = {
  en: en,
  es: en,
  zh: en
};
export var getLocalizeOptions = function getLocalizeOptions(languageTypes) {
  var languageNamesByKey = _.reduce(_.mapValues(LanguageType, function (l) {
    return l;
  }), function (acc, l, n) {
    acc[l] = n;
    return acc;
  }, {});

  return {
    languages: _.map(languageTypes, function (code) {
      return {
        code: code,
        name: languageNamesByKey[code]
      };
    }),
    options: {
      renderInnerHtml: true,
      renderToStaticMarkup: renderToStaticMarkup
    }
  };
};