import React, { useMemo, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  Text,
  ContactPhone,
  BootstrapDropdown as Dropdown,
  Header,
  SubHeader,
  SiteLogo,
  ContactEmail,
} from '@tradingblock/components';
import { ApplicationStep, AllApplicationSteps } from '../types';
import { getApplicationStepUrl, isApplicationStepInvalid } from '../services';
import { useApplication } from '../context';
import { useData } from '../state/selectors';
import { ApplicationType } from '@tradingblock/types';

const PageHeaderComponent: React.FC<RouteComponentProps> = ({ history }) => {
  const application = useData(d => d.data.application);
  const step = useData(d => d.ui.step);
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;
  const clearer = useData(d => d.ui.clearer);
  const [{ navMenuOpen }, { setNavMenuOpen }] = useApplication();

  const url = history.location.pathname;

  const showNavigation = useMemo(() => {
    // if already created account, always hide nav
    if (application && application.accountId) {
      return false;
    }
    // show nav when on a application page, and have application data loaded or not on customer service for new accts
    return _.startsWith(url, '/apply/') && (application || !_.endsWith(url, '/customer-service'));
  }, [url, application]);

  const currentStep = useMemo(() => (!_.endsWith(url, '/summary') ? step : undefined), [url, step]);
  const latestStep = useMemo(() => application && application.latestStepSaved, [application]);

  const canEdit = useCallback(
    (step: ApplicationStep) => {
      const stepIndex = _.indexOf(AllApplicationSteps, step);
      const latestStepIndex = latestStep && _.indexOf(AllApplicationSteps, latestStep);
      return (
        ((stepIndex === 0 || (!!latestStepIndex && latestStepIndex >= stepIndex)) &&
        !isApplicationStepInvalid(step, application, false, siteGrp, clearer)) && (application && application.type !== ApplicationType.Entity)
      );
    },
    [latestStep, application]
  );

  const onMenuToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setNavMenuOpen(!navMenuOpen);
  };

  const onMenuClose = () => {
    setNavMenuOpen(false);
  };

  return (
    <Header>
      <SubHeader onClick={onMenuClose}>
        <SiteLogo siteGroup={siteGrp} />
        <nav className="nav-utilities">
          <ul>
            <li>
              <Dropdown id="assistance" toggle={<Text id="contact" />}>
                <div className="dropdown-body">
                  <strong>
                    <Text id="contact.content" />
                  </strong>
                  <br />
                  <Text
                    id="contact.content"
                    textKey="description"
                    siteGroup={siteGrp}
                    data={{
                      email: <ContactEmail siteGroup={siteGrp} />,
                      phone: <ContactPhone siteGroup={siteGrp} />,
                    }}
                  />
                </div>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </SubHeader>

      {showNavigation && (
        <nav className={`nav ${navMenuOpen ? 'nav-menu-open' : ''}`}>
          <div className="content">
            <a
              aria-haspopup="true"
              aria-expanded={navMenuOpen}
              className="nav-toggle"
              href="#nav"
              id="nav-toggle"
              onClick={onMenuToggle}
            >
              <i className="fal fa-bars" />
            </a>
            <ul aria-labelledby="nav-toggle" className="nav-labels" id="nav">
              {AllApplicationSteps.map(s => (
                <NavigationStep
                  key={`step-${s}`}
                  step={s}
                  current={currentStep}
                  canEdit={canEdit(s)}
                  showLabel={true}
                  onClickLabel={() => setNavMenuOpen(false)}
                />
              ))}
            </ul>
            <ul className="nav-indicators" aria-hidden="true">
              {AllApplicationSteps.map(s => (
                <NavigationStep key={`step-${s}`} step={s} current={currentStep} canEdit={canEdit(s)} />
              ))}
            </ul>
          </div>
        </nav>
      )}
    </Header>
  );
};

export const PageHeader = withRouter(PageHeaderComponent);

interface NavigationStepProps {
  step: ApplicationStep;
  current?: ApplicationStep;
  canEdit?: boolean;
  showLabel?: boolean;
  onClickLabel?: () => void;
}

const NavigationStep: React.FC<NavigationStepProps> = ({ step, current, canEdit, showLabel, onClickLabel }) => {
  const stepIndex = useMemo(() => _.indexOf(AllApplicationSteps, step), [step]);
  const currentIndex = useMemo(() => _.indexOf(AllApplicationSteps, current), [current]);
  const isCurrent = useMemo(() => stepIndex > -1 && stepIndex === currentIndex, [stepIndex, currentIndex]);

  const className = useMemo(() => {
    if (isCurrent) {
      return 'nav-step nav-present';
    }
    if (canEdit) {
      return 'nav-step nav-past';
    }
    return 'nav-step nav-future';
  }, [isCurrent, canEdit]);

  const label = useMemo(() => {
    if (!showLabel) {
      return '';
    }
    const text = <Text id={`header.nav.${step}`} />;
    if (!isCurrent && canEdit) {
      return <Link to={getApplicationStepUrl(step)}>{text}</Link>;
    }
    return text;
  }, [step, showLabel, isCurrent, canEdit]);

  return (
    <li className={className} onClick={onClickLabel}>
      {label}
    </li>
  );
};
