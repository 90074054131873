export var CashieringRoute;

(function (CashieringRoute) {
  CashieringRoute["Withdraw"] = "withdraw";
  CashieringRoute["Deposit"] = "deposit";
  CashieringRoute["Accounts"] = "accounts";
  CashieringRoute["OAuthPlaidRedirect"] = "oauth";
})(CashieringRoute || (CashieringRoute = {}));

export var CashieringView;

(function (CashieringView) {
  CashieringView["Authorize"] = "authorize";
  CashieringView["Amount"] = "amount";
  CashieringView["Type"] = "type";
  CashieringView["Review"] = "review";
  CashieringView["Manage"] = "manage";
  CashieringView["OAuthPlaidRedirect"] = "oauth";
})(CashieringView || (CashieringView = {}));

export var CashieringManageAction;

(function (CashieringManageAction) {
  CashieringManageAction["Add"] = "add";
  CashieringManageAction["AddOptions"] = "add-options";
  CashieringManageAction["Verify"] = "verify";
  CashieringManageAction["Rename"] = "rename";
  CashieringManageAction["Delete"] = "delete";
  CashieringManageAction["Close"] = "close";
  CashieringManageAction["Upload"] = "upload";
})(CashieringManageAction || (CashieringManageAction = {}));

export var WireType;

(function (WireType) {
  WireType["Domestic"] = "domestic";
  WireType["International"] = "international";
})(WireType || (WireType = {}));

export var WireAccountType;

(function (WireAccountType) {
  WireAccountType["SelfOwned"] = "self-owned";
  WireAccountType["ThirdParty"] = "third-party";
})(WireAccountType || (WireAccountType = {}));

export var TransferBankIdentifierType;

(function (TransferBankIdentifierType) {
  TransferBankIdentifierType["ABA"] = "ABA";
  TransferBankIdentifierType["BIC"] = "BIC";
})(TransferBankIdentifierType || (TransferBankIdentifierType = {}));

export var TransferPendingStates = ['PendingRepCreateApproval', 'PendingFirmCreateApproval'];
export var TransferStates = [].concat(TransferPendingStates, ['Rejected', 'Canceled', 'Approved', 'FundsHeld', 'FundsPosted', 'SentToBank', 'Complete', 'Void', 'Returned', 'StopPayment', 'Pending', 'PendingPrinting']); // All transfer states that do not impact the current day ACH limit

export var TransferStatesNotAffectingAchLimit = ['Rejected', 'CreateRejected', 'Canceled', 'Void', 'Returned', 'StopPayment', 'FailedAtBank'];
export var TransferRecipientBankState;

(function (TransferRecipientBankState) {
  TransferRecipientBankState["Pending"] = "Pending";
  TransferRecipientBankState["PendingFirmApproval"] = "PendingFirmApproval";
  TransferRecipientBankState["Approved"] = "Approved";
  TransferRecipientBankState["Rejected"] = "Rejected";
})(TransferRecipientBankState || (TransferRecipientBankState = {}));

export var AchRelationshipPendingStates = ['PendingCreateApproval', 'PendingCancelApproval'];
export var CashieringPendingStates = [].concat(AchRelationshipPendingStates, TransferPendingStates);
export var BankRiskScoreValues;

(function (BankRiskScoreValues) {
  BankRiskScoreValues["LowRisk"] = "LowRisk";
  BankRiskScoreValues["MediumRisk"] = "MediumRisk";
  BankRiskScoreValues["HighRisk"] = "HighRisk";
})(BankRiskScoreValues || (BankRiskScoreValues = {}));

export var CashieringSearchEntityType;

(function (CashieringSearchEntityType) {
  CashieringSearchEntityType["Transfer"] = "Transfer";
  CashieringSearchEntityType["AchRelationship"] = "AchRelationship";
  CashieringSearchEntityType["TransferRecipientBank"] = "TransferRecipientBank";
  CashieringSearchEntityType["TransferWireInstruction"] = "TransferWireInstruction";
})(CashieringSearchEntityType || (CashieringSearchEntityType = {}));

export var CashieringDocumentType;

(function (CashieringDocumentType) {
  CashieringDocumentType["BankStatement"] = "BankStatement";
  CashieringDocumentType["VoidedCheck"] = "VoidedCheck";
})(CashieringDocumentType || (CashieringDocumentType = {}));

export var AllCashieringDocumentTypes = [CashieringDocumentType.BankStatement, CashieringDocumentType.VoidedCheck];
export var AccountStatuses = ['Prospect', 'ProspectApplicationStarted', 'ProspectApplicationCompleted', 'ProspectApplicationExpired', 'Processing', 'ProcessingItemsMissing', 'ActiveNotFunded', 'Active', 'Closed', 'ClosedNeverFunded', 'Rejected', 'Deceased', 'ActiveClosingTradesOnly'];