import { useStorageContext, buildStore } from '..';
export var getHttpStore = function getHttpStore(apiUrl, authToken) {
  return buildStore({
    kind: 'http',
    url: apiUrl,
    authToken: authToken
  });
};
export var useHttpStore = function useHttpStore(apiUrl, authToken) {
  var store = useStorageContext({
    kind: 'http',
    url: apiUrl,
    authToken: authToken
  });
  return store;
};