import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Section, Text } from '@tradingblock/components';
import { PageTitle } from '../components';
import { ApplicationLoader } from '../components/application';
import { FieldValue } from '../components/values';
import { UnsupportedFeatureType } from '../types';
import { useData } from '../state/selectors';

const UnsupportedFeaturePage: React.FC<RouteComponentProps<{ type: UnsupportedFeatureType }>> = ({ match }) => {
  const { type } = match.params;
  const application = useData(d => d.data.application);

  const textData = useMemo(() => {
    if (type === UnsupportedFeatureType.Country) {
      return { country: application && <FieldValue value={application.ownerCountry} textPrefix="defaults.country" /> };
    }
    if (type === UnsupportedFeatureType.IrsWithholding) {
      return {
        irsWithholding: application && <FieldValue value={application.primaryAccountHolder.disclosures.irsWithholding} textPrefix="defaults.irsWithholding" />,
      };
    }
    return undefined;
  }, [type, application]);

  return (
    <ApplicationLoader>
      <PageTitle page="unsupported" titleKey={type} />
      <Section>
        <div className="step-title">
          <h1>
            <Text id={type} page="unsupported" />
          </h1>
        </div>
      </Section>
      <Section>
        <h2 className="fields-title">
          <Text id={type} page="unsupported" />
        </h2>
        <p>
          <Text id={type} page="unsupported" textKey="description" data={textData} />
        </p>
      </Section>
    </ApplicationLoader>
  );
};

export default UnsupportedFeaturePage;
