import React, { useMemo, useEffect } from 'react';
import { Route, Switch, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { setPageTitle } from '@tradingblock/components';
import HomePage from './pages/Home';
import AuthenticationPage from './pages/Auth';
import SummaryPage from './pages/Summary';
import ApplyPage from './pages/Apply';
import ThankYouPage from './pages/ThankYou';
import OfflinePage from './pages/Offline';
import CustomerServicePage from './pages/CustomerService';
import UnsupportedFeaturePage from './pages/Unsupported';
import PendingPage from './pages/Pending';
import LogoutPage from './pages/Logout';
import StatusPage from './pages/Status';
import { useSiteGroup } from './hooks/useSiteGroup';
import { useClearer } from './hooks/useClearer';
import ExpiredTokenPage from './pages/ExpiredToken';
import { useAffiliate } from './hooks/useAffiliate';

export const ApplicationRouter: React.FC = () => {
  useSiteGroup();
  useClearer();
  useAffiliate();
  return (
    <>
      <Route component={ResetRoute} />
      <Switch>
        <Route path="/home" exact component={HomePage} />
        <Route path="/auth" exact component={AuthenticationPage} />
        <Route path="/apply/customer-service" exact component={CustomerServicePage} />
        <Route path="/apply/offline" exact component={OfflinePage} />
        <Route path="/apply/unsupported/:type" exact component={UnsupportedFeaturePage} />
        <Route path="/apply/summary" exact component={SummaryPage} />
        <Route path="/apply/:step" exact component={ApplyPage} />
        <Route path="/status" exact component={StatusPage} />
        <Route path="/pending" exact component={PendingPage} />
        <Route path="/thanks" exact component={ThankYouPage} />
        <Route path="/logout" exact component={LogoutPage} />
        <Route path="/expired" exact component={ExpiredTokenPage} />
        <Redirect to="/home" />
      </Switch>
    </>
  );
};

const ResetRouteComponent: React.FC<RouteComponentProps> = ({ location }) => {
  const shouldScrollToTop = useMemo(() => {
    if (location.state && _.isBoolean(location.state.scrollToTop)) {
      return location.state.scrollToTop;
    }
    return true;
  }, [location]);

  useEffect(() => {
    setPageTitle('');
    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location, shouldScrollToTop]);

  return null;
};

const ResetRoute = withRouter(ResetRouteComponent);
