export const TotalSecurityChallenges = 3;

export const MaxNumberOfPhones = 5;

// source: https://emailregex.com
// eslint-disable-next-line
export const RE_EMAIL = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

// PO Box addresses not allowed for home address
export const RE_PO_BOX = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

// social security number regex (with or without dashes: 123-45-6789 or 123456789)
export const RE_SSN = /^(\d{3}\s*\d{2}\s*\d{4}|\d{3}\s*-\s*\d{2}\s*-\s*\d{4})$/;

// employer identification number (with or without dashes)
export const RE_EIN = /^(\d{2}\s*\d{7}|\d{2}\s*-\s*\d{7})$/;

export const RE_VALID_FIRST_NAME = /^(\s*)[a-zA-Z-]+(\s*)$/;

export const RE_VALID_LAST_NAME = /^(\s*)[a-zA-Z-]+( [a-zA-Z-]+)?(\s*)$/;

export const RE_VALID_FIRST_NAME_WITH_NUMBERS = /^(\s*)[a-zA-Z0-9-]+(\s*)$/;

export const RE_VALID_LAST_NAME_WITH_NUMBERS = /^(\s*)[a-zA-Z0-9-]+( [a-zA-Z0-9-]+)?(\s*)$/;

export const RE_VALID_MIDDLE_INITIAL = /^(\s*)[a-zA-Z]?(\s*)$/;

// take all letters and '.' and '-'
export const RE_VALID_SUFFIX = /^(\s*)[a-zA-Z.-]+(\s*)$/;

export const SiteIds = {
  mb: 'mb',
  tb: 'tb',
};
export const AllSiteIds = Object.values(SiteIds);

const prefixes = [
  'Mr',
  'Mrs',
  'Ms',
  'Dr',
  'Prof',
  'Capt',
  'Col',
  'Maj',
  'Gen',
  'Lt',
  'Sir',
  'Lady',
  'Lord',
  'Dame',
  'Rev',
  'Fr',
  'Rabbi',
  'Imam',
  'Eng',
  'Arch',
];

export const RE_PREFIX_PATTERN = new RegExp(`^(${prefixes.join('|')})\\.?`, 'i');
