import { createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import _ from 'lodash';
import { RootReducer } from './reducer';
import { config } from '../config';
import { setupMiddleware } from './middleware';

const composeEnhancers = config.isProduction
  ? compose
  : composeWithDevTools({
      predicate: (state, action) => _.get(action, 'meta.verbose', false) !== true,
    });

// Setup the middleware instance.
const { middleware } = setupMiddleware();
export const DataStore = createStore(RootReducer, composeEnhancers(middleware));
