import { useStore } from '../context/StorageContext';
import { Dispatch } from 'redux';
import { RootApplicationAction, TokenActions } from '../state/actions';
export const applicationApiClient = (storage: ReturnType<typeof useStore>, dispatch: Dispatch<RootApplicationAction>) => {
  return {
    get: <T = any>(key: string): Promise<T | undefined> => {
      return storage.get<T>(key).catch(err => {
        if (err && err.response) {
          const response = err.response;
          const status = response.status;
          if (status === 403) {
            dispatch(TokenActions.uiTokenExpired());
          }
          return undefined;
        }
      });
    },
    save: <T = any>(key: string, value: T): Promise<T | undefined> => {
      return storage.save<T>(key, value).catch(err => {
        if (err && err.response) {
          const response = err.response;
          const status = response.status;
          if (status === 403) {
            dispatch(TokenActions.uiTokenExpired());
          }
          return undefined;
        }
      });
    },
  };
};
export type ApplicationApiClient = ReturnType<typeof applicationApiClient>;
