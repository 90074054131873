import { ApiFactory } from '@tradingblock/api';
import { useMemo } from 'react';
import { config } from '../config';
import { useData } from '../state/selectors';

export const buildApiClient = (url?: string, token?: string) => ApiFactory(url || config.tradingApi || '', token || '');

export const useApi = (): ReturnType<typeof ApiFactory> => {
  const authToken = useData(d => d.ui.apiToken);
  const url = config.tradingApi;
  const api = useMemo(() => buildApiClient(url, authToken), [url, authToken]);
  return api;
};
