import React, { useEffect, useState, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useLocalStorage, ExpireTokenInStorage, TokenFromStorage } from '@tradingblock/storage';
import { Section, LocalizeLoading as Loading } from '@tradingblock/components';
import { ApplicationStep, AllApplicationSteps } from '../../types';
import { useApplication, useStore, useApplicationStore } from '../../context';
import { getApplicationStepUrl, validateToken } from '../../services';
import { tryGetApplication, applicationApiClient } from '../../api';
import { useData } from '../../state/selectors';
import { UiActions, ApplicationActions } from '../../state/actions';
import { useDispatcher } from '../hooks';
import config from '../../config';
import { useLogout } from '../../hooks/useLogout';

interface ApplicationLoaderProps {
  logout?: boolean;
  allowAnonymous?: boolean;
  empty?: boolean;
  defaultRedirect?: string;
}

export const ApplicationLoader: React.FC<ApplicationLoaderProps> = ({
  logout,
  allowAnonymous,
  empty,
  defaultRedirect,
  children,
}) => {
  const mounted = useRef<boolean>();
  const store = useApplicationStore();

  const localStorage = useLocalStorage();
  const dispatch = useDispatcher();
  const [{ isFetching }, { setIsFetching }] = useApplication();
  const authToken = useData(d => d.ui.apiToken);
  const application = useData(d => d.data.application);
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;
  const step = useData(d => d.ui.step);
  const userRequirements = useData(d => d.ui.userRequirements);
  const heardAbout = useData(d => d.ui.heardAbout);
  const isFetchingUiData = useData(d => d.ui.isFetching);
  const nextPage = useData(d => d.ui.nextPage);

  const onLogout = useLogout();
  const [isRehydrating, setIsRehydrating] = useState<boolean | undefined>();

  const isApplicationLoading = useMemo(() => {
    const urlMatch = /^\/apply\/(\w+)$/.exec(window.location.pathname);
    if (urlMatch) {
      // handle first step different, since token might not be present
      if (urlMatch[1] === ApplicationStep.SecureAccount) {
        return false;
      }
      if (_.includes(AllApplicationSteps, urlMatch[1])) {
        return !step || (authToken && !application);
      }
      if (urlMatch[1] === 'summary') {
        return !application;
      }
    }
    return false;
  }, [step, authToken, application]);
  useEffect(() => {
    if (!userRequirements || !heardAbout) {
      dispatch(ApplicationActions.requestUiData({ siteConfigId: siteGrp === 'mb' ? 4 : 2 }));
    }
  }, [userRequirements, heardAbout, dispatch, siteGrp]);

  const sendToNextStep = useMemo(() => {
    let nextStep: ApplicationStep | 'summary' | 'home' | undefined = undefined;
    if (!authToken) {
      if (logout) {
        nextStep = 'home';
      } else if (!allowAnonymous) {
        nextStep = ApplicationStep.SecureAccount;
      }
    }
    if (nextStep && nextStep !== step && isRehydrating === false && isFetching === false) {
      // console.warn('sendToNextStep :: ', { nextStep, step, allowAnonymous });
      return nextStep;
    }
    return undefined;
  }, [authToken, isRehydrating, isFetching, logout, allowAnonymous, step]);

  const sendToNextUrl = useMemo(() => {
    if (sendToNextStep) {
      if (defaultRedirect) {
        return defaultRedirect;
      } else if (sendToNextStep === 'home') {
        return '/';
      } else {
        // console.warn('sending to nextStep url... ', sendToNextStep);
        return getApplicationStepUrl(sendToNextStep);
      }
    }
    return undefined;
  }, [sendToNextStep, defaultRedirect]);

  // rehydrate auth token from localStorage
  useEffect(() => {
    if (authToken) {
      if (logout) {
        onLogout();
      } else {
        setIsRehydrating(false);
      }
    } else if (isRehydrating === undefined) {
      setIsRehydrating(true);

      TokenFromStorage(localStorage)
        .then(async token => await validateToken(token || ''))
        .then(tokenRes => {
          // console.warn('ApplicationLoader :: fetched valid token from localstorage ', tokenRes);
          if (mounted.current) {
            if (tokenRes) {
              dispatch(UiActions.setAuthToken({ apiToken: tokenRes.decoded.token, storageToken: tokenRes.value }));
            }
          } else {
            console.warn('fetched authToken but ApplicationLoader is no longer mounted!');
          }
          setIsRehydrating(false);
        })
        .catch(() => {
          console.warn('failed to fetch authToken from storage!');
          setIsRehydrating(false);
        });
    }
  }, [authToken, isRehydrating, logout, onLogout, localStorage, dispatch]);

  // try to load application if needed
  useEffect(() => {
    const loadApplication = async () => {
      const res = await tryGetApplication(store);
      if (!mounted.current) {
        console.warn('loaded application but ApplicationLoader is no longer mounted!');
        return;
      }

      if (!_.isUndefined(res)) {
        const data = res || {};
        dispatch(ApplicationActions.receiveSaveApplication({ saveType: 'load', application: data }));
      }
      setIsFetching(false);
    };
    if (_.isUndefined(isFetching) && !application && isRehydrating === false && !sendToNextStep) {
      if (authToken && store && !config.isVirtual) {
        setIsFetching(true);
        loadApplication();
      } else {
        // if not signed in or on virtual app, don't fetch application
        setIsFetching(false);
      }
    }
  }, [isFetching, application, isRehydrating, authToken, sendToNextStep, store, setIsFetching, dispatch]);

  useEffect(() => {
    // send to next page if needed
    if (sendToNextUrl && !nextPage) {
      dispatch(UiActions.setNextPage(sendToNextUrl));
    }
  }, [sendToNextUrl, nextPage, dispatch]);

  useEffect(() => {
    mounted.current = true;
    // TODO: review the use of this effect, was limiting partial application's ability to load
    // return () => {
    //   // keep track of mounted state of component
    //   mounted.current = false;
    // };
  }, []);

  if (empty || (!authToken && !allowAnonymous)) {
    return null;
  }

  if (isFetching || isRehydrating || isApplicationLoading || nextPage) {
    return (
      <Section>
        <Loading wrapper="h2" />
      </Section>
    );
  }

  return <>{children}</>;
};
