import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect, useCallback } from 'react';
import constate from 'constate';
export var useWindowSizeContext = constate(function () {
  var _useState = useState({
    width: window ? window.innerWidth : Infinity,
    height: window ? window.innerHeight : Infinity
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var set = useCallback(function (event) {
    return setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);
  useEffect(function () {
    window.addEventListener('resize', set);
    return function () {
      return window.removeEventListener('resize', set);
    };
  }, [set]);
  return state;
});
export var useParentSizeContext = constate(function () {
  var _useState3 = useState({
    width: window ? window.parent.innerWidth : Infinity,
    height: window ? window.parent.innerHeight : Infinity
  }),
      _useState4 = _slicedToArray(_useState3, 2),
      state = _useState4[0],
      setState = _useState4[1];

  var set = useCallback(function (event) {
    return setState({
      width: window.parent.innerWidth,
      height: window.parent.innerHeight
    });
  }, []);
  useEffect(function () {
    window.addEventListener('resize', set);
    return function () {
      return window.removeEventListener('resize', set);
    };
  }, [set]);
  return state;
});