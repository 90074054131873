import React from 'react';
import _ from 'lodash';
import { AgreementKey, SummaryStepProps } from '../../../../types';
import { FieldValue } from '../../../values';
import { getAllAgreementKeysForApplication } from '../../../../services';

export const AccountAgreementsSummary: React.FC<SummaryStepProps> = ({ application, getText }) => {
  if (!application) {
    return null;
  }
  return (
    <>
      <h3 className="fields-title">{getText('agreements')}</h3>
      {_.map(getAllAgreementKeysForApplication(application), (a: AgreementKey, i) => {
        return (
          <div key={`agreement${i}`}>
            <p>{getText(`agreements.${a}`)}</p>
            <p>
              <strong>
                <FieldValue value={application.agreements && application.agreements[a]} textPrefix="defaults.agreements" />
              </strong>
            </p>
          </div>
        );
      })}
    </>
  );
};
