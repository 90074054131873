import dayjs from 'dayjs';
import { CreateUserUrl, CreateAccountUrl, UserRequirementsUrl, DocumentsUploadUrl, GetHeardAboutUrl, GetApplicationInvestigationStatusUrl, UploadApplicationDocumentUrl, UploadInvestigationDocumentUrl, DuplicateAccountCheckUrl } from '../constants';
import { DateFormats } from '..';
import { RequestHeaders } from './utilities';
export function Application(api) {
  return {
    getUserRequirements: function getUserRequirements() {
      return api.get(UserRequirementsUrl, undefined, RequestHeaders);
    },
    getHeardAbout: function getHeardAbout(siteConfigId) {
      return api.get(GetHeardAboutUrl(siteConfigId), {}, RequestHeaders);
    },
    getApplicationStatus: function getApplicationStatus(accountId) {
      return api.authenticated().get(GetApplicationInvestigationStatusUrl(accountId), undefined, RequestHeaders);
    },
    createUser: function createUser(data) {
      return api.post(CreateUserUrl, data, RequestHeaders);
    },
    uploadDocument: function uploadDocument(file, tag) {
      var TakenOn = dayjs(file.lastModified).utc().format(DateFormats.DateTimeApiFormat);
      var data = new FormData();
      data.append('metaData', JSON.stringify({
        tag: tag,
        ImageName: file.name,
        TakenOn: TakenOn
      }));
      data.append('file', file); // NOTE: when posting multipart/form-data, not passing Accept: 'application/json' header (see: https://stackoverflow.com/a/35206069)

      return api.authenticated().post(DocumentsUploadUrl, data).then(function (res) {
        return Object.assign({}, res, {
          payload: {
            id: res.payload,
            filename: file.name
          }
        });
      });
    },
    uploadApplicationDocument: function uploadApplicationDocument(file, accountId, accountHolderId, documentType) {
      var TakenOn = dayjs(file.lastModified).utc().format(DateFormats.DateTimeApiFormat);
      var uploadFormData = new FormData();
      uploadFormData.append('metaData', JSON.stringify({
        DocumentName: documentType,
        ImageName: file.name,
        TakenOn: TakenOn
      }));
      uploadFormData.append('file', file);
      return api.authenticated().post(UploadApplicationDocumentUrl(accountId, accountHolderId), uploadFormData);
    },
    uploadInvestigationDocument: function uploadInvestigationDocument(file, accountId, investigationId, documentType) {
      var TakenOn = dayjs(file.lastModified).utc().format(DateFormats.DateTimeApiFormat);
      var uploadFormData = new FormData();
      uploadFormData.append('metaData', JSON.stringify({
        DocumentName: documentType,
        ImageName: file.name,
        TakenOn: TakenOn
      }));
      uploadFormData.append('file', file);
      return api.authenticated().post(UploadInvestigationDocumentUrl(accountId, investigationId), uploadFormData);
    },
    createAccount: function createAccount(data) {
      return api.authenticated().post(CreateAccountUrl, data, RequestHeaders);
    },
    duplicateAccountCheck: function duplicateAccountCheck(data) {
      return api.authenticated().post(DuplicateAccountCheckUrl, data, RequestHeaders);
    }
  };
}