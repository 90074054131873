import React from 'react';
import { Country } from '@tradingblock/types';
import { Text } from '@tradingblock/components';
import { SummaryStepProps, EntityAccountType } from '../../../../types';
import { AddressValue, FieldValue, DateValue } from '../../../values';
import { StateValue } from '../../../values/State';

export const EntitySummary: React.FC<SummaryStepProps> = ({ application, getText, activeLanguage }) => {
  const entity = application && application.entity;
  if (!application || !entity) {
    return null;
  }
  const entityTypeTextPrefix = application.entityAccountType === EntityAccountType.PersonalTrust ? 'trust' : 'defaults';
  return (
    <>
      <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
        {getText(`entity.${entityTypeTextPrefix}`)}
      </h3>

      <p>
        <FieldValue value={entity.entityName}>
          <strong>{entity.entityName}</strong>
        </FieldValue>
        <br />
        <Text id={application.entityAccountType === EntityAccountType.PersonalTrust ? 'entity.trustTaxId' : 'entity.entityTaxId'} type="field" />: <FieldValue value={entity.entityTaxId} />
        <br />
        {application.entityAccountType === EntityAccountType.PersonalTrust && (
          <>
            <Text id="entity.trustCreatedDate" type="field" />: <DateValue value={entity.trustCreatedDate} activeLanguage={activeLanguage} />
          </>
        )}
        {application.entityAccountType !== EntityAccountType.PersonalTrust && (
          <>
            <Text id="entity.entityYourTitle" type="field" />: <FieldValue value={entity.entityYourTitle} />
            <br />
            {application.ownerCountry === Country.UnitedStatesOfAmerica && (
              <>
                <Text id="entity.usStateOfRegistration" type="field" />: <StateValue value={entity.usStateOfRegistration} country={application.ownerCountry} />
                <br />
              </>
            )}
            <Text id="entity.businessNature" type="field" />: <FieldValue value={entity.businessNature} textPrefix="entity.businessNature" />
          </>
        )}
      </p>

      <p>
        <strong>{getText('entity', `${entityTypeTextPrefix}.address.text`)}</strong>
        <br />
        <AddressValue value={application.entity && application.entity.address} />
      </p>

      {application.entityAccountType !== EntityAccountType.PersonalTrust && (
        <p>
          <strong>{getText('entity.businessPhone')}</strong>
          <br />
          <FieldValue value={application.entity && application.entity.businessPhone} />
        </p>
      )}
    </>
  );
};
