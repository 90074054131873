import _ from 'lodash';
import dayjs from 'dayjs';
import { isFieldValueFilled } from '@tradingblock/components';
import {
  AllExperienceKeys,
  ApplicationStep,
  BooleanToggleType,
  ApplicationType,
  FlexibilityType,
  DateModel,
  PhoneModel,
  ApplicationModel,
  TradingBlockAccountModel,
  EntityAccountType,
  RetirementAccountType,
  AllTradeGoalTypes,
  TradeGoalType,
  UploadKey,
  VisaType,
  AllExperienceYearTypes,
  ExperienceYearType,
  JointAccountType,
  ClearerType,
} from '../types';
import { isPreviouslyTouchedApplicationStep } from '.';
import { Country, TradeAuthorizationType } from '@tradingblock/types';

export const isDateFilled = (value: DateModel | null | undefined) => {
  return (
    !!value && (isFieldValueFilled(value.month) || isFieldValueFilled(value.day) || isFieldValueFilled(value.year))
  );
};

export const isPhoneFilled = (value: PhoneModel | null | undefined) => {
  return !!value && (isFieldValueFilled(value.type) || isFieldValueFilled(value.phoneNumber));
};

export const isAccountFilled = (value: TradingBlockAccountModel | null | undefined) => {
  return !!value && (isFieldValueFilled(value.accountNumber) || isFieldValueFilled(value.accountName));
};

export const isNonZeroYear = (value: string | undefined) => {
  if (value && _.includes(AllExperienceYearTypes, value)) {
    return value !== ExperienceYearType.Zero;
  }
  return !!value && _.parseInt(value) > 0;
};

export const getDatePartOptions = (part: keyof DateModel, forPastDates?: boolean) => {
  const min = part === 'year' ? (forPastDates ? 1900 : dayjs().year()) : 1;
  const max = part === 'year' ? (forPastDates ? dayjs().year() : 2030) : part === 'day' ? 31 : 12;
  const options = _.map(_.range(min, max + 1), v => v);
  if (part === 'year' && forPastDates) {
    return _.reverse(options);
  }
  return options;
};

export const getNumberOfApplicationInformationEntitySections = (application: ApplicationModel | undefined) => {
  if (application && application.type === ApplicationType.Entity) {
    return application.entityAccountType !== EntityAccountType.PersonalTrust ? 2 : 1;
  }
  return 0;
};

export const getNumberOfAgreementDisclosureSections = (application: ApplicationModel | undefined) => {
  return application && (application.type === ApplicationType.Joint || application.type === ApplicationType.UGMA)
    ? 2
    : 1;
};

export const getNumberOfSectionsInApplicationStep = (
  step: ApplicationStep,
  application: ApplicationModel | undefined
) => {
  switch (step) {
    case ApplicationStep.SecureAccount:
    case ApplicationStep.InvestingProfile:
      return 2;
    case ApplicationStep.AccountInformation:
      const entitySections = getNumberOfApplicationInformationEntitySections(application);
      const jointOwnerSections = application && application.type === ApplicationType.Joint ? 2 : 0;
      const ugmaOwnerSections = application && application.type === ApplicationType.UGMA ? 2 : 0;
      return 2 + entitySections + jointOwnerSections + ugmaOwnerSections;
    case ApplicationStep.Agreements:
      const jointOwnerSection = application && application.type === ApplicationType.Joint ? 1 : 0;
      return 3 + jointOwnerSection;
    default:
      return 1;
  }
};

export const getVisibleSectionsForApplicationStep = (latestStep: ApplicationStep, application: ApplicationModel) => {
  // if step completed, use total number of sections
  if (isPreviouslyTouchedApplicationStep(latestStep, application.latestStepCompleted)) {
    return getNumberOfSectionsInApplicationStep(latestStep, application);
  }
  // if step is last saved, use last number of visible sections
  if (application.latestStepSaved === latestStep) {
    return application.latestStepVisibleSections;
  }
  return undefined;
};

export const getDateFromModel = (date: DateModel | undefined) => {
  if (date && date.day && date.month && date.year) {
    return dayjs(
      `${date.year}-${_.toNumber(date.month) < 10 ? '0' : ''}${date.month}-${_.toNumber(date.day) < 10 ? '0' : ''}${
        date.day
      }`
    );
  }
  return undefined;
};

export const getAllExperienceKeysForApplication = (application: ApplicationModel | undefined) => {
  return _.filter(
    AllExperienceKeys,
    exp => exp !== 'margins' || !application || application.flexibilityType !== FlexibilityType.CashOnly
  );
};

export const getAllTradeGoalTypesForApplication = (application: ApplicationModel | undefined) => {
  return _.filter(
    AllTradeGoalTypes,
    t =>
    application !== undefined && 
    (
      ( 
        application.retirementAccount === BooleanToggleType.Yes &&
        t !== TradeGoalType.AllAbovePlusAllOptionStrategies
      ) ||
      (
        application.type === ApplicationType.UGMA && 
        t !== TradeGoalType.AllAbovePlusAllOptionStrategies &&
        t !== TradeGoalType.AllAbovePlusPutWriting
      ) || (
        application.type !== ApplicationType.UGMA &&
        application.retirementAccount !== BooleanToggleType.Yes
      )
    )
  );
};

export const getAllAgreementKeysForApplication = (
  application: ApplicationModel | undefined,
  needsApiAgreement?: boolean
) => {
  const isIra = application && application.retirementAccount === BooleanToggleType.Yes;
  const isEntity = application && application.type === ApplicationType.Entity;
  const isMargin = application && application.flexibilityType === FlexibilityType.Margin;
  // only first trade goal (stocks/ETFs/mutual funds/bonds) doesn't include options
  const isOptionsInTradeGoals =
    application && application.tradeGoalType && application.tradeGoalType !== TradeGoalType.StocksBonds;

  const allAgreementKeys: string[] = [
    'clientRelationshipSummary',
    'electronicConsent',
    'onlineServices',
    'exchangeDataAgreement',
  ];

  if (application && application.clearer === ClearerType.Apex) {
    allAgreementKeys.push('customerAndPrivacyPolicy');
    if (application.tradingAuthorization) {
      // if trading authorization type is set to full, then we need to show the full agreement
      if (application.tradingAuthorization.type === TradeAuthorizationType.Full) {
        allAgreementKeys.push('fullTradingAuthorization');
      }
      // if trading authorization type is set to limited or simple, then we need to show the limited agreement
      if (application.tradingAuthorization.type === TradeAuthorizationType.Limited) {
        allAgreementKeys.push('limitedTradingAuthorization');
      }
    }
  } else if (application && application.clearer === ClearerType.RQD) {
    allAgreementKeys.push('rqdCustomerAgreement');
    allAgreementKeys.push('rqdExecutionDisclosures');
    allAgreementKeys.push('rqdClearingDisclosures');
    allAgreementKeys.push('rqdCustomerInformationBrochure');
    allAgreementKeys.push('rqdPrivacyPolicy');
    allAgreementKeys.push('rqdPrivacyNotice');
    allAgreementKeys.push('rqdResponsibilitiesOfIbAndCarryingBroker');
    allAgreementKeys.push('rqdSpecialStatementForUncoveredOptionWriters');

    if (application && application.tradingAuthorization) {
      if (application.tradingAuthorization.type === TradeAuthorizationType.Full) {
        allAgreementKeys.push('rqdPowerOfAttorney');
      }
      if (application.tradingAuthorization.type === TradeAuthorizationType.Limited) {
        allAgreementKeys.push('rqdLimitedTradingAuthorization');
      }
    }
  }

  if (application && isIra) {
    if (application.clearer === ClearerType.RQD) {
      allAgreementKeys.push('rqdIRADisclosure');
    } else {
      if (application.retirementAccountType === RetirementAccountType.Roth) {
        allAgreementKeys.push('iRARothAdoption');
      } else if (application.retirementAccountType === RetirementAccountType.Simple) {
        allAgreementKeys.push('iRASimpleAdoption');
      } else if (application.retirementAccountType === RetirementAccountType.CoverDell) {
        allAgreementKeys.push('iRACoverDellAdoption');
      } else if (application.retirementAccountType === RetirementAccountType.RothBeneficiary) {
        allAgreementKeys.push('iRARothAdoption');
      } else {
        // SEP agreement applies to all other IRA types
        allAgreementKeys.push('iRASepAdoption');
      }
    }
  }
  if (isOptionsInTradeGoals) {
    if (application && application.clearer === ClearerType.Apex) {
      allAgreementKeys.push('option');
    } else if (application && application.clearer === ClearerType.RQD) {
      allAgreementKeys.push('rqdOption');
    }
    allAgreementKeys.push('riskDisclosure');
  }
  if (isMargin) {
    if (application && application.clearer === ClearerType.Apex) {
      if (isIra) {
        allAgreementKeys.push('limitedMargin');
      } else {
        allAgreementKeys.push('margin');
      }
    } else if (application && application.clearer === ClearerType.RQD) {
      if (isIra) {
        allAgreementKeys.push('rqdLimitedMargin');
      } else {
        allAgreementKeys.push('rqdMargin');
      }
    }
  }
  if (isEntity) {
    if (isOptionsInTradeGoals) {
      allAgreementKeys.push('entityOption');
    }
    if (isMargin) {
      allAgreementKeys.push('entityMargin');
    }
  }
  if (
    application &&
    isEntity &&
    _.includes([EntityAccountType.LlcCCorporation, EntityAccountType.LlcSCorporation], application.entityAccountType)
  ) {
    allAgreementKeys.push('llcAccount');
  }
  if (
    application &&
    isEntity &&
    _.includes([EntityAccountType.CCorporation, EntityAccountType.SCorporation], application.entityAccountType)
  ) {
    if (isMargin) {
      allAgreementKeys.push('corporateMargin');
    } else {
      allAgreementKeys.push('corporateCash');
    }
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'Community') {
    allAgreementKeys.push('jointCommunity');
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'Survivor') {
    allAgreementKeys.push('jointSurvivor');
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'TenantsInCommon') {
    allAgreementKeys.push('jointTenantsInCommon');
  }
  if (
    application &&
    application.type === ApplicationType.Joint &&
    application.jointAccountType === 'TenantsInEntirety'
  ) {
    allAgreementKeys.push('jointTenantsInEntirety');
  }
  if (application && needsApiAgreement) {
    allAgreementKeys.push('apiAgreement');
  }

  // if the clearer is Apex, then move the customer and privacy policy to the second item in the array
  if (application && application.clearer === ClearerType.Apex) {
    const customerAndPrivacyPolicyIndex = allAgreementKeys.indexOf('customerAndPrivacyPolicy');
    if (customerAndPrivacyPolicyIndex > -1) {
      allAgreementKeys.splice(customerAndPrivacyPolicyIndex, 1);
      allAgreementKeys.splice(1, 0, 'customerAndPrivacyPolicy');
    }
  }

  return allAgreementKeys;
};

export const getAllUploadKeysForApplication = (application: ApplicationModel | undefined) => {
  const allUploadKeys: UploadKey[] = ['photoId', 'socialSecurity'];

  if (
    application &&
    application.primaryAccountHolder &&
    application.primaryAccountHolder.citizenshipCountry !== Country.UnitedStatesOfAmerica
  ) {
    allUploadKeys.push('visa');
    if (_.includes([VisaType.E1, VisaType.E2, VisaType.E3], application.primaryAccountHolder.visaType)) {
      // not sure if this will be needed...
      // allUploadKeys.push('proofOfEmployment');
    }
  }

  if (application && application.type === ApplicationType.Entity) {
    if (_.includes([EntityAccountType.CCorporation, EntityAccountType.SCorporation], application.entityAccountType)) {
      allUploadKeys.push('articlesOfIncorporation');
    } else if (
      _.includes([EntityAccountType.LlcCCorporation, EntityAccountType.LlcSCorporation], application.entityAccountType)
    ) {
      allUploadKeys.push('articlesOfOrganization');
    } else if (application.entityAccountType === EntityAccountType.Partnership) {
      allUploadKeys.push('partnershipAgreement');
    } else if (application.entityAccountType === EntityAccountType.PersonalTrust) {
      allUploadKeys.push('trusteeCertification');
    }
  }

  // return allUploadKeys;

  // this feature may or may not be needed in some cases... for now, if any uploads are needed, those are handled after application submitted
  return [];
};

export const showBeneficiariesFields = (application: ApplicationModel | undefined) => {
  // show beneficiaries for any IRA type account
  return application && application.retirementAccount === BooleanToggleType.Yes;
};

export const showOwnersOfficersFields = (application: ApplicationModel | undefined) => {
  // show owners/officers for non-Trust Entities
  return (
    application &&
    application.type === ApplicationType.Entity &&
    application.entityAccountType !== EntityAccountType.PersonalTrust
  );
};

export const showTrustedContactFields = (application: ApplicationModel | undefined) => {
  // show trusted contact if non-Entity type OR if Trust
  return (
    application &&
    (application.type !== ApplicationType.Entity || application.entityAccountType === EntityAccountType.PersonalTrust)
  );
};

export const showTenantsInCommonInterestPercentFields = (application: ApplicationModel | undefined) => {
  // show interest percent for tenants in common joint account
  return application && application.jointAccountType === JointAccountType.TenantsInCommon;
};
