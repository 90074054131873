export var CreateAccountUrl = 'Accounts/CreateAccount';
export var DocumentsUploadUrl = 'Documents/Uploads';
export var UserRequirementsUrl = 'User/GetRequirements';
export var GetHeardAboutUrl = function GetHeardAboutUrl(siteConfigId) {
  return siteConfigId ? "Accounts/GetHeardAbout?siteConfigId=".concat(siteConfigId) : 'Accounts/GetHeardAbout';
};
export var GetApplicationInvestigationStatusUrl = function GetApplicationInvestigationStatusUrl(accountId) {
  return "Investigations/".concat(accountId, "/Investigation");
};
export var UploadInvestigationDocumentUrl = function UploadInvestigationDocumentUrl(accountId, investigationId) {
  return "Documents/".concat(accountId, "/Uploads/Investigations/").concat(investigationId);
};
export var UploadApplicationDocumentUrl = function UploadApplicationDocumentUrl(accountId, accountHolderId) {
  return "Documents/".concat(accountId, "/Uploads/Application/").concat(accountHolderId);
};
export var UploadMiscellaneousDocumentsUrl = function UploadMiscellaneousDocumentsUrl(accountId) {
  return "Documents/".concat(accountId, "/Uploads/Miscellaneous");
};
export var GetMiscellaneousDocumentsUrl = function GetMiscellaneousDocumentsUrl(accountId, documentId) {
  return "Documents/".concat(accountId, "/Uploads/Miscellaneous/").concat(documentId);
};
export var GetMiscellaneousDocumentsMetadataUrl = function GetMiscellaneousDocumentsMetadataUrl(accountId, documentId) {
  return "Documents/".concat(accountId, "/Uploads/Miscellaneous/").concat(documentId, "/Metadata");
};
export var DuplicateAccountCheckUrl = 'Accounts/DuplicateAccountCheck';
export var UploadCashieringDocumentUrl = function UploadCashieringDocumentUrl(accountId, instructionId) {
  return "Documents/".concat(accountId, "/Uploads/TransferInstructions/Wires/").concat(instructionId);
};
export var GetTransferInstructionsDocumentsIdUrl = function GetTransferInstructionsDocumentsIdUrl(accountId, instructionId) {
  return "Documents/".concat(accountId, "/Uploads/TransferInstructions/Wires/").concat(instructionId, "/Documents");
};
export var GetTransferInstructionsDocumentUrl = function GetTransferInstructionsDocumentUrl(accountId, instructionId, documentId) {
  return "Documents/".concat(accountId, "/Uploads/TransferInstructions/Wires/").concat(instructionId, "/").concat(documentId);
};
export var GetTransferInstructionsDocumentMetadataUrl = function GetTransferInstructionsDocumentMetadataUrl(accountId, instructionId, documentId) {
  return "Documents/".concat(accountId, "/Uploads/TransferInstructions/Wires/").concat(instructionId, "/").concat(documentId, "/Metadata");
};
export var CreateUserUrl = 'User/CreateUser';
export var GetSymbolsUrl = 'Symbol/Search';
export var PlaceOrderUrl = 'Orders/PlaceOrder';
export var ChangeOrderUrl = 'Orders/ChangeOrder';
export var CancelOrderUrl = 'Orders/CancelOrder';
export var GetOrdersUrl = 'Orders/GetOrders';
export var PositionsUrl = 'Positions/GetPositions';
export var HistoryGetHistoryUrl = 'History/GetHistory';
export var HistoryGetHistoryBalances = 'History/GetBalanceHistory';
export var HistoryGetBalancesEndpoint = 'History/GetBalances'; //post function set:

export var TokenRequestUrl = 'Auth/token';
export var LogoutTokenUrl = 'Auth/LogoutToken';
export var ApprovePendingUserMarketDataEntitlement = function ApprovePendingUserMarketDataEntitlement(pendingId) {
  return "User/ApprovePendingUserMarketDataEntitlement/?pendingId=".concat(pendingId);
};
export var RejectPendingUserMarketDataEntitlement = function RejectPendingUserMarketDataEntitlement(pendingId) {
  return "User/RejectPendingUserMarketDataEntitlement/?pendingId=".concat(pendingId);
};
export var SubmitMktDataUserProfileReviewUrl = 'User/SubmitMktDataUserProfileReview'; //get function set:

export var GetBalancesUrl = 'Balances/GetBalances'; //?accountId={0}&subaccountId={1}

export var GetBalancesForAccountsURL = 'Balances/GetBalancesForAccounts'; //?items={[{accountId:0,subaccountId:1},{accountId:2,subaccountId:3}]}

export var GetBalancesForWithdrawalURL = function GetBalancesForWithdrawalURL(accountId) {
  return "Cashiering/".concat(accountId, "/Balances");
};
export var GetAvailableFundsUrl = 'Balances/GetAvailableFunds'; //?accountId={0}&subaccountId={1}

export var GetExpirationsUrl = 'Chains/GetExpirations'; //?underlyingSymbol={0}

export var GetAccountsUrl = 'Accounts/GetAccounts'; //?accountId={0}&subaccountId={1}

export var GetSubaccountsUrl = 'Accounts/GetSubaccounts'; //?accountId={0}&subaccountId={1}

export var GetAccountDetailsUrl = 'Accounts/GetAccountDetails'; //?accountId={0}

export var AccountsSearchUrl = 'Accounts/SearchAccounts';
export var GetAccountUpdateUrl = 'Accounts/GetAccountUpdate'; //?confirm={0}

export var SearchAccountUpdatesUrl = 'Accounts/SearchAccountUpdates'; //?states={0} && items && search && startDate && endDate && sortBy && sortByOrder && page && pagesize

export var GetAccountUpdateDetailsUrl = 'Accounts/GetAccountUpdateDetail';
export var GetAccountManagementDetailsUrl = 'Accounts/GetAccountManagementDetails';
export var GetPendingUserMarketDataEntitlementsUrl = 'User/GetPendingUserMarketDataEntitlements'; //?page={0}&pagesize={1}

export var GetPendingAccountManagementUpdatesUrl = '/Accounts/PendingAccountUpdates'; //?accountId={0}

export var GetDocumentStatusUrl = 'Accounts/GetDocumentStatus';
export var GetUserMarketDataEntitlementsUrl = 'User/GetUserMarketDataEntitlements'; // put function set:

export var ConfirmAccountUpdateUrl = 'Accounts/ConfirmAccountUpdate'; //?confirm={0}

export var UpdateAccountUrl = 'Accounts/UpdateAccount'; //?accountId={0}

export var FirmApproveAccountUpdateUrl = 'Accounts/FirmApproveAccountUpdate';
export var FirmRejectAccountUpdateUrl = 'Accounts/FirmRejectAccountUpdate'; //?underlyingSymbol={0}
// &rootSymbol={1}&expiration={2}&strikeMin={3}&strikeMax={4}&fetchGreeks=true/false
//{1} {2} ... {5} are optional

export var GetChainUrl = 'Chains/GetChain'; //?underlyingSymbol={0}&expiration={1}

export var GetStrikesUrl = 'Chains/GetStrikes'; //?symbol={0}&starttime={1}&endtime={2}&period={3}
//symbol, "01-01-2010", "03-29-2019", "Day"

export var GetBarsUrl = 'Quotes/GetBars'; //?symbols={0}
//sample {0}=FB,TSLA,AMZN,NVDA
//this returns a list of position lot

export var GetQuoteUrl = 'Quotes/GetQuotes';
export var GetQuoteExtendedUrl = 'Symbol/GetCompanyStats';
export var GetQuoteDividendUrl = "Symbol/GetDividends";
export var GetQuoteEarningsUrl = "Symbol/GetEarnings"; //?spreads={0}
//sample {0}=[{"Legs":[{"Symbol":".PANW 181019C205000","AssetType":2,"LegBid":0.0,"LegAsk":0.0},{"Symbol":".PANW 181019C207500","AssetType":2,"LegBid":0.0,"LegAsk":0.0}],"Bid":0.0,"Ask":0.0,"Last":0.0,"Change":0.0,"Type":2,"SpreadTime":"0001-01-01T00:00:00"}]

export var GetSpreadQuotesUrl = 'Quotes/GetSpreadQuotes'; //?accountId={0}&subaccountId={1}&dateFrom={2}&dateTo={3}&pageSize={4}&pageIndex={5}
// {1} {2} ... {5} are all optional

export var GetPnLUrl = 'History/GetPnL'; //?accountId={0}&subaccountId={1}
//this returns a list of position lot

export var GetPositionsUrl = 'Positions/GetPositions';
export var SymbolsWithAMPMExpirations = ['$SPX'];
export var GetReleaseNotesUrl = '/SystemNotices';
export var CreateReleaseNotesUrl = '/SystemNotices/Templates';
export var UpdateReleaseNotesUrl = function UpdateReleaseNotesUrl(id) {
  return "/SystemNotices/Templates/".concat(id);
};
export var GetNewsFeedUrl = 'Symbol/GetNews';
export var AlertMessagesURL = '/AlertMessages';
export var GetAlertMessageTemplatesURL = '/AlertMessages/Templates';
export var UpdateAlertsURL = function UpdateAlertsURL(id, active) {
  return "/AlertMessages/Templates/".concat(id);
}; // Password Reset Get Routes

export var GetSecurityChallengeUrl = 'User/GetSecurityChallenge';
export var MarketScheduleURL = '/MarketSchedule'; // Password Reset Post Routes

export var VerifySecurityChallengeUrl = 'User/VerifySecurityChallenge';
export var PasswordChangeInitiateUrl = 'User/PasswordChangeInitiate';
export var PasswordChangeFinalizeUrl = 'User/PasswordChangeFinalize'; //Downloads endpoints for statements

export var DownloadsDailyConfirms = function DownloadsDailyConfirms(accountId) {
  return "Downloads/".concat(accountId, "/DailyConfirms");
};
export var DownloadsDailyConfirm = function DownloadsDailyConfirm(accountId) {
  return "Downloads/".concat(accountId, "/DailyConfirm");
};
export var DownloadsMonthlyStatements = function DownloadsMonthlyStatements(accountId) {
  return "/Downloads/".concat(accountId, "/MonthlyStatements");
};
export var DownloadsMonthlyStatement = function DownloadsMonthlyStatement(accountId) {
  return "/Downloads/".concat(accountId, "/MonthlyStatement");
};
export var DownloadsTaxDocuments = function DownloadsTaxDocuments(accountId, form) {
  return "/Downloads/".concat(accountId, "/TaxDocuments/").concat(form);
};
export var DownloadsTaxDocument = function DownloadsTaxDocument(accountId, form) {
  return "/Downloads/".concat(accountId, "/TaxDocument/").concat(form);
}; // NoPass endpoints

export var NoPassEnabledURL = function NoPassEnabledURL(username) {
  return "NoPass/NoPassIsEnabled?username=".concat(username);
};
export var NoPassLoginStartUrl = 'auth/NoPassLoginStart';
export var NoPassLoginFinishUrl = 'auth/NoPassLoginFinish';
export var NoPassDeleteDeviceURL = function NoPassDeleteDeviceURL(deviceid) {
  return "NoPass/NoPassDeleteDevice?deviceid=".concat(deviceid);
};
export var NoPassListDevicesURL = 'NoPass/NoPassListDevices';
export var GetLoginHistory = 'User/GetLoginHistory';