import React, { useEffect } from 'react';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
import {
  FormGroup,
  AddIcon,
  LocalizeButton as Button,
  TextboxField,
  AddressField,
  Text,
  CheckboxField,
} from '@tradingblock/components';
import { AllStates, getGroupedCountryOptions } from '@tradingblock/types';
import { ApplicationStepProps } from '../../../../types';
import { DateField } from '../../../fields';

interface OwnersOfficersFieldsProps extends Pick<ApplicationStepProps, 'values' | 'setValues' | 'getText'> {
  max: number;
}

export const OwnersOfficersFields: React.FC<OwnersOfficersFieldsProps> = ({ values, setValues, getText, max }) => {
  const total = _.size(values.ownersOfficers);

  return (
    <FieldArray
      name="ownersOfficers"
      render={arrHelpers => (
        <>
          {_.map(values.ownersOfficers, (o, i) => (
            <div key={`ownerOfficer${i}`} className="form-group-removable form-group-removable-titled">
              <h2 className="fields-title">{getText('ownersOfficers.name', undefined, { data: { number: i + 1 } })}</h2>

              <FormGroup>
                <Field component={TextboxField} id={`ownersOfficers[${i}].firstName`} defaultLabelId="firstName" />
              </FormGroup>
              <div className="remove">
                <Button type="remove" onClick={() => arrHelpers.remove(i)} tabIndex={-1}>
                  <i className="fal fa-lg fa-times-circle" />
                </Button>
              </div>
              <FormGroup>
                <Field component={TextboxField} id={`ownersOfficers[${i}].lastName`} defaultLabelId="lastName" />
              </FormGroup>
              <FormGroup>
                <Field component={DateField} id={`ownersOfficers[${i}].dateOfBirth`} defaultLabelId="dateOfBirth" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id={`ownersOfficers[${i}].ssn`}
                  label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
                  type="password"
                  allowUnmask={true}
                />
              </FormGroup>
              <Field
                component={AddressField}
                id={`ownersOfficers[${i}].address`}
                countryProps={{
                  options: getGroupedCountryOptions(),
                  defaultLabelId: 'address.country',
                  optionsDefaultLabelId: 'country',
                }}
                stateProps={{ options: AllStates, defaultLabelId: 'address.state', optionsDefaultLabelId: 'state' }}
              />
              <FormGroup>
                <Field component={CheckboxField} id={`ownersOfficers[${i}].isOwner`} />
              </FormGroup>
              <FormGroup>
                <Field component={CheckboxField} id={`ownersOfficers[${i}].isOfficer`} />
              </FormGroup>
            </div>
          ))}
          {total < max && (
            <p>
              <button
                onClick={e => {
                  e.currentTarget.blur();
                  e.preventDefault();
                  arrHelpers.push({});
                }}
                type="button"
              >
                <AddIcon />
                <strong>{getText('ownersOfficers.add')}</strong>
              </button>{' '}
              (<Text id={`available-counts.${total > 0 ? 'partial' : 'all'}`} data={{ max, left: max - total }} />)
            </p>
          )}
        </>
      )}
    />
  );
};
