import React from 'react';
import { PersonType, PersonModel, DefaultPersonDetails } from '../../types';
import { FieldValue, AddressValue } from './';

const Person: React.FC<{ value: PersonModel | null | undefined; personType: PersonType }> = ({
  value,
  personType,
  children,
}) => {
  const person = value || DefaultPersonDetails;
  return (
    <FieldValue value={!!person.firstName && !!person.lastName}>
      <FieldValue
        value={`${person.firstName || ''} ${person.middleInitial || ''} ${person.lastName || ''} ${person.suffix}`}
      />
      <br />

      {personType === PersonType.AccountHolder && <FieldValue value={person.email}>{person.email}</FieldValue>}

      {personType !== PersonType.AccountHolder && <AddressValue value={person.address} />}

      <br />
      {children}
    </FieldValue>
  );
};

export const PersonValue = Person;
