export var SizeType;

(function (SizeType) {
  SizeType["lg"] = "lg";
  SizeType["md"] = "md";
  SizeType["sm"] = "sm";
  SizeType["xs"] = "xs";
  SizeType["xxs"] = "xxs";
})(SizeType || (SizeType = {}));

export var DefaultGridLayout = {
  breakpoint: SizeType.lg,
  columns: 6,
  gridColumnsSmall: 2,
  gridColumnsMedium: 4,
  gridColumnsLarge: 6,
  width: 1200
};