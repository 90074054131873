import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import React from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { CheckboxField } from './';
import { Text } from '../Text';
export var CheckListField = function CheckListField(_ref) {
  var form = _ref.form,
      id = _ref.id,
      type = _ref.type,
      options = _ref.options,
      className = _ref.className,
      renderOption = _ref.renderOption,
      onchange = _ref.onchange;
  var values = form.values,
      errors = form.errors,
      touched = form.touched,
      submitCount = form.submitCount;
  var value = _.get(values, id) || [];
  var error = _.get(touched, id) ? _.get(errors, id) : undefined;

  var onChange = function onChange(val) {
    var newValues = _.includes(value, val) ? _.filter(value, function (v) {
      return v !== val;
    }) : [].concat(_toConsumableArray(value), [val]);
    var newValue = type === 'radio' ? val : newValues;

    if (onchange) {
      onchange(undefined, newValue);
    } else {
      form.setFieldValue(id, newValue);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement("ol", {
    className: "checklist checklist-icons ".concat(className)
  }, options.map(function (o, i) {
    return React.createElement("li", {
      key: "checklist-".concat(i)
    }, React.createElement(Field, {
      component: CheckboxField,
      id: id,
      type: type,
      value: o.value,
      label: renderOption ? renderOption(o) : React.createElement(Text, {
        id: "".concat(id, ".").concat(o.value),
        type: "field"
      }),
      icon: o.icon,
      onSetValue: function onSetValue() {
        return onChange(o.value);
      }
    }));
  })), error && !!submitCount && React.createElement("span", {
    className: "error"
  }, React.createElement(Text, {
    id: id,
    textKey: 'error',
    type: 'error'
  })));
};