import React, { useMemo } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import _ from 'lodash';
import { Text, useLocalize } from '@tradingblock/components';
import { DataValue } from '../../types';

interface FieldProps extends LocalizeContextProps {
  value: DataValue;
  textPrefix?: string;
  errorPrefix?: JSX.Element;
}

const Field: React.FC<FieldProps> = ({ value, textPrefix, errorPrefix, translate, children }) => {
  const { getTranslationId } = useLocalize();

  const isValid = useMemo(() => {
    return _.isNumber(value) || (!!value && (!_.isString(value) || !!_.trim(value)));
  }, [value]);

  const incompleteText = useMemo(() => {
    // only need incomplete text if not valid
    if (!isValid) {
      const text = translate(getTranslationId({ id: 'incomplete', page: 'summary' }));
      return _.isString(text) ? text : '[incomplete]';
    }
    return undefined;
  }, [isValid, translate, getTranslationId]);

  // show 'incomplete' text if not valid
  if (!isValid) {
    return (
      <strong className="error">
        {errorPrefix} {incompleteText}
      </strong>
    );
  }
  // use custom content if children passed in
  if (children) {
    return <>{children}</>;
  }
  // use language text if prefix passed in
  if (textPrefix) {
    return <Text id={`${textPrefix}.${value}`} type="field" />;
  }
  // default to raw value
  return <>{value}</>;
};

export const FieldValue = withLocalize(Field);
