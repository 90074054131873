import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Section, Text, Footer, LoadingImage, Svg, ExternalLink } from '@tradingblock/components';
import { PageTitle } from '../components';
import { ApplicationLoader } from '../components/application';
import { useData } from '../state/selectors';
import { RetirementAccountType } from '../types';
import config from '../config';

const OfflinePage: React.FC = () => {
  const application = useData(d => d.data.application);
  const isCoverDell =
    application &&
    application.retirementAccountType &&
    application.retirementAccountType === RetirementAccountType.CoverDell;
  const siteGrp = process.env.REACT_APP_TB_SITE_GROUP;
  let urlPrefix = siteGrp === 'tb' ? 'https://www.tradingblock.com' : 'https://www.moneyblock.com';
  let url;

  if (isCoverDell) {
    url = `${urlPrefix}/Admin/Forms/Apex/New_Accounts/Coverdell_Account_Application.pdf`;
  }

  const [isSaving, setIsSaving] = useState<boolean>();
  const onSaveClick = () => {
    setIsSaving(true);

    // send application via api...

    window.setTimeout(() => {
      setIsSaving(false);
    }, 1500);
  };

  if (isSaving === false) {
    return <Redirect to="/thanks" />;
  }

  return (
    <ApplicationLoader>
      <PageTitle page="offline" />
      <Section>
        <div className="step-title">
          <h1>
            <Text id="step-title" page="offline" />
          </h1>
        </div>
      </Section>
      <Section>
        <h2 className="fields-title">
          <Text id="content" page="offline" />
        </h2>
        <p>
          <Text id="content" page="offline" textKey="description" />
        </p>
        <p>
          <ExternalLink href={url || '/download'}>
            <Text id="content.downloadLink" page="offline" />
          </ExternalLink>
        </p>
      </Section>
      <Footer
        primaryPanel={
          <button
            className="btn btn-block btn-primary btn-icon"
            type="button"
            disabled={!!isSaving}
            onClick={onSaveClick}
          >
            {isSaving ? <LoadingImage /> : <Text id="btns.continue" />}{' '}
            <span className="tb-icon">
              <Svg path="icon-arrow" />
            </span>
          </button>
        }
      />
    </ApplicationLoader>
  );
};

export default OfflinePage;
