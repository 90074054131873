import {
  TradeGoalType,
  MonetaryRangeType,
  IncomeRangeType,
  ExperienceYearType,
  FlexibilityType,
  BooleanToggleType,
  InvestmentObjectiveType,
} from './Enums';
import {
  ApplicationModel,
  AccountHolderModel,
  ForeignDueDiligenceModel,
  OwnerEntityModel,
  UploadModel,
  ExperienceKey,
  PersonDetailsModel,
  BeneficiaryModel,
} from './Models';

export type SaveType = 'load' | 'partial' | 'continue' | 'confirm-data-mismatch' | 'complete' | 'unload';

export type MismatchedDataRuleKey = keyof Pick<
  ApplicationModel,
  'annualIncome' | 'flexibilityType' | 'retirementAccount' | 'totalNetWorth'
>;

export type RequiredDataRuleKey = keyof Pick<ApplicationModel, 'experience' | 'investmentObjectiveType'>;

export type MismatchedDataRules = {
  [key in MismatchedDataRuleKey]?: string[];
};

export type MismatchedDataRuleSet = {
  [key in TradeGoalType]?: MismatchedDataRules;
};

export type RequiredDataRules = {
  experience?: RequiredExperienceDataRules;
  investmentObjectiveType?: string;
};

export type RequiredExperienceDataRules = {
  [key in ExperienceKey]?: string[];
};

export type RequiredDataRuleSet = {
  [key in TradeGoalType]?: RequiredDataRules;
};

export const MismatchedDataRulesForTradeGoalTypes: MismatchedDataRuleSet = {
  [TradeGoalType.AllAbovePlusPutWriting]: {
    annualIncome: [IncomeRangeType.LessThan25K, IncomeRangeType.Between25KAnd50K],
    totalNetWorth: [MonetaryRangeType.LessThan50K],
  },
  [TradeGoalType.AllAbovePlusAllOptionStrategies]: {
    annualIncome: [IncomeRangeType.LessThan25K, IncomeRangeType.Between25KAnd50K, IncomeRangeType.Between50KAnd100K],
    flexibilityType: [FlexibilityType.CashOnly],
    retirementAccount: [BooleanToggleType.Yes],
    totalNetWorth: [MonetaryRangeType.LessThan50K, MonetaryRangeType.Between50KAnd100K],
  },
};

export const MismatchedDataRulesForTradeGoalTypesRetirementAccount: MismatchedDataRuleSet = {
  [TradeGoalType.AllAbovePlusOptionSpreads]: {
    flexibilityType: [FlexibilityType.CashOnly],
  },
  [TradeGoalType.AllAbovePlusPutWriting]: {
    flexibilityType: [FlexibilityType.CashOnly],
    annualIncome: [IncomeRangeType.LessThan25K, IncomeRangeType.Between25KAnd50K],
    totalNetWorth: [MonetaryRangeType.LessThan50K],
  },
};

export const RequiredDataRulesForTradeGoalTypes: RequiredDataRuleSet = {
  [TradeGoalType.AllAbovePlusOptionSpreads]: {
    experience: {
      options: [
        ExperienceYearType.One,
        ExperienceYearType.TwoToThree,
        ExperienceYearType.FourToNine,
        ExperienceYearType.TenOrMore,
      ],
    },
  },
  [TradeGoalType.AllAbovePlusPutWriting]: {
    experience: {
      options: [
        ExperienceYearType.One,
        ExperienceYearType.TwoToThree,
        ExperienceYearType.FourToNine,
        ExperienceYearType.TenOrMore,
      ],
    },
  },
  [TradeGoalType.AllAbovePlusAllOptionStrategies]: {
    experience: {
      options: [ExperienceYearType.TwoToThree, ExperienceYearType.FourToNine, ExperienceYearType.TenOrMore],
    },
    investmentObjectiveType: InvestmentObjectiveType.Speculation,
  },
};

export type MismatchedDataType = 'investing-profile';

export interface MismatchedDataModalState {
  type: MismatchedDataType;
}

type FieldsToStripType = {
  root: (keyof Pick<
    ApplicationModel,
    | '_id'
    | 'objectKey'
    | 'createdOn'
    | 'hearAboutUsId'
    | 'repId'
    | 'userName'
    | 'password'
    | 'passwordConfirm'
    | 'securityChallenges'
    | 'retirementAccount'
    | 'addTrustedContact'
    | 'agreeToElectronicConsent'
    | 'latestStepSaved'
    | 'latestStepCompleted'
    | 'latestStepVisibleSections'
    | 'lastSavedOn'
    | 'applicationSentOn'
    | 'accountId'
    | 'confirmedMismatchedDataTypes'
    | 'tradeAuthorization'
  >)[];
  accountHolder: (keyof Pick<AccountHolderModel, 'emailConfirm' | 'differentMailingAddress'>)[];
  person: (keyof Pick<PersonDetailsModel, 'ssn'>)[];
  beneficiary: (keyof Pick<BeneficiaryModel, 'ein'>)[];
  entity: (keyof Pick<OwnerEntityModel, 'hasTradingBlockAccounts'>)[];
  upload: (keyof Pick<UploadModel, 'documents' | 'skip'>)[];
  trustBeneficiary: (keyof Pick<BeneficiaryModel, 'dateOfBirth' | 'phones' | 'ein'>)[];
};

export const FieldsToStrip: FieldsToStripType = {
  root: [
    '_id',
    'objectKey',
    'createdOn',
    'hearAboutUsId',
    'repId',
    'userName',
    'password',
    'passwordConfirm',
    'securityChallenges',
    'retirementAccount',
    'addTrustedContact',
    'agreeToElectronicConsent',
    'latestStepSaved',
    'latestStepCompleted',
    'latestStepVisibleSections',
    'lastSavedOn',
    'applicationSentOn',
    'accountId',
    'confirmedMismatchedDataTypes',
    'tradeAuthorization',
  ],
  accountHolder: ['emailConfirm', 'differentMailingAddress'],
  person: ['ssn'],
  beneficiary: ['ein'],
  trustBeneficiary: ['dateOfBirth', 'phones', 'ein'],
  entity: ['hasTradingBlockAccounts'],
  upload: ['documents', 'skip'],
};
