import { useEffect } from 'react';
import { GetUrlParams } from '@tradingblock/api';
import { useDispatcher } from '../components/hooks';
import { UiActions } from '../state/actions';
import { useData } from '../state/selectors';

export const useClearer = () => {
  const dispatch = useDispatcher();
  const params = GetUrlParams(window.location.search);
  const clearer = useData(d => d.ui.clearer);
  useEffect(() => {
    const clearer = params['clearer'];
    if (clearer && clearer !== 'undefined') {
      // if arriving with clearer param, store in state
      dispatch(UiActions.setClearer(clearer));
    }
  }, [params, clearer, dispatch]);
};
