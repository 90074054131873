import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { DateModel } from '../../types';
import { isDateFilled, getDatePartOptions } from '../../services';
import {
  BaseFieldProps,
  FloatingLabelWrapper,
  FloatingLabelState,
  SelectDropdownField,
  Text,
} from '@tradingblock/components';

interface DateFieldProps extends BaseFieldProps {}

export const DateField: React.FC<DateFieldProps> = ({
  form,
  id,
  disabled,
  defaultLabelId,
  controlledLabel,
  onLabelStateChange,
}) => {
  const { values, touched, errors, submitCount } = form;
  const value = useMemo(() => _.get(values, id), [values, id]);
  const error = _.get(touched, id) ? _.pickBy(errors, (err, key) => err && _.startsWith(key, id)) : undefined;
  const forPastDates = useMemo(() => _.endsWith(id, 'dateOfBirth') || _.endsWith(id, 'CreatedDate'), [id]);
  const [floatingLabelState, setFloatingLabelState] = useState<FloatingLabelState>({ isFilled: isDateFilled(value) });
  const updateFloatingLabelState = useCallback(
    (state: FloatingLabelState) => {
      setFloatingLabelState(state);
      if (onLabelStateChange) {
        onLabelStateChange(state);
      }
    },
    [onLabelStateChange]
  );
  useEffect(() => {
    if (!controlledLabel) {
      updateFloatingLabelState({ isFilled: isDateFilled(value), hasFocus: floatingLabelState.hasFocus });
    }
  }, [value, controlledLabel, floatingLabelState.hasFocus, updateFloatingLabelState]);
  const handleLabelStateChange = (state: FloatingLabelState) => {
    updateFloatingLabelState({
      isFilled: isDateFilled(value),
      hasFocus: !_.isUndefined(state.hasFocus) ? state.hasFocus : floatingLabelState.hasFocus,
    });
  };
  const getFieldProps = (key: keyof DateModel) => {
    return {
      component: SelectDropdownField,
      id: `${id}.${key}`,
      options: getDatePartOptions(key, forPastDates),
      disabled,
      defaultLabelId: defaultLabelId && `${defaultLabelId}.${key}`,
      optionsDefaultLabelId: key,
      useRawOptions: key === 'day' || key === 'year',
      controlledLabel: true,
      onLabelStateChange: handleLabelStateChange,
    };
  };

  return (
    <>
      <FloatingLabelWrapper
        className={`flabel-multi ${!_.isEmpty(error) ? 'invalid' : ''}`}
        state={floatingLabelState}
        disabled={disabled}
      >
        <div className="row">
          <div className="col-5">
            <Field {...getFieldProps('month')} />
          </div>
          <div className="col-3">
            <Field {...getFieldProps('day')} prefix="/" />
          </div>
          <div className="col-3">
            <Field {...getFieldProps('year')} prefix="/" />
          </div>
        </div>
      </FloatingLabelWrapper>
      {!_.isEmpty(error) && !!submitCount && (
        <span className="error">
          <Text id={id} textKey={'error'} type={'error'} />
        </span>
      )}
    </>
  );
};
