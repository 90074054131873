import React from 'react';
export var Wrapper = function Wrapper(_ref) {
  var element = _ref.element,
      className = _ref.className,
      children = _ref.children;

  if (element) {
    return React.createElement(React.Fragment, null, React.createElement(element, {
      className: className
    }, children));
  } // no wrapper


  return React.createElement(React.Fragment, null, children);
};