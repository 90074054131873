import React from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { BeneficiaryType, SummaryStepProps } from '../../../../types';
import { FieldValue, DateValue, MaskedValue, AddressValue } from '../../../values';

interface BeneficiariesListProps extends Pick<SummaryStepProps, 'application' | 'getText' | 'activeLanguage'> {}

export const BeneficiariesList: React.FC<BeneficiariesListProps> = ({ application, getText, activeLanguage }) => {
  return (
    <>
      {_.isEmpty(application && application.beneficiaries) && (
        <p>
          <Text id="skipped" />
        </p>
      )}
      {_.map(application && application.beneficiaries, (b, i) => (
        <div key={`beneficiary${i}`}>
          <h3 className="fields-title" style={{ textDecoration: 'underline' }}>
            {getText('beneficiaries.name', undefined, { data: { number: i + 1 } })}
          </h3>
          <p>
            <strong>
              <FieldValue value={b.beneficiaryAccountType} textPrefix="beneficiaries.beneficiaryAccountType" /> (
              <FieldValue value={b.beneficiaryType} textPrefix="beneficiaries.beneficiaryType" />)
            </strong>
            {b.beneficiaryType === BeneficiaryType.Individual && (
              <>
                <br />
                <FieldValue value={b.relationship} />
                <br />
                <FieldValue
                  value={
                    (b.firstName || b.middleInitial || b.lastName || b.suffix) &&
                    `${b.firstName || ''} ${b.middleInitial || ''} ${b.lastName || ''} ${b.suffix || ''}`
                  }
                />
                <br />
                {getText('dateOfBirth', undefined, { defaultPage: true })}:{' '}
                <DateValue value={b.dateOfBirth} activeLanguage={activeLanguage} />
                <br />
                {getText('ssn', undefined, { defaultPage: true, data: { itin: <Text id="itin.label" /> } })}:{' '}
                <MaskedValue value={b.ssn} />
              </>
            )}
            {b.beneficiaryType === BeneficiaryType.Trust && (
              <>
                <br />
                {getText('beneficiaries.trustName')}: <FieldValue value={b.trustName} />
                <br />
                {getText('beneficiaries.ein')}: <MaskedValue value={b.ein} />
              </>
            )}
            <br />
            <strong>{getText('beneficiaries.address')}</strong>
            <br />
            <AddressValue value={b.address} />
            <br />
            <FieldValue value={b.percent}>
              {getText('beneficiaries.percent', undefined, { data: { percent: b.percent } })}
            </FieldValue>
          </p>
        </div>
      ))}
    </>
  );
};
