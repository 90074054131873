import createSagaMiddleware from 'redux-saga';
import { applyMiddleware } from 'redux';
import { applicationMiddleware } from './applicationMiddleware';

// Create the middleware instance.
const sagaMiddleware = createSagaMiddleware();
export const setupMiddleware = () => {
  const middleware = applyMiddleware(...[sagaMiddleware, applicationMiddleware]);
  return {
    middleware,
    sagaMiddleware,
  };
};
