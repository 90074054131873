import React, { useMemo } from 'react';
import _ from 'lodash';
import { Text } from '../Text';
import { StepperInput } from '../../fields';
import { useLocalize } from '../../../hooks';
export var StepperField = function StepperField(_ref) {
  var field = _ref.field,
      form = _ref.form,
      id = _ref.id,
      options = _ref.options,
      defaultLabelId = _ref.defaultLabelId,
      disabledOptions = _ref.disabledOptions,
      onChanged = _ref.onChanged,
      onChangedAndSetValue = _ref.onChangedAndSetValue;
  var values = form.values,
      errors = form.errors,
      touched = form.touched,
      submitCount = form.submitCount,
      setFieldValue = form.setFieldValue;

  var _useLocalize = useLocalize(),
      getFieldTranslationId = _useLocalize.getFieldTranslationId;

  var value = _.get(values, id);

  var error = _.get(touched, id) ? _.get(errors, id) : undefined;
  var translationId = getFieldTranslationId({
    id: id,
    defaultLabelId: defaultLabelId
  });
  var buttonOptions = useMemo(function () {
    return _.map(options, function (o) {
      return {
        value: _.isString(o) ? o : o.value,
        label: _.isString(o) ? React.createElement(Text, {
          id: "".concat(translationId, ".").concat(o),
          type: "field"
        }) : o.label,
        disabled: _.isString(o) ? _.includes(disabledOptions, o) : !!o.disabled
      };
    });
  }, [options, translationId, disabledOptions]);

  var onChange = function onChange(_e, option) {
    if (onChanged && !onChangedAndSetValue) {
      onChanged(option);
    } else if (onChanged && onChangedAndSetValue) {
      onChanged(option);
      setFieldValue(id, option);
    } else {
      setFieldValue(id, option);
    }
  };

  return React.createElement(React.Fragment, null, React.createElement(StepperInput, {
    name: id,
    options: buttonOptions,
    value: value,
    error: error,
    onblur: field.onBlur,
    onchange: onChange
  }), error && !!submitCount && React.createElement("span", {
    className: "error"
  }, React.createElement(Text, {
    id: id,
    textKey: error,
    type: 'error'
  })));
};