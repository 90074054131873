import React from 'react';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
import { PhoneModel, MaxNumberOfPhones } from '../../types';
import { BaseFieldProps, Text, AddIcon } from '@tradingblock/components';
import { PhoneField } from './';

interface PhonesArrayFieldProps extends BaseFieldProps {
  value?: PhoneModel[];
}

export const PhonesArrayField: React.FC<PhonesArrayFieldProps> = ({ id, value }) => {
  const prefix = id ? `${id}.` : '';
  return (
    <FieldArray
      name={`${prefix}phones`}
      render={arrHelpers => (
        <>
          {_.map(value, (_p, i) => (
            <div key={`${prefix}phone${i}`}>
              <Field
                component={PhoneField}
                id={`${prefix}phones[${i}]`}
                defaultLabelId="phone"
                onRemove={_.size(value) > 1 ? () => arrHelpers.remove(i) : undefined}
              />
            </div>
          ))}
          {_.size(value) < MaxNumberOfPhones && (
            <p>
              <button
                onClick={e => {
                  e.preventDefault();
                  arrHelpers.push({});
                }}
                type="button"
              >
                <AddIcon />
                <strong>
                  <Text id="phones.add" />
                </strong>
              </button>
            </p>
          )}
        </>
      )}
    />
  );
};
