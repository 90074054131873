import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ApplicationState } from './types';
import { SiteIds, AllSiteIds } from '../types';

const uiRootSelector = (state: ApplicationState) => state.ui;
const dataRootSelector = (state: ApplicationState) => state.data;
const siteGrp = createSelector(
  uiRootSelector,
  ui => ui.siteGrp
);
const clearer = createSelector(
  uiRootSelector,
  ui => ui.clearer
);
export const data = {
  ui: {
    siteGrp,
    siteId: createSelector(
      siteGrp,
      grp => {
        if (_.isNil(grp)) {
          return SiteIds.tb;
        }
        const cleanId = _.toLower(grp).trim();
        if (AllSiteIds.includes(cleanId)) {
          return cleanId;
        }
        return SiteIds.tb;
      }
    ),
    clearer,
    siteGrpParam: createSelector(
      uiRootSelector,
      ui => (ui.siteGrp ? `?grp=${ui.siteGrp}` : '')
    ),
    apiToken: createSelector(
      uiRootSelector,
      ui => ui.apiToken
    ),
    apiTokenExpired: createSelector(
      uiRootSelector,
      ui => ui.apiTokenExpired
    ),
    storageToken: createSelector(
      uiRootSelector,
      ui => ui.storageToken
    ),
    storageTokenExpired: createSelector(
      uiRootSelector,
      ui => (ui.storageTokenExpired === true ? true : false)
    ),
    step: createSelector(
      uiRootSelector,
      ui => ui.step
    ),
    visibleSections: createSelector(
      uiRootSelector,
      ui => ui.visibleSections
    ),
    sectionInfo: createSelector(
      uiRootSelector,
      ui => ({
        visibleSections: ui.visibleSections,
        numberOfSections: ui.numberOfSections,
        scrollToSection: ui.scrollToSection,
      })
    ),
    nextPage: createSelector(
      uiRootSelector,
      ui => ui.nextPage
    ),
    isFetching: createSelector(
      uiRootSelector,
      ui => ui.isFetching
    ),
    userRequirements: createSelector(
      uiRootSelector,
      ui => ui.userRequirements
    ),
    heardAbout: createSelector(
      uiRootSelector,
      ui => ui.heardAbout
    ),
    affiliate: createSelector(
      uiRootSelector,
      ui => ui.affiliate
    ),
  },
  data: {
    status: createSelector(
      dataRootSelector,
      data => data.status
    ),
    isFetchingStatus: createSelector(
      dataRootSelector,
      data => data.isFetchingStatus
    ),
    application: createSelector(
      dataRootSelector,
      data => data.application
    ),
    saveInfo: createSelector(
      dataRootSelector,
      data => ({ isSaving: data.isSaving, saveType: data.saveType, saveStep: data.saveStep })
    ),
    createdUser: createSelector(
      dataRootSelector,
      data => data.createdUser
    ),
    uploadState: createSelector(
      dataRootSelector,
      data => data.uploadState
    ),
    createdAccount: createSelector(
      dataRootSelector,
      data => data.createdAccount
    ),
    createErrorCode: createSelector(
      dataRootSelector,
      data => data.errorCode
    ),
    createErrorMessages: createSelector(
      dataRootSelector,
      data => data.errorMessages
    ),
    duplicateAccountValidation: createSelector(
      dataRootSelector,
      data => data.duplicateAccountCheck
    ),
  },
};

type DataSelectors = typeof data;

export const useStateSelector = <T>(sel: (state: ApplicationState) => T) =>
  useSelector<ApplicationState, T>(sel, _.isEqual);
export const useData = <T>(sel: (selectors: DataSelectors) => (state: ApplicationState) => T) =>
  useSelector<ApplicationState, T>(sel(data), _.isEqual);
