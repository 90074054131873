import { DownloadsDailyConfirm, DownloadsDailyConfirms, DownloadsMonthlyStatement, DownloadsMonthlyStatements, DownloadsTaxDocument, DownloadsTaxDocuments } from '..';
import dayjs from 'dayjs';
var RequestHeaders = {
  headers: {
    Accept: 'application/json'
  }
};
export function Downloads(api) {
  return {
    getDailyConfirms: function getDailyConfirms(accountId, startDate, endDate) {
      var startDateString = dayjs(startDate).format('YYYY-MM-DD');
      var endDateString = dayjs(endDate).format('YYYY-MM-DD');
      return api.authenticated().get(DownloadsDailyConfirms(accountId), {
        startDate: startDateString,
        endDate: endDateString
      }, RequestHeaders);
    },
    getDailyConfirm: function getDailyConfirm(accountId, date) {
      var requestedDate = dayjs(date).format('YYYY-MM-DD');
      return api.authenticated().getBinary(DownloadsDailyConfirm(accountId), {
        date: requestedDate
      }, RequestHeaders);
    },
    getMonthlyStatements: function getMonthlyStatements(accountId, startDate, endDate) {
      var startDateString = dayjs(startDate).format('YYYY-MM-DD');
      var endDateString = dayjs(endDate).format('YYYY-MM-DD');
      return api.authenticated().get(DownloadsMonthlyStatements(accountId), {
        startDate: startDateString,
        endDate: endDateString
      }, RequestHeaders);
    },
    getMonthlyStatement: function getMonthlyStatement(accountId, date) {
      var requestedDate = dayjs(date).format('YYYY-MM-DD');
      return api.authenticated().getBinary(DownloadsMonthlyStatement(accountId), {
        date: requestedDate
      }, RequestHeaders);
    },
    getTaxDocuments: function getTaxDocuments(accountId, form, taxYear) {
      return api.authenticated().get(DownloadsTaxDocuments(accountId, form), {
        taxYear: taxYear
      }, RequestHeaders);
    },
    getTaxDocument: function getTaxDocument(accountId, form, date) {
      return api.authenticated().getBinary(DownloadsTaxDocument(accountId, form), {
        date: date
      }, RequestHeaders);
    }
  };
}