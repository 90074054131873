import React from 'react';

var Blanket = function Blanket(props) {
  return React.createElement("div", Object.assign({
    css: {
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }
  }, props));
};

export var DropdownToggle = function DropdownToggle(_ref) {
  var children = _ref.children,
      isOpen = _ref.isOpen,
      target = _ref.target,
      onClose = _ref.onClose;
  return React.createElement(React.Fragment, null, target, isOpen ? React.createElement(React.Fragment, null, children) : null, isOpen ? React.createElement(Blanket, {
    onClick: onClose
  }) : null);
};