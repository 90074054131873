import { SecurityQuestionType } from '@tradingblock/types';

export enum AccountActivityType {
  ActiveTrading = 'ActiveTrading',
  LongTermInvesting = 'LongTermInvesting',
  ShortTermInvesting = 'ShortTermInvesting',
}

export enum AccountHolderType {
  Joint = 'joint',
  Primary = 'primary',
  Ugma = 'ugma',
}

export enum ApplicationStep {
  AccountType = 'account',
  Agreements = 'agreements',
  Beneficiaries = 'beneficiaries',
  ClearerType = 'clearerType',
  AccountInformation = 'information',
  InvestingProfile = 'investing',
  SecureAccount = 'secure',
  Sign = 'sign',
}

export enum ApplicationType {
  Entity = 'Entity',
  Individual = 'Individual',
  Joint = 'Joint',
  UGMA = 'Ugma',
}

export enum ClearerType {
  Apex = 'Apex',
  RQD = 'RQD',
}

export enum BeneficiaryAccountType {
  Contingent = 'Contingent',
  Primary = 'Primary',
}

export enum BeneficiaryType {
  Individual = 'Individual',
  Trust = 'Trust',
}

export enum BooleanToggleType {
  No = 'no',
  Yes = 'yes',
}

export enum IssuerDirectCommunicationType {
  Accept = 'Accept',
  Object = 'Object',
}

export enum ProSubscriptionType {
  Delayed = 'Delayed',
  RealTime = 'Realtime',
}

export enum BusinessNature {
  Agriculture = 'Agriculture',
  Communications = 'Communications',
  Construction = 'Construction',
  ElectricGas = 'ElectricGas',
  Finance = 'Finance',
  Fishing = 'Fishing',
  Forestry = 'Forestry',
  Insurance = 'Insurance',
  Manufacturing = 'Manufacturing',
  Mining = 'Mining',
  PublicAdministration = 'PublicAdministration',
  RealEstate = 'RealEstate',
  RetailTrade = 'RetailTrade',
  SanitaryServices = 'SanitaryServices',
  Services = 'Services',
  Transportation = 'Transportation',
  WholesaleTrade = 'WholesaleTrade',
}

export enum DeliveryType {
  Electronic = 'Electronic',
  Paper = 'Paper',
}

export enum EmploymentType {
  Employed = 'Employed',
  Retired = 'Retired',
  Student = 'Student',
  Unemployed = 'Unemployed',
}

export enum EntityAccountType {
  BusinessTrust = 'BusinessTrust',
  CCorporation = 'CCorporation',
  LlcCCorporation = 'LlcCCorporation',
  LlcSCorporation = 'LlcSCorporation',
  Partnership = 'Partnership',
  PersonalTrust = 'PersonalTrust',
  SCorporation = 'SCorporation',
  LlcPartnership = 'LlcPartnership',
}

export enum ErrorType {
  Amount = 'amount',
  Invalid = 'invalid',
  Mismatch = 'mismatch',
  Required = 'error',
  Contingent = 'contingent',
  InvalidLength = 'invalidLength',
  InvalidLegalName = 'invalidLegalName',
}

export enum ExperienceYearType {
  FourToNine = 'FourToNine',
  One = 'One',
  TenOrMore = 'TenOrMore',
  TwoToThree = 'TwoToThree',
  Zero = 'Zero',
}

export enum FlexibilityType {
  CashOnly = 'CashOnly',
  Margin = 'Margin',
}

export enum IncomeRangeType {
  Between100KAnd200K = 'Between100KAnd200K',
  Between200KAnd300K = 'Between200KAnd300K',
  Between25KAnd50K = 'Between25KAnd50K',
  Between300KAnd500K = 'Between300KAnd500K',
  Between500KAnd1Point2M = 'Between500KAnd1Point2M',
  Between50KAnd100K = 'Between50KAnd100K',
  LessThan25K = 'LessThan25K',
  MoreThan1Point2M = 'MoreThan1Point2M',
}

export enum InvestmentObjectiveType {
  CapitalPreservation = 'CapitalPreservation',
  Growth = 'Growth',
  GrowthAndIncome = 'GrowthAndIncome',
  Income = 'Income',
  Speculation = 'Speculation',
}

export enum JointAccountType {
  Community = 'Community',
  Survivor = 'Survivor',
  TenantsInCommon = 'TenantsInCommon',
  TenantsInEntirety = 'TenantsInEntirety',
}

export enum LiquidityNeedType {
  NotImportant = 'NotImportant',
  SomewhatImportant = 'SomewhatImportant',
  VeryImportant = 'VeryImportant',
}

export enum MaritalStatusType {
  Divorced = 'Divorced',
  Married = 'Married',
  Single = 'Single',
  Widowed = 'Widowed',
}

export enum MonetaryRangeType {
  Between100KAnd200K = 'Between100KAnd200K',
  Between1MAnd5M = 'Between1MAnd5M',
  Between200KAnd500K = 'Between200KAnd500K',
  Between500KAnd1M = 'Between500KAnd1M',
  Between50KAnd100K = 'Between50KAnd100K',
  LessThan50K = 'LessThan50K',
  MoreThan5M = 'MoreThan5M',
}

export enum PersonType {
  AccountHolder = 'account-holder',
  Beneficiary = 'beneficiary',
  OwnerOfficer = 'owner-officer',
  TrustedContact = 'trusted-contact',
  TradeAuthorization = 'trade-authorization',
}

export enum PhoneType {
  Home = 'Home',
  Mobile = 'Mobile',
  Work = 'Work',
}

export enum RetirementAccountType {
  Beneficiary = 'Beneficiary',
  CoverDell = 'CoverDell',
  Rollover = 'Rollover',
  Roth = 'Roth',
  SEP = 'SEP',
  Simple = 'Simple',
  Traditional = 'Traditional',
  RothBeneficiary = 'RothBeneficiary',
}

export enum RiskLevel {
  None = 0,
  VeryLow = 1,
  Low = 2,
  Moderate = 3,
  High = 4,
}

export enum RiskToleranceType {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export enum SourceOfFundsType {
  CorporateIncome = 'CorporateIncome',
  InvestmentCapital = 'InvestmentCapital',
  Other = 'Other',
}

export enum TaxbracketType {
  Bottom = 'Bottom',
  Middle = 'Middle',
  Top = 'Top',
}

export enum TimeHorizonType {
  Average = 'Average',
  Long = 'Long',
  Short = 'Short',
}

export enum TradeExperienceYearType {
  ElevenToTwenty = 'ElevenToTwenty',
  OneToFive = 'OneToFive',
  SixToTen = 'SixToTen',
  MoreThanTwenty = 'MoreThanTwenty',
}

export enum TradeGoalType {
  StocksBonds = 'StocksBonds',
  AllAbovePlusCallsPuts = 'AllAbovePlusCallsPuts',
  AllAbovePlusOptionSpreads = 'AllAbovePlusOptionSpreads',
  AllAbovePlusPutWriting = 'AllAbovePlusPutWriting',
  AllAbovePlusAllOptionStrategies = 'AllAbovePlusAllOptionStrategies',
}

export enum UnsupportedFeatureType {
  BearerShares = 'bearer-shares',
  Country = 'country',
  IrsWithholding = 'irs-withholding',
}

export enum VisaType {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  F1 = 'F1',
  K1 = 'K1',
  G4 = 'G4',
  H1B = 'H1B',
  L1 = 'L1',
  O1 = 'O1',
  TN1 = 'TN1',
}

export enum WithdrawalFrequencyType {
  Frequent = 'Frequent',
  Occasional = 'Occasional',
  Rare = 'Rare',
}

export enum YearsEmployed {
  BetweenZeroAndOne = 'BetweenZeroAndOne',
  BetweenTwoAndFive = 'BetweenTwoAndFive',
  BetweenFiveAndTen = 'BetweenFiveAndTen',
  BetweenTenAndTwenty = 'BetweenTenAndTwenty',
  MoreThanTwenty = 'MoreThanTwenty',
}

export const AllAccountActivityTypes = [
  AccountActivityType.LongTermInvesting,
  AccountActivityType.ShortTermInvesting,
  AccountActivityType.ActiveTrading,
];

export const AllApplicationSteps = [
  ApplicationStep.SecureAccount,
  ApplicationStep.ClearerType,
  ApplicationStep.AccountType,
  ApplicationStep.InvestingProfile,
  ApplicationStep.AccountInformation,
  ApplicationStep.Beneficiaries,
  ApplicationStep.Agreements,
  ApplicationStep.Sign,
];

export const AllApplicationTypes = [
  ApplicationType.Individual,
  ApplicationType.Joint,
  ApplicationType.UGMA,
  ApplicationType.Entity,
];

export const AllBeneficiaryAccountTypes = [BeneficiaryAccountType.Primary, BeneficiaryAccountType.Contingent];

export const AllBeneficiaryTypes = [BeneficiaryType.Individual]; // Add back BeneficiaryType.Trust once RQD/Apex support via API

export const AllBooleanToggleTypes = [BooleanToggleType.Yes, BooleanToggleType.No];

export const AllProSubscriptionTypes = [ProSubscriptionType.Delayed, ProSubscriptionType.RealTime];

export const AllIssuerDirectCommunicationTypes = [
  IssuerDirectCommunicationType.Accept,
  IssuerDirectCommunicationType.Object,
];

export const AllBusinessNatureTypes = [
  BusinessNature.Agriculture,
  BusinessNature.Communications,
  BusinessNature.Construction,
  BusinessNature.ElectricGas,
  BusinessNature.Finance,
  BusinessNature.Fishing,
  BusinessNature.Forestry,
  BusinessNature.Insurance,
  BusinessNature.Manufacturing,
  BusinessNature.Mining,
  BusinessNature.PublicAdministration,
  BusinessNature.RealEstate,
  BusinessNature.RetailTrade,
  BusinessNature.SanitaryServices,
  BusinessNature.Services,
  BusinessNature.Transportation,
  BusinessNature.WholesaleTrade,
];

export const AllClearerTypes = [ClearerType.Apex, ClearerType.RQD];

export const AllDeliveryTypes = [DeliveryType.Electronic, DeliveryType.Paper];

export const AllEmploymentTypes = [
  EmploymentType.Employed,
  EmploymentType.Retired,
  EmploymentType.Student,
  EmploymentType.Unemployed,
];

export const AllEntityTypes = [
  EntityAccountType.PersonalTrust,
  EntityAccountType.BusinessTrust,
  EntityAccountType.CCorporation,
  EntityAccountType.SCorporation,
  EntityAccountType.LlcCCorporation,
  EntityAccountType.LlcSCorporation,
  EntityAccountType.Partnership,
  EntityAccountType.LlcPartnership,
];

export const AllExperienceYearTypes = [
  ExperienceYearType.Zero,
  ExperienceYearType.One,
  ExperienceYearType.TwoToThree,
  ExperienceYearType.FourToNine,
  ExperienceYearType.TenOrMore,
];

export const AllFlexibilityTypes = [FlexibilityType.Margin, FlexibilityType.CashOnly];

export const AllIncomeRangeTypes = [
  IncomeRangeType.LessThan25K,
  IncomeRangeType.Between25KAnd50K,
  IncomeRangeType.Between50KAnd100K,
  IncomeRangeType.Between100KAnd200K,
  IncomeRangeType.Between200KAnd300K,
  IncomeRangeType.Between300KAnd500K,
  IncomeRangeType.Between500KAnd1Point2M,
  IncomeRangeType.MoreThan1Point2M,
];

export const AllInvestmentObjectiveTypes = [
  InvestmentObjectiveType.CapitalPreservation,
  InvestmentObjectiveType.Income,
  InvestmentObjectiveType.Growth,
  InvestmentObjectiveType.GrowthAndIncome,
  InvestmentObjectiveType.Speculation,
];

export const AllJointAccountTypes = [
  JointAccountType.Community,
  JointAccountType.Survivor,
  JointAccountType.TenantsInCommon,
  JointAccountType.TenantsInEntirety,
];

export const AllLiquidityNeedTypes = [
  LiquidityNeedType.VeryImportant,
  LiquidityNeedType.SomewhatImportant,
  LiquidityNeedType.NotImportant,
];

export const AllMaritalStatusTypes = [
  MaritalStatusType.Single,
  MaritalStatusType.Married,
  MaritalStatusType.Divorced,
  MaritalStatusType.Widowed,
];

export const AllMonetaryRangeTypes = [
  MonetaryRangeType.LessThan50K,
  MonetaryRangeType.Between50KAnd100K,
  MonetaryRangeType.Between100KAnd200K,
  MonetaryRangeType.Between200KAnd500K,
  MonetaryRangeType.Between500KAnd1M,
  MonetaryRangeType.Between1MAnd5M,
  MonetaryRangeType.MoreThan5M,
];

export const AllPhoneTypes = [PhoneType.Home, PhoneType.Work, PhoneType.Mobile];

export const AllRetirementAccountTypes = [
  RetirementAccountType.Traditional,
  RetirementAccountType.Rollover,
  RetirementAccountType.Roth,
  RetirementAccountType.SEP,
  RetirementAccountType.Simple,
  RetirementAccountType.Beneficiary,
  RetirementAccountType.CoverDell,
  RetirementAccountType.RothBeneficiary,
];

export const RQDRetirementAccountTypes = [
  RetirementAccountType.Traditional,
  RetirementAccountType.Roth,
  RetirementAccountType.SEP,
];

export const AllRiskToleranceTypes = [RiskToleranceType.Low, RiskToleranceType.Medium, RiskToleranceType.High];

export const AllSecurityQuestionTypes = [
  SecurityQuestionType.ChildhoodPetName,
  SecurityQuestionType.MothersMaidenName,
  SecurityQuestionType.BirthCity,
  SecurityQuestionType.FavoriteSportsTeam,
  SecurityQuestionType.PaternalGrandmothersFirstName,
  SecurityQuestionType.ChildhoodFutureJobWish,
  SecurityQuestionType.FavoriteBook,
  SecurityQuestionType.DriversLicenseLast4,
  SecurityQuestionType.FathersMiddleName,
];

export const AllSourceOfFundsTypes = [
  SourceOfFundsType.CorporateIncome,
  SourceOfFundsType.InvestmentCapital,
  SourceOfFundsType.Other,
];

export const AllTaxbracketTypes = [TaxbracketType.Bottom, TaxbracketType.Middle, TaxbracketType.Top];

export const AllTimeHorizonTypes = [TimeHorizonType.Short, TimeHorizonType.Average, TimeHorizonType.Long];

export const AllTradeExperienceYearTypes = [
  TradeExperienceYearType.OneToFive,
  TradeExperienceYearType.SixToTen,
  TradeExperienceYearType.ElevenToTwenty,
  TradeExperienceYearType.MoreThanTwenty,
];

export const AllTradeGoalTypes = [
  TradeGoalType.StocksBonds,
  TradeGoalType.AllAbovePlusCallsPuts,
  TradeGoalType.AllAbovePlusOptionSpreads,
  TradeGoalType.AllAbovePlusPutWriting,
  TradeGoalType.AllAbovePlusAllOptionStrategies,
];

export const AllVisaTypes = [
  VisaType.E1,
  VisaType.E2,
  VisaType.E3,
  VisaType.F1,
  VisaType.K1,
  VisaType.G4,
  VisaType.H1B,
  VisaType.L1,
  VisaType.O1,
  VisaType.TN1,
];

export const AllWithdrawalFrequencyTypes = [
  WithdrawalFrequencyType.Rare,
  WithdrawalFrequencyType.Occasional,
  WithdrawalFrequencyType.Frequent,
];

export const AllYearsEmployed = [
  YearsEmployed.BetweenZeroAndOne,
  YearsEmployed.BetweenTwoAndFive,
  YearsEmployed.BetweenFiveAndTen,
  YearsEmployed.BetweenTenAndTwenty,
  YearsEmployed.MoreThanTwenty,
];
