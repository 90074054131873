import React from 'react';
import { FieldValue } from './';

const Masked: React.FC<{ value: string | undefined }> = ({ value }) => {
  const masked = value || '';
  const prefix = 'xxx-xx-'; // could potentially pass in as prop or determine from value length...
  // reveal last 4 chars
  const unmaskedIdx = masked.length - 4;
  return (
    <FieldValue value={masked}>
      {unmaskedIdx > 0 && `${prefix}${masked.substr(unmaskedIdx)}`}
    </FieldValue>
  );
}

export const MaskedValue = Masked;
