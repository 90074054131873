import localforage from 'localforage';
export var LocalStorageProvider = function LocalStorageProvider(_ref) {
  var prefix = _ref.prefix;

  var keyVal = function keyVal(key) {
    return prefix === undefined ? key : "".concat(prefix, ".").concat(key);
  };

  var get = function get(key) {
    return localforage.getItem(keyVal(key));
  };

  var save = function save(key, value) {
    return localforage.setItem(keyVal(key), value);
  };

  var log = function log(value) {
    return Promise.resolve(false);
  };

  var del = function del(key) {
    return localforage.removeItem(keyVal(key));
  };

  var providerObj = {
    kind: 'localStorage',
    update: save,
    save: save,
    get: get,
    delete: del,
    log: log
  };
  return providerObj;
};