import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ApplicationModel } from '../types';

export const ErrorScrollWrapper: React.FunctionComponent<{}> = props => {
  const { children } = props;
  const context = useFormikContext<ApplicationModel>();

  useEffect(() => {
    if (context.isSubmitting && !context.isValidating) {
      let keys = Object.keys(context.errors);
      if (keys.length > 0) {
        const selector = '.error';
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [context.errors, context.isSubmitting, context.isValidating]);

  return (
    <>
      <div>{children}</div>
    </>
  );
};
