import React from 'react';
export var Footer = function Footer(_ref) {
  var primaryPanel = _ref.primaryPanel,
      secondaryPanel = _ref.secondaryPanel;
  return React.createElement("section", {
    className: "form-footer"
  }, React.createElement("div", {
    className: "form-content"
  }, React.createElement("div", {
    className: "form-footer-panel"
  }, secondaryPanel), React.createElement("div", {
    className: "form-footer-panel"
  }, primaryPanel)));
};