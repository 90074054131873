import React from 'react';
export var Header = function Header(_ref) {
  var children = _ref.children;
  return React.createElement("header", {
    className: "header"
  }, children);
};
export var SubHeader = function SubHeader(_ref2) {
  var onClick = _ref2.onClick,
      children = _ref2.children;
  return React.createElement("div", {
    className: "subheader",
    onClick: onClick
  }, React.createElement("div", {
    className: "content"
  }, children));
};