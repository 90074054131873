export var DefaultAccountDetails = {
  accountNames: [],
  accountNumber: undefined,
  accountStatus: undefined,
  accountType: undefined,
  repCode: undefined,
  repFirstName: undefined,
  repLastName: undefined,
  repPhoneNumber: undefined,
  mailingAddress: {
    address1: undefined,
    address2: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    country: undefined
  },
  physicalAddress: {
    address1: undefined,
    address2: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    country: undefined
  },
  email: undefined,
  homePhone: undefined,
  mobilePhone: undefined,
  workPhone: undefined,
  lastChanged: undefined,
  commissions: {
    commissionCodeId: -1
  }
};
export var ClearerType;

(function (ClearerType) {
  ClearerType["RQD"] = "RQD";
  ClearerType["Apex"] = "Apex";
})(ClearerType || (ClearerType = {}));