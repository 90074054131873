import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Modal, Text, LoadingImage } from '@tradingblock/components';
import { MismatchedDataType } from '../../types';
import { useData } from '../../state/selectors';

interface MismatchedDataModalProps {
  type: MismatchedDataType;
  onEdit: () => void;
  onContinue: () => void;
  onClose: () => void;
}

export const MismatchedDataModal: React.FC<MismatchedDataModalProps> = ({
  type,
  onEdit,
  onContinue,
  onClose,
  children,
}) => {
  const { isSaving } = useData(d => d.data.saveInfo);
  return (
    <Modal setShow={() => onClose()} show={true} backdrop="static">
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>
          <Text id="defaults.mismatchedDataModal.title" />
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>
          <Text id={`defaults.mismatchedDataModal.${type}`} />
        </p>
        {children}
        <p>
          <Text id="defaults.mismatchedDataModal" />
        </p>
        <p>
          <Text id="defaults.mismatchedDataModal.margin" />
        </p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <button type="button" className="btn btn-block btn-primary btn-icon" onClick={onEdit} disabled={!!isSaving}>
          <Text id="defaults.mismatchedDataModal.edit" />
        </button>
        {!isSaving && (
          <button type="button" onClick={onContinue} disabled={!!isSaving}>
            <Text id="defaults.mismatchedDataModal.continue" />
          </button>
        )}
        {isSaving && <LoadingImage />}
      </BootstrapModal.Footer>
    </Modal>
  );
};
