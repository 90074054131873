import React, { useMemo } from 'react';
import _ from 'lodash';
import { Text } from '@tradingblock/components';
import { SummaryStepProps, ApplicationType, BooleanToggleType } from '../../../types';
import { FieldValue } from '../../values';
import {
  PrimaryAccountHolderDisclosuresSummary,
  JointAccountHolderDisclosuresSummary,
} from './agreements/AccountHolderDisclosuresSummary';
import { AccountDisclosuresSummary } from './agreements/AccountDisclosuresSummary';
import { AccountAgreementsSummary } from './agreements/AccountAgreementsSummary';

export const AgreementsSummary: React.FC<SummaryStepProps> = props => {
  const { application, visibleSections, getText } = props;

  const sections = useMemo(() => {
    if (!application) {
      return [];
    }
    let allSections: React.FC<SummaryStepProps>[] = [];
    // primary account holder disclosures
    allSections = [...allSections, PrimaryAccountHolderDisclosuresSummary];
    if (application.type === ApplicationType.Joint) {
      // joint account holder disclosures
      allSections = [...allSections, JointAccountHolderDisclosuresSummary];
    }
    // account disclosures and agreements
    allSections = [...allSections, AccountDisclosuresSummary, AccountAgreementsSummary];

    return _.take(allSections, visibleSections || 1);
  }, [application, visibleSections]);

  return (
    <>
      <h3 className="fields-title">{getText('deliveryType')}</h3>
      <p>
        <FieldValue value={application && application.deliveryType} textPrefix="deliveryType" />
      </p>
      <h3 className="fields-title">{getText('tradeAuthorization')}</h3>
      <p>
        <FieldValue
          value={
            application && application.tradingAuthorization && !_.isEmpty(application.tradingAuthorization)
              ? BooleanToggleType.Yes
              : BooleanToggleType.No
          }
          textPrefix="defaults.boolean"
        />
      </p>
      {application && application.tradingAuthorization && !_.isEmpty(application.tradingAuthorization) && (
        <p>
          <Text id="tradeAuthorizationAgentName" type="field" />
          {': '}
          <FieldValue
            value={
              application &&
              `${application.tradingAuthorization.firstName} ${application.tradingAuthorization.lastName}`
            }
          />
        </p>
      )}
      {_.map(sections, (ChildSummary, index) => (
        <ChildSummary key={`summary-${index}`} {...props} />
      ))}
    </>
  );
};
