import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { Wrapper, Svg } from '../basic';
import { LocalizeButton } from './Button';
export var HelpIcon = function HelpIcon(_ref) {
  var iconSize = _ref.iconSize;
  return React.createElement("span", {
    className: "tb-icon ".concat(iconSize ? "tb-icon-".concat(iconSize) : '')
  }, React.createElement(Svg, {
    path: "icon-help"
  }));
};
export var HelpText = function HelpText(_ref2) {
  var help = _ref2.help,
      iconSize = _ref2.iconSize,
      wrapper = _ref2.wrapper,
      className = _ref2.className,
      children = _ref2.children;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showHelp = _useState2[0],
      setShowHelp = _useState2[1];

  var onClick = function onClick(e) {
    e.preventDefault();
    setShowHelp(!showHelp);
  };

  return React.createElement(React.Fragment, null, React.createElement(Wrapper, {
    element: wrapper || 'p',
    className: className
  }, children, ' ', React.createElement(LocalizeButton, {
    type: "info",
    onClick: onClick
  }, React.createElement(HelpIcon, {
    iconSize: iconSize
  }))), showHelp && React.createElement(Wrapper, {
    element: "p"
  }, React.createElement(HelpIcon, null), " ", help));
};