import React from 'react';
import _ from 'lodash';
import { ApplicationModel } from '../../types';
import { getTradeGoalsWithDataVerification, getTradeGoalsWithDataVerificationRetirementAccount } from '../../services';
import { TradeGoalText } from '.';

interface TradeGoalsVerificationProps {
  application: ApplicationModel | undefined;
}

export const TradeGoalsVerification: React.FC<TradeGoalsVerificationProps> = ({ application }) => {
  if (application && application.retirementAccount !== 'yes') {
    return (
      <ul>
        {_.map(getTradeGoalsWithDataVerification(application), ({ tradeGoalType, verified }, i) => (
          <li key={`tradeGoalType${i}`}>
            <i className={`fa ${verified ? 'fa-check valid' : 'fa-exclamation-circle error'}`} />{' '}
            <TradeGoalText type={tradeGoalType} application={application} />
          </li>
        ))}
      </ul>
    );
  } else if (application && application.retirementAccount === 'yes') {
    return (
      <ul>
        {_.map(getTradeGoalsWithDataVerificationRetirementAccount(application), ({ tradeGoalType, verified }, i) => (
          <li key={`tradeGoalType${i}`}>
            <i className={`fa ${verified ? 'fa-check valid' : 'fa-exclamation-circle error'}`} />{' '}
            <TradeGoalText type={tradeGoalType} application={application} />
          </li>
        ))}
      </ul>
    );
  }
  return null;
};
