import * as yup from 'yup';
import { BlockTypeValues } from '../enums';
import { LayoutSchemaFields } from './dashboard';
var BlockInfoSchemaFields = {
  blockId: yup.string().required(),
  groupId: yup.string().required()
};
var TabOptionsSchemaFields = {
  title: yup.string(),
  active: yup.boolean().notRequired()
};
var BlockSchemaFields = Object.assign({}, BlockInfoSchemaFields, {
  type: yup.mixed().oneOf(BlockTypeValues).required(),
  title: yup.string().notRequired(),
  subscriptions: yup.object({
    quotes: yup.array()
  }).notRequired(),
  tabs: yup.array().notRequired()
});
var BlockLayoutsSchemaFields = {
  lg: yup.object(LayoutSchemaFields).notRequired(),
  md: yup.object(LayoutSchemaFields).notRequired(),
  sm: yup.object(LayoutSchemaFields).notRequired(),
  xs: yup.object(LayoutSchemaFields).notRequired(),
  xxs: yup.object(LayoutSchemaFields).notRequired()
};
var BlockConfigurationSchemaFields = Object.assign({}, BlockSchemaFields, {
  layouts: yup.object(BlockLayoutsSchemaFields).notRequired(),
  data: yup.mixed().notRequired(),
  options: yup.object({
    autoFocus: yup.boolean().notRequired()
  }).notRequired()
});
var BlockGroupSchemaFields = {
  groupId: yup.string(),
  symbol: yup.mixed().notRequired()
};
export var BlockInfoSchema = yup.object(BlockInfoSchemaFields);
export var TabOptionsSchema = yup.object(TabOptionsSchemaFields);
export var BlockSchema = yup.object(BlockSchemaFields);
export var BlockLayoutsSchema = yup.object(BlockLayoutsSchemaFields);
export var BlockConfigurationSchema = yup.object(BlockConfigurationSchemaFields);
export var BlockGroupSchema = yup.object(BlockGroupSchemaFields); // export type BlockInfo = yup.InferType<typeof BlockInfoSchema>;
// export type TabOptions = yup.InferType<typeof TabOptionsSchema>;
// export type Block = yup.InferType<typeof BlockSchema>;
// export type BlockLayouts = yup.InferType<typeof BlockLayoutsSchema>;
// export type BlockConfiguration = yup.InferType<typeof BlockConfigurationSchema>;
// export type BlockGroup = yup.InferType<typeof BlockGroupSchema>;