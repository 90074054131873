import React from 'react';
import _ from 'lodash';
import { PhoneModel, DefaultPhone } from '../../types';
import { FieldValue } from './';

const Phone: React.FC<{ value: PhoneModel | null | undefined }> = ({ value }) => {
  const phone = value || DefaultPhone;
  return (
    <FieldValue value={phone.phoneNumber}>
      {phone.type ? (
        <>
          <FieldValue value={phone.type} textPrefix="defaults.phoneType" />:{' '}
        </>
      ) : (
        ''
      )}
      {phone.phoneNumber}
      {!_.isEqual(phone, {}) && <br />}
    </FieldValue>
  );
};

const Phones: React.FC<{ value: PhoneModel[] | undefined }> = ({ value }) => {
  const phones = value || [];
  return (
    <p>
      {phones.map((p, i) => (
        <Phone key={`phone${i}`} value={p} />
      ))}
    </p>
  );
};

export const PhoneValue = Phone;
export const PhonesValue = Phones;
